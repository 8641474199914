import { useQuery } from "@tanstack/react-query";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { countries } from "utils/api/countries";
import Role from "utils/enums/Role";
import { isOneOfRoles } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";

const ParentInfo = (props: { plan: any }) => {
  const { plan } = props;
  const { t } = useTranslation(["plans", "common"]);
  const { data: countriesData } = useQuery(["countries"], countries.list);
  const { data: user } = useUser();

  const attributes = [
    { label: t("name"), value: plan.user.name },
    { label: t("common:mobile"), value: plan.user.mobile },
    {
      label: t("common:email"),
      value: plan.user.email,
      condition: isOneOfRoles([Role.ADMIN, Role.FI, Role.USER], user),
    },
    {
      label: t("nationality"),
      value: countriesData
        ? countriesData?.find(
          (country: any) => country.id == plan.nationality_id
        )?.name
        : "",
      condition: isOneOfRoles([Role.ADMIN, Role.FI, Role.USER], user),
    },
    {
      label: t("personal_id"),
      value: plan.user.personal_id,
      condition: isOneOfRoles([Role.ADMIN], user),
    },
    {
      label: t("marital_status"),
      value: t("common:" + plan.marital_status?.toLowerCase()),
      condition: isOneOfRoles([Role.ADMIN, Role.FI, Role.USER], user),
    },
    {
      label: t("employment_status"),
      value: t("plans:" + plan.employment_type?.toLowerCase().replace(" ", "_")),
      condition: isOneOfRoles([Role.ADMIN, Role.FI, Role.USER], user),
    },
    {
      label: t("company"),
      value: plan.company,
      condition: isOneOfRoles([Role.ADMIN, Role.FI, Role.USER], user),
    },
    {
      label: t("work_experience"),
      value: plan.work_experience,
      condition: isOneOfRoles([Role.ADMIN, Role.FI, Role.USER], user),
    },
    {
      label: t("monthly_salary"),
      value: `${plan.salary} ${t("common:kd")}`,
      condition: isOneOfRoles([Role.ADMIN, Role.FI, Role.USER], user),
    },
    {
      label: t("monthly_loans"),
      value: `${plan.monthly_loans ?? 0} ${t("common:kd")}`,
      condition: isOneOfRoles([Role.ADMIN, Role.FI, Role.USER], user),
    },
  ];
  return (
    <Accordion defaultActiveKey="0" className="mb-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("plans:parent_details")}</Accordion.Header>
        <Accordion.Body>
          <Container>
            <Row>
              {attributes.map((element: any) => (
                <ParentInfoColumn key={element.label} {...element} />
              ))}
            </Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

function ParentInfoColumn(props: {
  label: string;
  value: any;
  condition?: boolean;
}) {
  const { label, value, condition = true } = props;
  if (!condition) return <></>;
  return (
    <Col md="4" className="mb-4">
      <div className="d-flex flex-column">
        <div className="text-gray">{label}</div>
        <div>{value}</div>
      </div>
    </Col>
  );
}

export default ParentInfo;
