import client from "../api";

export type Country = {
  id:string;
  code:string;
  created_at:string;
  dial_code:string;
  name:string;
  updated_at:string;
}

export function list():Promise<any> {
  return client.get("countries");
}

export const countries = { list };
