import { useQueryClient } from '@tanstack/react-query';
import RoleBasedRenderer from 'containers/common/RoleBasedRenderer';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { transactions } from 'utils/api/transactions';
import Role from 'utils/enums/Role';
import TransactionStatus from 'utils/enums/TransactionStatus';
import TransactionType from 'utils/enums/TransactionType';
import { isRole } from 'utils/functions/utilities';
import useAlert from 'utils/hooks/alert';
import { useUser } from 'utils/hooks/auth';


const ReferralProgress: React.FC<any> = ({ plan }) => {
  const { t } = useTranslation(["plans", "common"]);
  const { confirmOrDeny } = useAlert();
  const client = useQueryClient();

  // Default to an empty array if outgoingReferrals is undefined
  const outgoingReferrals: any[] = plan?.outgoingReferrals || [];

  // Ensure the array has 3 items
  const paddedReferrals: any[] = [...outgoingReferrals];
  while (paddedReferrals.length < 3) {
    paddedReferrals.push({ status: 'pending' });
  }

  // Function to handle copying the referral code
  const handleCopy = () => {
    if (plan?.referral_code) {
      navigator.clipboard.writeText(plan.referral_code);
      toast.success(t('common:copied_to_clipboard'));
    }
  };

  const serviceFeeTransaction = plan.transactions.find((t: any) => t.type === TransactionType.SERVICE_FEE);

  function refund() {
    confirmOrDeny({
      title: "Refunded?!",
      text: "Did you refund this transaction?",
      confirmButtonText: "Yes",
      showDenyButton: false,
      onConfirm: async () => {
        if (serviceFeeTransaction) {
          await transactions.refund(serviceFeeTransaction.id);
          client.invalidateQueries(["plan"]);
        } else {
          toast.error("Service fee transaction not found");
        }
      },
    });
  }

  return (
    <Alert variant="primary" className="mb-0">
      <div className='d-flex justify-content-between align-items-center'>
        <div className="d-flex align-items-center">
          <p onClick={handleCopy} className="mb-1">{t("plans:referral_code")}: <span className="fw-bold">{plan?.referral_code}</span> </p>
        </div>
        <div style={{ marginTop: '-3.6rem', marginRight: '-1rem' }}>
          <div className="d-flex justify-content-around">
            {paddedReferrals.map((item, index) => (
              <StarLink key={index} index={index} refereePlan={item} plan={plan} />
            ))}
          </div>
        </div>
      </div>
      <p className="fbs-4 text-gray">{t("plans:invite_using_referral_code")}</p>
      <RoleBasedRenderer admin={
        <div className='d-flex justify-content-end'>
          {serviceFeeTransaction?.status === TransactionStatus.SUCCESS &&
            <Button onClick={refund} variant="primary" className="mt-2">{t("plans:refund")}</Button>
          }
        </div>
      }
      />
    </Alert>
  );
};

const StarLink = (props: any) => {
  const { refereePlan } = props;
  const { data: user } = useUser();

  const starIcon = refereePlan.status === 'success' ?
    <FaStar style={{ fill: '#FFD700', strokeWidth: '8px' }} size={36} className="me-2 bounce" /> :
    <FaStar style={{ fill: '#a3a3a3', strokeWidth: '1px' }} size={36} className="me-2" />;

  if (isRole(Role.ADMIN, user) && refereePlan?.referee_plan_id) {
    return (
      <Link className='text-decoration-none' to={`/plans/${refereePlan?.referee_plan_id}`}>
        {starIcon}
      </Link>
    );
  } else {
    return (
      <div>
        {starIcon}
      </div>
    );
  }
}

export default ReferralProgress;
