import { useState } from "react";
import { Document, Page } from "react-pdf";

const PDFPreviewer = (props: any) => {
  const { file, closeModal } = props;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <div onClick={closeModal}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </div>
      </Document>
    </div>
  );
};

export default PDFPreviewer;
