import Role from "utils/enums/Role";
import { storage } from "../storage";
import client from "./api";
import Gender from "utils/enums/Gender";

export interface AuthResponse {
  user: User;
  jwt: {
    token: string;
  };
}

export interface User {
  id: string;
  role_id: Role;
  email: string;
  mobile: string;
  name: string;
  country_id: string;
  marital_status:string;
  language:string;
  birthday:string;
  nationality_id:number;
  gender:Gender;
  employment_type:string;
  monthly_loans:string;
  files?: Array<any>;
  fis?: Array<any>;
  schools?: Array<any>;
  students:Array<any>;
}

export async function handleApiResponse(response: any) {
  return response;
}

export async function getUserProfile(): Promise<{ user: User | undefined }> {
  const res = await client.get(`auth/me`).then(handleApiResponse, storage.clearToken);
  return res;
}

export async function loginWithUidAndPassword(
  data: any
): Promise<AuthResponse> {
  return client.post(`auth/login`, data).then(handleApiResponse);
}

export async function registerWithUidAndPassword(
  data: any
): Promise<AuthResponse> {
  return client.post(`auth/register`, data).then(handleApiResponse);
}

export async function logout(): Promise<AuthResponse> {
  return client.get(`auth/logout`).then(handleApiResponse);
}

export async function getOtp(
  mobile: string,
  resend: boolean = false,
  forgot: boolean = false
) {
  return client.post(`auth/get-otp`, { mobile, resend, forgot });
}

export async function verifyOtp(mobile: string, otp: string) {
  return client.post(`auth/verify-otp`, { mobile, otp });
}

export async function resetPassword(values: any) {
  return client.post(`auth/reset-password`, values);
}

export function updatePassword(passwords: any) {
  return client.post(`auth/update-password`, passwords) as any;
}
