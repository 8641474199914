import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

type Props = {
  name: string;
};

const OTPField = (props: Props) => {
  const fields = 4;
  const [OTP, setOTP] = useState([...Array(fields)].map((i) => " "));
  const refs = useRef<any>([]);
  const { setFieldValue } = useFormikContext();

  function changed(key: number, value: string) {
    let newOTP = [...OTP];
    value=value.replace(/\D/g,'');
    
    if (value.length >= fields) {
      newOTP = value.slice(0, 6).split("");
    } else {
      newOTP[key] = value.slice(-1);
      if (value != "") refs.current[key + 1]?.focus();
    }
    setOTP(newOTP);
    setFieldValue(props.name, newOTP.join(""));
  }

  return (
    <div className="d-flex justify-content-center" dir="ltr">
      {OTP.map((item, index) => (
        <Form.Control
          onFocus={() => changed(index, "")}
          ref={(e: any) => (refs.current[index] = e)}
          key={index}
          value={item}
          onChange={(e) => {
            changed(index, e.target.value);
          }}
          type="number"
          style={{ width: "12.5%", fontSize: "1.25rem" }}
          className="me-2 text-center text-gray fw-semibold"
          inputMode="numeric"
          size="lg"
        />
      ))}
    </div>
  );
};

export default OTPField;
