import cx from "classnames";
import { DateTime } from "luxon";
import { useUser } from "utils/hooks/auth";

type DateBoxProps = {
    date: DateTime;
    overDue?: boolean;
};


export default function DateBox({ date, overDue }: DateBoxProps) {
    const {data:user} = useUser();
    const localizedDate = date.setLocale(user?.language || "en");
    return (
        <div
            className={cx(
                "bg-white  border rounded-1 p-2 px-3 d-flex flex-column justify-items-center align-items-center",
                {
                    "border-danger": overDue,
                }
            )}
        >
            <p className="text-gray opacity-75">{localizedDate.monthShort}</p>
            <p className="fw-bold text-dark">{localizedDate.day}</p>
            <p className="text-gray opacity-75">{localizedDate.year}</p>
        </div>
    );
}