import { useQuery } from "@tanstack/react-query";
import files from "utils/api/files";
import { imageUrl } from "utils/functions/utilities";

type ImageProps = {
  id?: string;
  url?: string;
  autoFetch?: boolean;
  className?: string;
  style?: object;
};
const Image = (props: ImageProps) => {
  const { id, url, autoFetch, className = "", style = {} } = props;
  const { data } = useQuery(["file", id], () => files.view(id), {
    enabled: !!autoFetch,
  });

  let generatedUrl = url || data;
  if (data) {
    generatedUrl = imageUrl(data);
  }

  return <img alt={"uploaded-file-"+id} className={className} style={style} src={generatedUrl} />;
};

export default Image;
