import { VisibilityState, createColumnHelper } from "@tanstack/react-table";
import ReserveStatusRenderer from "components/widgets/ReserveStatusRenderer";
import StatusRenderer from "components/widgets/StatusRenderer";
import { reduce } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { User } from "utils/api/auth";
import { breakPoints } from "utils/constants";
import FileType from "utils/enums/FileType";
import PlanStatus from "utils/enums/PlanStatus";
import Role from "utils/enums/Role";
import TransactionStatus from "utils/enums/TransactionStatus";
import TransactionType from "utils/enums/TransactionType";
import { arabicValue, dateAndTime, isOneOfRoles } from "utils/functions/utilities";
import PlanAndReserveStatuses from "./components/PlanAndReserveStatuses";

const columnHelper = createColumnHelper<any>();
const plansTablecolumns = (t: any, user: User | null | undefined) => [
  columnHelper.accessor("public_id", {
    header: t("id"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("user.name", {
    id: "name",
    header: t("name"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("school", {
    header: t("school"),
    cell: (info) => arabicValue(info.getValue(), "name"),
  }),
  columnHelper.accessor("branch", {
    header: t("schools:branch"),
    cell: (info) => {
      let distinctBranches = info.row.original.students?.map((s: any) => s?.branch?.name).filter((v: any, i: any, a: any) => a.indexOf(v) === i)
      return distinctBranches?.join(", ")
    },
  }),
  columnHelper.accessor("fiUser.name", {
    id: "fi_user",
    header: t("fi_user"),
    cell: (info) => info.getValue() ?? "",
  }),
  columnHelper.accessor("user.mobile", {
    id: "mobile",
    header: t("auth:mobile"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("amount", {
    header: t("amount"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("status", {
    header: t("status"),
    cell: (info) => {
      return <>
        <PlanAndReserveStatuses plan={info.row.original} />
      </>
    },
  }),
  columnHelper.accessor("files", {
    id: "flags",
    header: t("common:flags"),
    cell: (info) => {
      return <>
        {
          ((isOneOfRoles([Role.ADMIN, Role.FI, Role.USER], user) && info.getValue().some((x: any) => x.type == FileType.CINET))) && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{t('common:signed')}</Tooltip>}
            >

              <i className="text-info bi bi-file-earmark-check-fill me-1"></i>
            </OverlayTrigger>
          )
        }
        {(info.row.original.user?.plans?.length > 0 &&
          ![PlanStatus.COMPLETE, PlanStatus.ACTIVE].includes(info.row.original.status)
        ) &&
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('plans:reapplying')}</Tooltip>}
          >
            <i className="bi bi-star-fill text-info me-1"></i>
          </OverlayTrigger>
        }
        {!!info.row.original?.paynow &&
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('common:paynow')}</Tooltip>}
          >
            <i className="text-info bi bi-lightning-fill text-info me-1"></i>
          </OverlayTrigger>
        }
        {isOneOfRoles([Role.ADMIN, Role.USER], user) && (info.row.original?.transactions?.some((trx: any) => trx.status === TransactionStatus.SUCCESS && trx.type == TransactionType.SERVICE_FEE)) &&
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('plans:paid_service_fee')}</Tooltip>}
          >
            <i className="text-info bi bi-cash text-info me-1"></i>
          </OverlayTrigger>
        }
        {(isOneOfRoles([Role.ADMIN], user) && info.row.original?.reserve_status) && <>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('plans:reserved_plan')}</Tooltip>}
          >
            <i className="text-info bi bi-card-checklist text-info me-1"></i>
          </OverlayTrigger>
        </>}
        {(isOneOfRoles([Role.ADMIN], user) && info.row.original?.extras?.split) && <>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Split</Tooltip>}
          >
            <i className="text-info bi bi-input-cursor text-info me-1"></i>
          </OverlayTrigger>
        </>}
      </>
    },
  }),
  columnHelper.accessor("created_at", {
    header: t("common:created_at"),
    cell: (info) => dateAndTime(info.getValue()),
  }),
  columnHelper.accessor("status_updated", {
    header: t("common:last_update"),
    cell: (info) => dateAndTime(info.getValue() ?? info.row.original.updated_at),
  }),
];

export function columnOrder(role: Role) {
  const orders: any = {
    [Role.ADMIN]: [
      "name",
      "school",
      "mobile",
      "status",
      "amount",
      "updated_at",
      "created_at",
      "public_id",
      "signed",
    ],
    [Role.MODERATOR]: [
      "name",
      "school",
      "mobile",
      "status",
      "amount",
      "updated_at",
      "created_at",
      "public_id",
      "signed",
    ],
    [Role.FI]: [
      "name",
      "status",
      "signed",
      "amount",
      "school",
      "public_id",
      "mobile",
      "fi_user",
      "updated_at",
      "created_at",
      "branch",
    ],
    [Role.SCHOOL]: [
      "name",
      "branch",
      "status",
      "amount",
      "public_id",
      "mobile",
      "updated_at",
      "created_at",
    ],
    [Role.USER]: [
      "name",
      "school",
      "branch",
      "status",
      "amount",
      "updated_at",
      "created_at",
      "mobile",
      "public_id",
    ],
  };
  return orders[role];
}

const allColumns = [
  "name",
  "school",
  "mobile",
  "status",
  "amount",
  "updated_at",
  "created_at",
  "public_id",
  "branch",
  "fi_user",
  "signed",
];

export const plansTablecolumnVisibility = (
  width: number,
  role: Role
): VisibilityState => {
  let visible = columnOrder(role);
  let hiddenColumns: any = reduce(
    allColumns.filter((col) => !visible.includes(col)),
    (acc, x) => ({ ...acc, [x]: false }),
    {}
  );
  if (width < breakPoints.xxl) {
    hiddenColumns[visible.pop()] = false;
    hiddenColumns[visible.pop()] = false;
  }

  if (width < breakPoints.xl) {
    hiddenColumns[visible.pop()] = false;
    hiddenColumns[visible.pop()] = false;
  }

  if (width < breakPoints.lg) {
    hiddenColumns[visible.pop()] = false;
    hiddenColumns[visible.pop()] = false;
  }

  return hiddenColumns;
};

export default plansTablecolumns;
