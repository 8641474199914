import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import PlanAdminActions from "./Actions/AdminActions";
import PlanFIActions from "./Actions/FIActions";
import PlanSchoolActions from "./Actions/SchoolActions";
import PlanUserActions from "./Actions/UserActions";
import { useTranslation } from "react-i18next";
import { useUser } from "utils/hooks/auth";
import actionablePlan from "utils/functions/actionablePlan";
import PlanModeratorActions from "./Actions/ModeratorActions";
import actionableReserve from "utils/functions/actionableReserve";

const PlanActionsDetails = (props: { plan: any }) => {
  const { plan } = props;
  const { t } = useTranslation(["common"]);
  const { data: user } = useUser();
  if (!actionablePlan(user!, plan) && !actionableReserve(user!, plan)) return <></>;

  return (
    <Accordion defaultActiveKey="0" className="mb-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("actions")}</Accordion.Header>
        <Accordion.Body>
          <RoleBasedRenderer
            admin={<PlanAdminActions plan={plan} />}
            moderator={<PlanModeratorActions plan={plan} />}
            fi={<PlanFIActions plan={plan} />}
            school={<PlanSchoolActions plan={plan} />}
            user={<PlanUserActions plan={plan} />}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default PlanActionsDetails;
