import { faBookBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import Checkbox from "components/inputs/Checkbox";
import Input from "components/inputs/Input";
import Radio from "components/inputs/Radio";
import Select from "components/inputs/Select";
import Icon from "components/widgets/Icon";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { Formik, Form as FormikForm } from "formik";
import { DateTime } from "luxon";
import { Fragment } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Row
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { countries } from "utils/api/countries";
import { fis } from "utils/api/fis";
import users from "utils/api/users";
import FIMethod from "utils/enums/FIMethod";
import Role from "utils/enums/Role";
import useAlert from "utils/hooks/alert";
import { useUser } from "utils/hooks/auth";
import * as Yup from "yup";

const schema = Yup.object().shape({
  name: Yup.string().required(),
  cut: Yup.number().required(),
  direct_cut: Yup.number().required(),
  minimum_cut: Yup.number(),
  location: Yup.string()
    .nullable(true)
    .transform((_, value) => {
      return value === "" ? null : value;
    }),
  location_ar: Yup.string()
    .nullable(true)
    .transform((_, value) => {
      return value === "" ? null : value;
    }),
});

const CreateFI = () => {
  const navigate = useNavigate();
  const { success } = useAlert();
  const { t } = useTranslation(["common"]);

  const { id } = useParams();
  const { data: user } = useUser();
  const { data: fiData } = useQuery(["fis", id], fis.view(id), {
    enabled: Boolean(id),
  });
  const { data: userData } = useQuery(
    ["users", Role.FI],
    () => users.list({ roleId: Role.FI, page: 1, perPage: 1000 }),
    {
      enabled: user?.role_id == Role.ADMIN,
      select: (data) =>
        data?.data?.map((user: any) => ({
          label: user.name,
          value: user.id,
        })),
      placeholderData: [],
    }
  );

  const { data: countriesData } = useQuery(["countries"], countries.list, {
    placeholderData: [],
    select: (data) =>
      data?.map((country: any) => ({
        label: country.name,
        value: country.id,
      })),
  });

  function confirmDeletion() {
    success({
      onConfirm: deleteFI,
      message: `FI "${fiData.name}" will be removed`,
    });
  }

  async function deleteFI() {
    await fis.destroy(id);
    navigate("/fis");
  }

  async function submit(values: any) {
    try {
      const castedSchema = schema.cast(values);
      let res;
      if (id) {
        res = await fis.update(id, castedSchema);
      } else {
        res = await fis.store(castedSchema);
      }

      navigate("/fis");
    } catch (e) { }
  }
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/fis">
            <FontAwesomeIcon icon={faBookBookmark} /> FIs
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{fiData?.name}</BreadcrumbItem>
      </Breadcrumb>

      <Card>
        <Card.Body>
          <Formik
            enableReinitialize
            initialValues={{
              name: "",
              name_ar: "",
              cut: "",
              is_dynamic_cuts: false,
              direct_cut: "",
              minimum_cut: "",
              split_cut: "",
              min_months: "",
              min_amount: "",
              allow_digital_signature: false,
              users: [],
              country_id: 115,
              ...fiData,
            }}
            validationSchema={schema}
            onSubmit={submit}
          >
            {(formikBag) => (
              <FormikForm>
                <Row className="gy-3 mb-3">
                  <Col md="6">
                    <Input
                      label="Name"
                      name="name"
                      disabled={user?.role_id == Role.FI}
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      label={t("common:arabic_name")}
                      name="name_ar"
                      disabled={user?.role_id == Role.FI}
                    />
                  </Col>
                  {user?.role_id == Role.ADMIN && (
                    <>
                      <Col md="3">
                        <Input type="number" label="Cut" name="cut" />
                      </Col>
                      <Col md="3">
                        <Input
                          type="number"
                          label="Direct cut"
                          name="direct_cut"
                        />
                      </Col>
                      <Col md="3">
                        <Input
                          type="number"
                          label="Minimum cut in KD"
                          name="minimum_cut"
                        />
                      </Col>
                      <Col md="3">
                        <Input
                          type="number"
                          label="Split cut"
                          name="split_cut"
                        />
                      </Col>
                    </>
                  )}
                  <Col md="6">
                    <Input
                      type="number"
                      label="Minimum Months"
                      name="min_months"
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      type="number"
                      label="Minimum Amount"
                      name="min_amount"
                    />
                  </Col>
                  <RoleBasedRenderer
                    admin={
                      <Col md="6">
                        <Select
                          disabled={user?.role_id == Role.SCHOOL}
                          options={countriesData}
                          name="country_id"
                          label={t("country")}
                        />
                      </Col>
                    }
                  />
                  <Col md="6">
                    <Input label="Website" name="website" />
                  </Col>
                  <Col md="6">
                    <Input type="textarea" label="Location" name="location" />
                  </Col>
                  <Col md="6">
                    <Input type="textarea" label="Location Arabic" name="location_ar" />
                  </Col>
                  <Col md="12">
                    <Card>
                      <Card.Header>
                        <Card.Title className="fs-6 fw-bold">
                          Flags
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className="d-flex p-3">
                        <Checkbox
                          name="allow_digital_signature"
                          label={t("allow_digital_signature")}
                        />
                        <Checkbox
                          name="is_dynamic_cuts"
                          label="Dynamic cuts"
                        />
                        <Checkbox
                          name="intermediated"
                          label="Ruba-intermediated Payment"
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {user?.role_id == Role.ADMIN && (
                  <Row className="gy-3">
                    <p className="fs-7 fw-semibold mb-3">Method</p>
                    <Col md="6">
                      <Radio
                        name="method"
                        label="Murabaha"
                        value={FIMethod.MURABAHA}
                      />
                    </Col>
                    <Col md="6">
                      <Radio
                        name="method"
                        label="Mosawama"
                        value={FIMethod.MOSAWAMA}
                      />
                    </Col>
                    <Card>
                      <Card.Header>
                        <Card.Title className="fs-6 fw-bold">
                          {t("users")}
                        </Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Row className="g-3">
                          {formikBag.values?.users?.map(
                            (user: any, i: number) => (
                              <Fragment key={user.id}>
                                <Col md="6">
                                  <Select
                                    label="Name"
                                    name={`users[${i}].id`}
                                    options={userData}
                                  />
                                </Col>
                                <Col md="6">
                                  <Input
                                    label="Title"
                                    name={`users[${i}].title`}
                                  />
                                </Col>
                                <Col md="3">
                                  <Input
                                    label="Cinet Code"
                                    name={`users[${i}].extras.cinet_code`}
                                  />
                                </Col>
                                <Col md="3">
                                  <Input
                                    label="Cinet Name"
                                    name={`users[${i}].extras.cinet_name`}
                                  />
                                </Col>
                                <Col md="3">
                                  <Input
                                    label="Cinet ID"
                                    name={`users[${i}].extras.cinet_id`}
                                  />
                                </Col>
                                <Col
                                  md="3"
                                  className="d-flex align-items-end mb-3"
                                >
                                  <Button
                                    className="w-100"
                                    variant="light"
                                    size="lg"
                                    onClick={() =>
                                      formikBag.setFieldValue(
                                        "users",
                                        formikBag.values.users.filter(
                                          (item: any) => item.id != user.id
                                        )
                                      )
                                    }
                                  >
                                    <Icon name="trash" />
                                    {t("common:delete")}
                                  </Button>
                                </Col>
                              </Fragment>
                            )
                          )}
                          <Col md="12" className="d-flex justify-content-end">
                            <Button
                              className="w-100 w-sm-auto mb-3 mt-4"
                              variant="light"
                              size="lg"
                              onClick={() =>
                                formikBag.setFieldValue("users", [
                                  ...formikBag.values.users,
                                  { id: DateTime.now(), title: "" },
                                ])
                              }
                            >
                              <i className="bi bi-plus-lg mx-3"></i>
                              Link A User
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Row>
                )}
                <Row className="mt-4">
                  <RoleBasedRenderer
                    admin={
                      id && (
                        <Col md="6">
                          <Button
                            className="w-100 w-sm-auto"
                            size="lg"
                            onClick={confirmDeletion}
                            variant="danger"
                          >
                            <i className="bi bi-trash3 mx-1"></i>
                            Delete FI
                          </Button>
                        </Col>
                      )
                    }
                  />
                  <Col md="6" className="text-end">
                    <Button size="lg" className="w-100 w-sm-auto" type="submit">
                      {t("common:save_changes")}
                    </Button>
                  </Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateFI;
