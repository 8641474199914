import React, { Fragment } from "react";
import cx from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import Icon from "components/widgets/Icon";

type Step = {
  title: string;
};
type Props = {
  steps: Array<Step>;
  currentStep: number;
};

const WizardStepper = (props: Props) => {
  const { steps, currentStep } = props;
  return (
    <Container fluid className="px-0">
      <div className="d-flex g-0 justify-content-between">
        {steps.map((step, index) => (
          <Fragment key={index}>
            {index != 0 && (
              <hr
                style={{ height: "2px" }}
                className={cx("d-none d-lg-block flex-grow-1 mx-4", {
                  "bg-success": index <= currentStep,
                  "bg-light": index > currentStep,
                })}
              />
            )}
            <div
              className={cx(
                "d-flex align-items-center flex-column flex-lg-row text-center text-lg-left fbs-4 fbs-lg-2"
              )}
            >
              <StepIndicator
                index={index}
                {...step}
                currentStep={currentStep}
              />
              <p
                className={cx("fw-semibold", {
                  "text-graycool opacity-50": currentStep < index,
                })}
              >
                {step.title}
              </p>
            </div>
          </Fragment>
        ))}
      </div>
    </Container>
  );
};

type StepIndicatorProps = {
  index: number;
  currentStep: number;
};
export function StepIndicator(props: StepIndicatorProps) {
  const { index, currentStep } = props;
  return (
    <div
      className={cx(
        "me-lg-3 fs-7 wizard-indicator rounded-circle justify-content-center align-items-center d-flex",
        {
          inactive: currentStep < index,
          success: currentStep > index,
        }
      )}
    >
      {currentStep > index ? <Icon size={44} name="mark" /> : index + 1}
    </div>
  );
}

export default WizardStepper;
