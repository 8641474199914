import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { interests } from "utils/api/interests";

const ListLogss = (props: any) => {
  const { data } = useQuery(["interests"], interests.list);
  return (
    <Row>
      <Col lg="12">
        <Card className="main-card mb-3">
          <CardBody>
            <Table striped className="mb-0">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Mobile</th>
                  <th>School</th>
                  <th>Students #</th>
                  <th>Registered?</th>
                  <th>Date</th>
                  {/* <th>{t("plans:actions")}</th> */}
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any) => (
                  <tr key={item.id}>
                    <td>{item.user?.name ?? item.name}</td>
                    <td>{item.user?.mobile ?? item.mobile}</td>
                    <td>{item.school.name}</td>
                    <td>{item.number_of_students}</td>
                    <td>{item.registered}</td>
                    <td>
                      {DateTime.fromISO(item.created_at).toFormat(
                        "dd/MM/yyyy HH:mm"
                      )}
                    </td>
                    <td>
                      {/* <Link to={"/schools/" + item.id}>
                        <Button size="sm" color="secondary">
                          {t("common:edit")}
                        </Button>
                      </Link> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ListLogss;
