import { Table } from "react-bootstrap";
import Amount from "components/widgets/Amount";
import { DateTime } from "luxon";
import { Fragment, useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { reserveTransactions } from "utils/api/reserveTransactions";

export interface ITransactionDetails {
  planId: number;
  amounts: Number[];
  dates: Date[];
  isPaid: boolean[];
}

export default function TransactionDetails(props: ITransactionDetails) {
  const { planId, amounts, dates, isPaid } = props;
  const [schedule, setSchedule] = useState([])

  useEffect(() => {
    (
      async () => {
        const response = await reserveTransactions.prepareSchedule({
          planId,
          amounts,
          dates,
          isPaid
        });
        setSchedule(response)
      }
    )()
  }, [amounts, dates, isPaid])




  return (
    <Fragment>
      <h6>Transaction details</h6>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {schedule?.map((item: any, index: number) => (
            <tr key={item.date}>
              <td>{DateTime.fromFormat(item.date, "yyyy-MM-dd").toFormat("dd LLL yyyy")}</td>
              <td><Amount amount={item.amount} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
}
