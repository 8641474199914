import React from "react";
import { Route, Routes } from "react-router-dom";
import StudentsList from "./List";
import { useUser } from "utils/hooks/auth";
import AddStudentsList from "containers/QuickSetup/AddStudents/list";
// import PlansList from "./List";
// import CreatePlan from "./Create";
// import ViewPlan from "./View";

const StudentsRoutes = () => {
  const { data: user } = useUser();
  return (
    <Routes >
      <Route path="/parent" element={<AddStudentsList />} />
      <Route path="/" element={<StudentsList />} />
      {/* <Route path="/new" element={<CreatePlan />} />
      <Route path="/:id/edit" element={<CreatePlan />} />
      <Route path="/:id" element={<ViewPlan />} /> */}
    </Routes>
  );
};

export default StudentsRoutes;
