import error from "components/assets/utils/images/payments/status/error.svg";
import success from "components/assets/utils/images/payments/status/success.svg";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  redirectTo?: string;
};

const AfterRedirectionPopup = (props: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [show, setShow] = useState(
    ["success", "failure"].includes(searchParams.get("status") as any)
  );
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  let items = {
    image: success,
    title: t("thank_you_for_payment"),
    description: t("transaction_processed_successfully"),
  };
  if (searchParams.get("status") === "failure") {
    items = {
      image: error,
      title: t("payment_failed"),
      description: t("payment_failed_description"),
    };
  }

  return (
    <Modal size="lg" show={show} onHide={() => setShow(false)} centered>
      <Modal.Body className="text-center p-5">
        <div className="mb-4">
          <img height="100px" src={items.image} />
        </div>
        <h4 className="text-primary fw-bold mb-3">{items.title}</h4>
        <p className="text-dark opacity-75 mb-5">{items.description}</p>
        <Button
          onClick={() => {
            setShow(false);
            props.redirectTo && navigate(props.redirectTo);
          }}
          variant="info"
          className="w-100"
        >
          {t("close")}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default AfterRedirectionPopup;
