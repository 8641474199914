import TablePages from "components/widgets/TablePages";
import { DateTime } from "luxon";
import { useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import logs from "utils/api/logs";
import { usePaginate } from "utils/hooks/paginate";
import LogsFilters from "../components/Filters";

const ListLogs = (props: any) => {
  const [filter, setFilter] = useState({});
  const paginate = usePaginate({
    key: "logs",
    api: logs.list,
    filter,
  });

  return (
    <Row>
      <Col lg="12">
        <Card className="main-card mb-3">
          <Card.Body>
            <LogsFilters filter={filter} setFilter={setFilter} />
            <Table striped className="mb-0">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Action</th>
                  <th>Date & Time</th>
                  <th>Data</th>
                  {/* <th>{t("plans:actions")}</th> */}
                </tr>
              </thead>
              <tbody>
                {paginate?.data?.data?.map((item: any) => (
                  <tr key={item.id}>
                    <td>{item.user?.name}</td>
                    <td>{item.action}</td>
                    <td>
                      {DateTime.fromISO(item.created_at).toFormat(
                        "dd/MM/yyyy HH:mm"
                      )}
                    </td>
                    <td>{JSON.stringify(item.data)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TablePages {...paginate} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ListLogs;
