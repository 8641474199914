import { useMutation, useQueryClient } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { plans } from "utils/api/plans";
import FileType from "utils/enums/FileType";
import PlanStatus from "utils/enums/PlanStatus";
import * as Yup from "yup";

const PlanPendingQuotation = (props: any) => {
  const { plan } = props;
  const { t } = useTranslation(["common", "plans"]);
  const client = useQueryClient();
  const [show, setShow] = useState(false);
  const [showAmount, setShowAmount] = useState(false);

  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  const planAmountMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateAmount(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Amount updated");
      },
    }
  );

  function updateAmount(amount: number) {
    planAmountMutation.mutate({ plan, values: { amount } });
    setShowAmount(false);
  }

  function pendingRegistration() {
    planMutation.mutate(
      { plan, values: { status: PlanStatus.PENDING_REGISTRATION } },
    );
  }

  function submit(values: any) {
    const { status, amount, ...files } = values;

    plans.upload({
      planId: plan.id,
      files,
    });

    planMutation.mutate(
      { plan, values },
      {
        onSuccess: () => {
          setShow(false);
        },
      }
    );
  }

  if (![PlanStatus.PENDING_REGISTRATION, PlanStatus.PENDING_QUOTATION].includes(plan.status)) {
    return <></>;
  }
  return (
    <>
      <Button
        variant="primary"
        onClick={() => setShow(true)}
        size="lg"
        className="w-100"
      >
        {t("plans:upload_quotation")}
      </Button>
      <Button
        variant="light"
        onClick={() => setShowAmount(true)}
        size="lg"
        className="w-100 my-3"
      >
        {t("plans:update_amount")}
      </Button>
      {plan.status !== PlanStatus.PENDING_REGISTRATION &&
        <Button
          variant="light"
          onClick={() => pendingRegistration()}
          size="lg"
          className="w-100"
        >
          {t("plans:pending_registration")}
        </Button>
      }
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">
            {t("plans:upload_quotation")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={submit}
            initialValues={{ status: PlanStatus.PENDING_CINET } as any}
            validationSchema={Yup.object().shape({
              [FileType.SIGNED_QUOTATION]: Yup.string().required(
                t("document_required")
              ),
            })}
          >
            {({ values }) => (
              <Form>
                <Row>
                  <Col lg={12}>
                    <Input
                      type="file"
                      id="quotation"
                      name={FileType.SIGNED_QUOTATION}
                      label={t("signed_quotation")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end mt-4" lg="12">
                    <Button size="lg" className="w-100" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={showAmount}
        onHide={() => setShowAmount(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">{t("plans:update_amount")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={(values) => updateAmount(values.amount)}
            initialValues={{ status: PlanStatus.PENDING_CINET } as any}
            validationSchema={Yup.object().shape({
              amount: Yup.number().min(
                300,
                t("common:number_must_be_more", { number: 300 })
              ),
            })}
          >
            {({ values }) => (
              <Form>
                {values.amount && values.amount != props.plan.amount && (
                  <Row>
                    <Col lg="12">
                      <Alert variant="warning">
                        {t("plans:click_update_amount", {
                          amount: values.amount,
                        })}
                      </Alert>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg={12}>
                    <Input
                      type="number"
                      id="amount"
                      name="amount"
                      label={`${t("plans:amount")} (${t("common:optional")})`}
                    />
                  </Col>
                  <Col lg={12} className="mt-4">
                    <Button
                      size="lg"
                      className="w-100"
                      type="submit"
                      variant="primary"
                    >
                      {t("plans:update_amount")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlanPendingQuotation;
