import client from "../api";

export function list() {
  return client.get("fis") as any;
}

export function view(id?:any){
  return () => client.get(`fis/${id}`) as any;
}

export function store(values:any){
  return client.post("fis",values) as any;
}

export function update(id:string,values:any){
  return client.put(`fis/${id}`,values) as any;
}

export function destroy(id:string |undefined){
  return client.delete(`fis/${id}`) as any;
}

export const fis = { list,view,store,update,destroy };

export default fis;
