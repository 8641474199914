import { useQuery } from '@tanstack/react-query'
import Input from 'components/inputs/Input'
import Amount from 'components/widgets/Amount'
import { Form, Formik } from 'formik'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { addons } from 'utils/api/addons'
import { arabicValue } from 'utils/functions/utilities'
import * as Yup from 'yup'

type Props = {

}

const AddonPaymentPage = (props: Props) => {
    const { t } = useTranslation(['addons', 'common', 'schools'])

    const { code } = useParams<{ code: string }>();
    const { data: addon } = useQuery(['addons', code], () => addons.viewByCode(code!), {
        enabled: !!code,
    })

    if (!addon) return <></>

    const logo = addon?.logo;

    async function submit(values: any) {
        const res = await addons.generateLink({
            id: addon.id,
            ...values
        })
        // console.log(res);
        window.location.replace(res);
    }

    return (
        <>
            <Container className="my-5">

                <Row className='gy-3'>
                    <Col className='d-flex flex-column align-items-center'>
                        {logo &&
                            <div className='rounded-3 my-2 overflow-hidden shadow ' style={{
                            }}>
                                <img className='m-3' style={{ height: 100 }} src={addon?.logo} />
                            </div>
                        }
                    </Col>
                </Row>

                {/* Addon Details */}
                <Row className="justify-content-center my-3">
                    <Col md={8}>
                        <Card>
                            <Card.Body className='p-3'>
                                <Card.Title className='text-center'>
                                    {arabicValue(addon.school, 'name')}
                                </Card.Title>
                                <Card.Text className='my-3'>
                                    <h5>{addon.name}</h5>
                                    <p>{addon.description}</p>
                                </Card.Text>
                                {/* inputs for parent name and number */}
                                <Formik
                                    initialValues={{
                                        name: '',
                                        mobile: '',
                                        custom_requirement: ''
                                    }}
                                    validationSchema={Yup.object({
                                        name: Yup.string().required('Required'),
                                        mobile: Yup.string().required('Required')
                                    })}

                                    onSubmit={submit}
                                >
                                    {({ values }) => <>
                                        <Form>
                                            <Row className='gy-3'>
                                                <Col lg={12} className='d-flex justify-content-between'>
                                                    <p className='fbs-2'>Name</p>
                                                    <p className='fbs-2'>الإسم</p>
                                                </Col>
                                                <Col lg={12}>
                                                    <Input type="text" name="name" />
                                                </Col>
                                                <Col lg={12} className='d-flex justify-content-between'>
                                                    <p className='fbs-2'>Mobile</p>
                                                    <p className='fbs-2'>الهاتف</p>
                                                </Col>
                                                <Col lg={12}>
                                                    <Input type="text" name="mobile" />
                                                </Col>
                                                {addon.custom_requirement_en &&
                                                    <>
                                                        <Col lg={12} className='d-flex justify-content-between'>
                                                            <p className='fbs-2'>
                                                                {addon.custom_requirement_en}
                                                            </p>
                                                            <p className='fbs-2'>
                                                                {addon.custom_requirement_ar}
                                                            </p>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <Input type="text" name="custom_requirement" />
                                                        </Col>
                                                    </>
                                                }

                                            </Row>


                                            <div className='text-center'>
                                                <Button type="submit" variant="primary" className='w-100 mt-3' >
                                                    <div className="d-flex justify-content-center">
                                                        <p className="mx-1">{t("addons:pay_addon")}</p> <Amount amount={addon.price ?? 0} />
                                                    </div>
                                                </Button>
                                            </div>
                                        </Form>
                                    </>}
                                </Formik>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Footer */}
                <Row className="mt-5 text-center">
                    <Col>
                        <p className='fbs-4'>&copy; {new Date().getFullYear()} {addon?.school?.name}. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AddonPaymentPage