import { Alert } from 'react-bootstrap'
import NotificationType from 'utils/enums/NotificationType'
import ReferralCompletedNotificationRenderer from './ReferralCompleted'
import ReferralSuccessfulNotificationRenderer from './ReferralSuccessful'
import { Notification } from 'utils/types/notification'
import { useEffect } from 'react'
import { notifications } from 'utils/api/notifications'

type Props = {
    notification: Notification
}

const NotificationRendererImp = (props: Props) => {
    if (props.notification.type === NotificationType.REFERRAL_SUCCESSFUL) {
        return <ReferralSuccessfulNotificationRenderer {...props.notification} />
    }
    if (props.notification.type === NotificationType.REFERRAL_COMPLETED) {
        return <ReferralCompletedNotificationRenderer {...props.notification} />
    }
    return (
        <div>NotificationRenderer</div>
    )
}

const NotificationRenderer = (props: Props) => {
    useEffect(() => {
        (async () => {
            if (!props.notification.read) {
                await notifications.markAsRead(props.notification.id)
            }
        })()
    }, [props?.notification])

    return (
        <Alert variant={props.notification?.read ? 'white' : 'info'} className='p-4'>
            <NotificationRendererImp {...props} />
        </Alert>
    )
}

export default NotificationRenderer