import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AddStudentsList from "./list";

type Props = {
  nextStep: () => void;
  backStep: () => void;
};

const QuickAddStudents = (props: Props) => {
  const { backStep, nextStep } = props;
  const { t } = useTranslation(["common"]);

  return (
    <Container className="px-0 mb-2">
      <AddStudentsList quickForm />
      <Row className="d-flex justify-content-between">
        <Col lg={4} className="mb-4">
          <Button
            onClick={backStep}
            variant="light"
            type="submit"
            size="lg"
            className="px-6 py-3 rounded-2 me-0 w-100"
          >
            {t("common:back")}
          </Button>
        </Col>
        <Col lg={5} className="mb-4 text-end">
          <Button
            onClick={nextStep}
            size="lg"
            className="px-6 py-3 rounded-2 w-100"
          >
            {t("common:save_and_continue")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default QuickAddStudents;
