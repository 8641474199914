import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { User } from "utils/api/auth";
import { transactionDoneStatuses } from "utils/constants";
import GradeType from "utils/enums/GradeType";
import Role from "utils/enums/Role";
import TransactionStatus from "utils/enums/TransactionStatus";
import i18n from "utils/i18n/config";

export const removeUnderScores = (name: string) => {
  return capitalize(name.replaceAll("_", " "));
};

export const gradeLabel = (grade: any) => {
  if (grade?.type === GradeType.monthlyRange)
    return i18n.t("common:grade_text", grade.objectName);
  return arabicValue(grade, "name");
};

export const arabicValue = (obj: { [key: string]: string }, key: string) => {
  try {
    if (i18n.language === "ar" && obj[key + "_ar"]) {
      return obj[key + "_ar"];
    }

    return obj[key] as string;
  } catch (e) {
    return "";
  }
};

export const dateAndTime = (date: string, hideTime: boolean = false) => {
  if (i18n.language === "ar") {
    if (hideTime) {
      return DateTime.fromISO(date).toFormat("yyyy/MM/dd");
    }
    return DateTime.fromISO(date).toFormat("HH:mm yyyy/MM/dd");
  }
  if (hideTime) {
    return DateTime.fromISO(date).toFormat("yyyy/MM/dd");
  }
  return DateTime.fromISO(date).toFormat("yyyy/MM/dd HH:mm");
};

export function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export function isRole(role: Role, user: User | undefined | null) {
  return user?.role_id === role;
}

export function isOneOfRoles(
  role: Array<Role | undefined>,
  user: User | undefined | null
) {
  return role.includes(user?.role_id);
}

export function moveElement(
  array: any[],
  element: any,
  direction: string
): any[] {
  let index = array.indexOf(element);
  if (index === -1) return [...array];
  let newArray = [...array];
  if (
    (direction === "up" && index === 0) ||
    (direction === "down" && index === newArray.length - 1)
  )
    return newArray;
  let removed = newArray.splice(index, 1);
  if (direction === "up") {
    newArray.splice(index - 1, 0, removed[0]);
  } else if (direction === "down") {
    newArray.splice(index + 1, 0, removed[0]);
  }
  return newArray;
}

export function imageUrl(path: string): string {
  if (process.env.NODE_ENV === "development")
    return process.env.REACT_APP_BACKEND + path;
  return path;
}

export function colsWidthBasedOnLength(text: string): number {
  if (text.length / 10 > 0) return parseInt((text.length / 10).toFixed(0));
  return 1;
}

export function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (event: any) => {
      resolve(event.target.result as string);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };

    fileReader.readAsDataURL(blob);
  });
}

export function imageURLToDataURL(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "Anonymous"; // This is required to prevent CORS issues when loading images from another domain

    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject("Error getting canvas context");
        return;
      }

      ctx.drawImage(image, 0, 0);

      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };

    image.onerror = (error) => {
      reject(`Error converting image URL to data URL: ${error}`);
    };

    image.src = url;
  });
}

export function download(name: string, data: any) {
  var blob = new Blob([data], { type: "application/pdf" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  if (!isIOS()) link.target = "_blank";
  link.click();
}

export function statusSpaceToUnderScore(status: any) {
  return status.toLowerCase().replaceAll(" ", "_").replaceAll(":", "");
}

export function errorsToFormikErrors(errors: any, formikBag: any) {
  let formikErrors: any = {};
  for (const error of errors) {
    formikErrors[error.field] = error.message;
  }
  formikBag.setErrors(formikErrors);
  console.log(formikErrors);
  return formikErrors;
}

export function parseJSONorString(str: string): any {
  try {
    const item = JSON.parse(str);
    return item;
  } catch (e) {
    return str;
  }
}

export function toFixed(num: number, fixed: number) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  const match = num.toString().match(re);
  return match ? match[0] : '';
}

export function installmentColor(item: any) {
  let color = "text-dark";
  const today = DateTime.local().startOf("day");
  const date = DateTime.fromISO(item.scheduled_date).startOf("day");

  if (date < today) color = "text-danger";
  if (
    paymentIsDone(item)
  )
    color = "text-success";
  return color;
}

export function paymentIsDone(item: any) {
  return transactionDoneStatuses.includes(item.status);
}

export function ordinal(n: number) {
  let s = ["th", "st", "nd", "rd"];
  let v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
