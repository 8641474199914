import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react'
import { Button } from 'react-bootstrap';
import client from 'utils/api/api';
import { doNotContact } from 'utils/api/doNotContact';

type Props = {
    mobile: string;
}

const ToggleDoNotContact = (props: Props) => {
    const { mobile } = props;
    const { data } = useQuery(['do-not-contact', mobile], () => doNotContact.showByMobile(mobile));
    const client = useQueryClient();
    const storeMutation = useMutation((values: any) => doNotContact.store(values),
        {
            onSuccess: () => {
                client.invalidateQueries(['do-not-contact', mobile]);
            }
        }
    );
    const destroyMutation = useMutation((id: number) => doNotContact.destroy(id),
        {
            onSuccess: () => {
                client.invalidateQueries(['do-not-contact', mobile
                ]);
            }
        });

    return (
        <>
            <Button
                variant="outline-primary"
                className="w-100"
                onClick={() => {
                    if (data) {
                        destroyMutation.mutate(data.id);
                    } else {
                        storeMutation.mutate({
                            mobile,
                        });
                    }
                }}
            >
                Turn ({data ? "Off" : "On"}) Do Not Contact
            </Button>
        </>
    )
}

export default ToggleDoNotContact