import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PlanStatus from "utils/enums/PlanStatus";
import * as Yup from "yup";

const PlanPendingRubaTIDFIActionsForm = (props: any) => {
  const { t } = useTranslation(["plans", "common"]);
  const { plan, planMutation } = props;

  function submit(values: any) {
    planMutation.mutate({
      plan, values: {
        status: values.status,
        ruba_trans_id: JSON.stringify(values.ruba_trans_id)
      }
    });
  }

  let status =
    plan.ruba_cut < 0
      ? PlanStatus.PENDING_RUBA_SCHOOL_TID
      : PlanStatus.ACTIVE;
  if (plan.fi?.intermediated) {
    status = PlanStatus.PENDING_RUBA_SCHOOL_TID;
  }

  let amount = plan.ruba_cut;
  if (plan.fi?.intermediated) {
    amount = plan.amount - plan.fi_cut;
  }

  return (
    <>
      {PlanStatus.PENDING_RUBA_TID == plan.status && (
        <Formik
          enableReinitialize
          onSubmit={submit}
          initialValues={{
            status,
            ruba_trans_id: {
              amount,
              tid: "",
              date: "",
            }
          }}
          validationSchema={Yup.object().shape({
            status: Yup.string().required(),
            ruba_trans_id: Yup.object().shape({
              amount: Yup.number(),
              tid: Yup.string(),
              date: Yup.string().required(),
            }),
          })}
        >
          <Form>
            <Row>
              <Col lg={12} className="mb-3">
                <Input
                  disabled
                  type="number"
                  name="ruba_trans_id.amount"
                  label={t("amount")}
                />
              </Col>
              <Col lg={12} className="mb-3">
                <Input
                  type="date"
                  name="ruba_trans_id.date"
                  label={t("common:date")}
                />
              </Col>
              <Col lg={12} className="mb-3">
                <Input
                  name="ruba_trans_id.tid"
                  label={t("fi_to_ruba_trans_id")}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-3" md="12">
                <Button type="submit" className="w-100" size="lg">
                  {t("common:submit")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default PlanPendingRubaTIDFIActionsForm;
