import i18n from 'i18next';
import enAuth from './en/auth.json';
import arAuth from './ar/auth.json';

import enPlans from './en/plans.json';
import arPlans from './ar/plans.json';

import enSchools from './en/schools.json';
import arSchools from './ar/schools.json';

import enCommon from './en/common.json';
import arCommon from './ar/common.json';

// addons
import enAddons from './en/addons.json';
import arAddons from './ar/addons.json';


import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    auth:enAuth,
    plans:enPlans,
    schools:enSchools,
    common:enCommon,
    addons:enAddons,
  },
  ar: {
    auth:arAuth,
    plans:arPlans,
    schools:arSchools,
    common:arCommon,
    addons:arAddons,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('lng') ?? 'en',
  ns:['auth','plans','schools','common','addons'],
  resources,
});

export default i18n