import { Accordion, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Col, Row } from "reactstrap";
import PlanStatus from "utils/enums/PlanStatus";
import Role from "utils/enums/Role";
import { dateAndTime, isOneOfRoles, isRole } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import PaymentDuer from "./Actions/AdminActions/PaymentDuer";

const PaymentInfo = (props: { plan: any }) => {
  const { plan } = props;
  const { t } = useTranslation(["plans", "common"]);
  const { data: user } = useUser();

  const attributes = [
    {
      label: `${plan.number_of_installments} ${t("payments")}`,
      value: plan.monthly_payment,
      row: true,
    },
    {
      label: t("total_amount"),
      value: plan.amount,
    },
    {
      label: t("principal_amount"),
      value: plan.principal_amount,
    },
  ];
  return (
    <Accordion defaultActiveKey="0" className="mb-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("plans:payment_info")}</Accordion.Header>
        <Accordion.Body>
          <Container>
            <Row>
              {attributes.map((element: any) => (
                <PaymentInfoColumn key={element.label} {...element} />
              ))}
              {isRole(Role.ADMIN, user) && <PaymentDuer plan={plan} />}
            </Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

function PaymentInfoColumn(props: {
  label: string;
  value: any;
  row?: boolean;
}) {
  const { label, value, row = false } = props;
  return (
    <Col md={row ? "12" : "6"} className="mb-4">
      <div className="d-flex flex-column">
        <div className="text-gray opacity-75">{label}</div>
        <div className="fs-6 text-dark">{value}</div>
      </div>
    </Col>
  );
}

export default PaymentInfo;
