import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { Row } from "reactstrap";
import AdminWidgets from "./AdminWidgets";
import FIWidgets from "./FIWidgets";
import SchoolWidgets from "./SchoolWidgets";
import UserWidgets from "./UserWidgets";
import ModeratorWidgets from "./ModeratorWidgets";

const Dashboard = () => {
  return (
    <Row>
      <RoleBasedRenderer
        admin={<AdminWidgets />}
        moderator={<ModeratorWidgets />}
        fi={<FIWidgets />}
        school={<SchoolWidgets />}
        user={<UserWidgets />}
      />
    </Row>
  );
};

export default Dashboard;
