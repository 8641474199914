import { Route, Routes } from "react-router-dom";
import CreateAddon from "./Create";
import AddonCustomers from "./Customers";
import AddonList from "./List";
import AddonsRestrictionRoute from "./RestrictedRoutes";

const AddonsRoutes = () => {
  return (
    <Routes>
      <Route element={<AddonsRestrictionRoute />} >
        <Route path="/new" element={<CreateAddon />} />
        <Route path="/:id/customers" element={<AddonCustomers />} />
        <Route path="/:id" element={<CreateAddon />} />
        <Route path="/" element={<AddonList />} />
      </Route>
    </Routes>
  );
};

export default AddonsRoutes;
