import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import StudentForm from "components/forms/Student";
import Icon from "components/widgets/Icon";
import { useState } from "react";
import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import students from "utils/api/students";
import { arabicValue, gradeLabel } from "utils/functions/utilities";
import useAlert from "utils/hooks/alert";
import { useUser } from "utils/hooks/auth";

type Props = {
  quickForm?: boolean;
};

const AddStudentsList = (props: Props) => {
  const { quickForm = false } = props;
  const { t } = useTranslation(["common", "plans"]);
  const { confirmOrDeny } = useAlert();
  const client = useQueryClient();
  const [error, setError] = useState(null);
  const [newStudentModal, setNewStudentModal] = useState(false);
  const [student, setStudent] = useState({});
  const { data: user } = useUser();

  const { data: studentsData } = useQuery(["students"], () => students.list(), {
    onSuccess: (data: any) => {
      if (data.length == 0 && quickForm) {
        setNewStudentModal(true);
      }
    },
  });

  const deleteMutation = useMutation((id: any) => students.destroy(id), {
    onSuccess: () => {
      client.invalidateQueries(["students"]);
    },
    onError: (error: any) => {
      setError(error?.message);
    },
  });

  function edit(student: any) {
    setStudent(student);
    setNewStudentModal(true);
  }

  function del(id: any) {
    confirmOrDeny({
      confirmButtonText: t("common:yes"),
      denyButtonText: t("common:no"),
      onConfirm: () => deleteMutation.mutate(id),
    });
  }

  return (
    <Container>
      <Modal
        centered
        aria-labelledby="contained-modal-students-vcenter"
        size="lg"
        show={newStudentModal}
        onHide={() => setNewStudentModal(false)}
        dir={user?.language == "ar" ? "rtl" : "ltr"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("plans:add_student")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StudentForm
            student={student}
            close={() => setNewStudentModal(false)}
          />
        </Modal.Body>
      </Modal>
      {!quickForm && (
        <Row>
          <Col
            lg={9}
            className="d-flex justify-content-between mb-2 mb-lg-5 px-0"
          >
            <div>
              <h5 className="text-dark fw-bold">{t("plans:students")}</h5>
              <p className="text-secondary">
                {t("plans:manage_students_here")}
              </p>
            </div>
          </Col>
          <Col lg={3} className="px-0">
            <Button
              size="lg"
              className="mb-3 w-100"
              onClick={() => {
                setStudent({});
                setNewStudentModal(true);
              }}
            >
              {t("plans:add_a_new_student")}
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        {error && (
          <Alert variant="danger" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
      </Row>
      <Row>
        {studentsData?.map((student: any, index: number) => (
          <StudentRow
            edit={edit}
            key={index}
            index={index}
            student={student}
            del={del}
          />
        ))}
      </Row>
      {quickForm && (
        <Row>
          <Col lg={12} className="px-0">
            <Button
              variant="light"
              size="lg"
              className="mb-3 w-100"
              onClick={() => {
                setStudent({});
                setNewStudentModal(true);
              }}
            >
              {t("plans:add_a_new_student")}
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

function StudentRow(props: any) {
  const { t } = useTranslation(["common", "plans"]);
  const { index, student, del, edit } = props;
  return (
    <Col lg={12} className="border border-primary rounded-2 p-4 mb-4">
      <Row>
        <Col lg={12} className="d-flex justify-content-between">
          <h6 className="text-dark fs-6 fw-bold">{student.name}</h6>
          <div>
            <span onClick={() => edit(student)} className="cursor-pointer mx-1">
              <Icon name="edit" />
            </span>
            <span
              onClick={() => del(student.id)}
              className="cursor-pointer mx-1"
            >
              <Icon name="trash" />
            </span>
          </div>
        </Col>
        <Col lg={6} className="mb-4">
          <p className="fs-7 text-graycool">{t("legal_name")}</p>
          <p className="fs-7 text-dark fw-semibold">{student.name}</p>
        </Col>
        <Col lg={6} className="mb-4">
          <p className="fs-7 text-graycool">{t("institution_name")}</p>
          <p className="fs-7 text-dark fw-semibold">
            {student.school && arabicValue(student.school, "name")}
          </p>
        </Col>
        <Col lg={6} className="mb-4">
          <p className="fs-7 text-graycool">{t("plans:grade")}</p>
          <p className="fs-7 text-dark fw-semibold">
            {student.grade && gradeLabel(student.grade)}
          </p>
        </Col>
        {/* <Col lg={6} className="mb-4">
          <p className="fs-7 text-graycool">{t("start_year")}</p>
          <p className="fs-7 text-dark fw-semibold">{student.name}</p>
        </Col> */}
      </Row>
    </Col>
  );
}

export default AddStudentsList;
