import { Notification } from "utils/types/notification";
import client from "../api";

const base = "notifications";

function list(): Promise<Notification[]> {
  return client.get(`${base}`);
}

function unread(): Promise<Notification[]> {
  return client.get(`${base}/unread`);
}

function markAsRead(id: number): Promise<Notification> {
  return client.get(`${base}/${id}/read`);
}


export const notifications = { list,unread,markAsRead };
