import { useMutation, useQueryClient } from "@tanstack/react-query";
import Icon from "components/widgets/Icon";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import plans from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";
import useAlert from "utils/hooks/alert";

type DashboardPlansActionsProps = {
  item: any;
};
const DashboardPlansActions = (props: DashboardPlansActionsProps) => {
  const { item } = props;
  return (
    <RoleBasedRenderer
      user={<UserActions item={item} />}
      moderator={<SchoolActions item={item} />}
      admin={<AdminActions item={item} />}
      fi={<FIActions item={item} />}
      school={<SchoolActions item={item} />}
    />
  );
};

const SchoolActions = (props: any) => {
  const { item } = props;
  return (
    <Link to={`/plans/${item.id}`}>
      <Icon name="openEye" />
    </Link>
  );
};

const AdminActions = (props: any) => {
  const { item } = props;
  return (
    <Link to={`/plans/${item.id}`}>
      <Icon name="openEye" />
    </Link>
  );
};

const FIActions = (props: any) => {
  const { item } = props;
  return (
    <Link to={`/plans/${item.id}`}>
      <Icon name="openEye" />
    </Link>
  );
};

const UserActions = (props: any) => {
  const client = useQueryClient();
  const { t } = useTranslation(["common"]);
  const { confirmOrDeny } = useAlert();
  const { item } = props;
  const deleteMutation = useMutation(() => plans.destroy(item.id), {
    onSuccess: (data) => {
      client.invalidateQueries(["plans"]);
    },
  });

  function del() {
    confirmOrDeny({
      confirmButtonText: t("common:yes"),
      denyButtonText: t("common:no"),
      onConfirm: deleteMutation.mutate,
    });
  }

  return [
    PlanStatus.INCOMPLETE,
    PlanStatus.ARCHIVED,
    PlanStatus.PENDING_DOCUMENTS,
  ].includes(item.status) ? (
    <>
      <Link to={`/plans/${item.id}/edit`}>
        <Icon name="edit" />
      </Link>
      <Link to="" onClick={del}>
        <Icon name="trash" />
      </Link>
    </>
  ) : (
    <>
      <Link to={`/plans/${item.id}`}>
        <Icon name="openEye" />
      </Link>
    </>
  );
};

export default DashboardPlansActions;
