import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { plans } from "utils/api/plans";
import PlanPendingFIActionsForm from "./FIActions/PlanPendingFIActionsForm";
import PlanPendingRubaTIDFIActionsForm from "./FIActions/PlanPendingRubaTIDFIActionsForm";
import PlanPendingSignatureFIActionsForm from "./FIActions/PlanPendingSignatureFIActionsForm";
import PlanPendingTIDFIActionsForm from "./FIActions/PlanPendingTIDFIActionsForm";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PlanStatus from "utils/enums/PlanStatus";
import TransactionType from "utils/enums/TransactionType";
import { useUser } from "utils/hooks/auth";

const PlanFIActions = (props: { plan: any }) => {
  const [expanded, setExpanded] = useState(false);
  const { plan } = props;
  const client = useQueryClient();
  const { t } = useTranslation(["common"]);
  const { data: user } = useUser();
  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  return (
    <>
      <PlanPendingFIActionsForm plan={plan} planMutation={planMutation} />
      <PlanPendingSignatureFIActionsForm
        plan={plan}
        planMutation={planMutation}
      />
      <PlanPendingTIDFIActionsForm plan={plan} planMutation={planMutation} />
      <PlanPendingRubaTIDFIActionsForm
        plan={plan}
        planMutation={planMutation}
      />
      {[PlanStatus.PENDING_FI, PlanStatus.PENDING_SIGNATURE, PlanStatus.ACTIVE, PlanStatus.COMPLETE].includes(plan.status) && (
        <Row>
          <Col lg={12}>
            <Button
              variant="outline-light"
              onClick={() => setExpanded((prev) => !prev)}
              className="w-100 my-3 py-3"
            >
              {t(expanded ? "minimize_actions" : "more_actions")}{" "}
              <i
                className={`ms-2 bi bi-chevron-${expanded ? "up" : "down"}`}
              ></i>
            </Button>
          </Col>
          {expanded && (
            <>
              {/* only if status is pending fi */}
              {[PlanStatus.PENDING_FI,PlanStatus.ACTIVE].includes(plan.status) && (
                <Col lg={12}>
                  <Button
                    variant="outline-primary"
                    className="w-100 mb-3"
                    onClick={() => planMutation.mutate({ plan: plan, values: { fi_user_id: user?.id, status: plan.status } })}
                  >
                    {t('plans:assign_to_myself')}
                  </Button>
                </Col>
              )}
              {!plan.transactions?.find((item: any) => item.type === TransactionType.COLLECTION) && <Col lg={12}>
                <Link to={`/reserve-transactions/${plan.id}/scheduler`}>
                  <Button variant="outline-primary" className="w-100 mb-3">
                    {t('create_installment_plan')}
                  </Button>
                </Link>
              </Col>}
            </>
          )}
        </Row>
      )}
    </>
  );
};

export default PlanFIActions;
