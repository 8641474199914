import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { countries } from "utils/api/countries";
import { fis } from "utils/api/fis";
import Role from "utils/enums/Role";
import { useUser } from "utils/hooks/auth";
import Icon from "components/widgets/Icon";

const ListFIs = (props: any) => {
  const { data, isSuccess } = useQuery(["fis"], fis.list);
  const { data: countriesData } = useQuery(["countries"], countries.list);
  const { data: user } = useUser();
  return (
    <Row>
      <Col lg="12">
        <Card className="main-card mb-3">
          <Card.Body>
            {user?.role_id == Role.ADMIN && (
              <Row className="justify-content-end">
                <Col lg={4} className="mb-3 text-end">
                  <Link to="/fis/new">
                    <Button className="w-100" variant="primary">
                      Create New Financial Institution!
                    </Button>
                  </Link>
                </Col>
              </Row>
            )}
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Country</th>
                  <th>Cut</th>
                  <th>Min. Amount</th>
                  <th>Min. Months</th>
                  <th>Active Plans</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>
                      {
                        countriesData?.find(
                          (country: any) => country.id == item.country_id
                        ).code
                      }
                    </td>
                    <td>{item.cut}</td>
                    <td>{item.min_amount}</td>
                    <td>{item.min_months}</td>
                    <td>{item.plans.length}</td>
                    <td>
                      <Link to={"/fis/" + item.id}>
                        <Icon name="edit" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ListFIs;
