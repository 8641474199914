import logo from "components/assets/utils/images/logo.svg";
import bg1 from "components/assets/utils/images/sidebar/joinruba.svg";
import bg2 from "components/assets/utils/images/sidebar/news-paper.svg";
import bg3 from "components/assets/utils/images/sidebar/peace-of-mind.svg";
import FormikInput from "components/inputs/Input";
import OTPField from "components/inputs/OTP";
import PhoneInput from "components/inputs/Phone";
import LanguageSwitch from "components/widgets/LanguageSwitch";
import { Formik, Form as FormikForm } from "formik";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getOtp, verifyOtp } from "utils/api/auth";
import { useRegister } from "utils/hooks/auth";
import { registerInitialValues, registerValidation } from "./validation";
import usePlaner from "utils/hooks/planer";
import i18n from "utils/i18n/config";

// Layout

const Register = (props) => {
  const defaultOtpTimer = 59;
  const register = useRegister();
  const navigate = useNavigate();
  const { t } = useTranslation();
  usePlaner();
  const [otpTimer, setOtpTimer] = useState(defaultOtpTimer);
  async function submit(values, formikBag) {
    const { setFieldValue, setErrors, setTouched } = formikBag;
    const { password, passwordConfirmation, ...eventData } = values;

    try {
      if (values.stage == 0) {
        await getOtp(values.mobile);
        TagManager.dataLayer({
          dataLayer: {
            event: "send_otp",
            data: JSON.stringify(eventData),
          },
        });
      }
      if (values.stage == 1) {
        await verifyOtp(values.mobile, values.otp);
        TagManager.dataLayer({
          dataLayer: {
            event: "verify_otp",
            data: JSON.stringify(eventData),
          },
        });
      }
      if (values.stage == 2) {
        await register.mutateAsync(values);
        TagManager.dataLayer({
          dataLayer: {
            event: "registered",
            data: JSON.stringify(eventData),
          },
        });
        navigate("/dashboard");
      }
      setOtpTimer(defaultOtpTimer);
      setFieldValue("stage", values.stage + 1);
      setTouched({}, false);
    } catch (e) {
      if (e.message) setErrors(e.message);
      else setErrors(e.errors);
    }
  }

  const stages = [
    {
      title: t("mobile"),
      desc: t("reachable_mobile"),
      buttonTitle: t("sign_up"),
      background: (
        <div className="text-center">
          <img src={bg1} alt="bg1" className="w-100 mb-5" />
          <h3 className="fw-bold text-dark">
            {t("discover_perfect_education")}
          </h3>
          <p className="secondary">{t("unlock_a_world")}</p>
        </div>
      ),
    },
    {
      title: t("verify_your_phone"),
      desc: t("we_sent_verification"),
      buttonTitle: t("verify_otp"),
      background: (
        <div
          className="text-center d-flex flex-column justify-content-center align-items-center"
          style={{
            height: "100%",
            width: "100%",
            background: `url('${bg2}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h3 className="fw-bold text-dark">{t("simplify_payments")}</h3>
          <p className="secondary">{t("effortlessly_manage")}</p>
        </div>
      ),
    },
    {
      title: t("create_your_password"),
      desc: t("sign_up_for_ruba"),
      buttonTitle: t("sign_up"),
      background: (
        <div className="text-center">
          <img src={bg3} alt="bg1" className="w-100 mb-5" />
          <h3 className="fw-bold text-dark">{t("peace_of_mind")}</h3>
          <p className="secondary">{t("ensure_journey")}</p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setOtpTimer((otpTimer) => otpTimer - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="vh-100" dir={i18n.language == "en" ? "ltr" : "rtl"}>
        <Formik
          initialValues={registerInitialValues}
          validationSchema={registerValidation}
          onSubmit={submit}
        >
          {(props) => (
            <FormikForm>
              <Row className="vh-100 g-0">
                <Col lg="6" md="12" className="h-100">
                  <Col
                    lg="9"
                    className="h-100 p-4 d-flex flex-column justify-content-between mx-auto"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <img src={logo} width={78} />
                      <LanguageSwitch />
                    </div>
                    <div className="w-100 w-lg-75 mx-auto">
                      <h3 className="text-dark">
                        {stages[props.values.stage].title}
                      </h3>
                      <p className="my-4 fbs-2 text-graycool">
                        {stages[props.values.stage].desc}{" "}
                        {props.values.stage == 1 && (
                          <>
                            <span className="fw-bold">
                              {props.values.mobile}{" "}
                            </span>
                            <a
                              href="#"
                              onClick={() => props.setFieldValue("stage", 0)}
                            >
                              ({t("edit")})
                            </a>
                          </>
                        )}
                      </p>
                      {typeof props.errors == "string" && (
                        <Alert color="danger">{props.errors}</Alert>
                      )}
                      {isArray(props.errors) && (
                        <Alert color="danger">
                          {props.errors.map((error) => (
                            <p className="my-0">
                              <b>{error.field}</b>: {error.message}
                            </p>
                          ))}
                        </Alert>
                      )}

                      {props.values.stage == 0 && (
                        <>
                          <div>
                            <Row>
                              <Col md={12}>
                                {props.values.stage == 0 && (
                                  <PhoneInput
                                    label={t("mobile")}
                                    name="mobile"
                                  />
                                )}
                              </Col>
                            </Row>
                            <Button
                              className="w-100 mt-4"
                              color="primary"
                              size="lg"
                              type="submit"
                            >
                              {stages[props.values.stage].buttonTitle}
                            </Button>
                          </div>
                          <p className="text-center fbs-3 mt-5">
                            {t("already_have_account") + " "}

                            <Link to="/login" className="text-primary fw-">
                              {t("sign_in")}
                            </Link>
                          </p>
                        </>
                      )}
                      {props.values.stage == 1 && (
                        <>
                          <OTPField name="otp" />
                          <Button
                            className="w-100 mt-4"
                            color="primary"
                            size="lg"
                            type="submit"
                          >
                            {stages[props.values.stage].buttonTitle}
                          </Button>
                          <p className="text-center mt-4">
                            {otpTimer >= 0 ? (
                              t("resend_code", {
                                seconds: otpTimer,
                              })
                            ) : (
                              <a
                                href="#"
                                color="link"
                                className="text-decoration-none fw-semibold"
                                onClick={() => {
                                  setOtpTimer(defaultOtpTimer);
                                  getOtp(props.values.mobile, true);
                                }}
                              >
                                {t("resend_otp")}
                              </a>
                            )}
                          </p>
                        </>
                      )}
                      {props.values.stage == 2 && (
                        <>
                          <Col lg={12} className="mb-3">
                            <FormikInput
                              type="password"
                              label={t("auth:password")}
                              name="password"
                            />
                          </Col>
                          <FormikInput
                            type="password"
                            label={t("auth:confirm_password")}
                            name="passwordConfirmation"
                          />
                          <Button
                            className="w-100 my-3"
                            color="primary"
                            size="lg"
                            type="submit"
                          >
                            {stages[props.values.stage].buttonTitle}
                          </Button>
                          <p className="text-center mt-3">
                            {t("already_have_account") + " "}

                            <Link to="/login" className="text-primary fw-">
                              {t("sign_in")}
                            </Link>
                          </p>
                        </>
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <a
                          href={`https://rubapay.com/privacy.html`}
                          target="_blank"
                          className="text-gray mx-3"
                        >
                          {t("common:privacy_policy")}
                        </a>
                        <a
                          href={`https://rubapay.com/terms.html`}
                          target="_blank"
                          className="text-gray"
                        >
                          {t("common:terms_of_service")}
                        </a>
                      </div>
                      <div>
                        <a href="https://facebook.com/findruba">
                          <i className="mx-2 text-gray bi bi-facebook"></i>
                        </a>
                        <a href="https://twitter.com/findruba">
                          <i className="mx-2 text-gray bi bi-twitter"></i>
                        </a>
                        <a href="https://instagram.com/findruba">
                          <i className="mx-2 text-gray bi bi-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Col>
                <Col lg="6" className="d-none d-lg-block bg-light">
                  <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                    {stages[props.values.stage].background}
                  </div>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Register;
