import AfterRedirectionPopup from "containers/Installments/List/PaymentSummary/AfterRedirectionPopup";
import PlanCinetSignatureActionsForm from "./UserActions/PlanCinetSignatureActionsForm";
import PlanMissingDocumentsActionsForm from "./UserActions/PlanMissingDocumentsActionsForm";
import PlanPendingServiceFeeActionsForm from "./UserActions/PlanPendingServiceFeeActionsForm";
import PlanProposedReserveScheduleActionsForm from "./UserActions/PlanProposedReserveScheduleActionsForm";

const PlanUserActions = (props: { plan: any }) => {
  const { plan } = props;

  return (
    <>
      <PlanProposedReserveScheduleActionsForm plan={plan} />
      <AfterRedirectionPopup redirectTo={`/plans/${plan.id}`} />
      <PlanMissingDocumentsActionsForm plan={plan} />
      <PlanCinetSignatureActionsForm plan={plan} />
      <PlanPendingServiceFeeActionsForm plan={plan} />
    </>
  );
};

export default PlanUserActions;
