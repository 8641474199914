import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Breadcrumb, BreadcrumbItem, Button, Card } from "reactstrap";
import { chatbot } from "utils/api/chatbot";
import users from "utils/api/users";
import * as Yup from "yup";

const ChatbotCreateQuestion = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: questionData } = useQuery(["chatbot", id], chatbot.show(id), {
    enabled: Boolean(id),
  });

  const deleteMutation = useMutation(chatbot.destroy, {
    onSuccess: () => {
      navigate("/chatbot");
    },
  });

  async function submit(values: any) {
    try {
      let res;
      if (id) {
        res = await chatbot.update(id, values);
      } else {
        res = await chatbot.store(values);
      }

      navigate("/chatbot");
    } catch (e) {}
  }
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/chatbot">
            <FontAwesomeIcon icon={faUser} /> Chatbot
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{id ?`Question #${id}` : "New Question" }</BreadcrumbItem>
      </Breadcrumb>
      <TransitionGroup>
        <CSSTransition
          component="div"
          classNames="TabsAnimation"
          appear={true}
          timeout={1500}
          enter={false}
          exit={false}
        >
          <Card body>
            <Formik
              enableReinitialize
              initialValues={{
                question: "",
                answer: "",
                ...questionData,
              }}
              validationSchema={Yup.object().shape({
                question: Yup.string().required(),
                answer: Yup.string().required(),
              })}
              onSubmit={submit}
            >
              <Form>
                <Input type="text" name="question" label="Question" />
                <Input type="text" name="answer" label="Answer" />
                {id && (
                  <Button
                    onClick={() => deleteMutation.mutate(id)}
                    className="me-4 btn-icon"
                    color="danger"
                  >
                    <i className="pe-7s-trash btn-icon-wrapper"> </i>
                    Delete Question
                  </Button>
                )}
                <Button color="primary" type="submit">
                  Save
                </Button>
              </Form>
            </Formik>
          </Card>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default ChatbotCreateQuestion;
