import { Route, Routes } from "react-router-dom";
import CreatePlan from "./Create";
import PlansList from "./List";
import ViewPlan from "./View";
import PlanTransactions from "./Transactions/List";

const PlansRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PlansList />} />
      <Route path="/new" element={<CreatePlan />} />
      <Route path="/transactions" element={<PlanTransactions />} />
      <Route path="/:id/edit" element={<CreatePlan />} />
      <Route path="/:id" element={<ViewPlan />} />
    </Routes>
  );
};

export default PlansRoutes;
