import { DoNotContactType } from "utils/types/DoNotContact";
import client from "../api";

const base = "do-not-contact";

export function list(): Promise<DoNotContactType[]> {
    return client.get(base);
}

export function view(id: string): Promise<DoNotContactType> {
    return client.get(`${base}/${id}`);
}

export function store(values: {
    mobile: string;
    reason?: string;
}): Promise<DoNotContactType> {
    return client.post(base, values);
}

export function update(id: string, values: DoNotContactType): Promise<DoNotContactType> {
    return client.put(`${base}/${id}`, values);
}

export function destroy(id: number): Promise<DoNotContactType> {
    return client.delete(`${base}/${id}`);
}

// showbymobile
export function showByMobile(mobile: string): Promise<DoNotContactType> {
    return client.get(`${base}/mobile/${mobile}`);
}

export const doNotContact = {
    list,
    view,
    store,
    update,
    destroy,
    showByMobile,
};
