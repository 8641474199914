import { useQueryClient } from "@tanstack/react-query";
import Amount from "components/widgets/Amount";
import ReserveTransactionStatusRenderer from "components/widgets/ReserveTransactionStatusRenderer";
import TransactionStatusRenderer from "components/widgets/TransactionStatusRenderer";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Card, Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { reserveTransactions } from "utils/api/reserveTransactions";
import { breakPoints } from "utils/constants";
import { ReserveTransactionStatus } from "utils/enums/ReserveTransactionStatus";
import TransactionStatus from "utils/enums/TransactionStatus";
import { download, installmentColor } from "utils/functions/utilities";
import useAlert from "utils/hooks/alert";
import { usePaginate } from "utils/hooks/paginate";
import useWindowDimensions from "utils/hooks/window";

type Props = {
};

const PendingReserveTransactions = (props: Props) => {
  const [filter, setFilter] = useState({});
  const { confirmOrDeny } = useAlert();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(["common", "plans", "schools"]);
  const { width } = useWindowDimensions();
  const client = useQueryClient();

  const { data } = usePaginate({
    key: "reserve_transactions",
    api: (...args:any) => id ? reserveTransactions.byReserveId(id) : reserveTransactions.list(...args),
    filter,
    options: {
      placeholderData: [],
    },
  });

  function settle(id: number) {
    confirmOrDeny({
      title: t("settle_transaction"),
      text: t("settle_transaction_desc"),
      confirmButtonText: t("settle"),
      showDenyButton: false,
      onConfirm: async () => {
        await reserveTransactions.settle(id.toString());
        client.invalidateQueries(["reserve_transactions"]);
      },
    });
  }

  async function getInvoice(id: number) {
    const data = await reserveTransactions.generatedDocument(id, "invoice");
    download(`Rubapay-${id}-settlment.pdf`, data);
  }

  return (
    <>
      {width > breakPoints.md ?
        <Card className="main-card mb-3">
          <Card.Body>
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>{t("plans:plan_id")}</th>
                  <th>{t("scheduled_date")}</th>
                  <th>{t("plans:amount")}</th>
                  <th>{t("plans:name")}</th>
                  <th>{t("plans:installments")}</th>
                  <th>{t("plans:status")}</th>
                  <RoleBasedRenderer
                    admin={
                      <th>{t("plans:actions")}</th>}
                    school={
                      <th>{t("plans:actions")}</th>}
                    user={
                      <th>{t("plans:actions")}</th>}
                  />
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((item: any) => (
                  <tr key={item.id}>
                    <td>
                      <Link
                        className="text-decoration-none"
                        to={`/plans/${item?.plan?.id}`}
                      >
                        {item?.plan?.public_id}
                      </Link>
                    </td>
                    <td>
                      {DateTime.fromISO(item.scheduled_date).toFormat("yyyy/MM/dd")}
                    </td>
                    <td className={"fw-bold " + installmentColor(item)}>
                      {item.amount} {t("kd")}
                    </td>
                    <td className="fw-bold">{item?.toReserve.entity?.name}</td>
                    <td className="fw-bold">
                      {t("x_out_of_y", {
                        x: item.order,
                        y: item.total_per_plan,
                      })}
                    </td>
                    <td className="fw-bold">
                      <ReserveTransactionStatusRenderer
                        {...item}
                      />
                    </td>
                    <RoleBasedRenderer admin={
                      <td>
                        {(!item.transactions?.some(
                          (trx: any) => trx.status != TransactionStatus.SUCCESS
                        ) && item.status != ReserveTransactionStatus.SETTLED) && (
                            <Button
                              onClick={() => settle(item.id)}
                              variant="link"
                              className="py-0 px-2"
                            >
                              <i className="bi bi-play-fill"></i>
                            </Button>
                          )}
                      </td>}
                      school={
                        <td>
                          {item.status == ReserveTransactionStatus.SETTLED &&
                            <Button
                              className="p-0 text-decoration-none fs-7 fw-semibold text-dark text-opacity-75"
                              onClick={() => getInvoice(item.id)}
                              variant="link"
                            >
                              <i className="bi bi-arrow-down-circle mx-1"></i>
                              {t("invoice")}
                            </Button>
                          }
                        </td>
                      }
                      user={
                        <td>
                          {item.status == ReserveTransactionStatus.SETTLED &&
                            <Button
                              className="p-0 text-decoration-none fs-7 fw-semibold text-dark text-opacity-75"
                              onClick={() => getInvoice(item.id)}
                              variant="link"
                            >
                              <i className="bi bi-arrow-down-circle mx-1"></i>
                              {t("invoice")}
                            </Button>
                          }
                        </td>
                      }
                    />
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card> :
        data?.data?.map((item: any) => (
          <Col
            onClick={() => getInvoice(item.id)}
            lg={12}
            className="border border-light rounded-2 mb-3 p-3"
          >
            <div className="d-flex justify-content-between">
              <h6 className={"fw-bold " + installmentColor(item)}>
                <Amount amount={item.amount} />
              </h6>
              <p className="fbs-4 fw-semibold">
                <TransactionStatusRenderer
                  {...item}
                />
              </p>
            </div>
            <p className="fs-7 fw-semibold text-graycool mb-2">
              {item?.toReserve.entity?.name}
            </p>
            <div className="d-flex justify-content-start mb-2">
              <div className="fbs-4">
                <b>{t("scheduled_date")}</b> {DateTime.fromISO(item.scheduled_date).toFormat("yyyy/MM/dd")}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <p className="fbs-4 fw-semibold text-dark">
                {t("plans:installments")}
              </p>
              <p className="fbs-4 fw-semibold text-dark">{t("plans:plan_id")}</p>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <p className="fbs-4 text-gray">{t("x_out_of_y", {
                x: item.order,
                y: item.total_per_plan,
              })}</p>
              <p className="fbs-4 text-gray">{item?.plan?.public_id}</p>
            </div>
            {item.status == ReserveTransactionStatus.SETTLED &&
              <div className="d-flex justify-content-end">
                <i className="text-info bi bi-download ms-auto"></i>
              </div>
            }
          </Col>
        ))}
    </>
  );
};

export default PendingReserveTransactions;
