import client from "../api";

export function list({ page = 1, perPage = 10, ...filter }: any): Promise<any> {
  return client.get(`logs?page=${page}&perPage=${perPage}`, { params: filter });
}


export function view(id?: any): Promise<any> {
  return client.get(`logs/${id}`);
}

export function destroy(id: string): Promise<any> {
  return client.delete(`logs/${id}`);
}

export function filterables(): Promise<any> {
  return client.get(`logs/filterables`);
}

export default { list, view, destroy, filterables };
