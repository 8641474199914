import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import grades from "utils/api/grades";
import Role from "utils/enums/Role";
import SchoolType from "utils/enums/SchoolType";
import { useUser } from "utils/hooks/auth";

const ListGrades = (props: any) => {
  const { data } = useQuery(["grades"], grades.list);
  const { t } = useTranslation(["common", "plans", "grades"]);
  const {data:user} = useUser();
  return (
    <Row>
      <Col lg="12">
        <Card className="main-card mb-3">
          <CardBody>
            {user?.role_id == Role.ADMIN && (
              <CardTitle className="d-flex justify-content-end">
                <Link to="/grades/new">
                  <Button size="sm" color="primary" className="mx-2">
                    {t("schools:add_a_grade")}
                  </Button>
                </Link>
              </CardTitle>
            )}
            <Table striped className="mb-0">
              <thead>
                <tr>
                  <th>{t("plans:name")}</th>
                  <th>{t("plans:arabic_name")}</th>
                  <th>{t("schools:grade_type")}</th>
                  <th>{t("plans:actions")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.name_ar}</td>
                    <td>{item.type}</td>
                    <td>
                      <Link to={"/grades/" + item.id}>
                        <Button size="sm" color="secondary">
                          {t("common:edit")}
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ListGrades;
