import Select from "components/inputs/Select";
import { useTranslation } from "react-i18next";
import { missingDocumentsOptions } from "utils/constants";

const MissingDocumentsChecklist = () => {
  const { t } = useTranslation(["plans", "common"]);
  return (
    <Select
      multiple
      options={missingDocumentsOptions.map((i: string) => ({
        name: t(i),
        id: i,
      }))}
      labelKey="name"
      valueKey="id"
      name="missing_document"
      label={t("missing_documents")}
      style={{ height: 160 }}
    />
  );
};

export default MissingDocumentsChecklist;
