import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { plans } from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";
import * as Yup from "yup";

// deprecated
const PlanPendingPayment = (props: any) => {
  const { plan } = props;
  const { t } = useTranslation(["common", "plans"]);
  const client = useQueryClient();
  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  function submit(values: any) {
    planMutation.mutate({ plan, values });
  }

  let status =
    plan.ruba_cut <= 0 ? PlanStatus.ACTIVE : PlanStatus.PENDING_RUBA_TID;

  return (
    <>
      {plan.status == PlanStatus.PENDING_PAYMENT_SCHOOL && (
        <Formik
          onSubmit={submit}
          initialValues={{ status }}
          validationSchema={Yup.object().shape({
            fi_trans_id: Yup.string().required(),
          })}
        >
          <Form>
            <Input name="fi_trans_id" label={t("plans:fi_trans_id")} required />
            <Row>
              <Col className="d-flex justify-content-end" md="12">
                <Button type="submit" color="primary">
                  {t("submit")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default PlanPendingPayment;
