import { faBookBookmark, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import TablePages from "components/widgets/TablePages";
import { useState } from "react";
import { Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { chatbot } from "utils/api/chatbot";
import { chatbotHistory } from "utils/api/chatbot/history";
import { dateAndTime } from "utils/functions/utilities";
import useAlert from "utils/hooks/alert";
import { usePaginate } from "utils/hooks/paginate";

type Props = {};

const ChatbotHistory = (props: Props) => {
  const [filter, setFilter] = useState({});
  const paginate = usePaginate({
    key: "chatbot-history",
    api: chatbotHistory.list,
    filter,
  });

  const client = useQueryClient();
  const { success } = useAlert();
  const deleteMutation = useMutation(chatbotHistory.destroy, {
    onSuccess: () => {
      client.invalidateQueries(["chatbot-history"]);
      toast.info("Deleted successfully!");
    },
  });

  const clearMutation = useMutation(chatbotHistory.clear, {
    onSuccess: () => {
      client.invalidateQueries(["chatbot-history"]);
      toast.info("Cleared successfully!");
    },
  });

  function confirmClear() {
    success({
      onConfirm: clearMutation.mutate,
      message: `All History will be deleted`,
    });
  }

  function del(id: number) {
    deleteMutation.mutate(id as any);
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/chatbot">
            <FontAwesomeIcon icon={faUser} /> Chatbot
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>History</BreadcrumbItem>
      </Breadcrumb>
      <Row>
        <Col lg="12">
          <Card className="main-card mb-3">
            <CardHeader>
              <div className="w-100 d-flex justify-content-between">
                <CardTitle>Chat History</CardTitle>
                <div>
                  <Button
                    className="mb-2 me-2 btn-icon"
                    outline
                    color="danger"
                    onClick={confirmClear}
                  >
                    <i className="pe-7s-trash btn-icon-wrapper"> </i>
                    Clear History
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Table striped className="mb-0">
                <thead>
                  <tr>
                    <th>Channel</th>
                    <th>Contact ID</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Date / Time</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paginate?.data?.data?.map((item: any) => (
                    <tr key={item.id}>
                      <td>{item.channel}</td>
                      <td>{item.contact_id}</td>
                      <td>{item.question}</td>
                      <td>{item.answer}</td>
                      <td>{dateAndTime(item.created_at)}</td>
                      <td>
                        <Button
                          size="sm"
                          color="danger"
                          outline
                          onClick={() => del(item.id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col lg="12">
          <TablePages {...paginate} />
        </Col>
      </Row>
    </>
  );
};

export default ChatbotHistory;
