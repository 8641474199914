import Amount from "components/widgets/Amount";
import { Accordion, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import PlanStatus from "utils/enums/PlanStatus";
import Role from "utils/enums/Role";
import TransactionStatus from "utils/enums/TransactionStatus";
import TransactionType from "utils/enums/TransactionType";
import { dateAndTime, isOneOfRoles, isRole } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";

const BasicInfo = (props: { plan: any; dashboard: boolean }) => {
  const { plan, dashboard } = props;
  const { t } = useTranslation(["plans", "common"]);
  const { data: user } = useUser();
  const serviceFee = plan.transactions?.find((trx: any) => trx.type == TransactionType.SERVICE_FEE)?.amount || 0;
  const reserveId = plan.transactions?.find((trx: any) => trx.type == TransactionType.RECHARGE)?.reserve?.id;

  const attributes = [
    {
      label: t("assigned_fi_user"),
      value: plan.fiUser?.name,
      condition: isOneOfRoles([Role.FI, Role.ADMIN], user),
    },
    {
      label: t("plans:reason"),
      value: plan.reason,
      condition:
        !!plan.reason &&
        (plan.status != PlanStatus.ARCHIVED || isOneOfRoles([Role.ADMIN, Role.MODERATOR], user)),
    },
    {
      label: t("plans:reserve_reason"),
      value: plan.reserve_reason,
      condition:
        !!plan.reserve_reason && isOneOfRoles([Role.ADMIN, Role.MODERATOR], user),
    },
    {
      label: t("plans:direct_financing"),
      value: <IoIosCheckmarkCircle fontSize="18px" color="#3ac47d" />,
      condition: !!plan.school.direct_financing,
    },
    {
      label: "Split Flag",
      value: <IoIosCheckmarkCircle fontSize="18px" color="#3ac47d" />,
      condition: !!plan.extras?.split,
    },
    {
      label: t("common:paynow"),
      value: <IoIosCheckmarkCircle fontSize="18px" color="#3ac47d" />,
      condition: !!plan.paynow && !isRole(Role.USER, user),
    },
    {
      label: t("plans:reserve_plan"),
      value: <>
        <IoIosCheckmarkCircle fontSize="18px" color="#3ac47d" />
        <Link state={
          {
            backReserve: {
              id: plan.fi?.reserve?.id,
              name: plan.fi?.name
            }
          }
        } to={isRole(Role.USER, user) ? `/installments` : `/reserves/${reserveId}`} className="btn btn-link p-0 text-decoration-none"> <i className="bi bi-box-arrow-up-right"></i></Link>
      </>,
      condition: !isRole(Role.FI, user) && !!plan?.transactions?.find((item: any) => item.type === TransactionType.RECHARGE),
    },
    {
      label: t("plans:payment_plan"),
      value: <>
        <IoIosCheckmarkCircle fontSize="18px" color="#3ac47d" />
        <Link state={
          {
            backReserve: {
              id: plan.fi?.reserve?.id,
              name: plan.fi?.name
            }
          }
        } to={isRole(Role.USER, user) ? `/installments` : `/reserves/${plan.transactions?.[0]?.reserve?.id}`} className="btn btn-link p-0 text-decoration-none"> <i className="bi bi-box-arrow-up-right"></i></Link>
      </>,
      condition: !isRole(Role.SCHOOL, user) && !!plan?.transactions?.find((item: any) => item.type === TransactionType.COLLECTION),
    },
    { label: t("common:creation_date"), value: dateAndTime(plan.created_at) },
    {
      label: t("installments"),
      value: plan.number_of_installments + " " + t("common:months"),
    },
    { label: t("amount"), value: plan.amount },
    {
      label: t("principal_amount"),
      value: plan.principal_amount,
      condition: isOneOfRoles([Role.ADMIN, Role.FI], user) && !plan.paynow,
    },
    {
      label: t("common:year"),
      value: plan.current_year,
    },
    {
      label: t("amount_after_discount_kd"),
      value: (plan.amount - plan.ruba_cut - plan.fi_cut).toFixed(3),
      condition:
        !plan.school.direct_financing &&
        plan.fi &&
        isOneOfRoles([Role.ADMIN, Role.FI, Role.SCHOOL], user),
    },
    {
      label: t("discounted_amount"),
      value: plan.ruba_cut + plan.fi_cut,
      condition:
        !plan.school.direct_financing &&
        plan.fi &&
        isOneOfRoles([Role.ADMIN, Role.FI, Role.SCHOOL], user),
    },
    { label: t("monthly_amount_in_kd"), value: plan.monthly_payment, condition: !plan.paynow },
    {
      label: t("service_fee"),
      value: <div className="d-flex">
        <Amount amount={serviceFee} />
        {plan.transactions?.some((trx: any) => trx.status === TransactionStatus.SUCCESS && trx.type == TransactionType.SERVICE_FEE) ? <IoIosCheckmarkCircle fontSize="18px" color="#3ac47d" /> : null}
      </div>,
      condition: isOneOfRoles([Role.ADMIN], user) && plan.transactions?.some((trx: any) => trx.status === TransactionStatus.SUCCESS && trx.type == TransactionType.SERVICE_FEE),
    },
    {
      label: t("ruba_cut"),
      value: plan.ruba_cut,
      condition: plan.fi && isOneOfRoles([Role.ADMIN, Role.FI], user),
    },
    {
      label: t("schools:discount"),
      value: `${(((plan.ruba_cut + plan.fi_cut) / plan.amount) * 100).toFixed(
        1
      )}%`,
      condition:
        plan.fi &&
        plan.status != PlanStatus.PENDING_QUOTATION &&
        isOneOfRoles([Role.ADMIN, Role.FI], user),
    },
    {
      label: `${plan.fi?.name} Cut`,
      value: plan.fi_cut,
      condition: plan.fi && isOneOfRoles([Role.ADMIN, Role.FI], user),
    },
    {
      label: t("school_iban"),
      value: plan.school.iban,
      condition: plan.fi && isOneOfRoles([Role.ADMIN, Role.FI], user),
    },
    {
      label: t("ruba_iban"),
      value: "KW39NBOK0000000000002025925845",
      condition: plan.fi && isOneOfRoles([Role.ADMIN, Role.FI], user),
    },
    {
      label: `${plan.fi?.name} ${t("iban")}`,
      value: plan.fi?.iban,
      condition: plan.fi && isOneOfRoles([Role.ADMIN, Role.FI], user),
    },
  ];
  return (
    <Accordion defaultActiveKey="0" className="mb-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("plans:basic_info")}</Accordion.Header>
        <Accordion.Body>
          <Container>
            <Row>
              {attributes.map((element: any) => (
                <BasicInfoColumn key={element.label} {...element} />
              ))}
            </Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

function BasicInfoColumn(props: {
  label: string;
  value: any;
  cols?: number;
  condition?: boolean;
}) {
  const { label, value, condition = true, cols = 6 } = props;
  if (!condition) return <></>;
  return (
    <Col md={cols} className="mb-4">
      <div className="d-flex flex-column">
        <div className="text-gray opacity-75">{label}</div>
        <div className="fs-6 text-dark">{value}</div>
      </div>
    </Col>
  );
}

export default BasicInfo;
