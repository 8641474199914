import logo from "components/assets/utils/images/logo.svg";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Card, Collapse, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import Role from "utils/enums/Role";
import { dateAndTime } from "utils/functions/utilities";

const PlanLogsDetails = (props: { plan: any }) => {
  const { plan } = props;
  const { logs } = plan;
  const { t } = useTranslation(["common"]);
  const [preview, setPreview] = useState(true);

  return (
    <Card>
      <Card.Header>
        <Card.Title className="fs-6">{t("logs")}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container>
          <Row>
            {logs?.length >= 0 && (
              <VerticalTimeline
                className="vertical-time-icons px-0"
                layout="1-column"
              >
                {preview
                  ? logs
                      .slice(0, 3)
                      .map((log: any, i: number) => (
                        <LogDetails hideLine={i == 2} key={i} log={log} />
                      ))
                  : logs.map((log: any, i: number) => (
                      <LogDetails
                        hideLine={i == logs.length - 1}
                        key={i}
                        log={log}
                      />
                    ))}
              </VerticalTimeline>
            )}
          </Row>
        </Container>
      </Card.Body>
      <Card.Footer
        onClick={() => setPreview((prev) => !prev)}
        className="cursor-pointer"
      >
        {preview ? t("common:view_more") : t("common:view_less")}
      </Card.Footer>
    </Card>
  );
};

function LogDetails(props: { log: any; hideLine: boolean }) {
  const { log, hideLine } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["common"]);
  const readable = dateAndTime(log.created_at)
  return (
    <VerticalTimelineElement
      className="vertical-timeline-item"
      icon={
        <div className="timeline-icon border-primary">
          <i className="lnr-pencil icon-gradient bg-night-fade" />
        </div>
      }
    >
      <div className="d-flex align-items-center">
        <div className="me-2">
          <img
            src={logo}
            className="border border-light rounded-circle p-1"
            style={{ height: "48px", width: "48px" }}
          />
        </div>
        <div>
          <h5 className="fbs-4 mb-0 fw-normal text-gray timeline-title">
            <span className="fs-7 fw-semibold text-dark">
              {Role[log.user.role_id].toLowerCase()+":"+log.user.name.split(' ')[0]+" "}
            </span>
            {readable}
          </h5>
          <h5 className="text-graycool fs-7 fw-normal mt-0">{log.action}</h5>
        </div>
        <div className="flex-grow-1 text-end">
          <Button
            className="m-0 mt-2 p-0 fbs-4"
            variant="link"
            onClick={() => setOpen((prev) => !prev)}
            aria-controls={"collapse-log-" + log.id}
            aria-expanded={open}
          >
            {!open ? t("common:view_more") : t("common:view_less")}
          </Button>
        </div>
      </div>

      <Collapse in={open}>
        <div className="mt-2" id={"collapse-log-" + log.id}>
          <Card bg="secondary">
            <Card.Body className="p-2">
              {Object.entries(log.data).map(([key, value], i) => (
                <p key={i} className="text-white">
                  <b>{key}:</b> {value}
                </p>
              ))}
            </Card.Body>
          </Card>
        </div>
      </Collapse>
      {!hideLine && (
        <hr style={{ height: "1px" }} className="bg-light opacity-75" />
      )}
    </VerticalTimelineElement>
  );
}

export default PlanLogsDetails;
