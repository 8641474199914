import PreviewButton from "containers/common/Previewers/PreviewButton";
import { Accordion, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FileType from "utils/enums/FileType";
import Role from "utils/enums/Role";
import { isRole } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";

const PlanSchoolDetails = (props: { plan: any }) => {
  const { data: user } = useUser();
  const { plan } = props;
  const { t } = useTranslation(["plans"]);
  return (
    <>
      {plan.school.files?.length > 0 && (
        <Container>
          <Row>
            {plan.school.files.map((file: any) => (
              <PlanSchoolDetailsColumn key={file.id} file={file} user={user} />
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

type File = {
  id: string;
  value: string;
  fi_id?: number;
  type: string;
};
function PlanSchoolDetailsColumn(props: { user: any; file: File }) {
  const {
    user,
    file: { id, type, fi_id },
  } = props;
  const { t } = useTranslation(["plans"]);

  if (type == FileType.SIGNATURE) return <></>;

  if (
    [FileType.BANK_LETTER, FileType.SIGNATURE_DELEGATION].includes(
      type as FileType
    ) &&
    isRole(Role.FI, user)
  ) {
    if (!user.fis.some((fi: any) => fi.id == fi_id)) return <></>;
  }

  return (
    <div className="w-100 mb-2">
      <PreviewButton id={id} label={t(type)} />
    </div>
  );
}

export default PlanSchoolDetails;
