import client from "../api";
import { Question } from "./types";

export function list() {
  return client.get(`chatbot`);
}

export function questions(): Promise<Question[]> {
  return client.get(`chatbot/questions`);
}

export function ask(question: string): Promise<any> {
  return client.post(`chatbot/ask`, { question });
}

export function settings(): Promise<any> {
  return client.get(`chatbot/settings`);
}

export function updateSettings(values: any): Promise<any> {
  return client.put(`chatbot/settings`, values);
}

export function show(id?: any) {
  return () => client.get(`chatbot/${id}`) as any;
}

export function store(values: any) {
  return client.post("chatbot", values) as any;
}

export function update(id: string, values: any) {
  return client.put(`chatbot/${id}`, values) as any;
}

export function destroy(id: string) {
  return client.delete(`chatbot/${id}`) as any;
}

export const chatbot = {
  list,
  questions,
  settings,
  updateSettings,
  show,
  store,
  update,
  destroy,
  ask,
};
