import { useMutation, useQuery } from "@tanstack/react-query";
import cx from "classnames";
import limitWarning from "components/assets/utils/images/plan/limit-warning.svg";
import AmountInput from "components/inputs/Amount";
import Amount from "components/widgets/Amount";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  Spinner,
  ToggleButton
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import plans from "utils/api/plans";
import { reserveTransactions } from "utils/api/reserveTransactions";
import { breakPoints } from "utils/constants";
import EMPLOYMENTTYPE from "utils/enums/EmploymentType";
import { useUser } from "utils/hooks/auth";
import usePlaner from "utils/hooks/planer";
import useWindowDimensions from "utils/hooks/window";
import * as Yup from "yup";

type Props = {
  previousStep: () => void;
  nextStep: (skipStep?: boolean) => void;
};

const PlanCustomizeAmount = (props: Props) => {
  const { previousStep, nextStep } = props;
  const { t } = useTranslation(["plans"]);
  const { id } = useParams();
  const { data: user } = useUser();
  const { width } = useWindowDimensions();

  const { data: plan } = useQuery(["plans", id], () => plans.view(id), {
    initialData: {},
  });

  const updatePlan = useMutation((values) => plans.update(id, values));

  async function submit(values: any, formikBag: FormikHelpers<any>) {
    try {
      const paynow: any = values.paynow ? {
        planId: id,
        amounts: plan.school.payment_dates.map((date: any) => ((date.percentage / 100) * plan.amount).toFixed(3)),
        dates: plan.school.payment_dates.map((date: any) => date.date),
        isPaid: values.extras?.paynow.map((p: any) => p.paymentDates[0].isPaid),
      } : null;
      let response = undefined;
      if (values.paynow) {
        response = await reserveTransactions.prepareSchedule(paynow);
      }
      await updatePlan.mutateAsync({
        ...values,
        extras: values.paynow ? { ...plan.extras, paynow: response } : plan.extras
      });
      nextStep(values?.paynow);
    } catch (e) {
      console.log(e);
    }
  }

  function paynow(formikBag: any, active: boolean) {
    formikBag.setFieldValue('number_of_installments', plan.school.payment_dates.length)
    formikBag.setFieldValue('monthly_payment', 0)
    formikBag.setFieldValue('paynow', active)
    if (active) {
      formikBag.setFieldValue('extras.paynow', Array(plan.school.payment_dates.length).fill({
        paymentDates: [{ isPaid: false }]
      }))
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...plan,
      }}
      validationSchema={Yup.object().shape({
        amount: Yup.number().required("Required"),
        paynow: Yup.boolean(),
        number_of_installments: Yup.number().when("paynow", {
          is: (t: any) => !t,
          then: Yup.number().required(t("Required")),
        }),
        monthly_payment: Yup.number().when("paynow", {
          is: (t: any) => !t,
          then: Yup.number().required(t("Required")),
        }),
      })}
      onSubmit={submit}
    >
      {(props) => (
        <>
          <Col lg={8}>
            <Row className="gy-4">
              <Col lg={12}>
                <h5 className="text-dark fw-bold">
                  {t("customize_your_payment")}
                </h5>
              </Col>
              {plan.extras?.acceptableAmount && (
                <Col lg={12} className="p-0">
                  <Alert variant="warning" className="m-0">
                    <div className="d-flex">
                      {t("plans:suggested_amount_is")}:
                      <Amount className="mx-1" amount={plan.extras?.acceptableAmount} />
                    </div>
                    <div>
                      {t("plans:suggested_period_is")}: {plan.extras?.acceptableMonths}
                    </div>
                  </Alert>
                </Col>
              )}
              <Col
                lg={12}
                className="bg-light-graycool border rounded-1 p-3"
              >
                <Row>
                  <Col lg={6}>
                    <p className="mb-2 fw-semibold fs-7">
                      {t("total_amount_for_plan")}
                    </p>
                    <p className="fbs-4 mb-3">{t("editable_total")}</p>
                  </Col>
                  <Col lg={6}>
                    <AmountInput name="amount" />
                  </Col>
                </Row>
              </Col>
              {!!plan.school?.paynow &&
                <Col lg={12} className="px-4">
                  <Col lg={12}>
                    <Row className="gy-2">
                      <Form.Check
                        id="paylater"
                        className="position-relative px-0"
                      >
                        <Form.Check.Input
                          type="radio"
                          name="paynow"
                          checked={!props.values.paynow}
                        />
                        <Form.Check.Label
                          onClick={() => paynow(props, false)}
                          htmlFor="paylater"
                          className={cx(
                            "form-check-container border rounded-2 ps-5 pe-2 py-3 w-100 border-bottom",
                            {
                              "border-primary bg-light-blue active": !props.values.paynow,
                            }
                          )}
                        >
                          {t("spread_up_to_x_months", {
                            months: 12
                          })}
                        </Form.Check.Label>
                      </Form.Check>

                      <Form.Check
                        id="paynow"
                        className="position-relative px-0"
                      >
                        <Form.Check.Input
                          type="radio"
                          name="paynow"
                          checked={props.values.paynow} />
                        <Form.Check.Label
                          onClick={() => paynow(props, true)}
                          htmlFor="paynow"
                          className={cx(
                            "form-check-container border rounded-2 ps-5 pe-2 py-3 w-100 border-bottom",
                            {
                              "border-primary bg-light-blue active": props.values.paynow,
                            }
                          )}
                        >
                          {t('pay_as_per_school_schedule')}
                        </Form.Check.Label>
                      </Form.Check>

                    </Row>
                  </Col>
                </Col>
              }
            </Row>
            <Row className="my-4 g-4">
              {props.touched?.monthly_payment &&
                props.errors?.monthly_payment && (
                  <Col lg={12}>
                    <Alert variant="danger">
                      {t("plans:choose_payment_plan")}
                    </Alert>
                  </Col>
                )}
              {props.values.school_id && props.values.amount &&
                props.values.paynow ?
                <PayNowSchedule formikBag={props} plan={plan} /> :
                <AvailablePlans />
              }
              <SalaryLimitWarning
                user={user}
                formikBag={props}
                percentage={
                  user?.employment_type == EMPLOYMENTTYPE.RETIRED ? 30 : 40
                }
                fee={plan.school?.parent_fee}
              />
            </Row>
          </Col>

          <Col lg={12} className="d-flex justify-content-between">
            <Button onClick={() => previousStep()} variant="light" size="lg">
              {user?.language == "ar" ? (
                <i className="bi bi-arrow-right ms-3"></i>
              ) : (
                <i className="bi bi-arrow-left me-3"></i>
              )}
              {t("common:back")}
            </Button>
            <Button onClick={props.submitForm} size="lg">
              {t("common:next")}
              {user?.language == "ar" ? (
                <i className="bi bi-arrow-left me-3"></i>
              ) : (
                <i className="bi bi-arrow-right ms-3"></i>
              )}
            </Button>
          </Col>
        </>
      )}
    </Formik>
  );
};

export function PayNowSchedule(props: any) {
  const { formikBag, plan } = props;
  const { t } = useTranslation(["plans", "common"]);
  return (
    <>
      <Alert variant="info">
        {t('uncheck_if_paid')}
      </Alert>
      {
        plan.school?.payment_dates.map((payment_date: any, index: number) => (
          <Alert
            className="mt-0"
            variant={!!formikBag?.values?.extras?.paynow[index]?.paymentDates[0].isPaid ? "danger" : "primary"}
            key={payment_date.date}
          >
            <Form.Check
              className="student-selector position-relative ps-2 ps-sm-4"
              id={payment_date.date}
              type="checkbox"
            >
              <Form.Check.Input
                onChange={(e: any) => {
                  formikBag.setFieldValue(
                    `extras.paynow[${index}].paymentDates[0].isPaid`,
                    !e.target.checked
                  );
                }}
                type="checkbox"
                name={`extras.paynow[${index}]`}
                checked={!formikBag?.values?.extras?.paynow[index].paymentDates[0].isPaid}
                value={payment_date.date}
                className="d-none d-sm-block"
                style={{
                  top: "35%",
                  left: "2.5%",
                }}
              />
              <Form.Check.Label className="w-100">
                <div className="d-flex w-100">
                  <div
                    className={cx(
                      "bg-white  border rounded-1 p-2 px-3 d-flex flex-column justify-items-center align-items-center",
                    )}
                  >
                    <p className="text-gray opacity-75">{DateTime.fromISO(payment_date.date).monthShort}</p>
                    <p className="fw-bold text-dark">{DateTime.fromISO(payment_date.date).day}</p>
                    <p className="text-gray opacity-75">{DateTime.fromISO(payment_date.date).year}</p>
                  </div>
                  <div className="mx-3 flex-grow-1 d-flex flex-column justify-content-center">
                    <div className="d-flex flex-wrap justify-content-between">
                      <p className="mb-1 text-dark">
                        {t('common:payment')} #{index + 1}
                      </p>
                    </div>

                    <Amount className="mb-1 fw-bold fs-6 text-dark" amount={formikBag.values?.amount * (payment_date.percentage / 100)} />
                    {!!formikBag?.values?.extras?.paynow[index].paymentDates[0].isPaid && (
                      <p className="fw-extrabold">
                        {t("skipped_or_already_paid")}
                      </p>)}
                  </div>
                </div>
              </Form.Check.Label>
            </Form.Check>
          </Alert>
        ))
      }
    </>
  );
}

function AvailablePlans(props: any) {
  const { setFieldValue, values } = useFormikContext<any>();
  const { t } = useTranslation(["common"]);
  const [selected, setSelected] = useState(values.number_of_installments);
  const [plan, _] = usePlaner();

  const { data } = useQuery(
    ["availablePlans", values.amount],
    () => plans.availablePlan(values.school_id, values.amount),
    {
      placeholderData: {},
      onSuccess: (data) => {
        if (plan) {
          if (
            values.amount == plan.amount &&
            data[plan.number_of_installments] &&
            !values.number_of_installments
          ) {
            select(data[plan.number_of_installments]);
          }
        }
      },
    }
  );

  function select(plan: any) {
    setFieldValue("number_of_installments", plan.months);
    setFieldValue("monthly_payment", plan.monthlyPayment);
    setSelected(plan.months);
  }

  if (Object.keys(data).length == 0)
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center bg-light"
        style={{ minHeight: "25vh" }}
      >
        <Spinner animation="border" role="status" className="mb-4">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p>{t("fetching_plans")}</p>
      </div>
    );

  return (
    <>
      {Object.keys(data).map((period: any) => (
        <PlanCard
          key={period}
          {...data[period]}
          select={select}
          selected={selected}
        />
      ))}
    </>
  );
}

function PlanCard(props: any) {
  const { t } = useTranslation(["common", "plans"]);
  const { select, selected, amount, monthlyPayment, monthlyFees, months, fee } =
    props;
  const active = selected === months;
  return (
    <Col lg={4} className="my-2 my-lg-0 cursor-pointer">
      <div
        onClick={() => select(props)}
        className={cx("border  rounded-4 opacity-50", {
          "border-primary bg-light-blue": active,
          "opacity-100": (months == 9 && !selected) || active,
          "border-primary": !active && months == 9,
        })}
      >
        <div className="p-3 pb-0">
          <div className="text-dark">
            {months == 9 &&
              <div className="position-relative">
                <div className="position-absolute text-center fbs-3 fw-bold text-white bg-primary mb-3 p-1 px-3 rounded-1"
                  style={{ top: "-1.75rem", left: "50%", transform: "translateX(-50%)" }}
                >
                  {t('recommended')}
                </div>
              </div>}
            <h6 className="fw-bold mb-1">
              {months} {t("plans:payments")}
            </h6>
            <h5>
              <span className="fw-bold">
                {monthlyPayment} {t("kd")}{" "}
              </span>
              <span className="fs-6 text-secondary">{t("every_month")}</span>
            </h5>
          </div>
          <hr style={{ height: "1px" }} className="bg-light" />
          <div className="fbs-4 d-flex justify-content-between mb-3">
            <div>
              <p>{t("common:total")}</p>
              <p className="fs-8 fw-semibold text-primary">
                {t("including_fees")}
              </p>
            </div>
            <p>
              {amount} {t("kd")}
            </p>
          </div>
          <div className="fbs-4 d-flex justify-content-between mb-3">
            <p>{t("plans:monthly_fees")}</p>
            <p>
              {Math.ceil(monthlyFees)} {t("kd")}
            </p>
          </div>
        </div>
        {active ? (
          <Button className="w-100 fs-7 rounded-0 rounded-bottom py-3">
            {t("plans:selected")}
          </Button>
        ) : (
          <Button
            variant="white"
            className="w-100 fs-7 border-top rounded-0 rounded-bottom py-3"
          >
            {t("plans:select_this_plan")}
          </Button>
        )}
      </div>
    </Col >
  );
}

function SalaryLimitWarning(props: any) {
  const { formikBag, percentage, user, fee } = props;
  const { t } = useTranslation(["plans", "common"]);
  let limitOnSalary = user.salary * (percentage / 100);
  limitOnSalary /= 1 + fee / 100;
  const total = limitOnSalary * formikBag.values.number_of_installments;
  if (total > formikBag.values.amount || formikBag.values.paynow || !formikBag.values.number_of_installments)
    return <></>

  return (
    <Col lg={12} className="p-0">
      <Alert>
        <div className="d-flex justify-content-between flex-wrap align-items-center">
          <div className="d-flex mb-3">
            <div className="flex-shrink-0">
              <img width={46} className="rounded-circle" src={limitWarning} />
            </div>
            <div className="flex-grow-1 ms-3">
              <p className="mb-2 fw-bold">
                {t("allowed_amount_period", {
                  months: formikBag.values.number_of_installments,
                })}
                <span className="fbs-1 text-dark mx-2">
                  {(
                    total
                  ).toFixed(0)}{" "}
                  {t("kd")}
                </span>
              </p>
              <p className="text-dark opacity-75">
                {t("based_on_your_commitments")}
              </p>
            </div>
          </div>
        </div>
      </Alert >
    </Col>
  );
}

export default PlanCustomizeAmount;
