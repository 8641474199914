import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { plans } from "utils/api/plans";
import FIMethod from "utils/enums/FIMethod";
import FileType from "utils/enums/FileType";
import PlanStatus from "utils/enums/PlanStatus";
import * as Yup from "yup";
import { useState } from "react";

const PlanPendingInvoice = (props: any) => {
  const { plan } = props;
  const { t } = useTranslation(["common", "plans"]);
  const client = useQueryClient();
  const [show, setShow] = useState(false);
  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  function submit(values: any) {
    const { status, ...files } = values;
    plans.upload({ planId: plan.id, files });
    planMutation.mutate(
      { plan, values: { status } },
      {
        onSuccess: () => {
          setShow(false);
        },
      }
    );
  }

  let status = PlanStatus.PENDING_PAYMENT;
  if (plan.fi?.intermediated) {
    status = PlanStatus.PENDING_RUBA_TID;
  }

  return (
    <>
      {plan.status == PlanStatus.PENDING_INVOICE && (
        <>
          <Button
            variant="primary"
            onClick={() => setShow(true)}
            size="lg"
            className="w-100"
            type="button"
          >
            {t("common:action_required")}
          </Button>
          <Modal
            centered
            aria-labelledby="contained-modal-title-vcenter"
            show={show}
            onHide={() => setShow(false)}
            size="lg"
          >
            <Modal.Header
              className="d-flex justify-content-between"
              closeButton
            >
              <Modal.Title className="fs-6">
                {t("common:action_required")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                onSubmit={submit}
                initialValues={{
                  [FileType.SIGNED_INVOICE]: "",
                  [FileType.SIGNED_PURCHASE_ORDER]: "",
                  status,
                }}
                validationSchema={Yup.object().shape({
                  [FileType.SIGNED_INVOICE]: Yup.string().required(
                    t("document_required")
                  ),
                  [FileType.SIGNED_PURCHASE_ORDER]: Yup.string().when(
                    "status",
                    {
                      is: plan.fi?.method == FIMethod.MURABAHA,
                      then: Yup.string().required(t("document_required")),
                    }
                  ),
                })}
              >
                <Form>
                  <Row>
                    <Col md={12}>
                      <Input
                        type="file"
                        id="signed_invoice"
                        name={`${FileType.SIGNED_INVOICE}`}
                        label={t("plans:signed_invoice")}
                      />
                    </Col>
                    {PlanStatus.PENDING_PAYMENT == status &&
                      plan.fi?.method == FIMethod.MURABAHA && (
                        <Col md={12}>
                          <Input
                            type="file"
                            id="receipt"
                            name={FileType.SIGNED_PURCHASE_ORDER}
                            label={t("signed_po")}
                          />
                        </Col>
                      )}
                    <Col className="mt-3" md="12">
                      <Button type="submit" className="w-100" size="lg">
                        {t("submit")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Formik>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default PlanPendingInvoice;
