import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { Notification } from 'utils/types/notification'
import { FaStar } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const ReferralSuccessfulNotificationRenderer = (notification: Notification) => {

    return (
        <Row>
            <Col lg={1}>
                <div className='bg-primary bg-opacity-10 p-3 rounded-circle' style={{
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <FaStar style={{ fill: '#FFD700', strokeWidth: '8px' }} size={48} className='bounce' />
                </div>
            </Col>
            <Col lg={9}>
                <p>
                    Successful Referrals <span className='text-gray'>just now</span>
                </p>
                <p className='text-black fs-6 my-1'>
                    A customer has successfully referred a friend to your business.
                </p>
                <p className='text-gray fw-light'>
                    "No action is needed from you."
                </p>
            </Col>
            <Col lg={2} className='d-flex align-items-center'>
                <Link to={notification.link ?? ""}>
                    <Button variant="info">View Plan</Button>
                </Link>
            </Col>
        </Row>
    )
}

export default ReferralSuccessfulNotificationRenderer
