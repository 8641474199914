import { Field, useField, useFormikContext } from "formik";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import cx from "classnames";
import Icon from "components/widgets/Icon";

const Input = (props) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [temporaryType, setTemporaryType] = useState("password");

  function handleChange(event) {
    if (props.type == "file")
      return setFieldValue(props.name, event.currentTarget.files[0]);
    props.onChange?.(event)
    return field.onChange(event);
  }

  function value() {
    if (props.type == "file") return undefined;
    if (props.type == "checkbox") return !!field.value;
    return field.value || "";
  }

  return (
    <Form.Group
      as={"div"}
      className={cx("position-relative", {
        "password-input": props.type == "password",
      })}
    >
      {props.label && (
        <Form.Label htmlFor={props.name}>{props.label}</Form.Label>
      )}
      <Form.Control
        {...field}
        {...props}
        onChange={handleChange}
        value={value()}
        isInvalid={meta.touched && meta.error}
        type={props.type == "password" ? temporaryType : props.type}
      />
      {props.type == "password" && (
        <div
          className={cx("password-revealer", { lg: props.bsSize == "lg" })}
          onClick={() =>
            setTemporaryType((prev) =>
              prev == "password" ? "text" : "password"
            )
          }
        >
          {temporaryType == "password" ? (
            <Icon name="openEye" />
          ) : (
            <Icon name="slashEye" />
          )}
        </div>
      )}
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default Input;
