import { useMutation, useQueryClient } from "@tanstack/react-query";
import Amount from "components/widgets/Amount";
import { DateTime } from "luxon";
import { Fragment, useState } from "react";
import { Alert, Button, Form, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { IUpdateProposedSchedule, reserveTransactions } from "utils/api/reserveTransactions";
import { transactions } from "utils/api/transactions";
import PlanStatus from "utils/enums/PlanStatus";
import ReserveStatus from "utils/enums/ReserveStatus";
import Role from "utils/enums/Role";
import TransactionType from "utils/enums/TransactionType";
import { isRole, ordinal } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";


type Props = {
  plan: any;
};

export const statusesToHideReserveApproval = [
  PlanStatus.PENDING_RUBA,
  PlanStatus.MISSING_DOCUMENTS_RUBA,
  PlanStatus.PENDING_QUOTATION,
  PlanStatus.PENDING_SCHOOL_APPROVAL,
  PlanStatus.PENDING_FI,
  PlanStatus.MISSING_DOCUMENTS_FI,
  PlanStatus.RUBA_CONDITIONAL_APPROVAL,
  PlanStatus.PENDING_CINET,
  PlanStatus.PENDING_SERVICE_FEE,
];

const PlanProposedReserveScheduleActionsForm = (props: Props) => {
  const { plan } = props;
  const [show, setShow] = useState(false)
  const { t } = useTranslation(['common', 'plans'])
  const [loading, setLoading] = useState(false);

  const { data: user } = useUser();
  const queryClient = useQueryClient();
  const reserveScheduleProposal = plan?.extras?.reserve;
  const updateProposedScheduleMutation = useMutation(
    (data: IUpdateProposedSchedule) => {
      return reserveTransactions.updateProposedSchedule(plan.id, data)
    }
  );

  if (![ReserveStatus.PENDING_APPROVAL, ReserveStatus.PENDING_PAYMENT].includes(plan.reserve_status) || statusesToHideReserveApproval.includes(plan.status))
    return <></>;

  function decide(decision: 'accept' | 'reject') {
    updateProposedScheduleMutation.mutate({ decision },
      {
        onSuccess: (data: any) => {
          queryClient.invalidateQueries(['plan']);
          if (data.success) {
            toast.success(data.message);
            if (![PlanStatus.REJECTED_RUBA, PlanStatus.REJECTED_FI, PlanStatus.USER_WITHDRAWN]) {
              setShow(false)
            }
          } else {
            toast.error(data.message);
            setShow(false)
          }
        }
      }
    );
  }

  const sfTrx = plan.transactions?.find((t: any) => t.type == TransactionType.SERVICE_FEE);

  async function redirectToPayment(values: any) {
    if (loading) return;
    setLoading(true);
    const res = await transactions.paymentLink([sfTrx?.id]);
    window.location.replace(res.link);
    setLoading(false);
  }

  return (
    <>
      {ReserveStatus.PENDING_APPROVAL == plan.reserve_status &&
        <Button
          variant="primary"
          onClick={() => setShow(true)}
          size="lg"
          className="w-100 my-3"
          type="button"
        >
          {t("proposed_schedule")}
          <i className="mx-1 bi bi-journal-text"></i>
        </Button>
      }
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">
            {t("proposed_schedule")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {ReserveStatus.PENDING_PAYMENT == plan.reserve_status &&
            <div className="text-center">
              <h3 className="fw-bold">
                {t('one_last_step')}
              </h3>
              <p>
                {t('please_note_proposal_payment')}
              </p>
              <Button
                variant="primary"
                onClick={redirectToPayment}
                size="lg"
                className="my-4"
                disabled={loading}
              >
                <div className="d-flex justify-content-center">
                  <p className="mx-1">{t("plans:pay_service_fee")}</p> <Amount amount={sfTrx?.amount} />
                </div>
              </Button>
            </div>
          }
          {ReserveStatus.PENDING_APPROVAL == plan.reserve_status &&
            <>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("plans:amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {reserveScheduleProposal?.map((tuition: any, index: number) => (
                    <Fragment key={tuition.date}>
                      {tuition.paymentDates?.map((paymentDate: any, ind: number) => (
                        <Fragment key={paymentDate.date}>
                          <tr >
                            <td>
                              {DateTime.fromISO(paymentDate.date).toLocaleString({
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              })}
                            </td>
                            <td>
                              <Amount amount={paymentDate.amount} />
                            </td>
                          </tr>
                          {(ind == tuition.paymentDates.length - 1 && !isRole(Role.USER, user)) && <tr>
                            <td colSpan={2} className="text-center fw-bold">
                              {t('end_of_x_installment', { x: ordinal(index + 1) })} <Amount amount={tuition.amount} />
                            </td>
                          </tr>}
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between mt-3">
                <Button
                  type="button"
                  onClick={() => decide('reject')}
                  variant="danger">
                  {t("plans:reject")}
                </Button>
                <Button
                  type="submit"
                  onClick={() => decide('accept')}
                  variant="primary" className="me-2">
                  {t("plans:approve")}
                </Button>
              </div>
            </>}

        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlanProposedReserveScheduleActionsForm;
