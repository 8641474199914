import Input from "components/inputs/Input";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import FileType from "utils/enums/FileType";
import PlanStatus from "utils/enums/PlanStatus";
import { Form, Formik } from "formik";
import plans from "utils/api/plans";

const PlanPendingRubaSchoolTIDActionsForm = (props: any) => {
  const { t } = useTranslation(["plans", "common"]);
  const { plan, planMutation } = props;

  function submit(values: any) {
    planMutation.mutate({
      plan,
      values: {
        status: values.status,
        ruba_school_trans_id: JSON.stringify(values.ruba_school_trans_id),
      },
    });
  }

  return (
    <>
      {PlanStatus.PENDING_RUBA_SCHOOL_TID == plan.status && (
        <Formik
          enableReinitialize
          onSubmit={submit}
          initialValues={{
            status: PlanStatus.ACTIVE,
            ruba_school_trans_id: {
              amount: plan.fi.intermediated ? (plan.amount - plan.ruba_cut - plan.fi_cut).toFixed(3) : plan.ruba_cut,
              tid: "",
              date: "",
            },
          }}
          validationSchema={Yup.object().shape({
            status: Yup.string().required(),
            ruba_school_trans_id: Yup.object().shape({
              amount: Yup.number(),
              tid: Yup.string(),
              date: Yup.string().required(),
            }),
          })}
        >
          <Form>
            <Row>
              <Col lg={12} className="mb-3">
                <Input
                  disabled
                  type="number"
                  name="ruba_school_trans_id.amount"
                  label={t("amount")}
                />
              </Col>
              <Col lg={12} className="mb-3">
                <Input
                  type="date"
                  name="ruba_school_trans_id.date"
                  label={t("common:date")}
                />
              </Col>
              <Col lg={12} className="mb-3">
                <Input
                  name="ruba_school_trans_id.tid"
                  label={t("ruba_school_trans_id")}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end" md="12">
                <Button
                  type="submit"
                  variant="primary"
                  className="w-100"
                  size="lg"
                >
                  {t("common:submit")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default PlanPendingRubaSchoolTIDActionsForm;
