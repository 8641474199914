import cx from "classnames";
import logo from "components/assets/utils/images/logo.svg";
import LanguageSwitch from "components/widgets/LanguageSwitch";
import WizardStepper from "components/widgets/Wizard/Stepper";
import { LayoutContext } from "containers/common/Layout/Context";
import { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { breakPoints } from "utils/constants";
import useWindowDimensions from "utils/hooks/window";
import QuickAddStudents from "./AddStudents";
import QuickCreateAccount from "./CreateAccount";
import QuickSetupComplete from "./SetupComplete";

type Props = {};

const QuickSetup = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { isSidebarVisible, toggleSidebar } = useContext(LayoutContext);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["common", "plans"]);
  const ref = useRef<any>(null);

  function goToCreatePlan() {
    if (!isSidebarVisible) toggleSidebar();
    TagManager.dataLayer({
      dataLayer: {
        event: "quick-to-create-plan",
      },
    });
    navigate("/plans/new");
  }

  useEffect(() => {
    if (ref.current) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [ref.current, currentStep]);

  useEffect(() => {
    if (isSidebarVisible) toggleSidebar();
  }, [isSidebarVisible]);
  const steps = [
    {
      title: t("create_account"),
      subTitle: t("plans:your_information"),
    },
    { title: t("setup_your_profile"), subTitle: t("add_students") },
    { title: t("plans:create_plan"), subTitle: "" },
  ];

  return (
    <Container
      fluid
      dir={i18n.language == "en" ? "ltr" : "rtl"}
      className="px-0"
      ref={ref}
    >
      <div
        className={cx("py-4", {
          "bg-light-graycool border-bottom": width >= breakPoints.sm,
        })}
      >
        <Container>
          <Row className="flex-row justify-content-between mb-3 mb-lg-5">
            <Col xs="2" className="offset-2 offset-sm-0 mx-auto mx-sm-0">
              <img src={logo} width={78} />
            </Col>
            <Col xs="2" className="d-flex justify-content-end">
              <LanguageSwitch />
            </Col>
          </Row>
          <Row className="px-3 px-lg-6">
            <WizardStepper
              steps={steps}
              currentStep={Math.max(1, currentStep)}
            />
          </Row>
        </Container>
      </div>
      <Container className="px-3 px-lg-6">
        {currentStep != 2 && (
          <Row className="mt-4 mt-lg-5 mb-4">
            <Col lg={12}>
              <div className="d-flex align-items-center">
                <h4 className="fs-6 fs-sm-4">{t("setup_your_profile")}</h4>
                <i className="mx-2 text-gray bi bi-chevron-right"></i>
                <h3 className="fs-5 fs-sm-3 text-dark">
                  {steps[currentStep].subTitle}
                </h3>
              </div>
            </Col>
            <Col lg={12}>
              <p className="text-gray">{t("information_is_required")}</p>
            </Col>
          </Row>
        )}

        {currentStep == 0 && (
          <QuickCreateAccount
            nextStep={() => setCurrentStep((prev) => prev + 1)}
          />
        )}
        {currentStep == 1 && (
          <QuickAddStudents
            backStep={() => setCurrentStep((prev) => prev - 1)}
            nextStep={() => setCurrentStep((prev) => prev + 1)}
          />
        )}
        {currentStep == 2 && (
          <QuickSetupComplete
            nextStep={goToCreatePlan}
            backStep={() => setCurrentStep((prev) => prev - 1)}
          />
        )}
      </Container>
    </Container>
  );
};

export default QuickSetup;
