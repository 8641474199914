import { useQuery } from "@tanstack/react-query";
import SmallCard from "components/widgets/Dashboard/SmallCard";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import dashboard from "utils/api/dashboard";
import PlanStatus from "utils/enums/PlanStatus";

type Props = {};

const AdminPendingActionsWidget = (props: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject = Object.fromEntries(queryParams.entries());

  const { data, isFetching } = useQuery(
    ["dashboard", "admin-pending", queryParamsObject],
    () => dashboard.adminPendingActions(queryParamsObject),
    {
      placeholderData: {
        pendingActions: [],
      },
    }
  );
  const { t } = useTranslation(["plans"]);
  return (
    <>
      <Col lg="12" className="mb-3">
        <p className="fw-bold">{t("pending_actions")}</p>
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("new_plans")}
          value={data?.newPlans}
        />
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("pending_ruba")}
          value={data?.pendingActions.find((i: any) => i.status === PlanStatus.PENDING_RUBA)?.total}
        />
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("pending_cinet")}
          value={data?.pendingActions.find((i: any) => i.status === PlanStatus.PENDING_CINET)?.total}
        />
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("pending_signature")}
          value={data?.pendingActions.find((i: any) => i.status === PlanStatus.PENDING_SIGNATURE)?.total}
        />
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("pending_quotation")}
          value={data?.pendingActions.find((i: any) => i.status === PlanStatus.PENDING_QUOTATION)?.total}
        />
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("pending_fi")}
          value={data?.pendingActions.find((i: any) => i.status === PlanStatus.PENDING_FI)?.total}
        />
      </Col>
    </>
  );
};

export default AdminPendingActionsWidget;
