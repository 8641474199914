import { useQuery } from "@tanstack/react-query";
import FileInput from "components/inputs/File";
import { Formik, FormikHelpers } from "formik";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import plans from "utils/api/plans";
import { schools } from "utils/api/schools";
import { FILE_SIZE, SUPPORTED_FORMATS } from "utils/constants";
import EMPLOYMENTTYPE from "utils/enums/EmploymentType";
import FileType from "utils/enums/FileType";
import { useUser } from "utils/hooks/auth";
import * as Yup from "yup";

type Props = {
  previousStep: () => void;
  nextStep: () => void;
};

const PlanUploadDocuments = (props: Props) => {
  const { previousStep, nextStep } = props;
  const { id } = useParams();
  const { t } = useTranslation(["plans"]);
  const { data: user } = useUser();

  const { data: plan } = useQuery(["plans", id], () => plans.view(id), {
    initialData: {},
  });
  const schoolsResponse = useQuery(["schools"], schools.list, {
    initialData: [],
  });

  function fileValidation(type: FileType) {
    if (plan.files.find((file: any) => file.type == type)) {
      return {
        [type]: Yup.mixed()
          .test(
            "fileSize",
            t("common:file_too_large", { size: FILE_SIZE / (1024 * 1024) }),
            (value) => !value || value?.size <= FILE_SIZE
          )
          .test(
            "fileFormat",
            t("common:unsupported_format"),
            (value) => !value || SUPPORTED_FORMATS.includes(value?.type)
          ),
      };
    }
    return {
      [type]: Yup.mixed()
        .required(t("common:document_required"))
        .test(
          "fileSize",
          t("common:file_too_large", { size: FILE_SIZE / (1024 * 1024) }),
          (value) => value && value.size <= FILE_SIZE
        )
        .test(
          "fileFormat",
          t("common:unsupported_format"),
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
    };
  }

  function generateFileValidators() {
    const { nationality_id, sector, school_id, employment_type } = plan;
    let directFinancing = schoolsResponse?.data?.find(
      (i: any) => i.id == school_id
    )?.direct_financing;
    let validations = {
      ...fileValidation(FileType.PERSONALID),
      ...fileValidation(FileType.PERSONALIDBACK),
      ...fileValidation(FileType.SALARY_CERTIFICATE),
      ...fileValidation(FileType.BANK_STATEMENT),
    };

    if (sector === "Private" && employment_type !== EMPLOYMENTTYPE.RETIRED) {
      validations = {
        ...validations,
        ...fileValidation(FileType.AUTHORIZED_SIGNATORY),
      };

      if (nationality_id === 115) {
        validations = {
          ...validations,
          ...fileValidation(FileType.MANPOWER_ALLOWANCE),
        };
      }
    }

    if (nationality_id != 115) {
      validations = {
        ...validations,
        ...fileValidation(FileType.PASSPORT),
      };
    }
    if (directFinancing) {
      validations = {
        ...validations,
        ...fileValidation(FileType.QUOTATION),
      };
    }

    return Yup.object().shape(validations);
  }

  async function submit(values: any, formikBag: FormikHelpers<any>) {
    try {
      if (values.documents && Object.keys(values.documents).length > 0) {
        formikBag.setFieldValue("uploading", true);
        await plans.upload({ planId: id, files: values.documents });
        formikBag.setFieldValue("uploading", false);
      }
      nextStep();
    } catch (e) {
      console.log(e);
      formikBag.setFieldValue("uploading_failed", true);
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        uploading: false,
      }}
      validationSchema={Yup.object().shape({
        documents: Yup.object().when(
          [],
          // @ts-ignore
          generateFileValidators
        ),
      })}
      onSubmit={submit}
    >
      {(props) => (
        <>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <h5 className="text-dark fw-bold">{t("upload_documents")}</h5>
              </Col>
              <Col lg={4} className="mb-3">
                <FileInput
                  id="documents.personal_id"
                  tooltip={t("personal_id_front_info")}
                  name={`documents.${FileType.PERSONALID}`}
                  label={t("civil_id_front")}
                  initialFileID={plan.files?.find(
                    (file: any) => file.type == FileType.PERSONALID
                  )}
                  helperImage="/paci-guide.jpg"
                />
              </Col>
              <Col lg={4} className="mb-3">
                <FileInput
                  id="documents.personal_id_back"
                  tooltip={t("personal_id_back_info")}
                  name={`documents.${FileType.PERSONALIDBACK}`}
                  label={t("personal_id_back")}
                  initialFileID={plan.files?.find(
                    (file: any) => file.type == FileType.PERSONALIDBACK
                  )}
                  helperImage="/paci-guide.jpg"
                />
              </Col>
              {plan.nationality_id != 115 && (
                <Col lg={4} className="mb-3">
                  <FileInput
                    id="documents.passport"
                    tooltip={t("passport_info")}
                    name={`documents.${FileType.PASSPORT}`}
                    label={t("passport_image")}
                    initialFileID={plan.files?.find(
                      (file: any) => file.type == FileType.PASSPORT
                    )}
                  />
                </Col>
              )}
              <Col lg={4} className="mb-3">
                <FileInput
                  id="documents.salary_certificate"
                  tooltip={t("salary_certificate_info")}
                  name={`documents.${FileType.SALARY_CERTIFICATE}`}
                  label={t("salary_certificate_detailed")}
                  initialFileID={plan.files?.find(
                    (file: any) => file.type == FileType.SALARY_CERTIFICATE
                  )}
                  helperImage="/salary_certificate_guide.png"
                />
              </Col>
              {(plan.sector != "Public" ||
                plan.employment_type == EMPLOYMENTTYPE.SELFEMPLOYED) && (
                  <Col lg={4} className="mb-3">
                    <FileInput
                      id="documents.authorized_signatory"
                      tooltip={t("authorized_signatory_info")}
                      name={`documents.${FileType.AUTHORIZED_SIGNATORY}`}
                      label={t("authorized_signatory")}
                      initialFileID={plan.files?.find(
                        (file: any) => file.type == FileType.AUTHORIZED_SIGNATORY
                      )}
                    />
                  </Col>
                )}
              <Col lg={4} className="mb-3">
                <FileInput
                  id="documents.bank_statement"
                  tooltip={t("bank_statement_info")}
                  name={`documents.${FileType.BANK_STATEMENT}`}
                  label={t("bank_statement")}
                  initialFileID={plan.files?.find(
                    (file: any) => file.type == FileType.BANK_STATEMENT
                  )}
                />
              </Col>
              {!!plan.school.direct_financing && (
                <Col lg={4} className="mb-3">
                  <FileInput
                    id="documents.quotation"
                    tooltip={t("quotation_info")}
                    name={`documents.${FileType.QUOTATION}`}
                    label={t("quotation")}
                    initialFileID={plan.files?.find(
                      (file: any) => file.type == FileType.QUOTATION
                    )}
                  />
                </Col>
              )}
              {plan.sector === "Private" &&
                plan.employment_type !== EMPLOYMENTTYPE.RETIRED &&
                plan.nationality_id === 115 && (
                  <Col lg={4} className="mb-3">
                    <FileInput
                      id="documents.manpower_allownace"
                      name={`documents.${FileType.MANPOWER_ALLOWANCE}`}
                      label={t("manpower_allowance_certificate")}
                      initialFileID={plan.files?.find(
                        (file: any) => file.type == FileType.MANPOWER_ALLOWANCE
                      )}
                      helperImage="/manpower-guide.jpg"
                    />
                  </Col>
                )}
            </Row>
          </Col>
          <Col lg={12} className="d-flex justify-content-between">
            <Button onClick={() => previousStep()} variant="light" size="lg">
              {user?.language == "ar" ? (
                <i className="bi bi-arrow-right ms-3"></i>
              ) : (
                <i className="bi bi-arrow-left me-3"></i>
              )}
              {t("common:back")}
            </Button>
            <Button
              disabled={props.values.uploading}
              onClick={props.submitForm}
              size="lg"
            >
              {props.values.uploading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="mx-2">{t("uploading_documents")}</span>
                </>
              ) : (
                <>
                  {t("common:next")}
                  {user?.language == "ar" ? (
                    <i className="bi bi-arrow-left me-3"></i>
                  ) : (
                    <i className="bi bi-arrow-right ms-3"></i>
                  )}
                </>
              )}
            </Button>
          </Col>
        </>
      )}
    </Formik>
  );
};

export default PlanUploadDocuments;
