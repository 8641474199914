import Amount from "components/widgets/Amount";
import { DateTime } from "luxon";
import { Fragment } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ordinal } from "utils/functions/utilities";

type Props = {
    reserve: any
}

const ReserveTable = (props: Props) => {
    const { t } = useTranslation(['common', 'plans']);
    const { reserve } = props;
    return (
        <Table striped hover>
            <thead>
                <tr>
                    <th>{t("date")}</th>
                    <th>{t("plans:amount")}</th>
                </tr>
            </thead>
            <tbody>
                {reserve.map((tuition: any, index: number) => (
                    <Fragment key={tuition.date}>
                        {tuition.paymentDates?.map((paymentDate: any, ind: number) => (
                            <Fragment key={paymentDate.date}>
                                <tr >
                                    <td>
                                        {DateTime.fromISO(paymentDate.date).toLocaleString({
                                            month: "short",
                                            day: "numeric",
                                            year: "numeric",
                                        })}
                                    </td>
                                    <td>
                                        <Amount amount={paymentDate.amount} />
                                    </td>
                                </tr>
                                {ind == tuition.paymentDates.length - 1 && <tr>
                                    <td colSpan={2} className="text-center fw-bold">
                                        {t('end_of_x_installment', { x: ordinal(index + 1) })} <Amount amount={tuition.amount} />
                                    </td>
                                </tr>}
                            </Fragment>
                        ))}
                    </Fragment>
                ))}
                <tr>
                    <td colSpan={2} className="text-center fw-bold">
                        <div className="d-flex justify-content-center">
                            {t('total')} <Amount className="mx-2" amount={
                                reserve.reduce((acc: number, tuition: any) => acc + tuition.amount, 0)
                            } />
                        </div>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}

export default ReserveTable