import * as Yup from "yup";
import i18next from "utils/i18n/config";

export const defaultStudent = {
  birthday: "",
  first_year: 0,
  gender: "",
  grade_id: "",
  branch_id: "",
  nationality_id: "115",
  custom_id: "",
  school_id: 0,
  school: {
    type: 0,
  },
};

export const StudentFormValidation = Yup.object().shape({
  gender: Yup.string()
    .required(i18next.t("required"))
    .typeError(i18next.t("required")),
  name: Yup.string()
    .required(i18next.t("required"))
    .typeError(i18next.t("required")),
  birthday: Yup.string()
    .required(i18next.t("required"))
    .typeError(i18next.t("required")),
  nationality_id: Yup.string()
    .required(i18next.t("required"))
    .typeError(i18next.t("required")),
  school_id: Yup.number()
    .required(i18next.t("required"))
    .typeError(i18next.t("required")),
  grade_id: Yup.number()
    .required(i18next.t("required"))
    .typeError(i18next.t("required")),
  first_year: Yup.number()
    .required(i18next.t("required"))
    .typeError(i18next.t("required")),
  branch_id: Yup.number().when("school_has_branches", {
    is: true,
    then: Yup.number()
      .required(i18next.t("required"))
      .typeError(i18next.t("required")),
  }),
});

export const StudentFormInitialValues = {
  students: [defaultStudent],
};
