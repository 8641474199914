import { useField, useFormikContext } from "formik";
import * as React from "react";
import { Form } from "react-bootstrap";
import { get } from "lodash";
import cx from "classnames";

const Radio = (props) => {
  const [field, meta] = useField(props);
  return (
    <Form.Check
      className="position-relative px-0"
      id={props.name + props.value}
      // isInvalid={!!meta.error}
      // feedback={meta.error}
      // feedbackType="invalid"
    >
      <Form.Check.Input
        type="radio"
        {...field}
        {...props}
        isInvalid={meta.touched && !!meta.error}
        checked={field.value == props.value}
      />
      <Form.Check.Label
        className={cx(
          "form-check-container border rounded-2 ps-5 pe-2 py-3 w-100 border-bottom",
          {
            "border-primary bg-light-blue active": field.value == props.value,
          }
        )}
      >
        {props.label}
      </Form.Check.Label>
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Check>
  );
};

export default Radio;
