import cx from "classnames";
import { useField, useFormikContext } from "formik";
import { Form } from "react-bootstrap";

const Checkbox = (props) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  return (
    <Form.Check
      className="position-relative px-0"
      id={props.name + props.value}
    >
      <Form.Check.Input
        type="checkbox"
        {...props}
        {...field}
        isInvalid={meta.touched && !!meta.error}
        checked={field.value}
        onChange={(e) => setFieldValue(props.name, e.target.checked)}
      />
      <Form.Check.Label
        className={cx("form-check-container ps-5 pe-2 py-3 w-100")}
      >
        {props.label}
      </Form.Check.Label>
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Check>
  );
};

export default Checkbox;
