import { Accordion, Button, Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import plans from "utils/api/plans";
import FileType from "utils/enums/FileType";
import FIMethod from "utils/enums/FIMethod";
import PlanStatus from "utils/enums/PlanStatus";
import Role from "utils/enums/Role";
import { download, isIOS, isRole } from "utils/functions/utilities";
import useAlert from "utils/hooks/alert";
import { useUser } from "utils/hooks/auth";

const PlanGeneratorsDetails = (props: any) => {
  const { plan } = props;
  const { data: user } = useUser();
  const { confirmOrDeny } = useAlert();
  const { t } = useTranslation(["plans", "common"]);
  const navigate = useNavigate();

  function checkIfSignatureAllowed(fn: any) {
    if (!isRole(Role.SCHOOL, user)) return fn();
    const allowed = plan.fi?.allow_digital_signature && plan.fi?.method == FIMethod.MOSAWAMA;
    const hasSignature = plan.school.files?.some(
      (file: any) => file.type == FileType.SIGNATURE
    );
    if (!hasSignature && allowed) {
      return confirmOrDeny({
        title: t("common:you_can_now_sign_digitally"),
        text: "",
        confirmButtonText: t("common:create_digital_signature"),
        denyButtonText: t("common:skip"),
        onConfirm: () => navigate(`/schools/${plan.school.id}`),
        onDeny: fn,
      });
    }

    if (!!allowed)
      return confirmOrDeny({
        title: t("common:do_you_want_to_sign_it_digitally"),
        text: "",
        confirmButtonText: t("common:yes"),
        denyButtonText: t("common:no"),
        onConfirm: () => fn(true),
        onDeny: fn,
      });
    fn();
  }

  async function getQuotation(signed = false) {
    const data = await plans.generatedDocument(plan.id, "quotation", signed);
    download(`Rubapay-${plan.public_id}-quotation.pdf`, data);
  }

  async function getMurabahaInvoice(signed = false) {
    const data = await plans.generatedDocument(
      plan.id,
      "murabaha-invoice",
      signed
    );
    download(`Rubapay-${plan.public_id}-invoice.pdf`, data);
  }

  async function getInvoice(signed = false) {
    const data = await plans.generatedDocument(plan.id, "invoice", signed);
    download(`Rubapay-${plan.public_id}-invoice.pdf`, data);
  }

  async function getReceipt(signed = false) {
    const data = await plans.generatedDocument(plan.id, "receipt", signed);
    download(`Rubapay-${plan.public_id}-receipt.pdf`, data);
  }

  let visible =
    [Role.ADMIN, Role.SCHOOL].includes(user?.role_id!) && plan.fi_id;

  return (
    <>
      {visible && (
        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {t("plans:documents_generators")}
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <Row>
                  <PlanGeneratorButton
                    handleClick={() => checkIfSignatureAllowed(getQuotation)}
                    label={t("generate_quotation")}
                  />
                  {plan.fi.method == FIMethod.MURABAHA && plan.status === PlanStatus.PENDING_INVOICE && (
                    <PlanGeneratorButton
                      handleClick={() =>
                        checkIfSignatureAllowed(getMurabahaInvoice)
                      }
                      label={t("generate_invoice")}
                    />
                  )}
                  {plan.fi.method == FIMethod.MOSAWAMA && (
                    <>
                      {plan.status === PlanStatus.PENDING_INVOICE && (<PlanGeneratorButton
                        handleClick={() => checkIfSignatureAllowed(getInvoice)}
                        label={t("generate_invoice")}
                      />)}

                      {!plan.fi.intermediated &&
                        <PlanGeneratorButton
                          handleClick={() => checkIfSignatureAllowed(getReceipt)}
                          label={t("generate_receipt")}
                        />}
                    </>
                  )}
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

function PlanGeneratorButton(props: any) {
  const { label, handleClick } = props;
  const { t } = useTranslation('common')
  return (
    <Col
      md="12"
      className="py-3 fw-bold w-100 border border-light rounded-2 d-flex justify-content-between align-items-center text-dark mb-3"
    >
      {label}
      <Button onClick={handleClick} className="document-generator">{t('generate')}</Button>
    </Col>
  );
}

export default PlanGeneratorsDetails;
