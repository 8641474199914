import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import GradeType from "utils/enums/GradeType";
import { gradeLabel, moveElement } from "utils/functions/utilities";
import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import Radio from "components/inputs/Radio";

const CreateSchoolGrades = (props: any) => {
  const { gradeData } = props;
  const { values, setFieldValue } = useFormikContext<any>();
  const { t } = useTranslation(["plans", "common", "schools"]);
  const [orderChanges, setOrderChanges] = useState({
    orders: [],
    changed: false,
  });

  useEffect(() => {
    let formatGrades: any = [];
    if (values.grades.find((i: any) => i.order === null)) {
      values.grades.forEach((grade: any, index: number) => {
        formatGrades[index] = grade.id;
      });
    } else {
      values.grades.forEach((grade: any, index: number) => {
        formatGrades[grade.order] = grade.id;
      });
    }
    setOrderChanges({ orders: formatGrades, changed: false });
  }, [values.grades]);

  function updateOrder(grade_id: number, direction: string) {
    const newOrders: any = moveElement(
      orderChanges.orders,
      grade_id,
      direction
    );
    setOrderChanges({ orders: newOrders, changed: true });
    setFieldValue(
      "grades",
      newOrders.map((id: number, order: number) => ({
        ...values.grades.find((g: any) => g.id === id),
        order,
      }))
    );
  }

  function addNewGrade() {
    const id = DateTime.now().toMillis();
    setFieldValue("grades", [
      ...values.grades,
      { id, order: values.grades.length, cost: 0 },
    ]);
  }

  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title className="fs-6 fw-bold">{t("schools:grades")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {orderChanges.orders.map((grade_id: any, i: number) => {
          const grade = values.grades.find((g: any) => g.id === grade_id);
          if (!grade) return <></>;
          return (
            <Row key={grade_id} className="mb-3">
              <Col lg={3} className="mt-3 mb-sm-0">
                <Select
                  label={t("schools:type")}
                  name={`grades[${i}].type`}
                  options={[
                    { label: t("schools:named_grade"), value: GradeType.name },
                    {
                      label: t("schools:monthly_grade"),
                      value: GradeType.monthlyRange,
                    },
                    {
                      label: t("schools:course_grade"),
                      value: GradeType.course,
                    },
                  ]}
                />
              </Col>
              <Col md="4" className="mt-3 mb-sm-0">
                {grade.type == GradeType.monthlyRange ? (
                  <Input
                    label={t("common:from_to_by_month")}
                    name={`grades[${i}].name`}
                  />
                ) : (
                  <Select
                    label={t("common:name")}
                    name={`grades[${i}].id`}
                    options={gradeData
                      ?.filter((i: any) => i.type == grade.type)
                      .map((i: any) => ({
                        value: i.id,
                        label: gradeLabel(i),
                      }))}
                  />
                )}
              </Col>
              <Col md="2" className="mt-3 mb-sm-0">
                <Input label={t("common:cost")} name={`grades[${i}].cost`} />
                <Input type="hidden" name={`grades[${i}].order`} />
              </Col>
              <Col md="1" className="d-flex align-items-end  my-3 mb-sm-0">
                <Button
                  className="w-100 px-1"
                  variant="light"
                  size="lg"
                  onClick={() =>
                    setFieldValue(
                      "grades",
                      values.grades.filter((item: any) => item.id != grade.id)
                    )
                  }
                >
                  <i className="bi bi-trash3 mx-1"></i>
                </Button>
              </Col>
              <Col md="1" className="d-flex align-items-end  mb-3 mb-sm-0">
                <Button
                  className="mx-1 w-100 px-1"
                  variant="light"
                  size="lg"
                  onClick={() => updateOrder(grade.id, "up")}
                >
                  <i className="bi bi-arrow-up-circle"></i>
                </Button>
              </Col>
              <Col md="1" className="d-flex align-items-end  mb-3 mb-sm-0">
                <Button
                  className="mx-1 w-100 px-1"
                  variant="light"
                  size="lg"
                  onClick={() => updateOrder(grade.id, "down")}
                >
                  <i className="bi bi-arrow-down-circle"></i>
                </Button>
              </Col>
              <hr
                style={{ height: "1px" }}
                className="bg-light d-block d-sm-none my-2"
              />
            </Row>
          );
        })}
        <Row>
          <Col md="12" className="d-flex justify-content-end mt-0 mt-sm-4">
            <Button
              className="w-100 w-sm-auto mb-3"
              variant="light"
              size="lg"
              onClick={addNewGrade}
            >
              <i className="bi bi-plus-lg mx-3"></i>
              {t("schools:add_a_grade")}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreateSchoolGrades;
