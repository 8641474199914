import { useQuery } from '@tanstack/react-query';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { schools } from 'utils/api/schools';
import { School } from 'utils/types/School';

const AddonsRestrictionRoute = () => {
    const { t } = useTranslation(['common']);

    const { data } = useQuery(['schools'], schools.list, {
        placeholderData: []
    });

    if (data?.length > 0 && data?.some((school: School) => school.paynow)) {
        return <Outlet />
    }

    return (
        <Container className="my-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card className="text-center p-3">
                        <Card.Body>
                            <Card.Title className="text-danger">Feature Not Available</Card.Title>
                            <Card.Text className='my-4'>
                                This feature is only accessible to partners enrolled in the PayNow program. To use this feature, please enroll in PayNow. For more information or assistance with enrollment, please contact our support team. You will receive guidance on how to get started and take advantage of this functionality.
                            </Card.Text>
                            <Button variant="primary" href="/contact-us">
                                Contact Support
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default AddonsRestrictionRoute;
