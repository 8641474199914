import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PlanCustomizeAmount from "./steps/PlanCustomizeAmount";
import PlanSelectStudents from "./steps/PlanSelectStudents";
import PlanUploadDocuments from "./steps/PlanUploadDocuments";
import PlanConfirmation from "./steps/PlanConfirmation";
import PlanWizard from "./components/PlanWizard";

const CreatePlan = () => {
  const { t } = useTranslation(["plans", "auth", "common"]);
  const [currentStep, setCurrentStep] = useState(0);
  const ref = useRef<any>(null);

  function next(skipStep?: boolean): void {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    setCurrentStep(currentStep + (skipStep ? 2 : 1));
  }

  function back(skipStep?: boolean): void {
    console.log(skipStep);
    ref.current?.scrollIntoView({ behavior: "smooth" });
    setCurrentStep(currentStep - (skipStep ? 2 : 1));
  }

  return (
    <Container>
      <Row className="mb-5">
        <h3 className="text-dark">{t("new_loan_application")}</h3>
        <p>{t("steps_await_from_application")}</p>
      </Row>
      <Row ref={ref}>
        <Col lg={4}>
          <PlanWizard currentStep={currentStep} />
        </Col>
        {currentStep == 0 && <PlanSelectStudents nextStep={next} />}
        {currentStep == 1 && (
          <PlanCustomizeAmount nextStep={next} previousStep={back} />
        )}
        {currentStep == 2 && (
          <PlanUploadDocuments nextStep={next} previousStep={back} />
        )}
        {currentStep == 3 && (
          <PlanConfirmation nextStep={next} previousStep={back} />
        )}
      </Row>
    </Container>
  );
};

export default CreatePlan;
