import { useQuery } from '@tanstack/react-query'
import Icon from 'components/widgets/Icon'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { notifications } from 'utils/api/notifications'

type Props = {}

const NotificationsWidget = (props: Props) => {
    const { data } = useQuery(["notifications-unread"], notifications.unread, {
        placeholderData: []
    })

    const unread = data?.filter((n: any) => !n.read) ?? [];

    return (
        <div className='d-flex align-items-center h-100'>
            <Link to="/notifications" className="text-decoration-none w-100">
                <Button variant='link' size='sm' className="position-relative w-100">
                    <Icon name="notification" />
                    {unread?.length > 0 &&
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">+{unread.length}
                            <span className="visually-hidden">unread messages</span>
                        </span>}
                </Button>
            </Link>
        </div>
    )
}

export default NotificationsWidget
