import { ITransactionDetails } from "containers/ReserveTransactions/Create/TransactionDetails";
import client from "../api";

function list({
  queryKey,
  signal,
  ...params
}: any | undefined = undefined): Promise<any> {
  return client.get("reserve-transactions", {
    params,
  });
}

function settle(id?: string): Promise<any> {
  return client.get(`reserve-transactions/settle/${id}`);
}

function byReserveId(id?: string): Promise<any> {
  return client.get(`reserve-transactions/reserve/${id}`);
}

async function generatedDocument(
  id: number,
  type: string,
) {
  return client.get(
    `reserve-transactions/${id}/generate/${type}`,
    {
      responseType: "blob",
    }
  ) as any;
}

async function prepareSchedule(data: ITransactionDetails): Promise<any> {
  return client.post('reserve-transactions/prepare-schedule', data)
}

async function proposeSchedule(planId: string, data: ITransactionDetails): Promise<any> {
  return client.post('reserve-transactions/propose-schedule/' + planId, data)
}

export type IUpdateProposedSchedule = {
  decision: 'accept' | 'reject';
  reserve_comment?: string;
}

async function updateProposedSchedule(planId: string, data: IUpdateProposedSchedule): Promise<any> {
  return client.post('reserve-transactions/update-propose-schedule/' + planId, data)
}

async function transferByReserveId(reserveId: string): Promise<any> {
  return client.get('reserve-transactions/reserve/' + reserveId + '/transfers');
}


export const reserveTransactions = {
  list,
  settle,
  byReserveId,
  generatedDocument,
  prepareSchedule,
  proposeSchedule,
  updateProposedSchedule,
  transferByReserveId
};
