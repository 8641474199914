import quickSetupCompletedImage from "components/assets/utils/images/quick/quick-setup-completed.svg";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
  backStep: () => void;
  nextStep: () => void;
};

const QuickSetupComplete = (props: Props) => {
  const { t } = useTranslation(["common", "plans"]);
  const { backStep, nextStep } = props;
  return (
    <Container className="text-center mt-5">
      <Row>
        <Col lg={12} className="my-2">
          <img src={quickSetupCompletedImage} />
        </Col>
        <Col lg={12} className="my-2">
          <h3 className="text-dark">{t("profile_setup_completed")}</h3>
        </Col>
        <Col lg={12} className="my-2">
          <p>{t("you_can_create_plan")}</p>
        </Col>
        <Col lg={12} className="my-2">
          <Button size="lg" onClick={nextStep} className="w-100 w-sm-50">
            {t("plans:create_plan")}
          </Button>
        </Col>
        <Col lg={12} className="my-2">
          <Button className="text-dark" onClick={backStep} variant="white">
            {t("back_to_profile_setup")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default QuickSetupComplete;
