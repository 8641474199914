import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import Radio from "components/inputs/Radio";
import Select from "components/inputs/Select";
import SelectSchool from "components/inputs/SelectSchool";
import Switch from "components/inputs/Switch";
import { Form, Formik } from "formik";
import { StudentFormValidation, defaultStudent } from "./validation";

import { Alert, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { countries } from "utils/api/countries";
import { schools } from "utils/api/schools";
import students from "utils/api/students";
import Gender from "utils/enums/Gender";
import SchoolType from "utils/enums/SchoolType";
import { arabicValue, gradeLabel } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";

type Props = {
  student?: any;
  close: () => void;
};

const StudentForm = (props: Props) => {
  const { close = () => {}, student = {} } = props;
  const { t } = useTranslation(["plans", "common", "auth", "schools"]);
  const client = useQueryClient();
  const { data: user } = useUser();

  const { data: countriesData } = useQuery(["countries"], countries.list, {
    initialData: [],
    select: (data) =>
      data.map((country: any) => ({
        value: country.id,
        label: arabicValue(country, "name"),
      })),
  });
  const { data: schoolsData } = useQuery(["schools"], schools.list, {
    initialData: [],
  });

  const createStudent = useMutation((values) => students.store(values), {
    onSuccess: () => {
      client.invalidateQueries(["students"]);
      close();
    },
  });
  const updateStudent = useMutation(
    (payload: any) => students.update(payload.id, payload),
    {
      onSuccess: () => {
        client.invalidateQueries(["students"]);
        close();
      },
    }
  );

  function addSelf(formikBag: any) {
    const student = {
      ...defaultStudent,
      gender: user?.gender,
      name: user?.name,
      birthday: user?.birthday,
      nationality_id: user?.nationality_id,
    };
    formikBag.setValues(student);
  }

  const types = Array.from(new Set(schoolsData.map((item: any) => item.type)))
    .filter((type: any) => SchoolType[type])
    .map((type: any) => ({
      value: type,
      label: t(`plans:a_${SchoolType[type].toLowerCase()}`),
    }));

  async function submit(values: any) {
    try {
      if (values.id) {
        await updateStudent.mutateAsync(values);
      } else {
        await createStudent.mutateAsync(values);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function selectedSchool(school_id: number): any {
    return schoolsData.find((school: any) => school.id == school_id);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...defaultStudent,
        ...student,
      }}
      validationSchema={StudentFormValidation}
      onSubmit={submit}
    >
      {(props) => (
        <Form>
          <Row>
            <Col lg={12} className="text-end">
              <Button
                onClick={() => addSelf(props)}
                variant="light"
                className="fw-normal w-100 w-sm-auto"
              >
                {t("add_myself_student")}
              </Button>
            </Col>
            <p className="fs-7 fw-semibold">{t("auth:gender")}</p>
            <Col lg={6} className="mt-2 mb-4">
              <Radio
                name={`gender`}
                value={Gender.MALE}
                label={`${t("auth:male")}`}
              />
            </Col>
            <Col lg={6} className="mt-2 mb-4">
              <Radio
                name={`gender`}
                value={Gender.FEMALE}
                label={`${t("auth:female")}`}
              />
            </Col>
            <Col lg={12} className="mb-4">
              <Input
                name={`name`}
                label={t("common:legal_name")}
                placeholder={t("common:legal_name_as_on_id")}
              />
            </Col>
            <Col lg={6} className="my-2">
              <Input
                name={`birthday`}
                type="date"
                label={t("plans:date_of_birth")}
              />
            </Col>
            <Col lg={6} className="my-2">
              <Select
                name={`nationality_id`}
                options={countriesData}
                label={t("plans:nationality")}
              />
            </Col>
          </Row>
          <hr style={{ height: "1px" }} className="bg-light" />
          <h6 className="text-dark py-3">
            {t("plans:educational_institution")}
          </h6>
          <Row>
            <Col lg={3} className="mb-4">
              <Select
                name="school.type"
                labelKey="label"
                label={t("plans:institution_type")}
                options={types}
              />
            </Col>
            <Col lg={6} className="mb-4">
              <SelectSchool
                name={`school_id`}
                initialType={props.values.school.type}
                intialSchool={props.values.school_id}
                label={t(`educational_institution`)}
                afterChange={(school_id) => {
                  props.setFieldValue(
                    `grade_id`,
                    selectedSchool(school_id)?.grades[0]?.id
                  );
                  props.setFieldValue(`branch_id`, "");
                  props.setFieldValue(`custom_id`, "");
                }}
              />
            </Col>
            <Col lg={3} className="mb-4">
              <Select
                name={`grade_id`}
                labelKey="name"
                valueKey="id"
                label={t("plans:grade")}
                disabled={!props.values.school_id}
                options={
                  selectedSchool(props.values.school_id)?.grades.map(
                    (grade: any) => ({
                      id: grade.id,
                      name: gradeLabel(grade),
                    })
                  ) ?? []
                }
              />
            </Col>
            {selectedSchool(props.values.school_id)?.custom_id && (
              <Col lg={5} className="mb-4">
                <Input
                  type="text"
                  label={selectedSchool(props.values.school_id)?.custom_id}
                  name="custom_id"
                />
              </Col>
            )}
            {selectedSchool(props.values.school_id)?.branches.length > 0 && (
              <Col lg={5} className="mb-4">
                <Select
                  label={t("schools:branch")}
                  options={selectedSchool(props.values.school_id)?.branches.map(
                    ({ id, name, name_ar }: any) => ({
                      value: id,
                      label: arabicValue({ name, name_ar }, "name"),
                    })
                  )}
                  name="branch_id"
                  placeholder={t("schools:branch")}
                />
              </Col>
            )}
            <Col lg={12}>
              <Row>
                <Col sm={12} lg={10} className="my-2 d-flex align-items-center">
                  <p>{t("plans:first_year_in_school")}</p>
                </Col>
                <Col sm={12} lg={2} className="my-2 text-end">
                  <Switch name={`first_year`} />
                </Col>
                {/* @ts-ignore */}
                {!props.values.first_year && (
                  <Col lg={12}>
                    <Alert variant="info">
                      {t("plans:students_not_registered_note")}
                    </Alert>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="px-1 mb-3 mb-lg-0">
              <Button onClick={close} variant="light" className="w-100 py-3">
                {t("common:cancel")}
              </Button>
            </Col>
            <Col lg={6} className="px-1">
              <Button type="submit" className="w-100 py-3">
                {t("common:submit")}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default StudentForm;
