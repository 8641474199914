import { Field, useField, useFormikContext } from "formik";
import * as React from "react";
import { ButtonGroup, Form, FormCheck } from "react-bootstrap";
import { get } from "lodash";
import cx from "classnames";
import { useTranslation } from "react-i18next";

// <Form.Check // prettier-ignore
//   {...field}
//   type="switch"
//   id="custom-switch"
//   // label={props.label}
//   arial-label={props.label}
//   className="form-switch-container position-relative"
// />
const Switch = (props) => {
  const [field] = useField(props);
  const { t } = useTranslation(["common"]);
  const { setFieldValue } = useFormikContext();

  return (
    <ButtonGroup aria-label={props.name + " radio"} className="w-100 rtl-flex">
      <input
        onChange={() => setFieldValue(field.name, 1)}
        name={field.name}
        value={1}
        type="radio"
        className="btn-check"
        id={props.name + "1"}
        autoComplete="off"
        checked={field.value == 1}
      />
      <Form.Label
        style={{ padding: "10px 12px" }}
        className="btn-sm btn btn-outline-primary"
        htmlFor={props.name + "1"}
      >
        {t("yes")}
      </Form.Label>

      <input
        onChange={() => setFieldValue(field.name, 0)}
        name={field.name}
        value={0}
        type="radio"
        className="btn-check"
        id={props.name + "2"}
        autoComplete="off"
        checked={field.value === 0}
      />
      <Form.Label
        style={{ padding: "10px 12px" }}
        className="btn-sm btn btn-outline-primary"
        htmlFor={props.name + "2"}
      >
        {t("no")}
      </Form.Label>
    </ButtonGroup>
  );
};

export default Switch;
