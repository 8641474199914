import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Role from "utils/enums/Role";
import { isOneOfRoles, isRole } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";

const PlanExtraDetails = (props: { plan: any }) => {
  const { plan } = props;
  const { data: user } = useUser();
  const { t } = useTranslation(["plans"]);
  const attributes = [
    {
      label: t("ruba_tid"),
      value: plan.ruba_trans_id,
      condition:
        !isOneOfRoles([Role.USER, Role.SCHOOL], user) && plan.ruba_trans_id,
    },
    {
      label: t("school_tid"),
      value: plan.school_trans_id,
      condition: !isRole(Role.USER, user) && plan.school_trans_id,
    },
    {
      label: t("ruba_school_trans_id"),
      value: plan.ruba_school_trans_id,
      condition:
        isOneOfRoles([Role.ADMIN, Role.SCHOOL], user) &&
        plan.ruba_school_trans_id,
    },
  ];
  const display = attributes.some((element) => element.condition);

  return (
    <>
      {display && (
        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t("plans:transfer_details")}</Accordion.Header>
            <Accordion.Body>
              <Container>
                <Row>
                  {attributes.map((element: any) => (
                    <PlanExtraDetailsColumn key={element.label} {...element} />
                  ))}
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

function PlanExtraDetailsColumn(props: {
  label: string;
  value: any;
  condition?: boolean;
  col?: number;
}) {
  const { label, value, condition = true, col = 6 } = props;
  if (!condition) return <></>;
  return (
    <Col lg={col} className="mb-4">
      <div className="d-flex flex-column">
        <div className="text-gray">{label}</div>
        <div>{value}</div>
      </div>
    </Col>
  );
}

export default PlanExtraDetails;
