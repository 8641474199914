import PreviewButton from "containers/common/Previewers/PreviewButton";
import { Accordion, Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FileType from "utils/enums/FileType";
import Role from "utils/enums/Role";
import { useUser } from "utils/hooks/auth";
import PlanSchoolDetails from "./SchoolDetails";
import { isRole } from "utils/functions/utilities";
import { toast } from "react-toastify";
import files from "utils/api/files";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlert from "utils/hooks/alert";

const PlanDocumentsDetails = (props: { plan: any }) => {
  const { plan } = props;
  const { data: user } = useUser();
  const { t } = useTranslation(["plans"]);
  const queryClient = useQueryClient();
  // use confirmation before deleting
  const { confirmOrDeny } = useAlert();
  // destroy file mutation
  const destroyFileMutation = useMutation(
    (id: number) => {
      return files.destroy(id);
    },
    {
      onSuccess: (data) => {
        toast.success(data.message);
        queryClient.invalidateQueries(["plan"]);
      },
    }
  );

  function deleteFile(id: number) {
    confirmOrDeny({
      title: "Delete file?",
      text: "Are you sure you want to delete this file?",
      confirmButtonText: "Yes",
      showDenyButton: false,
      onConfirm: () => destroyFileMutation.mutate(id),
    });
  }

  function filterDocumentsPerRole(item: any) {
    if (user?.role_id == Role.USER) {
      return ![
        FileType.PURCHASE_ORDER,
        FileType.SIGNED_INVOICE,
        FileType.SIGNED_RECEIPT,
        FileType.SIGNED_QUOTATION,
        FileType.SIGNED_RUBA_INVOICE,
        FileType.SIGNED_RUBA_QUOTATION,
        FileType.SIGNED_PURCHASE_ORDER,
        FileType.SIGNED_RUBA_PURCHASE_ORDER,
      ].includes(item.type);
    }
    if (user?.role_id == Role.SCHOOL) {
      let allowedDocuments = [
        FileType.QUOTATION,
        FileType.SIGNED_QUOTATION,
        FileType.SIGNED_INVOICE,
        FileType.RECEIPT,
        FileType.SIGNED_RECEIPT,
        FileType.SIGNED_CREDIT_NOTE,
        FileType.DELIVERY_NOTE,
      ];
      if (plan?.fi?.intermediated) {
        allowedDocuments.push(FileType.SIGNED_RUBA_PURCHASE_ORDER);
      } else {
        allowedDocuments.push(FileType.SIGNED_PURCHASE_ORDER, FileType.PURCHASE_ORDER);
      }
      return allowedDocuments.includes(item.type);
    }
    if (isRole(Role.FI, user)) {
      if (plan?.fi?.intermediated) {
        return ![FileType.SIGNED_RUBA_INVOICE, FileType.SIGNED_QUOTATION, FileType.SIGNED_RUBA_PURCHASE_ORDER, FileType.SIGNED_INVOICE].includes(item.type)
      } else {
        return ![FileType.SERVICE_FEE_RECEIPT].includes(item.type)
      }
    }
    return true;
  }

  return (
    <Accordion defaultActiveKey="0" className="mb-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("plans:documents")}</Accordion.Header>
        <Accordion.Body>
          <Tab.Container id="documents-tabs" defaultActiveKey="applicant">
            <Row>
              {user?.role_id != Role.USER && (
                <Col sm={12} className="document-tabs w-auto mx-auto mb-3">
                  <Nav variant="pills" className="">
                    <Nav.Item>
                      <Nav.Link eventKey="applicant">
                        {t("plans:applicant_documents")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="school">
                        {t("plans:school_documents")}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              )}
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="applicant">
                    <Container>
                      <Row>
                        {plan.files
                          .filter(filterDocumentsPerRole)
                          .map((item: any) => (
                            <Col key={item.id} lg={12}>
                              <PlanDocumentsDetailsColumn
                                id={item.id}
                                label={item.type}
                                value={item.path}
                                deleteFile={() => deleteFile(item.id)}
                              />
                            </Col>
                          ))}
                      </Row>
                    </Container>
                  </Tab.Pane>
                  {user?.role_id != Role.USER && (
                    <Tab.Pane eventKey="school">
                      <PlanSchoolDetails plan={plan} />
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

function PlanDocumentsDetailsColumn(props: {
  id: string;
  label: string;
  value: string;
  deleteFile: () => void;
}) {
  const { t } = useTranslation(["plans"]);
  const { id, label, deleteFile } = props;
  const { data: user } = useUser();

  return (
    <div className="w-100 mb-2">
      {isRole(Role.ADMIN, user) ? (
        <PreviewButton id={id} label={t(label)} deleteFile={deleteFile} />
      ) : (
        <PreviewButton id={id} label={t(label)} />
      )}
    </div>
  );
}

export default PlanDocumentsDetails;
