import { useMutation, useQueryClient } from "@tanstack/react-query";
import ReserveTable from "components/widgets/ReserveTable";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { IUpdateProposedSchedule, reserveTransactions } from "utils/api/reserveTransactions";
import ReserveStatus from "utils/enums/ReserveStatus";


type Props = {
  plan: any;
};

const PlanPendingReserveApproval = (props: Props) => {
  const { plan } = props;
  const [show, setShow] = useState(false)
  const [showComment, setShowComment] = useState(false)
  const [comment, setComment] = useState('');
  const { t } = useTranslation(['common', 'plans'])
  const client = useQueryClient();
  const reserveScheduleProposal = plan?.extras?.reserve;
  const updateProposedScheduleMutation = useMutation(
    (data: IUpdateProposedSchedule) => {
      return reserveTransactions.updateProposedSchedule(plan.id, data)
    },
  );

  if (plan.reserve_status !== ReserveStatus.PENDING_SCHOOL_APPROVAL)
    return <></>;

  function decide(decision: 'accept' | 'reject', reserve_comment: string = '') {
    updateProposedScheduleMutation.mutate({ decision, reserve_comment },
      {
        onSuccess: (data: any) => {
          client.invalidateQueries(['plan']);
          if (data.success) {
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
          setShow(false)
        }
      }
    );
  }

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setShow(true)}
        size="lg"
        className="w-100 my-3"
        type="button"
      >
        {t("proposed_schedule")}
        <i className="mx-1 bi bi-journal-text"></i>
      </Button>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">
            {t("proposed_schedule")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReserveTable reserve={reserveScheduleProposal} />
          <div className="d-flex justify-content-between mt-3">
            <Button
              type="button"
              // onClick={() => decide('reject')}
              onClick={() => setShowComment(prev => !prev)}
              variant="light">
              {t("plans:comment")}
            </Button>
            <Button
              type="submit"
              onClick={() => decide('accept')}
              variant="primary" className="me-2">
              {t("plans:approve")}
            </Button>
          </div>
          {showComment &&
            <>
              <div>
                <Form.Group className="mt-3">
                  <Form.Control
                    as="textarea"
                    placeholder={t("plans:comment")}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

              </div>
              <div className="d-flex justify-content-between mt-2">
                <Button
                  type="button"
                  onClick={() => setShowComment(false)}
                  variant="light" className="me-2">
                  {t("cancel")}
                </Button>
                <Button
                  type="button"
                  onClick={() => decide('reject', comment)}
                  variant="primary" className="me-2">
                  {t("submit")}
                </Button>
              </div>
            </>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlanPendingReserveApproval;
