import React from "react";
import { Button, ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { useTranslation } from "react-i18next";

interface TablePagesProps {
  isFetching: boolean;
  isSuccess: boolean;
  data: { data: any[]; meta: any };
  page: number;
  perPage: number;
  setPage: Function;
  setPerPage: Function;
}
const TablePages = (props: TablePagesProps) => {
  const { t } = useTranslation(["common"]);
  const { data, page, perPage, setPage, setPerPage, isFetching, isSuccess } =
    props;

  if (isFetching || !isSuccess) return <></>;
  
  // Calculate the range of pages to display
  const { meta } = data;
  const startPage = Math.max(1, page - 2);
  const endPage = Math.min(meta.last_page, page + 2);
  
  if(meta.last_page == meta.first_page) return (<></>);
  // Calculate the page numbers for "previous" and "next" buttons
  const prevPage = Math.max(1, page - 1);
  const nextPage = Math.min(meta.last_page, page + 1);

  return (
    <Row className="d-flex align-items-center flex-column px-3">
      <Col lg={12} className="mt-3">
        <Row>
          <Col xs="4" className="">
            <Button
              style={{ height: "48px" }}
              className="py-0 mx-2"
              variant="outline-light"
              onClick={() => setPage(prevPage)}
            >
              <i className="bi bi-arrow-left me-0 me-sm-2"></i>
              <span className="d-none d-sm-inline">{t("back")}</span>
            </Button>
          </Col>
          <Col xs="4" className="d-flex justify-content-center">
            <Pagination>
              {Array.from(
                { length: endPage - startPage + 1 },
                (_, i) => startPage + i
              ).map((pageNum) => (
                <Pagination.Item
                  key={pageNum}
                  active={pageNum === page}
                  onClick={() => setPage(pageNum)}
                >
                  {pageNum}
                </Pagination.Item>
              ))}
            </Pagination>
          </Col>
          <Col
            xs="4"
            className="d-flex justify-content-between justify-content-sm-end"
          >

            <Button
              className="ms-2"
              style={{ height: "48px" }}
              variant="outline-light"
              onClick={() => setPage(nextPage)}
            >
              <i className="bi bi-arrow-right me-0 me-sm-2"></i>
              <span className="d-none d-sm-inline">{t("next")}</span>
            </Button>
          </Col>
          <Col
            lg="12"
            className="d-flex justify-content-between mt-3"
          >
            <Button
              style={{ height: "48px" }}
              className="py-0"
              variant="outline-light"
              onClick={() => setPage(1)}
            >
              <i className="bi bi-arrow-left me-2"></i>
              {t("first")}
            </Button>

            <Button
              style={{ height: "48px" }}
              variant="outline-light"
              onClick={() => setPage(meta.last_page)}
            >
              {t("last")}
              <i className="bi bi-arrow-right ms-2"></i>
            </Button>
          </Col>
        </Row>
      </Col>
      <Col className="text-center">Pages: {meta.last_page}</Col>
    </Row>
  );
};

export default TablePages;
