import client from "../api";

export function list() {
  return client.get("grades") as any;
}

export function view(id:string |undefined){
  return () => client.get(`grades/${id}`) as any
}

export function store(values:any){
  return client.post("grades",values) as any;
}

export function update(id:string,values:any){
  return client.put(`grades/${id}`,values) as any;
}

export function destroy(id:string){
  return client.delete(`grades/${id}`) as any;
}

const grades = { list,view,store,update,destroy };

export default grades;
