import { Row } from "react-bootstrap";
import PaymentSummaryOverdue from "./Overdue";

type Props = {};

const PaymentSummary = (props: Props) => {
  return (
    <Row>
      <PaymentSummaryOverdue />
    </Row>
  );
};

export default PaymentSummary;
