import { useQuery } from "@tanstack/react-query";
import Checkbox from "components/inputs/Checkbox";
import Input from "components/inputs/Input";
import Radio from "components/inputs/Radio";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Accordion, Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import plans from "utils/api/plans";
import ReserveStatus from "utils/enums/ReserveStatus";
import { statusSpaceToUnderScore } from "utils/functions/utilities";

const PlanFilters = (props: any) => {
  const { filter, setFilter } = props;
  const [show, setShow] = useState(false);
  const { t } = useTranslation(["plans", "common"]);
  const { data: filterables } = useQuery(["filterables"], plans.filterables, {
    placeholderData: {
      status: [],
      branches: [],
    },
  });

  function submit(values: any) {
    const newFilters: any = {};
    if (values.search) newFilters["search"] = values.search;
    if (values.status) {
      const newStatus = Object.entries(values.status)
        .filter(([key, value]) => value)
        .map(([key, value]: any) => key)
        .join(",");
      newFilters["status"] = newStatus;
    }
    if (values.branches) {
      const newBranches = Object.entries(values.branches)
        .filter(([key, value]) => value)
        .map(([key, value]: any) => key)
        .join(",");
      newFilters["branches"] = newBranches;
    }
    if (values.reserve_status !== "all") newFilters["reserve_status"] = values.reserve_status;

    if (values.student_id) newFilters["student_id"] = values.student_id;
    setFilter(newFilters);
    setShow(false);
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        search: filter.search,
        status: filterables?.status?.reduce?.(
          (acc: any, status: any) => ({
            ...acc,
            [status]: false,
          }),
          {}
        ),
        branches: filterables?.branches?.reduce?.(
          (acc: any, branch: any) => ({ ...acc, [branch.id]: false }),
          {}
        ),
        student_id: filter.student_id,
        reserve_status: filter.reserve_status,
      }}
      onSubmit={submit}
    >
      {(formikBag) => (
        <Form>
          <Row className="d-flex justify-content-end flex-wrap">
            <Col lg="6" className="position-relative mb-3 mb-lg-0">
              <i
                style={{ zIndex: 1 }}
                className="position-absolute mt-2 bi bi-search fs-5 text-black-50 ms-3 py-2"
              ></i>
              <Input
                className="ps-5 mt-lg-0"
                name="search"
                placeholder={t("common:search")}
              />
            </Col>
            <Col className="mb-3 mb-sm-0">
              <Button
                type="button"
                className="w-100 h-100 p-3"
                variant="outline-light"
                onClick={() => setShow(true)}
              >
                <i className="bi bi-filter me-2"></i>
                {t("common:filters")}
              </Button>
            </Col>
            <Col className="mb-3 mb-sm-0">
              <Button
                type="button"
                className="w-100 h-100 p-3 px-4"
                variant="primary"
                onClick={formikBag.submitForm}
              >
                {t("common:apply")}
              </Button>
            </Col>
            <Modal
              centered
              aria-labelledby="contained-modal-title-vcenter"
              show={show}
              onHide={() => setShow(false)}
              size="sm"
            >
              <Modal.Header
                className="d-flex justify-content-between"
                closeButton
              >
                <Modal.Title className="fs-6">
                  {t("common:filters")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Accordion className="mb-4">
                  {filterables?.status?.length > 0 && (
                    <Accordion.Item eventKey="0" className="p-2 border-0">
                      <Accordion.Header>{t("status")}</Accordion.Header>
                      <Accordion.Body>
                        {filterables?.status?.map((status: string) => (
                          <Checkbox
                            key={status}
                            name={`status[${status}]`}
                            label={t(statusSpaceToUnderScore(status))}
                          />
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {filterables?.branches?.length > 0 && (
                    <Accordion.Item eventKey="1" className="p-2 border-0">
                      <Accordion.Header>
                        {t("schools:branches")}
                      </Accordion.Header>
                      <Accordion.Body>
                        {filterables?.branches?.map((branch: any) => (
                          <Checkbox
                            key={branch.id}
                            name={`branches[${branch.id}]`}
                            label={branch.name}
                          />
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  <Accordion.Item eventKey="2" className="p-2 border-0">
                    <Accordion.Header>{t("student_id")}</Accordion.Header>
                    <Accordion.Body>
                      <Input name={`student_id`} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="p-2 border-0">
                    <Accordion.Header>{t("common:has_reserve")}</Accordion.Header>
                    <Accordion.Body>
                      <Radio name={`reserve_status`} value={JSON.stringify(Object.values(ReserveStatus))} label={t("common:yes")} />
                      <Radio name={`reserve_status`} value={"no"} label={t("common:no")} />
                      <Radio name={`reserve_status`} value={"all"} label={t("common:all")} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Modal.Body>
            </Modal>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default PlanFilters;
