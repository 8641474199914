import React from "react";
import { Route, Routes } from "react-router-dom";
// import CreateSchool from "./Create";
import ListSettings from "./List";

const SettingsRoutes = () => {
  return (
    <Routes >
      {/* <Route path="/new" element={<CreateSchool />} />
      <Route path="/:id" element={<CreateSchool />} /> */}
      <Route path="/" element={<ListSettings />} />
    </Routes>
  );
};

export default SettingsRoutes;
