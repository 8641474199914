import { statusesToHideReserveApproval } from "containers/Plans/View/Actions/UserActions/PlanProposedReserveScheduleActionsForm";
import { User } from "utils/api/auth";
import ReserveStatus from "utils/enums/ReserveStatus";
import Role from "utils/enums/Role";

const actionableReserve = (user: User, plan: any) => {
  if (user.role_id === Role.ADMIN) return true;
  if (user.role_id === Role.MODERATOR) return true;
  if (user.role_id === Role.USER)
    if (plan.reserve_status === ReserveStatus.PENDING_PAYMENT)
      return true;

  if (plan.reserve_status === ReserveStatus.PENDING_APPROVAL &&
    !statusesToHideReserveApproval.includes(plan.status))
    return true;
  if (user.role_id === Role.SCHOOL)
    return [
      ReserveStatus.PENDING_SCHOOL_APPROVAL
    ].includes(plan.reserve_status);
  return false;
};

export default actionableReserve;
