import ReserveStatus from "utils/enums/ReserveStatus";

const baseStatus = {
  [ReserveStatus.PENDING_APPROVAL]: ReserveStatus.PENDING_APPROVAL,
  [ReserveStatus.REJECTED]: ReserveStatus.REJECTED,
  [ReserveStatus.PENDING_PAYMENT]: ReserveStatus.PENDING_PAYMENT,
  [ReserveStatus.ACTIVE]: ReserveStatus.ACTIVE,
  [ReserveStatus.COMPLETE]: ReserveStatus.COMPLETE,
  [ReserveStatus.SCHOOL_REJECTED]: ReserveStatus.SCHOOL_REJECTED,
};

export default function ReserveStatusPerUser(params) {
  return baseStatus
}

