import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
    size?: number
    currency?: string
    amount: number
    className?: string
}

const Amount = (props: Props) => {
    const { size = "sm", currency = "KD", amount, className = "" } = props;
    const { t } = useTranslation(['common']);
    let sizeClasses = "fbs-5";
    return (
        <>
            <p className={className}>{amount?.toLocaleString()}
                <sup className={sizeClasses + " fw-bold"}> {t(currency.toLowerCase())}</sup>
            </p>
        </>
    )
}

export default Amount