import client from "../api";

export function list(params: any): Promise<any> {
  return client.get("users", { params });
}

export function view(id?: any) {
  return () => client.get(`users/${id}`) as any;
}

export function store(values: any) {
  return client.post("users", values) as any;
}

export function update(id: string, values: any): Promise<any> {
  return client.put(`users/${id}`, values);
}

export function destroy(id: string) {
  return client.delete(`users/${id}`) as any;
}

export function upload(files: any): Promise<any> {
  const data = new FormData();
  for (const key of Object.keys(files)) {
    data.append(`data[]`, JSON.stringify(files[key as any].data));
    data.append(`files[]`, files[key as any].file);
  }
  return client.post("users/upload", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
    },
    onUploadProgress: (progressEvent: ProgressEvent) => { },
  });
}

export function updateLanguage(language: string) {
  return client.post(`users/language`, { language }) as any;
}

export function updateCountry(country_id: string) {
  return client.post(`users/country`, { country_id }) as any;
}

const users = {
  list,
  view,
  store,
  update,
  destroy,
  updateLanguage,
  updateCountry,
  upload,
};

export default users;
