import AccountForm from "components/forms/Account";
import { useTranslation } from "react-i18next";

type Props = {
  nextStep: () => void;
};

const QuickCreateAccount = (props: Props) => {
  const { nextStep } = props;
  const { t } = useTranslation(["common"]);
  return (
    <AccountForm
      nextStep={nextStep}
      submitTitle={t("common:next_add_students")}
    />
  );
};

export default QuickCreateAccount;
