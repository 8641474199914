import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { chatbot } from "utils/api/chatbot";

type Props = {};

const ChatbotQuestionsList = (props: Props) => {
  const { data } = useQuery(["questions"], chatbot.questions);

  
  return (
    <Row>
      <Col lg="12">
        <Card className="main-card mb-3">
          <CardHeader>
            <div className="w-100 d-flex justify-content-between">
              <CardTitle>Rubot Portal</CardTitle>
              <div>
                <Link to="/chatbot/history">
                  <Button
                    className="mb-2 me-2 btn-icon"
                    outline
                    color="primary"
                  >
                    <i className="pe-7s-note2 btn-icon-wrapper"> </i>
                    History
                  </Button>
                </Link>
                <Link to="/chatbot/settings">
                  <Button
                    className="mb-2 me-2 btn-icon"
                    outline
                    color="primary"
                  >
                    <i className="pe-7s-tools btn-icon-wrapper"> </i>
                    Settings
                  </Button>
                </Link>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div>
              <div className="d-flex justify-content-end">
                <Link to="/chatbot/new">
                  <Button size="sm" color="primary">
                    Create Question
                  </Button>
                </Link>
              </div>
            </div>
            <Table striped className="mb-0">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any) => (
                  <tr key={item.id}>
                    <td>{item.question}</td>
                    <td>{item.answer}</td>
                    <td>
                      <Link to={"/chatbot/" + item.id}>
                        <Button size="sm" color="secondary">
                          Edit
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ChatbotQuestionsList;
