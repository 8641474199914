import { useState } from "react";
import { InputGroup, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import SchoolsMenu from "./SchoolsMenu";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import SchoolType from "utils/enums/SchoolType";
import { useQuery } from "@tanstack/react-query";
import { schools } from "utils/api/schools";
import { useUser } from "utils/hooks/auth";
import { arabicValue } from "utils/functions/utilities";

type Props = {
  name: string;
  label: string;
  initialType: number;
  intialSchool?: number;
  afterChange?: (school_id:number) => void;
};

const SelectSchool = (props: Props) => {
  const [show, setShow] = useState(false);
  const [school, setSchool] = useState<any>(null);
  const [field, meta] = useField(props);
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const { initialType, intialSchool,afterChange } = props;
  const { t } = useTranslation(["plans", "common"]);

  const { data: schoolsData } = useQuery(["schools-list"], schools.list, {
    initialData: [],
    onSuccess: (data) => {
      if (intialSchool) {
        const school = data.find((item: any) => item.id === intialSchool);
        setSchool(school);
      }
    },
  });

  const handleOpen = () => {
    if (!!initialType) setShow(true);
    setFieldTouched(field.name, true);
  };

  const handleClose = () => setShow(false);

  function selectSchool(school: any) {
    setFieldValue(field.name, school.id);
    setFieldValue("school_has_branches", school.branches?.length > 0);
    afterChange && afterChange(school.id);
    setSchool(school);
    setShow(false);
  }

  return (
    <>
      <Form.Group>
        <Form.Label>{props.label}</Form.Label>
        <Form.Select
          onClick={handleOpen}
          className="form-control rounded-3"
          id={props.name}
          isInvalid={meta.touched && !initialType}
        >
          <option>{school?.name ? arabicValue(school,'name') : t(`select_educational_institution`)}</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {t(`select_type_first`)}
        </Form.Control.Feedback>
      </Form.Group>
      <Modal
        centered
        aria-labelledby="contained-modal-select-school-vcenter"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">{`${t("common:select")} ${t(
            "a_" + SchoolType[initialType]?.toLowerCase()
          )}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-7 apply p-0 pb-3 p-lg-4">
          <SchoolsMenu
            handleClose={handleClose}
            selectedSchool={field.value}
            selectSchool={selectSchool}
            initialType={initialType}
            schoolsData={schoolsData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectSchool;
