import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { PerPage } from "utils/constants";

export interface PaginationParams {
  key: any | any[];
  api: Function;
  filter?: any;
  perPage?: number;
  options?: any;
}

export function usePaginate(props: PaginationParams) {
  const { key, api, perPage = PerPage, filter = {}, options = {} } = props;

  const [pagination, setPagination] = useState({
    page: 1,
    perPage,
  });

  const { data, isSuccess, isFetching } = useQuery(
    [key, { page: pagination.page, perPage: pagination.perPage,...filter }],
    () => api({ ...pagination, ...filter }),
    {
      placeholderData: {
        data: [],
        meta: {
          total: 0,
          page: 1,
          perPage: 10,
        },
      },
      ...options,
    }
  );

  const setPage = (page: number) => {
    setPagination((prevPagination) => ({ ...prevPagination, page }));
  };

  const setPerPage = (perPage: number) => {
    setPagination((prevPagination) => ({ ...prevPagination, perPage }));
  };

  return { isSuccess, isFetching, data, ...pagination, setPage, setPerPage };
}
