import { Field, useField } from "formik";
import { useState } from "react";
import { Dropdown, Form, InputGroup, DropdownButton } from "react-bootstrap";
import { CircleFlag } from "react-circle-flags";
import { useTranslation } from "react-i18next";

const countries = [
  {
    code: "kw",
    dialCode: "+965",
  },
];

const PhoneInput = (props: any) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [field, meta] = useField(props);

  return (
    <>
      {props.label && (
        <Form.Label>{props.label || t("mobile_here")}</Form.Label>
      )}
      <InputGroup dir="ltr">
        <DropdownButton
          variant="white"
          className="border rounded-0 rounded-start"
          title={<Flag {...countries[0]} />}
        >
          <Dropdown.Item>
            {countries.map((country) => (
              <Flag key={country.code} {...country} />
            ))}
          </Dropdown.Item>
        </DropdownButton>
        <Form.Control
          {...field}
          {...props}
          type="number"
          inputMode="numeric"
          size="lg"
          className="rounded-0 rounded-end"
          isInvalid={meta.touched && meta.error}
        />
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      </InputGroup>
    </>
  );
};

function Flag(props: any) {
  return (
    <span>
      <CircleFlag countryCode={props.code} height="14" className="me-2" />
      {props.dialCode}
    </span>
  );
}

export default PhoneInput;
