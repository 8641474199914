import Input from "components/inputs/Input";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "react-bootstrap";

type Props = {};

const CreateRegistrationDates = (props: Props) => {
  const { t } = useTranslation(["schools", "common"]);
  const { values, setFieldValue } = useFormikContext<any>();

  function newDate() {
    let dates = values.registration_dates || [];
    setFieldValue("registration_dates", [...dates, DateTime.now()]);
  }
  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title className="fs-6 fw-bold">
          {t("registration_dates")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {values.registration_dates?.map((registerDate: any, i: number) => (
          <Row key={registerDate}>
            <Col md="6" className="mb-3">
              <Input type="date" name={`registration_dates[${i}]`} />
            </Col>
            <Col md="2" className="d-flex align-items-end mb-3">
              <Button
                className="w-100"
                variant="light"
                size="lg"
                onClick={() =>
                  setFieldValue(
                    "registration_dates",
                    values.registration_dates.filter(
                      (item: any) => item != registerDate
                    )
                  )
                }
              >
                <i className="bi bi-trash3 mx-1"></i>
                {t("common:delete")}
              </Button>
            </Col>
          </Row>
        ))}
        <Row>
          <Col md="12" className="d-flex justify-content-end mt-4">
            <Button
              className="w-100 w-sm-auto mb-3"
              variant="light"
              size="lg"
              onClick={newDate}
            >
              <i className="bi bi-plus-lg mx-3"></i>
              {t("add_registration_date")}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreateRegistrationDates;
