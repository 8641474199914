import client from "../api";

export function list(): Promise<any> {
  return client.get(`chatbot/history`);
}

export function destroy(id: any): Promise<any> {
  return client.delete(`chatbot/history/${id}`);
}

export function clear(id: any): Promise<any> {
  return client.delete(`chatbot/history`);
}

export const chatbotHistory = { list, destroy, clear };
