import client from "../api";

export function list() {
  return client.get("schools") as any;
}

export function view(id:string |undefined){
  return () => client.get(`schools/${id}`) as any
}

export function store(values:any){
  return client.post("schools",values) as any;
}

export function update(id:string,values:any):Promise<any>{
  return client.put(`schools/${id}`,values);
}

export function destroy(id:string | undefined){
  return client.delete(`schools/${id}`) as any;
}
export function grades(id:string) {
  return () => client.get(`schools/${id}/grades`);
}

export function upload(schoolId:string,files:any){
  const data = new FormData();
  data.append("schoolId",schoolId);
  for (const key of Object.keys(files)) {
    data.append(`data[]`, JSON.stringify(files[key as any].data));
    data.append(`files[]`, files[key as any].file);
  }
  return client.post("schools/upload", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
    },
    onUploadProgress: (progressEvent: ProgressEvent) => {
    },
  });
}

export const schools = { list,view, store,update,destroy,grades,upload };
