import { useQuery } from "@tanstack/react-query";
import { Alert, Breadcrumb, BreadcrumbItem, Col, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import reserves from "utils/api/reserves";
import ReserveBasicInfo from "./BasicInfo";
import ReserveTransactions from "./Transactions";
import ReserveVendorInfo from "./VendorInfo";
import { useTranslation } from "react-i18next";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { isRole } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import Role from "utils/enums/Role";
import TransactionStatus from "utils/enums/TransactionStatus";

type Props = {};

const ViewReserve = (props: Props) => {
  const { id } = useParams();
  const { state } = useLocation();
  const { data: reserve } = useQuery(["reserve", id], () => reserves.view(id));
  const { t } = useTranslation(["common"]);
  const { data: user } = useUser();
  const hasRefundRequest = reserve?.transactions.some((item: any) => item.status === TransactionStatus.PENDING_REFUND);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem linkAs="div">
          <Link to="/reserves">
            {isRole(Role.FI, user) ? t("accounts") : t("reserves")}
          </Link>
        </BreadcrumbItem>
        {state?.backReserve && (
          <BreadcrumbItem linkAs="div">
            <Link to={`/reserves/${state?.backReserve?.id}/clients`}>
              {state?.backReserve?.name}
            </Link>
          </BreadcrumbItem>
        )}
        <BreadcrumbItem active>{reserve?.entity?.name}</BreadcrumbItem>
      </Breadcrumb>
      <Row className="gap-4">
        <RoleBasedRenderer
          admin={
            <>
              {/* Alert to inform user that there is a pending request */}
              {hasRefundRequest && (
                <Col xs={12}>
                  <Alert variant="warning">
                    {t("has_refund_request")}
                  </Alert>
                </Col>
              )}
              <ReserveBasicInfo />
              <ReserveVendorInfo />
            </>}
        />
        <Col xs={12}>
          <ReserveTransactions reserve={reserve} />
        </Col>
      </Row>
    </>
  );
};

export default ViewReserve;
