import React, { createContext, useState } from "react";

// Create the context
export const LayoutContext = createContext({
  isSidebarVisible: true,
  toggleSidebar: () => {},
});

// Create the provider component
export const LayoutProvider: React.FC = ({ children }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <LayoutContext.Provider value={{ isSidebarVisible, toggleSidebar }}>
      {children}
    </LayoutContext.Provider>
  );
};
