import FIPendingActionsWidget from "../Widgets/FIPendingActions";
import FIStatsWidget from "../Widgets/FIStats";
import PlansTableWidget from "../Widgets/NewPlans";
import WelcomeWidget from "../Widgets/Welcome";

const FIWidgets = () => {
  return (
    <>
      <WelcomeWidget />
      <FIPendingActionsWidget />
      <FIStatsWidget />
      <PlansTableWidget />
    </>
  );
};

export default FIWidgets;
