import InstallmentsHistory from "containers/Installments/List/History";
import PendingReserveTransactions from "containers/Reserves/View/PendingTransactions";
import UnsettledList from "containers/Reserves/View/UnsettledList";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PaymentSummary from "./PaymentSummary";
import AfterRedirectionPopup from "./PaymentSummary/AfterRedirectionPopup";
import { usePaginate } from "utils/hooks/paginate";
import reserves from "utils/api/reserves";

const ListInstallments = (props: any) => {
  const { t } = useTranslation(["common", "plans"]);
  const { data } = usePaginate({
    key: ["reserve", "me"],
    api: () => reserves.view("me"),
    filter: {},
    options: {
      placeholderData: {},
    },
  });

  return (
    <Row>
      <Tab.Container id="installments-tabs" defaultActiveKey="unsettled">
        <Col lg={6} className="mb-2 mb-sm-5">
          <h4 className="text-dark fw-semibold">{t("plans:payments")}</h4>
        </Col>
        <Col lg={6} className="d-flex justify-content-center justify-content-sm-end">
          <Nav variant="tabs" className="none-top-border fs-7 mb-3 flex-column flex-sm-row text-center">
            {data?.fromReserveTransactions?.length > 0 &&
              <Nav.Item>
                <Nav.Link eventKey="pending">
                  {t("school_transfers")}
                </Nav.Link>
              </Nav.Item>}
            <Nav.Item className="mx-4">
              <Nav.Link eventKey="unsettled">
                {t("unsettled_installments")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="history">{t("payment_history")}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Tab.Content>
          <Tab.Pane eventKey="pending">
            <Col lg="12">
              <PendingReserveTransactions />
            </Col>
          </Tab.Pane>
          <Tab.Pane eventKey="unsettled">
            <PaymentSummary />
            <AfterRedirectionPopup redirectTo="/installments" />
            <Col lg="12">
              <UnsettledList />
            </Col>
          </Tab.Pane>
          <Tab.Pane eventKey="history">
            <InstallmentsHistory />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Row>
  );
};

export default ListInstallments;
