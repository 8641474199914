import { VisibilityState, createColumnHelper } from "@tanstack/react-table";
import Amount from "components/widgets/Amount";
import StatusRenderer from "components/widgets/StatusRenderer";
import TransactionStatusRenderer from "components/widgets/TransactionStatusRenderer";
import { DateTime } from "luxon";
import { breakPoints } from "utils/constants";
import Role from "utils/enums/Role";
import TransactionStatus from "utils/enums/TransactionStatus";
import { arabicValue, dateAndTime, paymentIsDone } from "utils/functions/utilities";

const columnHelper = createColumnHelper<any>();
const clientsTablecolumns = (t: any) => [
  columnHelper.accessor("transactions", {
    id: "id",
    header: t("plans:id"),
    cell: (info) => info.getValue()[0]?.custom_id,
  }),
  columnHelper.accessor("name", {
    id: "name",
    header: t("name"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("students", {
    id: "students",
    header: t("plans:students"),
    cell: (info) => info.row.original?.students?.map((i: any) => i.name).join(', '),
  }),
  columnHelper.accessor("mobile", {
    id: "mobile",
    header: t("auth:mobile"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("transactions", {
    id: "status",
    header: t("plans:status"),
    cell: (info) => {
      let pendingAndOverdue = info.getValue().find((t: any) => !paymentIsDone(t) && DateTime.fromISO(t.due_date).diffNow().milliseconds < 0);
      if (!pendingAndOverdue) pendingAndOverdue = info.getValue().find((t: any) => !paymentIsDone(t));
      if (!pendingAndOverdue) pendingAndOverdue = info.getValue()[0];
      return <TransactionStatusRenderer {...pendingAndOverdue} />
    },
  }),
  columnHelper.accessor("transactions", {
    id: "total",
    header: t("plans:amount"),
    cell: (info) => {
      const amount = info.getValue().reduce((acc: number, t: any) => acc + t.amount, 0);
      return <Amount amount={amount} />
    },
  }),
  columnHelper.accessor("transactions", {
    id: "progress",
    header: t("plans:installments"),
    cell: (info) => info.getValue().length,
  }),
];

export function columnOrder(role: Role) {
  const orders: any = {
    [Role.ADMIN]: [
      "name",
    ],
    [Role.MODERATOR]: [
      "name",
    ],
    [Role.FI]: [
      "id",
      "name",
    ],
    [Role.SCHOOL]: [
      "name",
    ],
  };
  return orders[role];
}

export const clientsTablecolumnVisibility = (
  width: number,
  role: Role
): VisibilityState => {
  let hiddenColumns: any = { id: false };
  if (role === Role.FI) hiddenColumns = { ...hiddenColumns, students: false };
  return hiddenColumns;
};

export default clientsTablecolumns;
