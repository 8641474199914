import { useMutation, useQuery } from '@tanstack/react-query'
import Checkbox from 'components/inputs/Checkbox'
import Input from 'components/inputs/Input'
import Select from 'components/inputs/Select'
import Icon from 'components/widgets/Icon'
import { Form, Formik } from 'formik'
import { Breadcrumb, BreadcrumbItem, Button, Col, FormGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { addons } from 'utils/api/addons'
import { schools } from 'utils/api/schools'
import * as Yup from 'yup'
import AddonPaymentPagePreviewer from './Preview'


type Props = {}

const CreateAddon = (props: Props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation(["addons", "common", "schools"]);
    const { data: schoolsData } = useQuery(['schools'], schools.list, {
        placeholderData: []
    });

    const { data: addonData } = useQuery(['addons', id], () => addons.view(id!), {
        enabled: !!id,
    });


    const storeMutation = useMutation(addons.store, {
        onSuccess: () => {
            navigate('/addons')
        }
    })

    const updateMutation = useMutation((values: any) => addons.update(id!, values), {
        onSuccess: () => {
            navigate('/addons')
        }
    })

    function submit(values: any) {
        if (id) {
            updateMutation.mutate(values)
            return
        }
        storeMutation.mutate(values)
    }

    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/addons">
                        <Icon name="bagPlus" /> {t("addons:all_addons")}
                    </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                    {id ? addonData?.name : t("addons:new_addon")}
                </BreadcrumbItem>
            </Breadcrumb>
            <Formik
                enableReinitialize
                initialValues={{
                    school_id: '',
                    name: '',
                    description: '',
                    custom_requirement_en: '',
                    custom_requirement_ar: '',
                    price: 0,
                    quantity: 0,
                    is_active: 1,
                    ...addonData
                }}
                validationSchema={Yup.object({
                    school_id: Yup.string().required('Required'),
                    name: Yup.string().required('Required'),
                    description: Yup.string().required('Required'),
                    price: Yup.number().required('Required'),
                    quantity: Yup.number()
                })}
                onSubmit={submit}
            >
                {({ values }) => <>
                    <Form >
                        <Row>
                            <Col lg={8} className='mb-4'>
                                <h4 className="text-dark fw-semibold">{t("create_addon")}</h4>
                                <p className="text-gray">{t("create_addon_description")}</p>
                            </Col>
                        </Row>
                        <Row >
                            <Col lg={6}>
                                <Row className='gy-4'>
                                    <Col lg={12}>
                                        <Select label={t('schools:schools')} name="school_id" options={schoolsData?.map((school: any) => ({ label: school.name, value: school.id }))} />
                                    </Col>
                                    <Col lg={12}>
                                        <Input label={t('common:name')} type="text" name="name" />
                                    </Col>
                                    <Col lg={12}>
                                        <Input label={t('common:description')} type="text" name="description" />
                                    </Col>
                                    <Col lg={12}>
                                        <Input label={t('common:price')} type="number" name="price" />
                                    </Col>
                                    <Col lg={12}>
                                        <Input label={t('common:quantity')} type="number" name="quantity" />
                                    </Col>
                                    <Col lg={12}>
                                        <Input label={`${t('custom_requirement_en')} (${t('common:optional')}) `} type="text" name="custom_requirement_en" />
                                    </Col>
                                    <Col lg={12}>
                                        <Input label={`${t('custom_requirement_ar')} (${t('common:optional')}) `} type="text" name="custom_requirement_ar" />
                                    </Col>
                                    <Col lg={12}>
                                        <FormGroup className="pt-0 pt-sm-4">
                                            <Checkbox label={t('common:active')} name="is_active" />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={12} className="d-flex justify-content-end px-0">
                                        <Button variant='primary' type="submit">Submit</Button>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={6}>
                                <AddonPaymentPagePreviewer preview={values} />
                            </Col>
                        </Row>
                    </Form>
                </>}
            </Formik>


        </>
    )
}

export default CreateAddon