import { capitalize } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Card, Col, Row, Table } from "react-bootstrap";
import { countries } from "utils/api/countries";
import { schools } from "utils/api/schools";
import EntityStatus from "utils/enums/EntityStatus";
import Role from "utils/enums/Role";
import SchoolType from "utils/enums/SchoolType";
import { useUser } from "utils/hooks/auth";
import Icon from "components/widgets/Icon";

const ListSchools = (props: any) => {
  const { data } = useQuery(["schools"], schools.list);
  const [filter, setFilter] = useState({ status: EntityStatus.ACTIVE });
  const { data: countriesData } = useQuery(["countries"], countries.list);
  const { t } = useTranslation(["common", "plans", "schools"]);
  const { data: user } = useUser();
  return (
    <Row>
      <Col lg="12">
        <Card className="main-card mb-3">
          <Card.Body>
            {user?.role_id == Role.ADMIN && (
              <Row className="justify-content-end">
                <Col lg={3} className="mb-3 d-flex justify-content-end">
                  <ButtonGroup className="w-100">
                    <Button
                      variant="outline-light"
                      onClick={() =>
                        setFilter((prev) => ({
                          ...prev,
                          status: EntityStatus.ACTIVE,
                        }))
                      }
                      active={filter.status == EntityStatus.ACTIVE}
                    >
                      {t("active")}
                    </Button>
                    <Button
                      variant="outline-light"
                      onClick={() =>
                        setFilter((prev) => ({
                          ...prev,
                          status: EntityStatus.INACTIVE,
                        }))
                      }
                      active={filter.status == EntityStatus.INACTIVE}
                    >
                      {t("inactive")}
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col lg={3} className="mb-3 text-end">
                  <Link to="/schools/new">
                    <Button className="w-100" variant="primary">
                      {t("schools:create_new_school")}
                    </Button>
                  </Link>
                </Col>
              </Row>
            )}
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>{t("plans:id")}</th>
                  <th>{t("plans:name")}</th>
                  <th>{t("schools:type")}</th>
                  <th>{t("country")}</th>
                  <th>{t("schools:discount")}</th>
                  <th>{t("schools:active_plans")}</th>
                  <th>{t("plans:actions")}</th>
                </tr>
              </thead>
              <tbody>
                {data
                  ?.filter((item: any) => item.status == filter.status)
                  .map((item: any) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{capitalize(SchoolType[item.type])}</td>
                      <td>
                        {
                          countriesData?.find(
                            (country: any) => country.id == item.country_id
                          ).code
                        }
                      </td>
                      <td>{item.discount}</td>
                      <td>{item.plans.length}</td>
                      <td>
                        <Link to={"/schools/" + item.id}>
                          <Icon name="edit" />
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ListSchools;
