import { useQueryClient } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import plans from "utils/api/plans";
import { FILE_SIZE, SUPPORTED_FORMATS } from "utils/constants";
import FileType from "utils/enums/FileType";
import { removeUnderScores } from "utils/functions/utilities";
import * as Yup from "yup";

const AdminPlanFileUploader = (props: any) => {
  const { plan } = props;
  const { t } = useTranslation(["plans", "auth", "common"]);
  const client = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  async function submit(values: any) {
    if (values.file) {
      setLoading(true);
      plans
        .upload({ planId: plan.id, files: { [values.type]: values.file } })
        .then((res) => {
          client.invalidateQueries(["plan"]);
          toast.success("Successfully uploaded the file!");
          setLoading(false);
          setShow(false);
        });
    }
  }
  return (
    <>
      <Button
        variant="light"
        onClick={() => setShow(true)}
        size="lg"
        className="w-100"
      >
        Upload File
      </Button>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={submit}
            initialValues={{
              file: "",
            }}
            validationSchema={Yup.object().shape({
              file: Yup.mixed()
                .required(t("common:document_required"))
                .test(
                  "fileSize",
                  t("common:file_too_large", {
                    size: FILE_SIZE / (1024 * 1024),
                  }),
                  (value) => value && value.size <= FILE_SIZE
                )
                .test(
                  "fileFormat",
                  t("common:unsupported_format"),
                  (value) => value && SUPPORTED_FORMATS.includes(value.type)
                ),
            })}
          >
            <Form>
              <Row>
                <Col lg={6}>
                  <Select
                    label="File Type"
                    name="type"
                    labelKey="name"
                    valueKey="id"
                    options={Object.entries(FileType).map(([_, name]) => ({
                      id: name,
                      name: removeUnderScores(name),
                    }))}
                  />
                </Col>
                <Col lg={6}>
                  <Input label="File" type="file" name={`file`} />
                </Col>
                <Col lg={12} className="text-end">
                  <Button
                    size="lg"
                    className="mt-4 w-100"
                    color="primary"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? "Uploading" : "Upload"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminPlanFileUploader;
