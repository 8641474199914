import Input from 'components/inputs/Input';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useToast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import referrals from 'utils/api/referrals';
import { toast } from "react-toastify";
import { useMutation } from '@tanstack/react-query';

type Props = {}

const ReferralCode = (props: Props) => {
    const { t } = useTranslation(["plans"]);
    const [value, setValue] = useState("");
    const [debouncedValue] = useDebounce(value, 500);
    const mutateReferralCode = useMutation(() => referrals.validCode(debouncedValue),
        {
            onSuccess: (data) => {
                if (data?.valid) {
                    toast.success(data?.message)
                } else {
                    toast.error(data?.message)
                }
            },
            onError: (error) => {
                toast.error("Error connecting to service")
            },
        });

    useEffect(() => {
        if (debouncedValue) {
            mutateReferralCode.mutate()
        }
    }, [debouncedValue])



    return (
        <>
            <Input
                name="referral_code"
                label={`${t("plans:referral_code")} (${t("common:optional")})`}
                onChange={(val: any) => { setValue(val.target.value) }}
            />
        </>
    )
}

export default ReferralCode