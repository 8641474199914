import Input from "components/inputs/Input";
import Radio from "components/inputs/Radio";
import ConditionalApprovalCheckboxes from "containers/common/ConditionalApprovalCheckboxes";
import MissingDocumentsChecklist from "containers/common/MissingDocumentCheckboxes";
import RejectionsChecklist from "containers/common/RejectionsCheckboxes";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import plans from "utils/api/plans";
import { rejectionOptions } from "utils/constants";
import FileType from "utils/enums/FileType";
import PlanStatus from "utils/enums/PlanStatus";
import * as Yup from "yup";

const PlanPendingFIActionsForm = (props: any) => {
  const { t } = useTranslation(["plans", "common"]);
  const { plan, planMutation } = props;
  const [show, setShow] = useState(false);
  const [contract, setContract] = useState({
    show: false,
    loading: false,
  });

  function submit(values: any) {
    const extend: any = {};
    if (values.status == PlanStatus.MISSING_DOCUMENTS_FI) {
      extend.reason = values.missing_document.join(",") + " " + values.reason;
    }
    if ([PlanStatus.REJECTED_FI,PlanStatus.RUBA_CONDITIONAL_APPROVAL].includes(values.status)) {
      extend.reason = values.reason_category + ": " + values.reason;
    }
    planMutation.mutate(
      { plan, values: { ...values, ...extend } },
      {
        onSuccess: () => {
          setShow(false);
        },
      }
    );
  }

  async function uploadContract(values: any) {
    setContract((prev) => ({
      ...prev,
      loading: true,
    }));
    await plans.upload({
      planId: plan.id,
      files: values,
    });
    setContract((prev) => ({
      ...prev,
      loading: false,
      show: false,
    }));
  }

  return (
    <>
      {PlanStatus.PENDING_FI == plan.status && (
        <>
          <Button
            disabled={contract.loading}
            variant="primary"
            onClick={() => setShow(true)}
            size="lg"
            className="w-100"
            type="button"
          >
            {t("common:action_required")}
          </Button>
          <Modal
            centered
            aria-labelledby="contained-modal-title-vcenter"
            show={show}
            onHide={() => setShow(false)}
            size="lg"
          >
            <Modal.Header
              className="d-flex justify-content-between"
              closeButton
            >
              <Modal.Title className="fs-6">
                {t("common:action_required")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                enableReinitialize
                onSubmit={submit}
                initialValues={{
                  status: "",
                  reason: "",
                  reason_category: rejectionOptions[0]
                }}
                validationSchema={Yup.object().shape({
                  status: Yup.string().required("Decide First"),
                  reason_category: Yup.string(),
                  reason: Yup.string(),
                })}
              >
                {({ values }) => (
                  <Form>
                    <Row className="g-3">
                      {!!plan.fi?.allow_digital_signature && (
                        <Col lg={6}>
                          <Radio
                            name="status"
                            label={t("request_cinet_signature")}
                            value={PlanStatus.PENDING_CINET}
                          />
                        </Col>
                      )}
                      <Col lg={6}>
                        <Radio
                          name="status"
                          label={t("common:accept")}
                          value={PlanStatus.PENDING_SERVICE_FEE}
                        />
                      </Col>
                      <Col lg={6}>
                        <Radio
                          name="status"
                          label={t("plans:conditional_approval")}
                          value={PlanStatus.RUBA_CONDITIONAL_APPROVAL}
                        />
                      </Col>
                      <Col lg={6}>
                        <Radio
                          name="status"
                          label={t("missing_documents")}
                          value={PlanStatus.MISSING_DOCUMENTS_FI}
                        />
                      </Col>
                      <Col lg={6}>
                        <Radio
                          name="status"
                          label={t("reject")}
                          value={PlanStatus.REJECTED_FI}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mt-3">
                        {values.status == PlanStatus.REJECTED_FI && (
                          <>
                            <div className="mb-3">
                              <RejectionsChecklist name="reason_category" />
                            </div>
                            <Input name="reason" label="Reason" type="textarea" />
                            {/* <Input
                            name="reason"
                            label={t("reason")}
                            type="textarea"
                          /> */}
                          </>
                        )}
                        {values.status == PlanStatus.MISSING_DOCUMENTS_FI && (
                          <>
                            <MissingDocumentsChecklist />
                            <Input
                              name="reason"
                              label={t("reason")}
                              type="textarea"
                            />
                          </>
                        )}
                        {values.status == PlanStatus.RUBA_CONDITIONAL_APPROVAL && (
                          <>
                            <div className="mb-3">
                              <ConditionalApprovalCheckboxes name="reason_category" />
                            </div>
                            <Input name="reason" label="Reason" type="textarea" />
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3" md="12">
                        <Button type="submit" className="w-100" size="lg">
                          {t("common:submit")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
          <Button
            disabled={contract.loading}
            variant="outline-primary"
            onClick={() =>
              setContract((prev) => ({
                ...prev,
                show: true,
              }))
            }
            size="lg"
            className="w-100 mt-3"
            type="button"
          >
            {t("plans:upload_financing_contract")}
          </Button>
          <Modal
            centered
            aria-labelledby="contained-modal-title-vcenter"
            show={contract.show}
            onHide={() =>
              setContract((prev) => ({
                ...prev,
                show: false,
              }))
            }
            size="lg"
          >
            <Modal.Header
              className="d-flex justify-content-between"
              closeButton
            >
              <Modal.Title className="fs-6">
                {t("plans:upload_financing_contract")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                onSubmit={uploadContract}
                initialValues={{}}
                validationSchema={Yup.object().shape({
                  [FileType.FINANCING_CONTRACT]: Yup.string().required(
                    t("document_required")
                  ),
                })}
              >
                {({ values }) => (
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <Input
                          type="file"
                          id="financing_contract"
                          name={FileType.FINANCING_CONTRACT}
                          label={t("financing_contract")}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-end mt-4" lg="12">
                        <Button
                          disabled={contract.loading}
                          size="lg"
                          className="w-100"
                          type="submit"
                        >
                          {contract.loading ? t("plans:uploading_documents") : t("common:submit")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default PlanPendingFIActionsForm;
