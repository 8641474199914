import client from "../api";

const base = "referrals";

interface valid {
    valid: boolean;
    message: string;
}

export function validCode(code: string): Promise<valid> {
    return client.post(`${base}/valid-code`, { code });
}

export default {
    validCode
};
