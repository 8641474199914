import cx from "classnames";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Icon from "components/widgets/Icon";
import StatusRenderer from "components/widgets/StatusRenderer";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { plans } from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";
import Role from "utils/enums/Role";
import {
  arabicValue,
  dateAndTime,
  isOneOfRoles,
  isRole,
} from "utils/functions/utilities";
import useAlert from "utils/hooks/alert";
import { useUser } from "utils/hooks/auth";
import FileType from "utils/enums/FileType";
import PlanAndReserveStatuses from "./PlanAndReserveStatuses";

function PlanListCard(props: any) {
  const { t } = useTranslation(["plans", "common", "auth"]);
  const { plan } = props;
  const navigate = useNavigate();
  const client = useQueryClient();
  const { data: user } = useUser();
  const { confirmOrDeny } = useAlert();
  const deleteMutation = useMutation(() => plans.destroy(plan.id), {
    onSuccess: (data) => {
      client.invalidateQueries(["plans"]);
    },
  });

  function del() {
    confirmOrDeny({
      confirmButtonText: t("common:yes"),
      denyButtonText: t("common:no"),
      onConfirm: deleteMutation.mutate,
    });
  }

  function handleClick() {
    if (
      [
        PlanStatus.ARCHIVED,
        PlanStatus.INCOMPLETE,
        PlanStatus.PENDING_DOCUMENTS,
      ].includes(plan.status) &&
      isRole(Role.USER, user)
    ) {
      return navigate(`/plans/${plan.id}/edit`);
    }
    return navigate(`/plans/${plan.id}`);
  }
  return (
    <Col
      onClick={handleClick}
      lg={12}
      className="border border-light rounded-2 mb-3 p-3"
    >
      <div className="d-flex justify-content-between">
        <h6 className="fw-bold text-dark">
          {plan.amount} {t("kd")}
        </h6>
        <p className="fbs-4 fw-semibold">
          {plan.number_of_installments} {t("payments")}
        </p>
      </div>
      <p className="fs-7 fw-semibold text-graycool mb-2">
        {arabicValue(plan.school, "name")}
      </p>
      <div
        className={cx("d-flex justify-content-end align-items-center mb-2", {
          "justify-content-between": !!plan.branch?.id,
        })}
      >
        {/* <span className="px-2 text-primary fbs-4 border rounded-2 border-primary py-1">
          {t("plans:current_school_year")}
        </span> */}
        {plan.branch && (
          <p className="fbs-4 text-dark mb-2">
            {arabicValue(plan.branch, "name")}
          </p>
        )}
        <p className="fbs-4 text-end">
          <Icon className="dark" size={14} name="student" />{" "}
          {plan.students?.length} {t("students")}
        </p>
      </div>
      <div className="d-flex justify-content-start mb-2">
        <div className="fbs-4">
          <PlanAndReserveStatuses plan={plan} />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <p className="fbs-4 text-gray">{t("common:created_at")}</p>
        <p className="fbs-4 text-gray">{t("common:last_update")}</p>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <p className="fbs-4 text-gray">{dateAndTime(plan.created_at)}</p>
        <p className="fbs-4 text-gray">{dateAndTime(plan.updated_at)}</p>
      </div>
      <div className="d-flex justify-content-between">
        {isOneOfRoles([Role.ADMIN, Role.USER], user) &&
          [
            PlanStatus.INCOMPLETE,
            PlanStatus.ARCHIVED,
            PlanStatus.PENDING_DOCUMENTS,
          ].includes(plan.status) ? (
          <>
            <Link to="">
              <Icon name="edit" />
            </Link>
            <Link
              to=""
              onClick={(e) => {
                e.stopPropagation();
                del();
              }}
            >
              <Icon name="trash" />
            </Link>
          </>
        ) : (
          <>
            <Link to={`/plans/${plan.id}`}>
              <Icon name="openEye" />
            </Link>
            <div>
              {
                plan.files.some((x: any) => x.type == FileType.CINET) && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{t('common:signed')}</Tooltip>}
                  >

                    <i className="text-info bi bi-file-earmark-check-fill me-1"></i>
                  </OverlayTrigger>
                )
              }
              {plan?.user?.plans?.length > 0 &&
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t('plans:reapplying')}</Tooltip>}
                >
                  <i className="bi bi-star-fill text-info me-1"></i>
                </OverlayTrigger>
              }
              {!!plan?.paynow &&
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{t('common:paynow')}</Tooltip>}
                >
                  <i className="text-info bi bi-lightning-fill text-info me-1"></i>
                </OverlayTrigger>
              }
            </div>
          </>
        )}
      </div>
    </Col>
  );
}

export default PlanListCard;
