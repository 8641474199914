import { Field, useField, useFormikContext } from "formik";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AmountInput = (props: any) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const {t} = useTranslation(["plans"]);
  return (
    <InputGroup className="mb-0 mb-lg-3 amount-input">
      <Button
        onClick={() => setFieldValue(props.name, parseInt(field.value) - 10)}
        className="border bg-white minus"
      >
        <h5 className="text-dark text-opacity-75 my-0">
          <i className="bi bi-dash-square"></i>
        </h5>
      </Button>
      <Field
        as={Form.Control}
        className="text-center border-start-0 border-end-0"
        name={props.name}
        placeholder="0"
        aria-label={props.name}
        aria-describedby={props.name}
      />
      <Button
        onClick={() => setFieldValue(props.name, parseInt(field.value) + 10)}
        className="bg-white border plus"
      >
        <h5 className="text-dark text-opacity-75 my-0">
          <i className="bi bi-plus-square"></i>
        </h5>
      </Button>
      <InputGroup.Text className="bg-white" id={props.name}>{t('kd_per_year')}</InputGroup.Text>
    </InputGroup>
  );
};

export default AmountInput;
