import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import TablePages from "components/widgets/TablePages";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
} from "reactstrap";
import { chatbotContacts } from "utils/api/chatbot/contacts";
import { dateAndTime } from "utils/functions/utilities";
import { usePaginate } from "utils/hooks/paginate";

type Props = {};

const ChatbotContacts = (props: Props) => {

  const [filter, setFilter] = useState({});

  const paginate = usePaginate({
    key: "chatbot-contacts",
    api: chatbotContacts.list,
    filter,
  });

  const client = useQueryClient();
  const deleteMutation = useMutation(chatbotContacts.destroy, {
    onSuccess: () => {
      client.invalidateQueries(["chatbot-contacts"]);
      toast.info("Deleted successfully!");
    },
  });

  function del(id: number) {
    deleteMutation.mutate(id as any);
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/chatbot">
            <FontAwesomeIcon icon={faUser} /> Chatbot
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Contacts</BreadcrumbItem>
      </Breadcrumb>
      <Row>
        <Col lg="12">
          <Card className="main-card mb-3">
            <CardBody>
              <Table striped className="mb-0">
                <thead>
                  <tr>
                    <th>Contact ID</th>
                    <th>Channel</th>
                    <th>Status</th>
                    <th>Reference ID</th>
                    <th>Last modified</th>
                    <th>Last Introduction</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paginate?.data?.data?.map((item: any) => (
                    <tr key={item.id}>
                      <td>{item.contact_id}</td>
                      <td>{item.channel}</td>
                      <td>{item.status}</td>
                      <td>{item.reference_id}</td>
                      <td>{dateAndTime(item.updated_at)}</td>
                      <td>{item.last_introduction ? dateAndTime(item.last_introduction) : "Never"}</td>
                      <td>
                        <Button
                          size="sm"
                          color="danger"
                          outline
                          onClick={() => del(item.id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col lg="12">
          <TablePages {...paginate} />
        </Col>
      </Row>
    </>
  );
};

export default ChatbotContacts;
