import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import TablePages from "components/widgets/TablePages";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { useMemo, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { plans } from "utils/api/plans";
import { breakPoints } from "utils/constants";
import { usePaginate } from "utils/hooks/paginate";
import useWindowDimensions from "utils/hooks/window";
import DashboardPlansActions from "./Actions";
import Filters from "./components/Filters";
import PlanListCard from "./components/PlanListCard";
import PlansDownloader from "./components/PlansDownloader";
import plansTablecolumns, {
  columnOrder,
  plansTablecolumnVisibility,
} from "./tableConfig";
import { useUser } from "utils/hooks/auth";

const PlansList = () => {
  const [filter, setFilter] = useState({});
  const { t } = useTranslation(["plans", "common", "auth"]);
  const { data: user } = useUser();

  const paginate = usePaginate({
    key: "plans",
    api: plans.list,
    filter,
  });

  const { width } = useWindowDimensions();

  const table = useReactTable({
    data: paginate.data?.data || [],
    columns: useMemo(() => plansTablecolumns(t,user), [t]),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnOrder: useMemo(() => columnOrder(user?.role_id!), [width]),
      columnVisibility: useMemo(
        () => plansTablecolumnVisibility(width, user?.role_id!),
        [width]
      ),
    },
  });

  return (
    <>
      <Col lg="12">
        <Row>
          <Col lg={8} className="mb-2 mb-sm-5">
            <h4 className="text-dark fw-semibold">{t("all_plans")}</h4>
            <p className="text-gray">{t("manage_and_track")}</p>
          </Col>
          <Col lg={4} className="d-flex justify-content-end">
            <RoleBasedRenderer
              user={
                <Link to="/plans/new" className="w-100 w-sm-auto">
                  <Button
                    size="lg"
                    color="primary"
                    id="create-plan"
                    className="w-100 w-sm-auto my-2"
                  >
                    {t("create_new_plan")}
                  </Button>
                </Link>
              }
            />
          </Col>
        </Row>
      </Col>
      <Col lg="12">
        {width > breakPoints.md ? (
          <Card className="mb-3">
            <Card.Header>
              <Row className="justify-content-between align-items-center">
                <Col lg="2">
                  <h5>{t("all_plans")}</h5>
                </Col>
                <Col lg="8">
                  <Filters filter={filter} setFilter={setFilter} />
                </Col>
                <RoleBasedRenderer
                  admin={
                    <Col lg="10" className="d-flex">
                      <PlansDownloader />
                    </Col>
                  }
                  school={
                    <Col lg="10" className="d-flex">
                      <PlansDownloader />
                    </Col>
                  }
                />
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <Table className="mb-0">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                      <th>{t("actions")}</th>
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                      <td>
                        <div className="d-flex">
                          <DashboardPlansActions item={row.original} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <TablePages {...paginate} />
            </Card.Body>
          </Card>
        ) : (
          <Row>
            <RoleBasedRenderer
              admin={
                <>
                  <Col lg="12" className="d-flex">
                    <div className="flex-grow-1 m-2">
                      <Filters filter={filter} setFilter={setFilter} />
                    </div>
                  </Col>
                </>
              }
              school={
                <Col lg="12">
                  <Filters filter={filter} setFilter={setFilter} />
                </Col>
              }
            />
            <Col lg="12">
              {paginate.data?.data.map((plan: any) => (
                <PlanListCard key={plan.id} plan={plan} />
              ))}
            </Col>
            <Col lg="12">
              <TablePages {...paginate} />
            </Col>
          </Row>
        )}
      </Col>
    </>
  );
};

export default PlansList;
