import { useTranslation } from "react-i18next";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import i18n from "utils/i18n/config";
import { Accordion, Alert, Col, Container, Row } from "react-bootstrap";
import { arabicValue } from "utils/functions/utilities";

const PlanStudentsDetails = (props: {
  plan: {
    students: any[];
    school: any;
    registered: boolean;
    branch_id: any;
    plan_branch_id: any;
  };
}) => {
  const { plan } = props;
  const { students, school } = plan;
  const { t } = useTranslation(["plans", "schools"]);
  return (
    <Accordion defaultActiveKey="0" className="mb-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("student_details")}</Accordion.Header>
        <Accordion.Body>
          <Container>
            <RoleBasedRenderer
              admin={<StudentRegisteredWarning plan={plan} />}
              school={<StudentRegisteredWarning plan={plan} />}
              user={<StudentRegisteredWarning plan={plan} />}
            />
            <Row>
              {students?.map((student: any) => (
                <StudentRow
                  {...student}
                  branch_id={student.plan_branch_id ?? plan.branch_id ?? student.branch_id}
                  customIdLabel={school.plan_custom_id}
                  school={school}
                  grades={school.grades}
                  key={student.id}
                />
              ))}
            </Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

function StudentRow(props: any) {
  const { t } = useTranslation(["plans", "common", "schools"]);
  let branch: any = undefined;
  if (props.school?.branches?.length > 0) {
    branch = props.school?.branches?.find((b: any) => b.id == props.branch_id);
  }
  const gradeName = arabicValue(
    props.grades.find((grade: any) => props.plan_grade_id == grade.id),
    "name"
  );
  const attributes = [
    {
      label: t("common:legal_name"),
      value: props.name,
      condition: true,
    },
    {
      label: t("school"),
      value: arabicValue(props.school, "name"),
      condition: true,
    },
    {
      label: t("schools:branch"),
      value: arabicValue(branch, "name"),
      condition: !!branch,
    },
    {
      label: t("grade"),
      value: gradeName,
      condition: true,
    },
    {
      label: props.school?.custom_id,
      value: props.custom_id ?? "N/A",
      condition: props.school?.custom_id,
    },
  ];
  return (
    <Col lg={12}>
      <Row>
        {attributes.map((element: any) => (
          <StudentColumn key={element.label} {...element} />
        ))}
      </Row>
    </Col>
  );
}

function StudentColumn(props: {
  label: string;
  value: any;
  condition?: boolean;
}) {
  const { label, value, condition = true } = props;
  if (!condition) return <></>;
  return (
    <Col md="6" className="mb-4">
      <div className="d-flex flex-column">
        <div className="text-gray opacity-75 mb-2">{label}</div>
        <div className="fs-6 text-dark">{value}</div>
      </div>
    </Col>
  );
}

function StudentRegisteredWarning(props: any) {
  const { plan } = props;
  const { t } = useTranslation(["plans"]);
  return (
    <Row>
      <Col className="fw-bold mb-2 text-gray">
        <Alert variant={plan.registered ? "success" : "warning"} dismissible>
          {plan.registered
            ? t("students_already_registered")
            : t("students_not_registered")}
        </Alert>
      </Col>
    </Row>
  );
}

export default PlanStudentsDetails;
