import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import PreviewButton from "containers/common/Previewers/PreviewButton";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import files from "utils/api/files";
import { fis } from "utils/api/fis";
import FileType from "utils/enums/FileType";
import Role from "utils/enums/Role";
import {
  arabicValue,
  isRole,
  removeUnderScores,
} from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";

const CreateSchoolFiles = (props: any) => {
  const { id: schoolId } = props;
  const { data: user } = useUser();
  const { t } = useTranslation(["schools", "common"]);
  const { values, setFieldValue } = useFormikContext<any>();
  const { data: fiData, isSuccess } = useQuery(["fis"], fis.list);
  const client = useQueryClient();

  const fileDeleteMutation = useMutation(
    (id: number) => {
      return files.destroy(id);
    },
    {
      onSuccess: (data) => {
        client.invalidateQueries(["schools", schoolId]);
        toast.success(data.message);
      },
    }
  );

  function deleteFile(id: number) {
    fileDeleteMutation.mutate(id);
  }

  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title className="fs-6 fw-bold">
          {t("common:documents")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <div>
            {values.files
              ?.filter(
                (document: any) => ![FileType.SIGNATURE].includes(document.type)
              )
              .map((document: any, i: number) => {
                let label = t(`plans:${document.type}`);

                if (document.fi_id)
                  label =
                    `${label} - ` +
                    fiData?.find((fi: any) => document.fi_id == fi.id)?.name;

                return (
                  <Col lg={12} className="my-3" key={document.id}>
                    <PreviewButton
                      label={label}
                      id={document.id}
                      deleteFile={
                        isRole(Role.ADMIN, user)
                          ? () => deleteFile(document.id)
                          : undefined
                      }
                    />
                  </Col>
                );
              })}
          </div>
        </Row>

        {values.documents.map((document: any, i: number) => (
          <Row key={document.id}>
            <Col md="3" className="mb-3">
              <Select
                name={`documents[${i}].data.type`}
                options={[
                  FileType.COMMERCIAL_LICENSE,
                  FileType.AUTHORIZED_SIGNATORY,
                  FileType.LICENSE_SOCIAL_AFFAIRS,
                  FileType.LOGO,
                  FileType.BANK_LETTER,
                  FileType.CIVIL_ID_OF_AUTHORIZED_SIGNATORY,
                  FileType.SIGNATURE_DELEGATION,
                  FileType.MINISTRY_EDUCATION_LICENSE,
                ]?.map((i: any) => ({
                  value: i,
                  label: removeUnderScores(i),
                }))}
              />
            </Col>
            {[FileType.BANK_LETTER, FileType.SIGNATURE_DELEGATION].includes(
              document.data.type
            ) &&
              isSuccess && (
                <Col md="3">
                  <Select
                    name={`documents[${i}].data.fi_id`}
                    options={fiData?.map((item: any) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </Col>
              )}
            <Col md="6" className="mb-3">
              <Input type="file" name={`documents[${i}].file`} />
            </Col>
          </Row>
        ))}
        <Row>
          <Col md="12" className="d-flex justify-content-end">
            <Button
              className="w-100 w-sm-auto mb-3 mt-4"
              variant="light"
              size="lg"
              onClick={() =>
                setFieldValue("documents", [
                  ...values.documents,
                  { id: DateTime.now(), data: {} },
                ])
              }
            >
              <i className="bi bi-plus-lg mx-3"></i>
              {t("common:add_document")}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreateSchoolFiles;
