import React from "react";
import { Route, Routes } from "react-router-dom";
import ListLogs from "./List";

const LogsRoutes = () => {
  return (
    <Routes >
      <Route path="/" element={<ListLogs />} />
    </Routes>
  );
};

export default LogsRoutes;
