import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { Button, Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InstallmentsHistory from "../History";
import PendingReserveTransactions from "../PendingTransactions";
import UnsettledList from "../UnsettledList";
//@ts-ignore
import { creatCsvFile, downloadFile } from "download-csv";
import { transactions } from "utils/api/transactions";

type Props = {
  reserve: any;
};

const ReserveTransactions = (props: Props) => {
  const { t } = useTranslation(["common"]);
  const { reserve } = props;
  async function download(startDate: any) {
    const data = await transactions.download(reserve.id, startDate);
    const now = DateTime.now().toFormat("HH-mm-dd-MM-yyyy");
    let csvfile = creatCsvFile(data);
    downloadFile(csvfile, `transactions-${now}`, { delimeter: ";" });
  }
  return (
    <Row>
      <Tab.Container id="installments-tabs" defaultActiveKey="unsettled">
        <Col lg={6} className="mb-2 mb-sm-5">
          <h4 className="text-dark fw-semibold">{t("plans:installments")}</h4>
        </Col>
        <Col lg={6} className="d-flex justify-content-end">
          <Nav variant="tabs" className="none-top-border fs-7 mb-3">
            <RoleBasedRenderer
              admin={
                <Nav.Item className="me-4">
                  <Nav.Link eventKey="pending">
                    {t("school_transfers")}
                  </Nav.Link>
                </Nav.Item>
              }
              fi={
                <Nav.Item className="me-4">
                  <Nav.Link eventKey="export">{t("export")}</Nav.Link>
                </Nav.Item>
              }
              school={
                <Nav.Item className="me-4">
                  <Nav.Link eventKey="pending">
                    {t("school_transfers")}
                  </Nav.Link>
                </Nav.Item>
              }
            />
            <Nav.Item className="me-4">
              <Nav.Link eventKey="unsettled">
                {t("unsettled_installments")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="history">{t("payment_history")}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Tab.Content>
          <RoleBasedRenderer
            admin={
              <Tab.Pane eventKey="pending">
                <Col lg="12">
                  <PendingReserveTransactions />
                </Col>
              </Tab.Pane>
            }
            school={
              <Tab.Pane eventKey="pending">
                <Col lg="12">
                  <Card className="main-card mb-3">
                    <Card.Body>
                      <PendingReserveTransactions />
                    </Card.Body>
                  </Card>
                </Col>
              </Tab.Pane>
            }
            fi={
              <Tab.Pane eventKey="export">
                <Col lg="12">
                  <Card className="main-card mb-3">
                    <Card.Body className="p-2">
                      {/* formik form with a month selector and export button */}
                      <Formik
                        initialValues={{ month: "" }}
                        onSubmit={(values) => {
                          download(values.month);
                        }}
                      >
                        {({ handleSubmit, handleChange, values }) => (
                          <form onSubmit={handleSubmit}>
                            <Row className="justify-content-between">
                              <Col lg="6">
                                <label htmlFor="month">Month</label>
                                <input
                                  type="month"
                                  className="form-control"
                                  id="month"
                                  name="month"
                                  value={values.month}
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col lg="2">
                                <Button
                                  size="lg"
                                  type="submit"
                                  className="mt-4 w-100"
                                >
                                  {t("export")}
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </Col>
              </Tab.Pane>
            }
          />
          <Tab.Pane eventKey="unsettled">
            <Col lg="12">
              {reserve && <UnsettledList reserve={reserve} />}
            </Col>
          </Tab.Pane>
          <Tab.Pane eventKey="history">
            {reserve && <InstallmentsHistory reserve={reserve} />}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Row>
  );
};

export default ReserveTransactions;
