import { useMutation, useQueryClient } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { plans } from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";
import useAlert from "utils/hooks/alert";

const PlanSeatWithdrawer = (props: any) => {
  const { plan } = props;
  const { t } = useTranslation(["common", "plans"]);
  const client = useQueryClient();
  const { confirmOrDeny } = useAlert();
  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  function submit(values: any) {
    confirmOrDeny({
      title: t("are_you_sure"),
      text: t("wont_be_able_to_revert"),
      confirmButtonText: t("yes"),
      denyButtonText: t("no"),
      onConfirm: () => {
        planMutation.mutate({ plan, values });
      },
    });
  }

  return (
    <>
      {[
        PlanStatus.PENDING_RUBA,
        PlanStatus.PENDING_REGISTRATION,
        PlanStatus.PENDING_QUOTATION,
        PlanStatus.MISSING_DOCUMENTS_RUBA,
        PlanStatus.REJECTED_RUBA,
      ].includes(plan.status) && (
          <Formik
            onSubmit={submit}
            initialValues={{ status: PlanStatus.SEAT_WITHDRAWN }}
          >
            <Form>
              <Row>
                <Col md="12" className="mt-3">
                  <Input
                    type="hidden"
                    name="status"
                    value={PlanStatus.SEAT_WITHDRAWN}
                  />
                  <Button
                    size="lg"
                    type="submit"
                    variant="light"
                    className="w-100"
                  >
                    {t("plans:seat_withdrawn")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Formik>
        )}
    </>
  );
};

export default PlanSeatWithdrawer;
