import Input from "components/inputs/Input";
import Radio from "components/inputs/Radio";
import MissingDocumentsChecklist from "containers/common/MissingDocumentCheckboxes";
import RejectionsChecklist from "containers/common/RejectionsCheckboxes";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button, Col, FormGroup, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import plans from "utils/api/plans";
import { rejectionOptions } from "utils/constants";
import FileType from "utils/enums/FileType";
import PlanStatus from "utils/enums/PlanStatus";
import * as Yup from "yup";

const PlanPendingSignatureFIActionsForm = (props: any) => {
  const { plan, planMutation } = props;
  const { t } = useTranslation(["plans", "common"]);
  const [show, setShow] = useState(false);

  function submit(values: any) {
    const extend: any = {};

    if (values[FileType.PURCHASE_ORDER]) {
      plans.upload({
        planId: plan.id,
        files: { [FileType.PURCHASE_ORDER]: values[FileType.PURCHASE_ORDER] },
      });
    }
    if (values.status == PlanStatus.MISSING_DOCUMENTS_FI) {
      extend.reason = values.missing_document.join(",") + " " + values.reason;
    }
    if (values.status == PlanStatus.REJECTED_FI) {
      extend.reason = values.reason_category + ": " + values.reason;
    }

    planMutation.mutate(
      { plan, values: { ...values, ...extend } },
      {
        onSuccess: () => {
          setShow(false);
        },
      }
    );
  }

  let status = PlanStatus.PENDING_INVOICE;
  if (plan.school.direct_financing) {
    if (plan.ruba_cut <= 0) {
      status = PlanStatus.ACTIVE;
    } else {
      status = PlanStatus.PENDING_RUBA_TID;
    }
  }

  return (
    <>
      {PlanStatus.PENDING_SIGNATURE == plan.status && (
        <>
          <Button
            variant="primary"
            onClick={() => setShow(true)}
            size="lg"
            className="w-100"
          >
            {t("common:action_required")}
          </Button>
          <Modal
            centered
            aria-labelledby="contained-modal-title-vcenter"
            show={show}
            onHide={() => setShow(false)}
            size="lg"
          >
            <Modal.Header
              className="d-flex justify-content-between"
              closeButton
            >
              <Modal.Title className="fs-6">
                {t("common:action_required")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                enableReinitialize
                onSubmit={submit}
                initialValues={{
                  status,
                  reason: "",
                  reason_category: rejectionOptions[0]
                }}
                validationSchema={Yup.object().shape({
                  status: Yup.string().required("Decide First"),
                  reason_category: Yup.string(),
                  reason: Yup.string(),
                  [FileType.PURCHASE_ORDER]: Yup.string().when("status", {
                    is: PlanStatus.PENDING_INVOICE,
                    then: Yup.string().required(t("document_required")),
                  }),
                })}
              >
                {({ values, ...props }) => (
                  <Form>
                    <Row>
                      <Col lg="4">
                        <Radio
                          name="status"
                          label={
                            status === PlanStatus.PENDING_INVOICE
                              ? t("create_purchase_order")
                              : t("common:accept")
                          }
                          value={status}
                        />
                      </Col>
                      <Col lg="4">
                        <Radio
                          name="status"
                          label={t("missing_documents")}
                          value={PlanStatus.MISSING_DOCUMENTS_FI}
                        />
                      </Col>
                      <Col lg="4">
                        <Radio
                          name="status"
                          label={t("reject")}
                          value={PlanStatus.REJECTED_FI}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      {[
                        PlanStatus.PENDING_RECEIPT,
                        PlanStatus.PENDING_INVOICE,
                      ].includes(values.status) && (
                          <>
                            <Col md="6">
                              <Input
                                type="file"
                                id="purchase_order"
                                name={`${FileType.PURCHASE_ORDER}`}
                                label={t("purchase_order")}
                              />
                            </Col>
                            <Col md="6">
                              <Input
                                name="po_id"
                                label={t("purchase_order_id")}
                                required
                              />
                            </Col>
                          </>
                        )}
                      {/* @ts-ignore */}
                      {values.status == PlanStatus.REJECTED_FI && (
                        <Col md="12">
                          <div className="mb-3">
                            <RejectionsChecklist name="reason_category" />
                          </div>
                          <Input
                            name="reason"
                            label={t("reason")}
                            type="textarea"
                          />
                        </Col>
                      )}
                      {/* @ts-ignore */}
                      {values.status == PlanStatus.MISSING_DOCUMENTS_FI && (
                        <Col md="12">
                          <MissingDocumentsChecklist />
                          <Input
                            name="reason"
                            label={t("reason")}
                            type="textarea"
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col className="mt-3" md="12">
                        <Button type="submit" className="w-100" size="lg">
                          {t("common:submit")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default PlanPendingSignatureFIActionsForm;
