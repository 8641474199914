import { useQuery } from "@tanstack/react-query";
import Image from "containers/common/Previewers/Image";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import dashboard from "utils/api/dashboard";
import { arabicValue } from "utils/functions/utilities";

const TopSchoolsWidget = () => {
  const { data, isFetching } = useQuery<any>(
    ["onboarded-schools"],
    dashboard.topSchools
  );
  const { t } = useTranslation(["plans", "schools"]);

  return (
    <Col md={6} className="mb-4">
      <LoadingOverlay
        active={isFetching}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "#fff",
            opacity: 0.5,
          }),
        }}
        spinner={<Loader active type="ball-pulse" />}
      >
        <Card>
          <Card.Body className="p-4">
            <Row>
              <Col lg={12} className="fs-6 text-graycool fw-semibold mb-3">
                <p>{t("schools:top_educational_institutions")}</p>
              </Col>
              <Col lg={12}>
                <Table responsive hover className="align-middle mb-0">
                  <thead>
                    <tr>
                      <th className="text-left fs-7 fw-light">
                        {t("schools:educational_institution_name")}
                      </th>
                      <th className="fs-7 fw-light">{t("number_of_plans")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((school: any) => (
                      <tr key={school.id}>
                        <td className="text-left text-muted">
                          {school.logo && (
                            <Image
                              style={{ width: 32 }}
                              className="rounded"
                              autoFetch
                              id={school.logo}
                            />
                          )}
                          {arabicValue(school,"name")}
                        </td>
                        <td className="text-end text-graycool fw-bolder">
                          {school.plan_count}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </LoadingOverlay>
    </Col>
  );
};

export default TopSchoolsWidget;
