import { Route, Routes } from "react-router-dom";
import ListNotifications from "./List";

const NotificationsRoutes = () => {
  return (
    <Routes >
      <Route path="/" element={<ListNotifications />} />
    </Routes>
  );
};

export default NotificationsRoutes;
