import Select from "components/inputs/Select";
import { useTranslation } from "react-i18next";
import { missingDocumentsOptions, rejectionOptions } from "utils/constants";

type Props = {
  label?: string,
  name?: string,
}

const RejectionsChecklist = (props: Props) => {
  const { label = "Category", name = "reason" } = props;
  const { t } = useTranslation(["plans", "common"]);
  return (
    <Select
      options={rejectionOptions.map((i: string) => ({
        name: t(i),
        id: i,
      }))}
      labelKey="name"
      valueKey="id"
      name={name}
      label={label}
    />
  );
};

export default RejectionsChecklist;
