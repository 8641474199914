import { Col, ProgressBar, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
    value: number;
    max: number;
}

const ProgressWithTitles = (props: Props) => {
    const { value = 1, max = 1 } = props;
    const { t } = useTranslation(["common"]);
    const progress = (value / max) * 100;
    return (
        <Row className="text-dark">
            <Col lg={6} className="mb-2 mb-sm-3">
                <p className="fbs-4 mb-1">{t("common:total_paid")}</p>
                <p className="fs-4 fw-semibold">
                    {value.toLocaleString() || 0}
                    <sup className="mx-1">{t("kd")}</sup>
                </p>
            </Col>

            <Col lg={6} className="mb-2 mb-sm-3 text-end">
                <p className="fbs-4 mb-1">{t("plans:total_amount")}</p>
                <p className="fs-4 fw-semibold">
                    {max.toLocaleString()}{" "}
                    <sup className="">{t("kd")}</sup>
                </p>
            </Col>
            <Col lg={12}>
                <ProgressBar
                    now={progress}
                    label={`${progress.toFixed(0)}%`}
                    variant="success"
                />
            </Col>
        </Row>
    )
}

export default ProgressWithTitles