import { useMutation, useQueryClient } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { plans } from "utils/api/plans";
import FIMethod from "utils/enums/FIMethod";
import PlanStatus from "utils/enums/PlanStatus";
import { parseJSONorString } from "utils/functions/utilities";
import * as Yup from "yup";

type Props = {
  plan: any;
  ruba?: boolean;
  admin?: boolean;
};

const TransactionsSinglePayment = (props: Props) => {
  const [show, setShow] = useState(false);
  const { plan, ruba = false } = props;
  const { t } = useTranslation(["plans", "common"]);
  const client = useQueryClient();
  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["transactions", { type: "pending" }]);
        toast.success("Status updated");
        setShow(false);
      },
    }
  );

  function submit(values: any) {
    let status = PlanStatus.ACTIVE;
    if (plan.ruba_cut > 0 && !values.ruba.date)
      status = PlanStatus.PENDING_RUBA_TID;
    else if (plan.ruba_cut < 0) status = PlanStatus.PENDING_RUBA_SCHOOL_TID;

    if (plan.fi?.method == FIMethod.MOSAWAMA)
      status = PlanStatus.PENDING_RECEIPT;

    const parsed: any = {};
    if (values.school.date) {
      parsed.school_trans_id = JSON.stringify(values.school);
    }
    if (values.ruba.tid) parsed.ruba_trans_id = JSON.stringify(values.ruba);
    parsed.status = status;
    planMutation.mutate({ plan, values: parsed });
  }
  return (
    <>
      {(ruba ? plan.ruba_trans_id : plan.school_trans_id) ? (
        <Badge className="mt-2" bg="success">
          {t("common:paid")}
        </Badge>
      ) : (
        <Button
          onClick={() => setShow(true)}
          className="p-2 px-3"
          size="sm"
          variant="outline-light"
        >
          {t("common:pay")}
        </Button>
      )}
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">
            {t("common:record_single_payment")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={submit}
            initialValues={{
              status: plan.status,
              school: {
                amount: plan.principal_amount - plan.fi_cut - plan.ruba_cut,
                tid: "",
                date: "",
              },
              ruba: {
                amount: plan.ruba_cut,
                tid: "",
                date: "",
              },
            }}
            validationSchema={Yup.object().shape({
              school: Yup.object().shape({
                amount: Yup.number(),
                tid: Yup.string(),
                date: Yup.string().when("status", {
                  is: () => plan.status == PlanStatus.PENDING_PAYMENT,
                  then: Yup.string().required(t("common:required")),
                }),
              }),
              ruba: Yup.object().shape({
                amount: Yup.number(),
                tid: Yup.string(),
                date: Yup.string().when("status", {
                  is: () => plan.status == PlanStatus.PENDING_RUBA_TID,
                  then: Yup.string().required(t("common:required")),
                }),
              }),
            })}
          >
            {(formikBag) => (
              <Form>
                <Card>
                  <Card.Body className="p-3">
                    <Row className="justify-content-between">
                      <Col lg={6} className="mb-2">
                        <h6 className="m-0">
                          {t("common:educational_institution_transaction")}
                        </h6>
                      </Col>

                      {plan.school_trans_id && (
                        <Col lg={4}>
                          <Badge bg="success" className="me-2 ">
                            {t("common:paid")}
                          </Badge>
                          <Badge bg="light" className="text-dark opacity-75">
                            {t("plans:amount")}{" "}
                            <b>{plan.principal_amount.toLocaleString()}</b> -
                            TRX:{" "}
                            <b>
                              {parseJSONorString(plan.school_trans_id)?.tid ??
                                plan.school_trans_id}
                            </b>
                          </Badge>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {!plan.school_trans_id && (
                        <Col lg={12}>
                          <Row>
                            <Col lg={4}>
                              <Input
                                disabled
                                type="number"
                                name="school.amount"
                                label={t("amount")}
                              />
                            </Col>
                            <Col lg={4}>
                              <Input
                                type="date"
                                name="school.date"
                                label={t("common:date")}
                              />
                            </Col>
                            <Col lg={4}>
                              <Input
                                name="school.tid"
                                label={t("school_trans_id")}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <hr className="bg-light my-4" style={{ height: 1 }} />
                      <Row className="justify-content-between">
                        <Col lg={6} className="mb-2">
                          <h6 className="m-0">
                            {t("common:ruba_cut_transaction")}
                          </h6>
                        </Col>

                        {plan.ruba_trans_id && (
                          <Col lg={4}>
                            <Badge bg="success" className="me-2 ">
                              {t("common:paid")}
                            </Badge>
                            <Badge bg="light" className="text-dark opacity-75">
                              {t("plans:amount")}{" "}
                              <b>{plan.principal_amount.toLocaleString()}</b> -
                              TRX:{" "}
                              <b>
                                {parseJSONorString(plan.ruba_trans_id)?.tid ??
                                  plan.ruba_trans_id}
                              </b>
                            </Badge>
                          </Col>
                        )}
                      </Row>
                      {!plan.ruba_trans_id && (
                        <Col lg={12}>
                          <Row>
                            <Col lg={4}>
                              <Input
                                disabled
                                type="number"
                                name="ruba.amount"
                                label={t("amount")}
                              />
                            </Col>
                            <Col lg={4}>
                              <Input
                                type="date"
                                name="ruba.date"
                                label={t("common:date")}
                              />
                            </Col>
                            <Col lg={4}>
                              <Input
                                name="ruba.tid"
                                label={t("ruba_trans_id")}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
                <Button
                  size="lg"
                  className="w-100 mt-5"
                  variant="primary"
                  type="submit"
                >
                  {t("common:submit")}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionsSinglePayment;
