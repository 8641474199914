import { useQuery } from '@tanstack/react-query';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addons } from 'utils/api/addons';
import { AddonType } from 'utils/types/Addon';

type Props = {}

const AddonsList = (props: Props) => {
    const { t } = useTranslation(["addons", "common", "auth"]); // Update translation keys

    const { data } = useQuery(['addons'], addons.list, {
        placeholderData: []
    })

    const handleCopy = (addon: AddonType) => {
        if (addon.generated_link) {
            navigator.clipboard.writeText(addon.generated_link);
            toast.success(t('common:copied_to_clipboard'));
        }
    };

    return (
        <Row>
            <Col lg="12">
                <Row>
                    <Col lg={8} className="mb-2 mb-sm-5">
                        <h4 className="text-dark fw-semibold">{t("all_addons")}</h4>
                        <p className="text-gray">{t("manage_and_track_addons")}</p>
                    </Col>
                    <Col lg={4} className="d-flex justify-content-end">
                    </Col>
                </Row>
            </Col>
            {/* Create addon button */}
            <Col lg="12" className="d-flex justify-content-end">
                <Link to="/addons/new">
                    <Button variant="primary">{t("create_addon")}</Button>
                </Link>
            </Col>
            {/* table of addons from data */}
            <Col lg="12">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>{t("common:name")}</th>
                            <th>{t("common:description")}</th>
                            <th>{t("common:price")}</th>
                            <th>{t("common:sold")}</th>
                            <th>{t("common:quantity")}</th>
                            <th>{t("common:status")}</th>
                            <th>{t("common:actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((addon: any) => (
                            <tr key={addon.id}>
                                <td>{addon.name}</td>
                                <td>{addon.description}</td>
                                <td>{addon.price}</td>
                                <td>{addon.sold}</td>
                                <td>{addon.quantity}</td>
                                <td>{addon.is_active ? t("common:active") : t("common:inactive")}</td>
                                <td className='d-flex'>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-copy-${addon.id}`}>{t('copy_addon_link')}</Tooltip>}
                                    >
                                        <a href="#" onClick={() => handleCopy(addon)}>
                                            <i className="bi bi-clipboard text-gray fbs-2 me-1"></i>
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-edit-${addon.id}`}>{t('edit_addon')}</Tooltip>}
                                    >
                                        <Link to={`/addons/${addon.id}`}>
                                            <i className="bi bi-pencil text-gray fbs-2"></i>
                                        </Link>
                                    </OverlayTrigger>
                                    {/* show paid list */}
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-paid-${addon.id}`}>{t('common:customers')}</Tooltip>}
                                    >
                                        <Link to={`/addons/${addon.id}/customers`}>
                                            <i className="bi bi-people text-gray fbs-2 ms-1"></i>
                                        </Link>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Col>
        </Row>
    )
}

export default AddonsList