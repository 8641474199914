import FileType from "./enums/FileType";
import TransactionStatus from "./enums/TransactionStatus";

export const missingDocumentsOptions: Array<FileType | string> = [
  FileType.PERSONALID,
  FileType.SALARY_CERTIFICATE,
  FileType.AUTHORIZED_SIGNATORY,
  FileType.BANK_STATEMENT,
  FileType.QUOTATION,
  FileType.PURCHASE_ORDER,
  FileType.RECEIPT,
  FileType.PASSPORT,
  FileType.MANPOWER_ALLOWANCE
];

export const rejectionOptions: Array<FileType | string> = [
  "Credit obligations (Cinet)",
  "Salary",
  "Employer",
  "Low Financing Amount",
  "Other",
];

export const conditionalApprovalOptions: Array<FileType | string> = [
  "Guarantor",
  "Lower amount",
  "Postponed by user",
  "Other",
];

export const FILE_SIZE = 80 * 1024 * 1024;
export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/jpe",
  "image/gif",
  "image/png",
  "application/pdf",
];

export const DATE_FORMAT = "yyyy-MM-dd";

export const PerPage = 25;

export const graphColors = [
  "#32BDC5",
  "#86c6fb",
  "#444054",
  "#83588a",
  "#FD7184",
  "#d92550",
  "#fd7e14",
];

export const breakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}

export const transactionDoneStatuses = [
  TransactionStatus.SUCCESS,
  TransactionStatus.MANUALLY_SETTLED,
  TransactionStatus.PENDING_REFUND,
  TransactionStatus.REFUNDED,
  TransactionStatus.SETTLED,
]
