import SignatureComponent from "components/inputs/Signature";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "react-bootstrap";
import FileType from "utils/enums/FileType";
import Role from "utils/enums/Role";
import { isRole } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";

type Props = {
  schoolData: any;
  activeTab: any;
};

const CreateSchoolSignature = (props: Props) => {
  const { data: user } = useUser();
  const { schoolData, activeTab } = props;
  const { t } = useTranslation(["schools", "common"]);
  const signatureId =
    schoolData?.files?.find((file: any) => file.type == FileType.SIGNATURE)
      ?.id ?? "";

  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title className="fs-6 fw-bold">
          {t("common:signature")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md="12" className="mb-3">
            <div>
              {activeTab === "signatures" && (
                <SignatureComponent
                  name="signature"
                  initialSignatureId={signatureId}
                  disabled={!isRole(Role.SCHOOL, user)}
                  hideLabel={true}
                />
              )}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreateSchoolSignature;
