import { useQuery } from "@tanstack/react-query";
import Amount from "components/widgets/Amount";
import ReferralCode from "containers/Plans/Create/components/ReferralCode";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { transactions } from "utils/api/transactions";
import PlanStatus from "utils/enums/PlanStatus";
import ReserveStatus from "utils/enums/ReserveStatus";
import TransactionType from "utils/enums/TransactionType";
import * as Yup from "yup";

type Props = {
  plan: any;
  alert?: boolean;
};

const PlanPendingServiceFeeActionsForm = (props: Props) => {
  const { t } = useTranslation(["common", "plans"]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const { plan, alert = false } = props;

  const sfTrx = plan.transactions?.find((t: any) => t.type == TransactionType.SERVICE_FEE);

  const { data } = useQuery(
    ["estimate", plan.id],
    () => transactions.estimate(sfTrx?.id),
    {
      enabled: !!sfTrx,
      placeholderData: {
        amount: 0,
        discounted: false,
      },
    }
  )

  async function submit(values: any) {
    if (loading) return;
    setLoading(true);
    const res = await transactions.paymentLink([sfTrx?.id], values.referral_code);
    window.location.replace(res.link);
    setLoading(false);
  }

  if (PlanStatus.PENDING_SERVICE_FEE != plan.status && ReserveStatus.PENDING_PAYMENT != plan.reserve_status)
    return <></>;


  return (
    <>
      <Formik
        onSubmit={submit}
        initialValues={{
          status:
            plan.status == PlanStatus.MISSING_DOCUMENTS_RUBA
              ? PlanStatus.PENDING_RUBA
              : PlanStatus.PENDING_FI,
          referral_code: "",
        }}
        validationSchema={Yup.object().shape({})}
      >
        <Form>
          {!!data?.discounted ?
            <div className="text-success d-flex flex-column align-items-center">
              <i className="mx-2 bi bi-check-circle" style={{
                fontSize: "3rem",
              }}></i>
              <p className="  text-center w-100 rounded-2">
                {t("plans:discount_of_x_applied", { discount: "20" })}
              </p>
            </div>
            : <ReferralCode />}
          <Button
            variant={alert ? "info" : "primary"}
            type="submit"
            size="lg"
            className="w-100 mt-3"
            disabled={loading}
          >
            <div className="d-flex justify-content-center">
              <p className="mx-1">{t("plans:pay_service_fee")}</p> <Amount amount={data?.amount ?? 0} />
            </div>
          </Button>
        </Form>
      </Formik>
      <p className="fbs-4 text-center mt-3">( {t('nonrefundable_service_fee')} )</p>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">
            {t("plans:pay_service_fee",)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={submit}
            initialValues={{
              status:
                plan.status == PlanStatus.MISSING_DOCUMENTS_RUBA
                  ? PlanStatus.PENDING_RUBA
                  : PlanStatus.PENDING_FI,
            }}
            validationSchema={Yup.object().shape({})}
          >
            <Form>
              <>
                <Row>
                  <Col lg="12">
                    <Button
                      size="lg"
                      type="submit"
                      variant="primary"
                      className="w-100 mt-3"
                    >
                      {t("submit")}
                    </Button>
                  </Col>
                </Row>
              </>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlanPendingServiceFeeActionsForm;
