enum PlanStatus {
    INCOMPLETE = "Incomplete",
    PENDING_DOCUMENTS = "Pending documents",
    ARCHIVED = "Archived",
    USER_WITHDRAWN = "User withdrawn",
    PENDING_RUBA = "Ruba: Pending",
    MISSING_DOCUMENTS_RUBA = "Ruba: Missing Documents",
    REJECTED_RUBA = "Ruba: Rejected",
    PENDING_REGISTRATION = "School: Pending Registration",
    PENDING_QUOTATION = "School: Pending Quotation",
    PENDING_SCHOOL_APPROVAL = "School: Pending Approval",
    PENDING_USER_APPROVAL = "User: Pending Approval",
    REJECTED_SCHOOL = "School: Rejected",
    PENDING_FI = "FI: Pending",
    MISSING_DOCUMENTS_FI = "FI: Missing Documents",
    REJECTED_FI = "FI: Rejected",
    RUBA_CONDITIONAL_APPROVAL = "Ruba: Conditional Approval",
    PENDING_CINET = "User: Pending Cinet Signature",
    PENDING_SERVICE_FEE = "User: Pending Service Fee",
    PENDING_SIGNATURE = "FI: Pending Signature",
    SEAT_WITHDRAWN = "School: Seat Withdrawn",
    PENDING_RECEIPT = "School: Pending Receipt",
    PENDING_PAYMENT = "FI: Pending Payment",
    PENDING_PAYMENT_SCHOOL = "School: Pending Payment",
    PENDING_INVOICE = "School: Pending Invoice",
    PENDING_RUBA_TID = "FI: Pending Ruba TID",
    PENDING_RUBA_SCHOOL_TID = "Ruba: Pending School TID",
    ACTIVE = "Active",
    COMPLETE = "Complete",
}

export const acceptedPlanStatuses = [
    PlanStatus.PENDING_RECEIPT,
    PlanStatus.PENDING_PAYMENT,
    PlanStatus.PENDING_PAYMENT_SCHOOL,
    PlanStatus.PENDING_INVOICE,
    PlanStatus.PENDING_RUBA_TID,
    PlanStatus.PENDING_RUBA_SCHOOL_TID,
    PlanStatus.ACTIVE,
    PlanStatus.COMPLETE,
]

export const uploadDocumentStatuses = [
    PlanStatus.PENDING_DOCUMENTS,
    PlanStatus.MISSING_DOCUMENTS_RUBA,
    PlanStatus.MISSING_DOCUMENTS_FI,
]

export default PlanStatus