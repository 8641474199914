import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom";
import { RouterProvider } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { router } from "./App";
import "./components/assets/base.scss";
// import * as serviceWorker from "./serviceWorker";


//@ts-ignore
//@ts-ignore
import pdfjsWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";
import { pdfjs } from "react-pdf";
//@ts-ignore
//@ts-ignore
import TagManager, { TagManagerArgs } from "react-gtm-module";


pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const rootElement = document.getElementById("root");
const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID!,
  dataLayer: {
    userId: "001",
    userProject: "project",
  },
};

TagManager.initialize(tagManagerArgs);
export const onPageView = (pathname: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "pageview",
      page: pathname,
    },
  });
};

export const queryClient = new QueryClient();


const renderApp = () => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>,
    rootElement
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// serviceWorker.unregister();
