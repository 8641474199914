import React from "react";
import { useQuery } from "@tanstack/react-query";
import files from "utils/api/files";

type LogoPreviewerProps = {
  fileId?: string;
  className?: string;
};

const LogoPreviewer = (props: LogoPreviewerProps) => {
  const { fileId,className="" } = props;
  const { data, isSuccess } = useQuery(["file", fileId], () => files.view(fileId), {
    enabled: Boolean(fileId),
  });
  return (
    <div>
      {data && <img className={className} style={
      {height:100}
      } src={data} />}
    </div>
  );
};

export default LogoPreviewer;
