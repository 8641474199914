//@ts-ignore
import flag from "components/assets/utils/images/cookies/flag.svg";
import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Cookies from 'js-cookie';

const CookieConsentPopup = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (Cookies.get("ruba")) return;
    setVisible(true);
  }, []);

  function handleAccept() {
    Cookies.set("ruba", "accepted");
    setVisible(false);
  }

  function handleReject() {
    Cookies.set("ruba", "rejected");
    setVisible(false);
  }


  return (
    <Modal isOpen={visible} centered={true}>
      <ModalHeader closeButton onHide={() => setVisible(false)}></ModalHeader>
      <ModalBody>
        <img src={flag.toString()} className="w-100" />
        <h4>Your Privacy matters to Us</h4>
        <p>Enhancing Your Experience, Respecting Your Privacy</p>
        <div className="d-flex justify-content-around">
          <Button
            onClick={handleReject}
            color="light"
            className="w-100 mx-2 rounded-3"
          >
            Reject
          </Button>
          <Button color="primary" onClick={handleAccept} className="w-100 mx-2 rounded-3">
            Accept
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CookieConsentPopup;
