import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import FIMethod from "utils/enums/FIMethod";
import PlanStatus from "utils/enums/PlanStatus";
import * as Yup from "yup";

const PlanPendingTIDFIActionsForm = (props: any) => {
  const { plan, planMutation } = props;
  const { t } = useTranslation(["plans", "common"]);

  function submit(values: any) {
    planMutation.mutate({ plan, values });
  }

  let status = PlanStatus.ACTIVE;
  if (plan.ruba_cut > 0) status = PlanStatus.PENDING_RUBA_TID;
  else if (plan.ruba_cut < 0) status = PlanStatus.PENDING_RUBA_SCHOOL_TID;

  if (plan.fi?.method == FIMethod.MOSAWAMA) status = PlanStatus.PENDING_RECEIPT;

  return (
    <>
      {PlanStatus.PENDING_PAYMENT == plan.status && (
        <Formik
          enableReinitialize
          onSubmit={submit}
          initialValues={{
            status,
          }}
          validationSchema={Yup.object().shape({
            school_trans_id: Yup.string().trim().required(),
            status: Yup.string().required(),
          })}
        >
          <Form>
            <Row>
              <Col lg="12">
                <Input name="school_trans_id" label={t("school_trans_id")} />
              </Col>
            </Row>
            <Row>
              <Col className="mt-3" md="12">
                <Button type="submit" className="w-100" size="lg">
                  {t("common:submit")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default PlanPendingTIDFIActionsForm;
