import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import reserves from "utils/api/reserves";
import { useUser } from "utils/hooks/auth";

type Props = {};

const ReserveBasicInfo = (props: Props) => {
  const { id } = useParams();
  const { data: user } = useUser();
  const { t } = useTranslation(["common"]);
  const { data: reserve, isLoading } = useQuery(["reserve", id], () =>
    reserves.view(id)
  );

  const attributes = [
    {
      label: t("common:owner"),
      value: reserve?.entity?.name,
      condition: true,
    },
    {
      label: t("common:type"),
      value: reserve?.type,
      condition: true,
    },
    {
      label: t("common:balance"),
      value: reserve?.balance,
      condition: true,
    },
    {
      label: "RRN",
      value: reserve?.reserve_number,
      condition: true,
    },
  ];

  if (isLoading) return <></>;

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("common:basic_info")}</Accordion.Header>
        <Accordion.Body>
          <Container>
            <Row>
              {attributes.map((element: any) => (
                <ReserveInfoColumn key={element.label} {...element} />
              ))}
            </Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

function ReserveInfoColumn(props: {
  label: string;
  value: any;
  condition?: boolean;
}) {
  const { label, value, condition = true } = props;
  if (!condition) return <></>;
  return (
    <Col md="6" className="mb-4">
      <div className="d-flex flex-column">
        <div className="text-gray opacity-75">{label}</div>
        <div className="fs-6 text-dark">{value}</div>
      </div>
    </Col>
  );
}

export default ReserveBasicInfo;
