import { useQuery } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import Radio from "components/inputs/Radio";
import Select from "components/inputs/Select";
import MissingDocumentsChecklist from "containers/common/MissingDocumentCheckboxes";
import RejectionsChecklist from "containers/common/RejectionsCheckboxes";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { fis } from "utils/api/fis";
import { rejectionOptions } from "utils/constants";
import PlanStatus from "utils/enums/PlanStatus";
import * as Yup from "yup";

const PlanModeratorActionsForm = (props: any) => {
  const { plan, planMutation } = props;
  const [show, setShow] = useState(false);

  function submit(values: any) {
    const extend: any = {};
    if (values.status == PlanStatus.MISSING_DOCUMENTS_RUBA) {
      extend.reason = values.missing_document.join(",") + " " + values.reason;
    }
    if (values.status == PlanStatus.REJECTED_RUBA) {
      extend.reason = values.reason_category + ": " + values.reason;
    }
    planMutation.mutate({ plan, values: { ...values, ...extend } });
    setShow(false);
  }

  const { data } = useQuery(["fis"], fis.list);
  let status = !!plan.school.direct_financing
    ? PlanStatus.PENDING_FI
    : PlanStatus.PENDING_QUOTATION;

  if (plan.paynow) {
    status = PlanStatus.PENDING_SCHOOL_APPROVAL;
  }

  return (
    <>
      <Button onClick={() => setShow(true)} size="lg" className="w-100">
        Action Required!
      </Button>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">Take Action!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-7 apply p-0 p-lg-4">
          <Formik
            onSubmit={submit}
            initialValues={{ status: "", reason: "", reason_category: rejectionOptions[0] }}
            validationSchema={Yup.object().shape({
              status: Yup.string(),
              reason_category: Yup.string(),
              reason: Yup.string(),
            })}
          >
            {({ values, ...props }) => (
              <Form>
                <Row>
                  <Col lg="4" className="mb-2">
                    <Radio name="status" label="Approve" value={status} />
                  </Col>
                  <Col lg="4" className="mb-2">
                    <Radio
                      name="status"
                      label="Incomplete"
                      value={PlanStatus.MISSING_DOCUMENTS_RUBA}
                    />
                  </Col>
                  <Col lg="4" className="mb-2">
                    <Radio
                      name="status"
                      label="Reject"
                      value={PlanStatus.REJECTED_RUBA}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="mb-4">
                    {values.status == PlanStatus.REJECTED_RUBA && (
                      <>
                        <div className="mb-3">
                          <RejectionsChecklist name="reason_category" />
                        </div>
                        <Input name="reason" label="Reason" type="textarea" />
                      </>
                    )}
                    {values.status == PlanStatus.MISSING_DOCUMENTS_RUBA && (
                      <>
                        <MissingDocumentsChecklist />
                        <Input name="reason" label="Reason" type="textarea" />
                      </>
                    )}
                    {[
                      PlanStatus.PENDING_QUOTATION,
                      PlanStatus.PENDING_FI,
                    ].includes(values.status as PlanStatus) && (
                        <Select
                          labelKey="name"
                          valueKey="id"
                          options={data}
                          name="fi_id"
                          label="Financial Institution"
                        />
                      )}
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end" md="12">
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100"
                      size="lg"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlanModeratorActionsForm;
