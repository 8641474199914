import { useQuery } from "@tanstack/react-query";
import { capitalize } from "lodash";
import React, { useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useParams } from "react-router-dom";
import reserves from "utils/api/reserves";
import { useUser } from "utils/hooks/auth";
import VendorExtraModal from "./Extra";

type Props = {};

const ReserveVendorInfo = (props: Props) => {
  const { id } = useParams();
  const { data: user } = useUser();
  const [showExtraModal, setShowExtraModal] = useState(false)
  const { t } = useTranslation(["common"]);
  const { data: reserve, isLoading } = useQuery(["reserve", id], () =>
    reserves.view(id)
  );

  const attributes = [
    {
      label: t("common:vendor"),
      value: capitalize(reserve?.vendor),
      condition: true,
    },
    {
      label: t("common:bank_account"),
      value: reserve?.vendorInfo?.bank_account_number,
      condition: true,
    },
  ];

  if (isLoading) return <></>;

  return (
    <Accordion defaultActiveKey="0" className="">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("common:vendor_info")}</Accordion.Header>
        <Accordion.Body>
          <Container>
            <Row className="g-4">
              {attributes.map((element: any) => (
                <ReserveInfoColumn key={element.label} {...element} />
              ))}
              <Col md="6">
                <div className="d-flex flex-column">
                  <div className="text-gray opacity-75">
                    Reference
                  </div>
                  <div className="fs-6 text-dark">
                    <div className="d-flex gap-2">
                      {reserve?.vendor_reference}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="d-flex flex-column">
                  <div className="text-gray opacity-75">
                    Action
                  </div>
                  <div className="fs-6 text-dark">
                    <div className="d-flex gap-2">
                      <a onClick={() => setShowExtraModal(true)} className="btn btn-link p-0 text-decoration-none"> Update vendor reference <i className="bi bi-box-arrow-up-right"></i></a>
                      <VendorExtraModal vendor={reserve?.vendor} show={showExtraModal} setShow={setShowExtraModal} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

function ReserveInfoColumn(props: {
  label: string;
  value: any;
  condition?: boolean;
}) {
  const { label, value, condition = true } = props;
  if (!condition) return <></>;
  return (
    <Col md="6">
      <div className="d-flex flex-column">
        <div className="text-gray opacity-75">{label}</div>
        <div className="fs-6 text-dark">{value}</div>
      </div>
    </Col>
  );
}

export default ReserveVendorInfo;
