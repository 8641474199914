import axios from "axios";
import { storage } from "utils/storage";
import i18next from "utils/i18n/config";

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND + "/api/",
  headers: {
    Authorization: storage.getToken(),
  },
});

client.interceptors.request.use(
  async (config) => {
    // client.defaults.headers.common["Authorization"] = storage.getToken();
    config.headers!.Authorization = storage.getToken();
    config.headers!["Accept-Language"] = i18next.language;
    return config;
  },
  (error) => {
    return error;
  }
);

client.interceptors.response.use(
  (response) => {
    return response.data as any;
  },
  (error) => {
    // if(error?.response?.status === 401){
    //   storage.clearToken();
    //   // window.location.href = "/login";
    // }
    return Promise.reject(error?.response?.data);
  }
);

export default client;
