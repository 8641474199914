import { useQuery } from "@tanstack/react-query";
import MediumLineCard from "components/widgets/Dashboard/MediumLineCard";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "react-loaders";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { useLocation } from "react-router-dom";
import dashboard from "utils/api/dashboard";

type Props = {};

const AdminStudentStatsWidget = (props: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslation(["common"]);
  const school_id = queryParams.get("school_id");
  const { data, isFetching } = useQuery(
    ["dashboard", {
      endpoint: "adminStudentsStats",
    }],
    () => {
      return dashboard.adminStudentsStats(school_id)
    },


  );

  return (
    <>
      <>
        <Col lg={6} className="mb-3">
          <LoadingOverlayWrapper
            active={isFetching}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "#fff",
                opacity: 0.5,
              }),
            }}
            spinner={<Loader active type="ball-pulse" />}
          >
            <Card>
              <Card.Body className="p-4">
                <Row>
                  <Col
                    lg={6}
                    className="fs-6 d-flex justify-content-between text-dark fw-bold mb-3 mb-sm-5"
                  >
                    <p>{t("plans:students")}</p>
                  </Col>
                  {/* <Col lg={6} className="text-end">
                  Filter 🔜
                </Col> */}
                </Row>
                <Row>
                  <Col lg={4} className="">
                    <p className="text-graycool fs-7 mb-2">{t("paylater")}</p>
                    <h3 className="text-dark fw-bold">{data?.paylater}</h3>
                  </Col>
                  <Col lg={4} className="">
                    <p className="text-graycool fs-7 mb-2">
                      {t("reserve")}
                    </p>
                    <h3 className="text-dark fw-bold">{data?.reserve}</h3>
                  </Col>
                  <Col lg={4} className="">
                    <p className="text-graycool fs-7 mb-2">{t("total")}</p>
                    <h3 className="text-success fw-bold">
                      {data?.total}
                    </h3>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </LoadingOverlayWrapper>
        </Col>

      </>
    </>
  );
};

export default AdminStudentStatsWidget;
