import { useQuery } from "@tanstack/react-query";
import Checkbox from "components/inputs/Checkbox";
import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { useFormikContext } from "formik";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { countries } from "utils/api/countries";
import { fis } from "utils/api/fis";
import users from "utils/api/users";
import EntityStatus from "utils/enums/EntityStatus";
import FileType from "utils/enums/FileType";
import Role from "utils/enums/Role";
import SchoolType from "utils/enums/SchoolType";
import { arabicValue } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import CreateSchoolUsers from "../Users";
import CreateReserves from "../Reserves";
import LogoPreviewer from "containers/common/Previewers/Logo";

type Props = {};

const CreateSchoolBasic = (props: Props) => {
  const { t } = useTranslation(["schools", "common", "plans"]);
  const { data: user } = useUser();
  const { values, setFieldValue } = useFormikContext<any>();

  const { data: countriesData } = useQuery(["countries"], countries.list, {
    initialData: [],
    select: (data) =>
      data.map((country: any) => ({
        value: country.id,
        label: arabicValue(country, "name"),
      })),
  });

  const { data: fiData } = useQuery(["fis"], fis.list, {
    enabled: user?.role_id == Role.ADMIN,
    select: (data: any) =>
      data.map((item: any) => ({
        label: item.name,
        value: item.id,
      })),
    placeholderData: [],
  });

  const { data: userData } = useQuery(
    ["users", Role.SCHOOL],
    () => users.list({ roleId: Role.SCHOOL, page: 1, perPage: 1000 }),
    { enabled: user?.role_id == Role.ADMIN }
  );

  const logo = values.files?.find(
    (file: any) => file.type == FileType.LOGO
  )?.id;

  const types = Object.keys(SchoolType).filter((type) => isNaN(Number(type)));

  return (
    <>
      <Card className="mb-4">
        <Card.Header>
          <Card.Title className="fs-6 fw-bold">
            {t("institution_basics")}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row className="gy-3">
              <RoleBasedRenderer
                admin={
                  <>
                    <Col md="4">
                      <FormGroup>
                        <Form.Label>{t("plans:status")}</Form.Label>
                        <div>
                          <ButtonGroup size="lg">
                            <Button
                              className="p-3"
                              variant="outline-primary"
                              onClick={() =>
                                setFieldValue("status", EntityStatus.ACTIVE)
                              }
                              active={values.status == EntityStatus.ACTIVE}
                            >
                              Active
                            </Button>
                            <Button
                              className="p-3"
                              variant="outline-primary"
                              onClick={() =>
                                setFieldValue("status", EntityStatus.INACTIVE)
                              }
                              active={values.status == EntityStatus.INACTIVE}
                            >
                              Inactive
                            </Button>
                          </ButtonGroup>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <Input
                        label={t("schools:discount")}
                        name="discount"
                        type="text"
                        min="0"
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        label={t("schools:reserve_discount")}
                        name="reserve_discount"
                        type="text"
                        min="0"
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        label={t("schools:parent_fee")}
                        name="parent_fee"
                        type="text"
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        label="Custom Institution ID (optional)"
                        name="custom_id"
                        type="text"
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        label="Tags (comma separated)"
                        name="tags"
                        type="text"
                      />
                    </Col>

                    <Col md="12">
                      <Card>
                        <Card.Header>
                          <Card.Title className="fs-6 fw-bold">
                            Flags
                          </Card.Title>
                        </Card.Header>
                        <Card.Body className="d-flex p-3">
                          <Checkbox
                            name="direct_financing"
                            label={t("schools:direct_parent_financing")}
                          />
                          <Checkbox
                            name="paynow"
                            label={t("schools:paynow")}
                          />
                          <Checkbox
                            name="reserve_enabled"
                            label={t("common:reserve")}
                          />
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md="12">
                      <CreateReserves />
                    </Col>

                    <Col md="12">
                      <h6 className="my-5 text-black">
                        {t("schools:constrained_fi")}
                      </h6>
                      <hr style={{ height: "1px" }} className="bg-light mb-5" />
                      <Select
                        label={t("select_fi")}
                        multiple
                        options={fiData}
                        name="constrainedFis"
                      />
                    </Col>
                    <Col md="12">
                      <h6 className="my-5 text-black">
                        {t("schools:assign_user_to_school")}
                      </h6>
                      <hr style={{ height: "1px" }} className="bg-light mb-5" />
                      <CreateSchoolUsers userData={userData?.data} />
                    </Col>
                  </>
                }
              />
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header>
          <Card.Title className="fs-6 fw-bold">
            {t("institution_details")}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              <Col md="3" className="mb-4">
                {logo && (
                  <FormGroup>
                    <LogoPreviewer fileId={logo} />
                  </FormGroup>
                )}
              </Col>
              <Col md="9">
                <Row>
                  <Col md="12">
                    <Select
                      disabled={user?.role_id == Role.SCHOOL}
                      options={types.map((type: any) => ({
                        label: t(`plans:a_${type.toLowerCase()}`),
                        value: SchoolType[type],
                      }))}
                      name="type"
                      label={t("schools:type")}
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      label={t("common:name")}
                      name="name"
                      disabled={user?.role_id == Role.SCHOOL}
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      label={t("common:arabic_name")}
                      name="name_ar"
                      disabled={user?.role_id == Role.SCHOOL}
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      label="Company (optional)"
                      name="company"
                      type="text"
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      type="number"
                      label={t("plans:number_of_students")}
                      name="number_of_students"
                    />
                  </Col>
                  <Col md="12">
                    <Input label="Address" name="address" />
                  </Col>

                  <RoleBasedRenderer
                    admin={
                      <Col md="12">
                        <Select
                          disabled={user?.role_id == Role.SCHOOL}
                          options={countriesData}
                          name="country_id"
                          label={t("country")}
                        />
                      </Col>
                    }
                  />
                </Row>
              </Col>

              <hr style={{ height: "1px" }} className="bg-light mb-5" />

              <Col md="12">
                <h5>{t("institution_bank_details")}</h5>
              </Col>

              <Col md="4">
                <Input label="Bank" name="bank_name" />
              </Col>
              <Col md="4">
                <Input label="Account Number" name="account_number" />
              </Col>
              <Col md="4">
                <Input label="IBAN" name="iban" />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title className="fs-6 fw-bold">{t("school_years")}</Card.Title>
        </Card.Header>
        <Card.Body>
          {/* <CreateSchoolYears /> */}
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateSchoolBasic;
