import { faBookBookmark, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/inputs/Input";
import Radio from "components/inputs/Radio";
import { Form, Formik, useFormikContext } from "formik";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import users from "utils/api/users";
import Gender from "utils/enums/Gender";
import Role from "utils/enums/Role";
import useAlert from "utils/hooks/alert";
import * as Yup from "yup";

const CreateUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: userData } = useQuery(["users", id], users.view(id), {
    enabled: Boolean(id),
  });

  async function submit(values: any) {
    try {
      let res;
      if (id) {
        res = await users.update(id, values);
      } else {
        res = await users.store(values);
      }

      navigate("/users");
    } catch (e) {}
  }
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/users">
            <FontAwesomeIcon icon={faUser} /> Users
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>New School</BreadcrumbItem>
      </Breadcrumb>
      <TransitionGroup>
        <CSSTransition
          component="div"
          classNames="TabsAnimation"
          appear={true}
          timeout={1500}
          enter={false}
          exit={false}
        >
          <Card body>
            <Formik
              enableReinitialize
              initialValues={{
                mobile: "",
                name: "",
                email: "",
                gender: 1,
                role_id: Role.USER,
                ...userData,
              }}
              validationSchema={Yup.object().shape({
                mobile:Yup.string().required(),
                name:Yup.string().required(),
                email:Yup.string().required(),
                gender:Yup.string().required(),
                role_id:Yup.string().required(),
              })}
              onSubmit={submit}
            >
              <Form>
                <CreateUserForm userData={userData} id={id} />
              </Form>
            </Formik>
          </Card>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

function CreateUserForm(props: any) {
  const { userData, id } = props;
  const { values, setFieldValue } = useFormikContext<any>();
  const { success } = useAlert();
  const navigate = useNavigate();

  function confirmDeletion() {
    success({
      onConfirm: deleteUser,
      message: `User "${userData.name}" will be removed`,
    });
  }

  async function deleteUser() {
    await users.destroy(id);
    navigate("/users");
  }

  return (
    <div>
      <Row>
        <Col md="6">
          <Input label="Name" name="name" />
        </Col>
        <Col md="6">
          <Input label="Mobile" name="mobile" />
        </Col>
        <Col md="6">
          <Input label="Email" name="email" type="email" />
        </Col>

        <Col md="6">
          <FormGroup>
            <Label for="gender">Gender</Label>
            <FormGroup>
              <Radio name="gender" label="Male" value={Gender.MALE} />
              <Radio name="gender" label="Female" value={Gender.FEMALE} />
            </FormGroup>
          </FormGroup>
        </Col>

        <Col md="12">
          <FormGroup>
            <Label for="role">Role</Label>
            <FormGroup>
              <Radio name="role_id" label="Admin" value={Role.ADMIN} />
              <Radio
                name="role_id"
                label="Moderator (Not Active)"
                value={Role.MODERATOR}
              />
              <Radio name="role_id" label="School" value={Role.SCHOOL} />
              <Radio name="role_id" label="FI" value={Role.FI} />
              <Radio name="role_id" label="User" value={Role.USER} />
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col
          md="12"
          className={`d-flex justify-content-${id ? "between" : "end"}`}
        >
          {id && (
            <Button
              onClick={confirmDeletion}
              className="mb-2 me-2 btn-icon"
              color="danger"
            >
              <i className="pe-7s-trash btn-icon-wrapper"> </i>
              Delete Users
            </Button>
          )}
          <Button type="submit" color="primary">
            Submit
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CreateUser;
