import { StepIndicator } from "components/widgets/Wizard/Stepper";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import cx from "classnames";
import useWindowDimensions from "utils/hooks/window";
import { breakPoints } from "utils/constants";
import Icon from "components/widgets/Icon";
import { useTranslation } from "react-i18next";

type Props = {
  currentStep: number;
};

const PlanWizard = (props: Props) => {
  const { currentStep } = props;
  const { t } = useTranslation(["plans", "common"]);
  const { width } = useWindowDimensions();
  const steps = [
    {
      title: t('select_students'),
      mobileTitle: t('students'),
    },
    {
      title: t('customize_payment_plan'),
      mobileTitle: t('payments'),
    },
    {
      title: t('upload_documents'),
      mobileTitle: t("documents"),
    },
    {
      title: t('review_and_finalize'),
      mobileTitle: t('review'),
    },
  ];
  return (
    <div
      className={cx({
        "forms-wizard-vertical": width > breakPoints.sm,
      })}
    >
      <ol className="forms-wizard">
        {steps.map((step: any, index: number) => {
          const status =
            index == currentStep
              ? "doing"
              : index > currentStep
              ? "todo"
              : "done";
          return (
            <li className={"form-wizard-step-" + status} key={index}>
              <em className="fbs-4">
                {index >= currentStep ? index + 1 : <Icon name="mark" />}
              </em>
              <span className="d-none d-sm-inline fbs-2">{step.title}</span>
              <span className="d-inline d-sm-none fbs-4">
                {step.mobileTitle}
              </span>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default PlanWizard;
