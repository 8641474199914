import PlanPendingApproval from "./SchoolActions/PlanPendingApproval";
import PlanPendingInvoice from "./SchoolActions/PlanPendingInvoice";
import PlanPendingQuotation from "./SchoolActions/PlanPendingQuotation";
import PlanPendingReceipt from "./SchoolActions/PlanPendingReceipt";
import PlanPendingReserveApproval from "./SchoolActions/PlanPendingReserveApproval";
import PlanSeatWithdrawer from "./SchoolActions/PlanSeatWithdrawer";
import PlanPendingPayment from "./SchoolActions/PlanPendingPayment";

const PlanSchoolActions = (props: { plan: any }) => {
  const { plan } = props;

  return (
    <>
      <PlanPendingReserveApproval plan={plan} />
      <PlanPendingInvoice plan={plan} />
      <PlanPendingPayment plan={plan} />
      <PlanPendingReceipt plan={plan} />
      <PlanPendingQuotation plan={plan} />
      <PlanSeatWithdrawer plan={plan} />
      <PlanPendingApproval plan={plan} />
    </>
  );
};

export default PlanSchoolActions;
