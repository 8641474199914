import React from "react";
import { Route, Routes } from "react-router-dom";
// import CreateReserveTransactions from "./Create";
import ReservesList from "./List";
// import CreatePlan from "./Create";
// import NotListedSchools from "./NotListedSchools";
import ViewReserve from "./View";
import ReserveClientsList from "./Clients";

const ReserveRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ReservesList />} />
      <Route path="/:id/clients" element={<ReserveClientsList />} />
      {/* <Route path="/:id/scheduler" element={<CreateReserveTransactions />} /> */}
      {/* <Route path="/not-listed" element={<NotListedSchools />} /> */}
      {/* <Route path="/:id/edit" element={<CreatePlan />} /> */}
      <Route path="/:id" element={<ViewReserve />} />
    </Routes>
  );
};

export default ReserveRoutes;
