import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import files from "utils/api/files";
import { imageUrl } from "utils/functions/utilities";
import Image from "./Image";
import PDFPreviewer from "./Pdf";
import { Button, Modal } from "react-bootstrap";
import Icon from "components/widgets/Icon";

type PreviewButtonProps = {
  id?: string;
  label?: string;
  url?: string;
  type?: string;
  className?: string;
  color?: string;
  deleteFile?: () => void;
};
export const PreviewButton = (props: PreviewButtonProps) => {
  const {
    id,
    label,
    url,
    type,
    className,
    color = "white",
    deleteFile,
  } = props;
  const [open, setOpen] = useState(false);
  const { data, refetch } = useQuery(["file", id], () => files.view(id), {
    enabled: false,
  });

  let generatedUrl = url || data;
  if (data) {
    generatedUrl = imageUrl(data);
  }

  var fileExt = type || generatedUrl?.split(".").pop();

  return (
    <>
      <Button
        onClick={() => {
          if (!url) refetch();
          setOpen(true);
        }}
        className={
          className ||
          "py-3 fs-7 text-dark w-100 border border-light rounded-2 d-flex justify-content-between align-items-center"
        }
        variant={color}
      >
        {label}

        <div>
          {deleteFile && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                deleteFile();
              }}
            >
              <Icon name="trash" />
            </span>
          )}
          <Icon name="download" size={24} />
        </div>
      </Button>
      <Modal show={open} onHide={() => setOpen(false)}>
        {url || data ? (
          <>
            {!url && (
              <a
                target="_blank"
                rel="noreferrer"
                className="btn btn-white"
                href={generatedUrl}
              >
                Open
              </a>
            )}
            {["pdf", "application/pdf"].includes(fileExt) ? (
              <PDFPreviewer
                closeModal={() => setOpen(false)}
                file={generatedUrl}
              />
            ) : (
              <Image url={generatedUrl} />
            )}
          </>
        ) : (
          <div>Loading Image</div>
        )}
      </Modal>
    </>
  );
};

export default PreviewButton;
