import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Button } from "react-bootstrap";
import plans from "utils/api/plans";
//@ts-ignore
import { creatCsvFile, downloadFile } from "download-csv";
import { DateTime } from "luxon";

const PlansDownloader = () => {
  const { t } = useTranslation(["plans", "common", "auth"]);
  const { refetch } = useQuery(["plans.download"], plans.download, {
    enabled: false,
  });
  async function download() {
    const { data } = await refetch();
    const now = DateTime.now().toFormat("HH-mm-dd-MM-yyyy");
    let csvfile = creatCsvFile(data);
    downloadFile(csvfile, `plans-${now}`);
  }
  return (
    <Button onClick={download}  variant="outline-light">
      <i className="bi bi-download me-2"></i> {t("common:download")} CSV
    </Button>
  );
};

export default PlansDownloader;
