import client from "../api";

export function list() {
  return client.get("interests") as any;
}

export function view(id?:any){
  return () => client.get(`interests/${id}`) as any;
}

export function store(values:any){
  return client.post("interests",values) as any;
}

export function update(id:string,values:any){
  return client.put(`interests/${id}`,values) as any;
}

export function destroy(id:string){
  return client.delete(`interests/${id}`) as any;
}

export const interests = { list,view,store,update,destroy };
