import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from 'components/assets/utils/images/logo.png';
import error from "components/assets/utils/images/payments/status/error.svg";
import success from "components/assets/utils/images/payments/status/success.svg";

const AddonPaymentResultPage = () => {
    const { t } = useTranslation(['addons', 'common']);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const status = searchParams.get("status");
    console.log(status);

    // Mock payment result; replace with real data as needed
    const paymentResult = {
        success: {
            image: success,
            title: "Thank you for your payment.",
            message: "Your payment has been successfully processed. You will receive a WhatsApp message shortly with the details of your transaction.",
        },
        failure: {
            image: error,
            title: "Payment failed.",
            message: "Sorry, your payment could not be processed. Please try again later.",
        }
    };

    function close() {
        window.close();
    }

    return (
        <Container className="my-5">
            <Row className="justify-content-center my-3">
                <Col md={8}>
                    <Card>
                        <Card.Header className='text-center'>
                            <img height="100px" src={paymentResult[status as keyof typeof paymentResult].image} />
                        </Card.Header>
                        <Card.Body className='p-3 text-center'>
                            <Card.Title className='mb-4'>
                                {/* <img className='rounded-circle' src={logo} alt="Logo" width={75} /> */}
                            </Card.Title>
                            <Card.Text className='my-3 fw-bold'>
                                {paymentResult[status as keyof typeof paymentResult].title}
                            </Card.Text>
                            <Card.Text className='my-3 fbs-3'>
                                {paymentResult[status as keyof typeof paymentResult].message}
                            </Card.Text>
                            <Button variant="primary" onClick={close}>
                                {t('common:close')}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-5 text-center">
                <Col>
                    <p className='fbs-4'>Powered by RUBA&copy;</p>
                </Col>
            </Row>
        </Container>
    );
};

export default AddonPaymentResultPage;
