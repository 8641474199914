import Input from "components/inputs/Input";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {};

const CreatePaymentDates = (props: Props) => {
  const { t } = useTranslation(["schools", "common"]);
  const { values, setFieldValue } = useFormikContext<any>();

  function newDate() {
    let dates = values.payment_dates || [];
    setFieldValue("payment_dates", [...dates, { percentage: 0, date: DateTime.now() }]);
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title className="fs-6 fw-bold">
          {t("payment_dates")}
          {values.payment_dates?.reduce((total: number, paymentDate: any) => total + paymentDate.percentage, 0) > 100 && (
            <div className={`badge bg-danger`}>
              {t('common:exceeded_percentage')}
            </div>
          )}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {values.payment_dates?.map((registerDate: any, i: number) => (
          <Row key={registerDate}>
            <Col md="5" className="mb-3">
              <Input label="Date" type="date" name={`payment_dates[${i}].date`} />
            </Col>
            <Col md="5" className="mb-3">
              <Input label="Percentage" type="number" name={`payment_dates[${i}].percentage`} />
            </Col>
            <Col md="2" className="d-flex align-items-center">
              <Button
                className="w-100"
                variant="light"
                size="lg"
                onClick={() =>
                  setFieldValue(
                    "payment_dates",
                    values.payment_dates.filter(
                      (item: any) => item != registerDate
                    )
                  )
                }
              >
                {t("common:delete")}
              </Button>
            </Col>
          </Row>
        ))}

        <Row>
          <Col md="12" className="d-flex justify-content-end mt-4">
            <Button
              className="w-100 w-sm-auto mb-3"
              variant="light"
              size="lg"
              onClick={newDate}
            >
              <i className="bi bi-plus-lg mx-3"></i>
              {t("add_payment_date")}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card >
  );
};

export default CreatePaymentDates;
