import client from "../api";

export function list(): Promise<any> {
  return client.get(`chatbot/contacts`);
}

export function show(id?: any) {
  return () => client.get(`chatbot/contacts/${id}`) as any;
}

export function store(values: any) {
  return client.post("chatbot/contacts", values) as any;
}

export function update(id: string, values: any) {
  return client.put(`chatbot/contacts/${id}`, values) as any;
}

export function destroy(id: string) {
  return client.delete(`chatbot/contacts/${id}`) as any;
}

export const chatbotContacts = { list, show, store, update, destroy };
