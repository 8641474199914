enum ReserveStatus{
    PENDING_SCHOOL_APPROVAL="School: Pending Approval",
    PENDING_APPROVAL="User: Pending Approval",
    UPDATE_REQUIRED="Ruba: Update Required",
    REJECTED="User: Rejected",
    SCHOOL_REJECTED="School: Rejected",
    PENDING_PAYMENT="User: Pending Payment",
    ACTIVE="Active",
    COMPLETE="Complete",
}

export default ReserveStatus
