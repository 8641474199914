import { flexRender } from "@tanstack/react-table";
import { Col, Container, Row, Table } from "react-bootstrap";
import TablePages from "../TablePages";

type Props = {
  paginate: any;
  table: any;
};

const PaginatedTable = (props: Props) => {
  const { paginate, table } = props;

  return (
    <Container>
      <Row>
        <Col lg={12} className="mb-4">
          <Table className="mb-0">
            <thead>
              {table.getHeaderGroups().map((headerGroup: any) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row: any) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell: any) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col lg={12}>
          <TablePages {...paginate} />
        </Col>
      </Row>
    </Container>
  );
};

export default PaginatedTable;
