import * as Yup from "yup";
import i18next from "utils/i18n/config";

export const loginValidation = Yup.object().shape({
  uid: Yup.string().required(i18next.t("mobile_email_required")),
  password: Yup.string().required(i18next.t("password_required")),
});

export const loginInitialValues = {
  uid: "",
  password: "",
};
