import { useMutation, useQueryClient } from "@tanstack/react-query";
import FileInput from "components/inputs/File";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { plans } from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";
import * as Yup from "yup";

type Props = {
  plan: any;
  alert?: boolean;
};

const PlanMissingDocumentsActionsForm = (props: Props) => {
  const { t } = useTranslation(["common", "plans"]);
  const [show, setShow] = useState(false);
  const { plan, alert = false } = props;
  const client = useQueryClient();
  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(alert ? ["plans"] : ["plan"]);
        toast.success(t("plans:documents_uploaded_successfully"));
      },
    }
  );

  function submit(values: any) {
    const { status, planId, ...files } = values;

    plans.upload({
      planId: plan.id,
      files,
      types: Object.keys(files),
    });
    planMutation.mutate(
      { plan, values },
      {
        onSuccess: () => setShow(false),
      }
    );
  }

  let [files, _] = plan.reason?.split(" ") ?? ["", ""];
  files = files.split(",");

  return (
    <>
      {[
        PlanStatus.MISSING_DOCUMENTS_RUBA,
        PlanStatus.MISSING_DOCUMENTS_FI,
      ].includes(plan.status) &&
        <>
          <Button
            variant={alert ? "info" : "primary"}
            onClick={() => setShow(true)}
            size="lg"
            className="w-100"
          >
            {t("plans:upload_missing_documents")}
          </Button>
          <Modal
            centered
            aria-labelledby="contained-modal-title-vcenter"
            show={show}
            onHide={() => setShow(false)}
            size="lg"
          >
            <Modal.Header className="d-flex justify-content-between" closeButton>
              <Modal.Title className="fs-6">
                {t("plans:upload_missing_documents")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                onSubmit={submit}
                initialValues={{
                  status:
                    plan.status == PlanStatus.MISSING_DOCUMENTS_RUBA
                      ? PlanStatus.PENDING_RUBA
                      : PlanStatus.PENDING_FI,
                }}
                validationSchema={Yup.object().shape({})}
              >
                <Form>
                  <>
                    <Row>
                      {files.map((file: any) => (
                        <Col lg="6" className="mb-3" key={file}>
                          <FileInput
                            type="file"
                            id={file}
                            name={file}
                            label={t(`plans:${file}`)}
                          />
                        </Col>
                      ))}
                      <Col lg="12">
                        <Button
                          size="lg"
                          type="submit"
                          variant="primary"
                          className="w-100 mt-3"
                        >
                          {t("submit")}
                        </Button>
                      </Col>
                    </Row>
                  </>
                </Form>
              </Formik>
            </Modal.Body>
          </Modal>
        </>
      }
    </>
  );
};

export default PlanMissingDocumentsActionsForm;
