import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { Form, Formik } from "formik";
import { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import grades from "utils/api/grades";
import { schools } from "utils/api/schools";
import FileType from "utils/enums/FileType";
import SchoolType from "utils/enums/SchoolType";
import useAlert from "utils/hooks/alert";
import * as Yup from "yup";
import CreateSchoolBasic from "./Basic";
import CreateSchoolBranches from "./Branches";
import CreateSchoolFiles from "./Files";
import CreateSchoolGrades from "./Grades";
import CreatePaymentDates from "./PaymentDates";
import CreateRegistrationDates from "./RegistrationDates";
import CreateSchoolSignature from "./Signature";

const schema = Yup.object().shape({
  name: Yup.string().required(),
  discount: Yup.number().required(),
  parent_fee: Yup.number().required(),
  iban: Yup.string().nullable(),
  number_of_students: Yup.number().nullable(),
  tags: Yup.string()
    .nullable(true)
    .transform((_, value) => {
      return value === "" ? null : value;
    }),
  address: Yup.string()
    .nullable(true)
    .transform((_, value) => {
      return value === "" ? null : value;
    }),
});

const CreateSchool = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["plans", "common", "schools"]);
  const { id } = useParams();
  const { success } = useAlert();
  const client = useQueryClient();
  const [activeTab, setActiveTab] = useState('basics'); // set the default tab


  const { data: schoolData } = useQuery(["schools", id], schools.view(id), {
    enabled: Boolean(id),
  });
  const { data: gradeData } = useQuery(["grades"], grades.list);

  function preparePrefetchedSchool(school: any) {
    if (school?.constrainedFis)
      school.constrainedFis = school.constrainedFis.map((i: any) => i.id ?? i);
    return school;
  }

  const updateMutation = useMutation(
    (values: any) => schools.update(id as string, values),
    {
      onSuccess: () => {
        client.invalidateQueries(["schools", id]);
        navigate("/schools");
      },
    }
  );

  function confirmDeletion() {
    success({
      onConfirm: deleteSchool,
      message: `School "${schoolData.name}" will be removed`,
    });
  }

  async function deleteSchool() {
    await schools.destroy(id);
    navigate("/schools");
  }

  async function submit(values: any) {
    try {
      console.log("submitting");
      const castedSchema = schema.cast(values);
      let res;
      if (id) {
        res = await updateMutation.mutateAsync(castedSchema);
      } else {
        res = await schools.store(castedSchema);
      }
      if (values.signature)
        await schools.upload(res.id, [
          {
            data: { type: FileType.SIGNATURE },
            file: values.signature,
          },
        ]);
      if (values.documents.length > 0) {
        await schools.upload(res.id, values.documents);
      }

      navigate("/schools");
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          {/* <Link to="/schools">
            <FontAwesomeIcon icon={faBookBookmark} /> {t("schools:schools")}
          </Link> */}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {id ? schoolData?.name : t("schools:new_school")}
        </BreadcrumbItem>
      </Breadcrumb>
      <Formik
        enableReinitialize
        initialValues={{
          name: "",
          discount: "",
          parent_fee: "",
          type: SchoolType.NURSERY,
          direct_financing: false,
          custom_id: "",
          account_number: "",
          bank_name: "",
          users: [],
          grades: [],
          schoolYears: [],
          documents: [],
          country_id: 115,
          ...preparePrefetchedSchool(schoolData),
        }}
        validationSchema={schema}
        onSubmit={submit}
      >
        <Form>
          <Tabs
            defaultActiveKey="basics"
            activeKey={activeTab}
            id="fill-tab-example"
            className="mb-3"
            fill
            onSelect={(k: any) => setActiveTab(k)}
          >
            <Tab eventKey="basics" title={t("common:basics")}>
              <CreateSchoolBasic />
            </Tab>
            <Tab eventKey="branches" title={t("schools:branches")}>
              <CreateSchoolBranches />
            </Tab>
            <Tab eventKey="grades" title={t("schools:grades")}>
              <CreateSchoolGrades gradeData={gradeData} />
            </Tab>
            <Tab eventKey="documents" title={t("plans:documents")}>
              <CreateSchoolFiles id={id} />
            </Tab>
            <Tab
              eventKey="registration"
              title={t("schools:registration_dates")}
            >
              <CreateRegistrationDates />
            </Tab>
            <Tab eventKey="paymentDates" title={t("schools:payment_dates")}>
              <CreatePaymentDates />
            </Tab>
            <Tab eventKey="signatures" title={t("common:signatures")}>
              <CreateSchoolSignature activeTab={activeTab} schoolData={schoolData} />
            </Tab>
          </Tabs>
          <Row className="mt-4">
            <RoleBasedRenderer
              admin={
                id && (
                  <Col md="6" className="mb-3">
                    <Button
                      className="w-100 w-sm-auto"
                      size="lg"
                      onClick={confirmDeletion}
                      variant="danger"
                    >
                      <i className="bi bi-trash3 mx-1"></i>
                      {t("schools:delete_educational_institution")}
                    </Button>
                  </Col>
                )
              }
            />
            <Col md="6" className="text-end">
              <Button size="lg" className="w-100 w-sm-auto" type="submit">
                {t("common:save_changes")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </>
  );
};

export default CreateSchool;
