import { useField } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/*let handleBlur = event => {
    if (this.ignoreNextBlur === true) {
        // The parent components are relying on the bubbling of the event.
        event.stopPropagation();
        this.ignoreNextBlur = false;
        event.target.name = this.props.name;
        return;
    }
};*/
type SelectProps = {
  labelKey?: string;
  valueKey?: string;
  optgroup?: boolean;
  options: any[];
  label?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  style?: any;
  className?: string;
  onChange?: (event: any) => void;
};

const Select = ({
  options,
  labelKey = "label",
  valueKey = "value",
  ...props
}: SelectProps) => {
  const [field, meta] = useField(props as any);
  const { t } = useTranslation(["common"]);
  return (
    <Form.Group>
      {props.label && (
        <Form.Label htmlFor={props.id} className={"label-color"}>
          {props.label}
        </Form.Label>
      )}
      <Form.Select
        {...field}
        {...props}
        isInvalid={meta.touched && !!meta.error}
      >
        <option hidden value="">
          {props.placeholder ?? t("please_select")}
        </option>
        {options?.map((option, index) => {
          if (typeof option !== "string")
            return (
              <option value={option[valueKey]} key={index}>
                {option[labelKey] || option.label}
              </option>
            );
          return (
            <option value={option} key={index}>
              {option}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default Select;
