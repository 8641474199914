import Select from "components/inputs/Select";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import plans from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";
import { statusSpaceToUnderScore } from "utils/functions/utilities";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import useAlert from "utils/hooks/alert";
import ConditionalApprovalCheckboxes from "containers/common/ConditionalApprovalCheckboxes";
import Input from "components/inputs/Input";

const reasons: any = {
  [PlanStatus.INCOMPLETE]: [
    "Lower amount",
  ],
  [PlanStatus.USER_WITHDRAWN]: [
    "Credit obligations (Cinet)",
    "Decided to pay directly to institution",
    "Direct financing fees too high",
    "Interested in another institution",
    "Just trying it out of curiosity",
    "Withdrawn after approval",
    "Other",
  ],
  [PlanStatus.ARCHIVED]: [
    "No response",
    "To apply next year",
    "Credit obligations (Cinet)",
    "Missing Documents",
    "Other",
  ],
};

const AdminPlanUpdateStatus = (props: { plan: any }) => {
  const { plan } = props;
  const client = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation(["plans", "common"]);
  const [show, setShow] = useState(false);
  const { confirmOrDeny } = useAlert();

  const planDeleteMutation = useMutation(
    (id: number) => {
      return plans.destroy(id);
    },
    {
      onSuccess: (data) => {
        client.invalidateQueries(["plan"]);
        toast.success(data.message);
        navigate("/dashboard");
      },
    }
  );

  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  function destroy() {
    confirmOrDeny({
      confirmButtonText: t("common:yes"),
      denyButtonText: t("common:no"),
      onConfirm: () => planDeleteMutation.mutate(plan.id),
    });
  }

  function submit(values: any) {
    const extend: any = {};
    if (values.status == PlanStatus.MISSING_DOCUMENTS_RUBA) {
      extend.reason = values.missing_document.join(",") + " " + values.reason;
    }
    if ([PlanStatus.REJECTED_FI, PlanStatus.RUBA_CONDITIONAL_APPROVAL].includes(values.status)) {
      extend.reason = values.reason_category + ": " + values.reason;
    }
    planMutation.mutate({ plan, values: { ...values, ...extend } });
    setShow(false);
  }
  return (
    <>
      <Button onClick={() => setShow(true)} size="lg" className="w-100">
        Force Status
      </Button>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">Force Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={submit}
            initialValues={{ status: "", reason: "" }}
            validationSchema={Yup.object().shape({
              status: Yup.string(),
              reason: Yup.string().when("status", {
                is: PlanStatus.REJECTED_RUBA,
                then: Yup.string().required(),
              }),
            })}
          >
            {(props) => (
              <Form>
                <Select
                  options={Object.entries(PlanStatus).map(([_, name]) => ({
                    id: name,
                    name: t(statusSpaceToUnderScore(name)),
                  }))}
                  name="status"
                  label="Force Status"
                  labelKey="name"
                  valueKey="id"
                  className="mb-3"
                />
                {[PlanStatus.USER_WITHDRAWN, PlanStatus.ARCHIVED].includes(
                  props.values?.status as PlanStatus
                ) && (
                    <Select
                      options={reasons[props.values?.status]}
                      name="reason"
                      label="Reason"
                    />
                  )}
                {props.values.status == PlanStatus.RUBA_CONDITIONAL_APPROVAL && (
                  <>
                    <div className="mb-3">
                      <ConditionalApprovalCheckboxes name="reason_category" />
                    </div>
                    <Input name="reason" label="Reason" type="textarea" />
                  </>
                )}
                {props.values.status == PlanStatus.INCOMPLETE && (
                  <>
                    <Input name="extras.acceptableAmount" label="Acceptable amount" type="number" />
                    <Input name="extras.acceptableMonths" label="Acceptable period" type="number" />
                  </>
                )}
                <div className="mt-4 d-flex justify-content-between">
                  <Button
                    size="lg"
                    className="w-50 me-4"
                    onClick={destroy}
                    variant="danger"
                    type="button"
                  >
                    Delete Plan
                  </Button>
                  <Button
                    size="lg"
                    className="w-50"
                    variant="primary"
                    type="submit"
                  >
                    Update Status
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminPlanUpdateStatus;
