import { VisibilityState, createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import Role from "utils/enums/Role";
import { dateAndTime, parseJSONorString } from "utils/functions/utilities";

const columnHelper = createColumnHelper<any>();
const plansTablecolumns = (t: any) => [
  columnHelper.accessor("public_id", {
    header: t("id"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("updated_at", {
    header: t("common:last_update"),
    cell: (info) => dateAndTime(info.getValue()),
  }),
  columnHelper.accessor("school.name", {
    id: "beneficiary",
    header: t("common:beneficiary"),
    cell: (info) => info.getValue(),
  }),

  columnHelper.accessor("user.name", {
    id: "name",
    header: t("plans:applicant_name"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("amount", {
    id: "amount",
    header: t("plans:amount"),
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("school_trans_id", {
    id: "school_tid",
    header: t("plans:school_trans_id"),
    cell: (info) => <ShowTid transaction={info.getValue()} />,
  }),
  columnHelper.accessor("ruba_trans_id", {
    id: "ruba_tid",
    header: t("plans:ruba_trans_id"),
    cell: (info) => <ShowTid transaction={info.getValue()} />,
  }),
  columnHelper.accessor("ruba_school_trans_id", {
    id: "ruba_school_tid",
    header: t("plans:ruba_school_trans_id"),
    cell: (info) => <ShowTid transaction={info.getValue()} />,
  }),
];

function ShowTid(props: any) {
  const { transaction } = props;
  const { t } = useTranslation();
  const parsed = parseJSONorString(transaction);
  if (parsed?.date) {
    return (
      <>
        <p>
          <b>{t("plans:amount")}:</b> {parsed?.amount}
        </p>
        <p>
          <b>TID:</b> {parsed?.tid}
        </p>
        <p>
          <b>{t("common:date")}:</b> {parsed?.date}
        </p>
      </>
    );
  }
  return <p>{transaction}</p>;
}

export const plansTablecolumnVisibility = (
  width: number,
  role: Role
): VisibilityState => {
  let hiddenColumns = {};
  if (role === Role.FI) {
    hiddenColumns = {
      ruba_school_tid: false,
    };
  }
  if (role === Role.SCHOOL) {
    hiddenColumns = {
      ruba_tid: false,
    };
  }

  return hiddenColumns;
};

export default plansTablecolumns;
