import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const usePlaner = (): [any, (plan: any) => void] => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [plan, setPlan] = useState<any>(() => {
    const storedPlan = localStorage.getItem("plan");
    return storedPlan ? JSON.parse(storedPlan) : null;
  });

  useEffect(() => {
    if (searchParams.get("data")) {
      const parsedPlan = JSON.parse(searchParams.get("data")!);
      if (parsedPlan.id == plan?.id) return;
      localStorage.setItem("plan", searchParams.get("data")!);
    }
    // else {
    //   localStorage.removeItem("plan");
    // }
  }, [searchParams]);

  return [plan, setPlan];
};

export default usePlaner;
