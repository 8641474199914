import { Button } from "react-bootstrap";
import users from "utils/api/users";
import { useUser } from "utils/hooks/auth";
import i18n from "utils/i18n/config";
import React, { useEffect, useState } from "react";

export function switchLanguage(updateUser = true) {
  const lng = i18n.language === "en" ? "ar" : "en";
  localStorage.setItem("lng", lng);
  i18n.changeLanguage(lng);
  if (updateUser) users.updateLanguage(lng);
  window.location.reload();
}

const LanguageSwitch = (props: any) => {
  const { data: user } = useUser();
  const [language, setLanguage] = useState(i18n.language || "en");

  useEffect(() => {
    const lng = localStorage.getItem("lng") || i18n.language;
    setLanguage(lng);

    const handleLanguageChange = (lng: string) => {
      setLanguage(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  return (
    <Button variant="outline-light" onClick={() => switchLanguage(!!user)}>
      {language === "en" ? "عربي" : "English"}
    </Button>
  );
};

export default LanguageSwitch;
