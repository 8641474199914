//@ts-nocheck
import React, { CSSProperties, FC } from "react";
import {ReactComponent as IconHouse} from "../../assets/utils/icons/house.svg";
import {ReactComponent as IconDocument} from "../../assets/utils/icons/document.svg";
import {ReactComponent as IconDollar} from "../../assets/utils/icons/dollar.svg";
import {ReactComponent as IconProfile} from "../../assets/utils/icons/profile.svg";
import {ReactComponent as IconStudent} from "../../assets/utils/icons/student.svg";
import {ReactComponent as IconSupport} from "../../assets/utils/icons/support.svg";
import {ReactComponent as IconSettings} from "../../assets/utils/icons/settings.svg";
import {ReactComponent as IconLanguage} from "../../assets/utils/icons/language.svg";
import {ReactComponent as IconOpenEye} from "../../assets/utils/icons/open-eye.svg";
import {ReactComponent as IconSlashEye} from "../../assets/utils/icons/slash-eye.svg";
import {ReactComponent as IconEdit} from "../../assets/utils/icons/edit.svg";
import {ReactComponent as IconMark} from "../../assets/utils/icons/mark.svg";
import {ReactComponent as IconTrash} from "../../assets/utils/icons/trash.svg";
import {ReactComponent as IconUpload} from "../../assets/utils/icons/upload.svg";
import {ReactComponent as IconInfo} from "../../assets/utils/icons/info.svg";
import {ReactComponent as IconBoxedPlus} from "../../assets/utils/icons/boxed-plus.svg";
import {ReactComponent as IconDownload} from "../../assets/utils/icons/download.svg";
import {ReactComponent as IconSidebar} from "../../assets/utils/icons/sidebar.svg";
import {ReactComponent as IconMore} from "../../assets/utils/icons/more.svg";
import {ReactComponent as IconBank} from "../../assets/utils/icons/bank.svg";
import {ReactComponent as IconStickyNote} from "../../assets/utils/icons/sticky-note.svg";
import {ReactComponent as IconBuilding} from "../../assets/utils/icons/building.svg";
import {ReactComponent as IconDocumentHeart} from "../../assets/utils/icons/document-heart.svg";
import {ReactComponent as IconOpenBook} from "../../assets/utils/icons/open-book.svg";
import {ReactComponent as IconUsers} from "../../assets/utils/icons/users.svg";
import {ReactComponent as IconGrid} from "../../assets/utils/icons/grid.svg";
import {ReactComponent as IconTransactions} from "../../assets/utils/icons/transactions.svg";
import {ReactComponent as IconReports} from "../../assets/utils/icons/reports.svg";
import {ReactComponent as IconReserve} from "../../assets/utils/icons/reserve.svg";
import {ReactComponent as IconStudents} from "../../assets/utils/icons/students.svg";
import {ReactComponent as IconNotification} from "../../assets/utils/icons/notification.svg";
import {ReactComponent as IconBagPlus} from "../../assets/utils/icons/bag-plus.svg";

type IconName = keyof typeof icons;

const icons = {
  house: IconHouse,
  document: IconDocument,
  dollar: IconDollar,
  profile: IconProfile,
  student: IconStudent,
  support: IconSupport,
  settings: IconSettings,
  language: IconLanguage,
  openEye: IconOpenEye,
  slashEye: IconSlashEye,
  edit: IconEdit,
  mark: IconMark,
  trash: IconTrash,
  upload: IconUpload,
  info: IconInfo,
  boxedPlus: IconBoxedPlus,
  download: IconDownload,
  sidebar: IconSidebar,
  more: IconMore,
  bank: IconBank,
  stickyNote: IconStickyNote,
  building: IconBuilding,
  documentHeart: IconDocumentHeart,
  openBook: IconOpenBook,
  users: IconUsers,
  grid: IconGrid,
  transactions: IconTransactions,
  reports: IconReports,
  reserve: IconReserve,
  students: IconStudents,
  notification: IconNotification,
  bagPlus: IconBagPlus,
};

interface IconProps {
  name: IconName;
  size?: number;
  color?: string;
  style?: CSSProperties;
  className?: string;
  onClick?: any;
}

const Icon: FC<IconProps> = ({
  name,
  size,
  color,
  style,
  className="",
  onClick = () => {},
}) => {
  const iconStyle: CSSProperties = {
    width: size || 18,
    height: size || 18,
    fill: color || "none",
    ...style,
  };

  const IconName = icons[name];

  return (
    <span onClick={onClick} className={"mx-1 icon "+className}>
      <IconName {...iconStyle} />
    </span>
  );
};

export default Icon;
