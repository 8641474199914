import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { Notification } from 'utils/types/notification'
import { FaStar } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const ReferralCompletedNotificationRenderer = (notification: Notification) => {

    return (
        <Row>
            <Col lg={1}>
                <div className='bg-primary bg-opacity-10 p-3 rounded-circle position-relative' style={{
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <FaStar  style={{ fill: '#FFD700', strokeWidth: '8px',top:'35%',left:'10%' }} size={18} className='bounce position-absolute' />
                    <FaStar  style={{ fill: '#FFD700', strokeWidth: '8px',top:'10%',left:'35%' }} size={18} className='bounce position-absolute' />
                    <FaStar  style={{ fill: '#FFD700', strokeWidth: '8px',top:'35%',left:'60%' }} size={18} className='bounce position-absolute' />
                </div>
            </Col>
            <Col lg={9}>
                <p>
                    Completed Referrals <span className='text-gray'>just now</span>
                </p>
                <p className='text-black fs-6 my-1'>
                    Please proceed with refunding the service fee
                </p>
                <p className='text-gray fw-light'>
                    "The service fee transaction has been queued as pending refund.."
                </p>
            </Col>
            <Col lg={2} className='d-flex align-items-center'>
                <Link to={notification.link ?? ""}>
                    <Button variant="info">View Plan</Button>
                </Link>
            </Col>
        </Row>
    )
}

export default ReferralCompletedNotificationRenderer
