import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateFI from "./Create";
import ListFIs from "./List";
// import CreatePlan from "./Create";
// import ViewPlan from "./View";

const FIsRoutes = () => {
  return (
    <Routes >
      <Route path="/new" element={<CreateFI />} />
      <Route path="/:id" element={<CreateFI />} />
      <Route path="/" element={<ListFIs />} />
    </Routes>
  );
};

export default FIsRoutes;
