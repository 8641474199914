import React from "react";
import { Route, Routes } from "react-router-dom";
import ListInterests from "./List";

const InterestsRoutes = () => {
  return (
    <Routes >
      <Route path="/" element={<ListInterests />} />
    </Routes>
  );
};

export default InterestsRoutes;
