import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import Amount from 'components/widgets/Amount';
import DateBox from 'components/widgets/DateBox';
import ProgressWithTitles from 'components/widgets/ProgressWithTitles';
import { DateTime } from 'luxon';
import React from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import plans from 'utils/api/plans';
import TransactionType from 'utils/enums/TransactionType';
import { arabicValue, dateAndTime, gradeLabel, paymentIsDone } from 'utils/functions/utilities';

type Props = {
    open: boolean,
    setOpen: any,
    planId: any,
    handlePaynowClick: () => void
}

const TransactionDetailsModal = (props: Props) => {
    const { open, setOpen, planId, handlePaynowClick } = props;
    const [showMoreTransactions, setShowMoreTransactions] = React.useState(false);

    const { data } = useQuery(["transaction-details", planId], () => plans.view(planId), {
        enabled: open,
        placeholderData: {},
    });

    const { t } = useTranslation(["common", "plans", "schools"]);

    const reserveTransactions = data?.transactions?.filter((item: any) => item.type != TransactionType.SERVICE_FEE);
    const remainingTransactions = reserveTransactions?.filter((item: any) => !paymentIsDone(item));
    const total = reserveTransactions?.reduce((acc: any, item: any) => acc + item.amount, 0);
    const paid = reserveTransactions?.reduce((acc: any, item: any) => {
        return paymentIsDone(item) ? acc + item.amount : acc
    }, 0);


    return (
        <Modal
            show={open}
            onHide={setOpen}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <p>{data?.school?.name}</p>
                <Link to={`/plans/${data?.id}`} className='text-decoration-none'>
                    <p className='mx-3 text-info fbs-3'>{t('plans:plan')} #{data?.id}</p>
                </Link>
            </Modal.Header>
            <Modal.Body>
                <Row className='gy-3'>
                    <Col lg={12}>
                        <ProgressWithTitles value={paid} max={total} />
                    </Col>
                    <Col lg={12}>
                        <hr style={{ height: "1px" }} className="bg-light" />
                    </Col>
                    <Col col={6} className='d-flex align-items-center'>
                        <h6 className="text-dark fw-bold">{t('remaining_payments')}</h6>
                    </Col>
                    <Col col={6} className='d-flex justify-content-end'>
                        <Button
                            onClick={() => {
                                handlePaynowClick()
                                setOpen(false)
                            }}
                            variant="primary"
                            className="fbs-4 px-3"
                        >
                            {t("plans:pay_now")}!
                        </Button>
                    </Col>
                    <Col lg={12}>
                        {remainingTransactions?.slice(0, showMoreTransactions ? remainingTransactions.length : 3).map((item: any) => {
                            const payment_date = DateTime.fromISO(item.payment_date);
                            const overDue = payment_date < DateTime.now();
                            return (
                                <Alert
                                    key={item.id}
                                    variant={overDue ? "danger" : "white"}
                                    className={cx(`border`, {
                                        'border-danger': overDue,
                                        'border-dark border-opacity-25': !overDue
                                    })}
                                >
                                    <div className="d-flex w-100">
                                        <DateBox
                                            date={DateTime.fromISO(item.scheduled_date)}
                                            overDue={overDue}
                                        />
                                        <div className="mx-3 flex-grow-1 d-flex flex-column justify-content-center">
                                            <Amount className='fw-bold fs-6 my-1' amount={item.amount} />
                                            <p className="text-dark opacity-75 fw-normal">
                                                {t("plans:installment")}
                                                <span className="mx-1 fw-bold">
                                                    {t("common:x_out_of_y", {
                                                        x: item.order,
                                                        y: item.total_per_plan,
                                                    })}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </Alert>
                            )
                        })}
                        {remainingTransactions?.length > 3 && (
                            <div className='text-center'>
                                <Button
                                    size="sm"
                                    className='py-2 px-3 text-dark'
                                    variant="outline-light"
                                    onClick={() => setShowMoreTransactions((prev) => !prev)}
                                >
                                    {t(showMoreTransactions ? "show_less" : "show_more")}{" "}
                                    <i
                                        className={`ms-2 bi bi-chevron-${showMoreTransactions ? "up" : "down"}`}
                                    ></i>
                                </Button>
                            </div>
                        )}
                    </Col>
                    <Col lg={12}>
                        <hr style={{ height: "1px" }} className="bg-light" />
                    </Col>
                    <Col lg={12} className='d-flex align-items-center'>
                        <h6 className="text-dark fw-bold">{t('plans:students')}</h6>
                    </Col>
                    {data.students?.map((student: any) => (
                        <Col key={student.id} lg={6} className="p-1">
                            <div className="p-3 border border-gray border-opacity-25 rounded-2">
                                <p className="mb-2 fw-bold text-dark fs-7">
                                    {student.name}
                                </p>
                                <p className="fw-semibold text-gray fbs-4 mb-3">
                                    {arabicValue(student.school, 'name')} - {gradeLabel(student.grade)}
                                </p>
                                <span className="px-2 py-1 mt-2 text-primary fbs-4 border rounded-2 border-primary">
                                    {data.current_year}
                                </span>
                            </div>
                        </Col>
                    ))}
                    <Col lg={12}>
                        <hr style={{ height: "1px" }} className="bg-light" />
                    </Col>
                    <Col lg={12} className='d-flex align-items-center'>
                        <h6 className="text-dark fw-bold">{t('plans:plan_info')}</h6>
                    </Col>
                    <Col lg={4}>
                        <p className="fbs-4 text-dark opacity-75 fw-bold mb-1">
                            {t("plans:total_amount")}
                        </p>
                        <Amount amount={total} />
                    </Col>
                    <Col lg={4}>
                        <p className="fbs-4 text-dark opacity-75 fw-bold mb-1">
                            {t("plans:installments")}
                        </p>
                        <p className="text-dark fw-bold">
                            {reserveTransactions?.length} {t('plans:payments')}
                        </p>
                    </Col>
                    <Col lg={4}>
                        <p className="fbs-4 text-dark opacity-75 fw-bold mb-1">
                            {t("creation_date")}
                        </p>
                        <p className="text-dark fw-bold">
                            {dateAndTime(data.created_at, true)}
                        </p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default TransactionDetailsModal