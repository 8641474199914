import { faBookBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/inputs/Input";
import { Form, Formik, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  Card,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import grades from "utils/api/grades";
import GradeType from "utils/enums/GradeType";
import { gradeLabel } from "utils/functions/utilities";
import useAlert from "utils/hooks/alert";
import * as Yup from "yup";

const CreateGrade = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["plans", "common", "schools"]);
  const { id } = useParams();
  const { data } = useQuery(["grades", id], grades.view(id), {
    enabled: Boolean(id),
  });

  async function submit(values: any) {
    try {
      let res;
      if (id) {
        res = await grades.update(id, values);
      } else {
        res = await grades.store(values);
      }
      navigate("/grades");
    } catch (e) {}
  }
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/grades">
            <FontAwesomeIcon icon={faBookBookmark} /> {t("grades:grades")}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("schools:new_grade")}</BreadcrumbItem>
      </Breadcrumb>
      <TransitionGroup>
        <CSSTransition
          component="div"
          classNames="TabsAnimation"
          appear={true}
          timeout={1500}
          enter={false}
          exit={false}
        >
          <Card body>
            <Formik
              enableReinitialize
              initialValues={{
                name: "",
                name_ar: "",
                type: GradeType.name,
                ...data,
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required(),
                name_ar: Yup.string().required(),
                type: Yup.string().required(),
              })}
              onSubmit={submit}
            >
              <Form>
                <CreateGradeForm gradeData={data} />
              </Form>
            </Formik>
          </Card>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

function CreateGradeForm(props: any) {
  const { gradeData } = props;
  const { values, setFieldValue } = useFormikContext<any>();
  const { t } = useTranslation(["plans", "common"]);
  const { success } = useAlert();
  const navigate = useNavigate();

  function confirmDeletion() {
    success({
      onConfirm: deleteGrade,
      message: `Grade "${gradeData.name}" will be removed`,
    });
  }

  async function deleteGrade() {
    await grades.destroy(values.id);
    navigate("/grades");
  }

  return (
    <div>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>{t("schools:grade_type")}</Label>
            <br />
            <ButtonGroup size="lg" className="mb-2 w-100">
              <Button
                outline
                color="primary"
                onClick={() => setFieldValue("type", GradeType.name)}
                active={values.type === GradeType.name}
              >
                {t("schools:named_grade")}
              </Button>
              <Button
                outline
                color="primary"
                onClick={() => setFieldValue("type", GradeType.monthlyRange)}
                active={values.type === GradeType.monthlyRange}
              >
                {t("schools:monthly_grade")}
              </Button>
              <Button
                outline
                color="primary"
                onClick={() => setFieldValue("type", GradeType.course)}
                active={values.type === GradeType.course}
              >
                {t("schools:course_grade")}
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>How its displayed</Label>
            <p>
              {values.type == GradeType.monthlyRange
                ? gradeLabel({
                    ...values,
                    objectName: {
                      from: values.name.split("-")[0],
                      end: values.name.split("-")[1],
                    },
                  })
                : values.name}
            </p>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Input label={t("common:name")} name="name" />
          {values.type == GradeType.monthlyRange && <p>
            <b>Note:</b> use the following format: 10-24</p>}
        </Col>
        <Col md="6">
          <Input label={t("common:arabic_name")} name="name_ar" />
        </Col>
      </Row>
      <Row>
        <Col
          lg="12"
          className={`d-flex justify-content-${
            gradeData?.id ? "between" : "end"
          }`}
        >
          {gradeData?.id && (
            <Button
              onClick={confirmDeletion}
              className="mb-2 me-2 btn-icon"
              color="danger"
            >
              <i className="pe-7s-trash btn-icon-wrapper"> </i>
              {t('schools:delete_grade')}
            </Button>
          )}
          <Button type="submit" color="primary">
            {t("common:submit")}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CreateGrade;
