import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import { Pie, PieChart, ResponsiveContainer, Tooltip, Cell, BarChart, XAxis, Bar, CartesianGrid, YAxis, Legend, Rectangle } from "recharts";
import { graphColors } from "utils/constants";
import { PieDataItem } from "utils/types/dashboard";

type Props = {
  loading: boolean;
  title: string;
  data?: PieDataItem[];
  currency?: boolean;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      className="fbs-4 fw-bold"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const MediumBarCard = (props: Props) => {
  const { loading, title, data, currency = false } = props;
  const { t } = useTranslation(["common"]);
  const [open, setOpen] = useState(false);
  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "#fff",
          opacity: 0.5,
        }),
      }}
      spinner={<Loader active type="ball-pulse" />}
    >
      <Card>
        <Card.Body className="p-4">
          <Row>
            <Col
              lg={12}
              className="fs-7 d-flex justify-content-between text-graycool fw-semibold mb-3"
            >
              <p>{title}</p>
              <i onClick={
                () => setOpen(!open)
              } style={{
                WebkitTextStroke: '0.9px',
              }} className="bi bi-chevron-right fs-6"></i>
            </Col>
          </Row>
          <Row>
            {open ?
              <>
                <Col lg={12} style={{ maxHeight: "200px", overflowY: "auto" }}>
                  <ul className="w-100">
                    {data?.map((item) => (
                      <li
                        key={item.name}
                        style={{ color: item.color }}
                        className="text-capitalize fs-5"
                      >
                        <div className="">
                          <p className="fbs-4 text-graycool">{item.name} {currency ? (
                            <>
                              {item.value?.toLocaleString(undefined, {
                                minimumFractionDigits: 3,
                              })}
                              <sup className="fbs-5"> {t('kd')}</sup>
                            </>
                          ) : (
                            `${(((item.value) ?? 0) /
                              data.reduce((acc, curr) => acc + (curr.value ?? 0), 0) * 100).toFixed(0)
                            }%`
                          )}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Col>
              </>
              :
              <>
                <Col lg={12} style={{ height: "208px" }}>
                  {console.log(data)}
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={data?.slice(0, 3)}
                      barSize={30}
                      margin={{
                        top:12,
                        right: 24,
                        left: -24,
                        // bottom: 5,
                      }}
                    >
                      <CartesianGrid fillOpacity={0.1} vertical={false} strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#1570EF" width={40} />
                    </BarChart>
                  </ResponsiveContainer>
                </Col>
              </>}

          </Row>
        </Card.Body>
      </Card>
    </LoadingOverlay>
  );
};

export default MediumBarCard;
