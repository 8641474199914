import PlansTableWidget from "../Widgets/NewPlans";
import WelcomeWidget from "../Widgets/Welcome";

const ModeratorWidgets = () => {
  return (
    <>
      <WelcomeWidget />
      <PlansTableWidget />
    </>
  );
};

export default ModeratorWidgets;
