import EMPLOYMENTTYPE from "utils/enums/EmploymentType";
import Role from "utils/enums/Role";
import i18next from "utils/i18n/config";
import * as Yup from "yup";

export const AccountFormValidation = (role) => {
  if (role !== Role.USER)
    return Yup.object().shape({
      name: Yup.string().required(i18next.t("auth:name_required")),
      marital_status: Yup.string().typeError(
        i18next.t("plans:marital_status_required")
      ),
      birthday: Yup.string().required(i18next.t("birthday_required")),
      nationality_id: Yup.string().typeError(
        i18next.t("plans:nationality_required")
      ),
      personal_id: Yup.string()
        .test(
          "len",
          i18next.t("plans:civil_id_length"),
          (val) => val?.length === 12
        )
        .typeError(i18next.t("plans:personal_id_required")),
    });
  else
    return Yup.object().shape({
      name: Yup.string().required(i18next.t("auth:name_required")),
      employment_type: Yup.string().required(),
      marital_status: Yup.string()
        .required(i18next.t("plans:marital_status_required"))
        .typeError(i18next.t("plans:marital_status_required")),
      birthday: Yup.string().required(i18next.t("birthday_required")),
      nationality_id: Yup.string()
        .required(i18next.t("plans:nationality_required"))
        .typeError(i18next.t("plans:nationality_required")),
      personal_id: Yup.string()
        .required(i18next.t("plans:personal_id_required"))
        .test(
          "len",
          i18next.t("plans:civil_id_length"),
          (val) => val?.length === 12
        )
        .typeError(i18next.t("plans:personal_id_required")),
      sector: Yup.string()
        .nullable()
        .when("employment_type", {
          is: (val) => [EMPLOYMENTTYPE.SALARIED].includes(val),
          then: Yup.string().required(i18next.t("plans:sector_required")),
        }),
      company: Yup.string()
        .nullable()
        .when("employment_type", {
          is: (val) =>
            [EMPLOYMENTTYPE.SALARIED, EMPLOYMENTTYPE.SELFEMPLOYED].includes(
              val
            ),
          then: Yup.string().required(i18next.t("plans:company_required")),
        }),
      salary: Yup.string()
        .required(i18next.t("plans:salary_required"))
        .typeError(i18next.t("plans:salary_required")),
      work_experience: Yup.number()
        .nullable()
        .min(0, i18next.t("common:number_must_be_more", { number: 0 }))
        .max(99, i18next.t("common:number_must_be_less", { number: 99 }))
        .typeError(i18next.t("common:must_be_number"))
        .when("employment_type", {
          is: (val) => [EMPLOYMENTTYPE.SALARIED].includes(val),
          then: Yup.number().required(
            i18next.t("plans:work_experience_required")
          ),
        }),
      monthly_loans: Yup.string().nullable(),
    });
};

export const AccountFormInitialValues = {
  name: "",
  gender: "",
  email: "",
  personal_id: "",
  marital_status: "",
  birthday: "",
  nationality_id: 115,
  employment_type: "",
  company: "",
  sector: "",
  work_experience: "",
  salary: "",
  monthly_loans: 0,
  has_loans: 0,
};
