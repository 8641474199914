import PlansTableWidget from "../Widgets/NewPlans";
import SchoolPendingActionsWidget from "../Widgets/SchoolPendingActions";
import SchoolStatsWidget from "../Widgets/SchoolStats";
import WelcomeWidget from "../Widgets/Welcome";

const SchoolWidgets = () => {
  return (
    <>
      <WelcomeWidget />
      <SchoolPendingActionsWidget />
      <SchoolStatsWidget />
      <PlansTableWidget />
    </>
  );
};

export default SchoolWidgets;
