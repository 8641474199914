import { useQuery } from "@tanstack/react-query";
import MediumPieCard from "components/widgets/Dashboard/MediumPieCard";
import SmallCard from "components/widgets/Dashboard/SmallCard";
import { t } from "i18next";
import { DateTime } from "luxon";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import plans from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";

const placeholderData = {
  generated: 0,
  pending: 0,
  total_plans: [
    {
      name: t("plans:accepted"),
      value: 0,
      color: "#1570EF",
    },
    {
      name: t("plans:rejected"),
      value: 0,
      color: "#F04438",
    },
    {
      name: t("plans:pending"),
      value: 0,
      color: "#FDB022",
    },
  ],
  students: [
    {
      name: t("schools:new_students"),
      value: 0,
      color: "#1570EF",
    },
    {
      name: t("schools:existing_students"),
      value: 0,
      color: "#84CAFF",
    },
  ],
};
const SchoolStatsWidget = () => {
  const { data, isFetching } = useQuery(
    ["plans", "schools"],
    () => plans.list({}),
    {
      placeholderData,
      select: (data) => {
        let parsed = placeholderData;
        if (!data.data?.length) return parsed;

        data.data.forEach((plan: any) => {
          if (DateTime.fromISO(plan.created_at).diffNow("weeks").weeks >= -1) {
            parsed.students[0].value += plan.students.length;
          } else {
            parsed.students[1].value += plan.students.length;
          }
          // Total plans
          if (plan.status === PlanStatus.SEAT_WITHDRAWN)
            parsed.total_plans[1].value += 1;
          if (
            [
              PlanStatus.PENDING_QUOTATION,
              PlanStatus.PENDING_INVOICE,
              PlanStatus.PENDING_RECEIPT,
            ].includes(plan.status)
          )
            parsed.total_plans[2].value += 1;

          // Generated revenue
          if (
            [PlanStatus.PENDING_RUBA_TID, PlanStatus.ACTIVE,PlanStatus.COMPLETE].includes(
              plan.status
            )
          ) {
            parsed.generated += plan.amount - plan.fi_cut - plan.ruba_cut;
            parsed.total_plans[0].value += 1;
          }
          // Pending revenue
          if (
            [
              PlanStatus.PENDING_RECEIPT,
              PlanStatus.PENDING_PAYMENT,
              PlanStatus.PENDING_PAYMENT_SCHOOL,
              PlanStatus.PENDING_INVOICE,
              PlanStatus.PENDING_RUBA_TID,
              PlanStatus.PENDING_RUBA_SCHOOL_TID,
            ].includes(plan.status)
          )
            parsed.pending += plan.amount - plan.fi_cut - plan.ruba_cut;
        });
        return parsed;
      },
    }
  );
  const { t } = useTranslation(["common", "schools"]);

  return (
    <>
      <Col lg="12" className="my-3">
        <p className="fw-bold">{t("stats")}</p>
      </Col>
      <Col lg={6} className="mb-3">
        <SmallCard
          loading={isFetching}
          title={t("plans:generated_revenue")}
          value={
            <p>
              {data?.generated.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </p>
          }
        />
      </Col>
      <Col lg={6} className="mb-3">
        <SmallCard
          loading={isFetching}
          title={t("plans:pending_revenue")}
          value={
            <p>
              {data?.pending.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </p>
          }
        />
      </Col>
      <Col lg={6} className="mb-3">
        <MediumPieCard
          loading={isFetching}
          title={t("plans:total_plans")}
          data={data?.total_plans}
        />
      </Col>
      <Col lg={6} className="mb-3">
        <MediumPieCard
          loading={isFetching}
          title={t("plans:students")}
          data={data?.students}
        />
      </Col>
    </>
  );
};

export default SchoolStatsWidget;
