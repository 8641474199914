import { useQuery } from "@tanstack/react-query";
import Icon from "components/widgets/Icon";
import { useField, useFormikContext } from "formik";
import { useState } from "react";
// @ts-ignore
import pdfPlaceholder from "components/assets/utils/images/forms/pdf.png";
import {
  Card,
  Form,
  FormGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import files from "utils/api/files";
import { SUPPORTED_FORMATS } from "utils/constants";
import { imageUrl } from "utils/functions/utilities";

type Props = any & {
  name: string;
  label: string;
  tooltip?: string;
  initialFileID: string;
  helperImage?: string;
};

const FileInput = ({ helperImage, initialFileID, ...props }: Props) => {
  const { label, tooltip } = props;
  const [show, setShow] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [previewImage, setPreviewImage] = useState<any>({});
  const [fetched, setFetched] = useState(false);

  useQuery(["file", initialFileID], () => files.view(initialFileID.id), {
    enabled: !!initialFileID?.id,
    onSuccess: (data) => {
      if (!fetched) {
        setPreviewImage({ backgroundImage: `url(${imageUrl(data)})` });
        setFetched(true);
      }
    },
  });

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  );

  const { t } = useTranslation(["common"]);

  function handleChange(event: any) {
    const file = event.currentTarget.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src =
          file.type === "application/pdf"
            ? pdfPlaceholder
            : (reader.result as string);
        img.onload = () => {
          setPreviewImage({
            backgroundImage: `url(${img.src})`,
            height: img.height,
          });
        };
        setShow(false);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage({});
    }

    return setFieldValue(field.name, event.currentTarget.files[0]);
  }

  return (
    <>
      <Card className="p-0">
        <Card.Header className="p-0">
          <div className="p-3 pt-0 ps-0 d-flex justify-content-between align-items-center">
            <p className="fbs-4 fw-bold">{label}</p>
            {tooltip && (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <div>
                  <Icon name="info" />
                </div>
              </OverlayTrigger>
            )}
          </div>
        </Card.Header>
        <Card.Body
          onClick={() => setShow(true)}
          style={{
            minHeight: 150,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            ...previewImage,
            height: 150,
          }}
          className="p-0 bg-light-graycool d-flex justify-content-center align-items-center"
        >
          <div
            onClick={
              initialFileID
                ? (e) => {
                    e.stopPropagation();
                    setShowPreview(true);
                  }
                : () => {}
            }
            style={{ height: 48, width: 48 }}
            className="opacity-50 bg-light rounded-circle d-flex justify-content-center align-items-center"
          >
            <Icon name="openEye" size={24} />
          </div>
        </Card.Body>
        <Card.Footer className="p-0">
          {meta.error && (
            <p className="fbs-4 fw-semibold text-danger text-center">
              {t("common:document_required")}
            </p>
          )}
          <p
            onClick={() => setShow(true)}
            className="fbs-3 p-3 text-graycool text-center fw-bold"
          >
            {initialFileID ? (
              t("change")
            ) : (
              <>
                <Icon name="upload" />
                {t("upload")}
              </>
            )}
          </p>
        </Card.Footer>
      </Card>
      <Modal show={show} size="lg" onHide={() => setShow(false)}>
        <Modal.Header className="border-0" closeButton>
          {t("common:upload")} {label}
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="">
            <Form.Label
              className="w-100 text-black"
              htmlFor="documents.personal_id"
            >
              <p className="mb-2">{label}</p>
              <div
                style={{
                  height: 150,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  //   ...previewImage,
                }}
                className="border rounded-2 d-flex flex-column justify-content-center align-items-center"
              >
                <div
                  style={{ height: 48, width: 48 }}
                  className="bg-light-graycool rounded-circle d-flex justify-content-center align-items-center"
                >
                  <div>
                    <Icon color="#7D89B0" name="upload" size={24} />
                  </div>
                </div>
                <p className="mt-2">
                  <span className="text-primary">{t("click_to_upload")} </span>
                  {/* <span className="fw-normal text-gray">
                    {t("or_drag_and_drop")}
                  </span> */}
                </p>
              </div>

              <p className="fbs-3 text-gray opacity-75 mt-3 fw-normal">
                {t("make_sure_image_clear")}
              </p>
              <p className="fbs-3 text-gray opacity-75 mt-3 fw-normal">
                {t("supported_file_types")}{" "}
                {SUPPORTED_FORMATS.map(
                  (format: string) => format.split("/")[1]
                ).join(", ")}
              </p>
              {helperImage && (
                <div className="text-center mt-3">
                  <img src={helperImage} width="80%" />
                </div>
              )}
            </Form.Label>
            <Form.Control
              {...field}
              {...props}
              accept={SUPPORTED_FORMATS.join(",")}
              onChange={handleChange}
              value={undefined}
              className="d-none"
              type="file"
              id="documents.personal_id"
            />
          </FormGroup>
        </Modal.Body>
      </Modal>
      <Modal show={showPreview} size="lg" onHide={() => setShowPreview(false)}>
        <Modal.Header className="border-0" closeButton>
          {t("common:preview")} {label}
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            ...previewImage,
            height: "50vh",
          }}
        ></Modal.Body>
      </Modal>
    </>
  );
};

export default FileInput;
