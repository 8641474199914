import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateGrade from "./Create";
import ListGrades from "./List";

const GradesRoutes = () => {
  return (
    <Routes >
      <Route path="/new" element={<CreateGrade />} />
      <Route path="/:id" element={<CreateGrade />} />
      <Route path="/" element={<ListGrades />} />
    </Routes>
  );
};

export default GradesRoutes;
