import TablePages from "components/widgets/TablePages";
import { DateTime } from "luxon";
import { Card, Col, ListGroup, Row, Table } from "react-bootstrap";
import { notifications } from "utils/api/notifications";
import { usePaginate } from "utils/hooks/paginate";
import NotificationRenderer from "../Renderers";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

const ListNotifications = (props: any) => {
  const { data } = useQuery(["notifications"], notifications.list, {
    placeholderData: []
  });
  const { t } = useTranslation(["common"]);

  return (
    <Row className="gy-2">
      <Col lg={12} className="mb-2 mb-sm-5">
        <h4 className="text-dark fw-semibold">{t("notifications")}</h4>
      </Col>
      <Col lg={12}>
        {data?.map((notification: any) => (
          <NotificationRenderer key={notification.id} notification={notification} />
        ))}
      </Col>
    </Row>
  );
};

export default ListNotifications;
