import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PlanStatus from "utils/enums/PlanStatus";
import Role from "utils/enums/Role";
import {
  isOneOfRoles,
  isRole,
  statusSpaceToUnderScore,
} from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import StatusPerUser from "./rules";

const StatusRenderer = (props) => {
  const { t } = useTranslation(["plans", "common"]);
  const { data: user } = useUser({});
  const { status, intermediated } = props;

  const [newStatus, setNewStatus] = useState(null);
  const [color, setColor] = useState("warning");

  useEffect(() => {
    if (user) {
      const computedStatus = StatusPerUser({
        role: user?.role_id ?? Role.ADMIN,
        intermediated,
      })[status];

      setNewStatus(computedStatus);

      let computedColor = "warning";
      switch (computedStatus) {
        case PlanStatus.INCOMPLETE:
        case PlanStatus.ARCHIVED:
        case PlanStatus.PENDING_DOCUMENTS:
          computedColor = "gray";
          break;
        case PlanStatus.REJECTED_FI:
        case PlanStatus.REJECTED_RUBA:
        case PlanStatus.REJECTED_SCHOOL:
        case PlanStatus.SEAT_WITHDRAWN:
        case PlanStatus.USER_WITHDRAWN:
        case "not_eligible":
          computedColor = "danger";
          break;
        case PlanStatus.ACTIVE:
        case PlanStatus.COMPLETE:
          computedColor = "success";
          break;
        case PlanStatus.PENDING_PAYMENT:
          if (isRole(Role.USER, user)) {
            computedColor = "success";
          }
          break;
        case PlanStatus.PENDING_RUBA_TID:
          if (isOneOfRoles([Role.USER, Role.SCHOOL], user)) {
            computedColor = "success";
          }
          break;
        case PlanStatus.PENDING_RUBA_SCHOOL_TID:
          if (isOneOfRoles([Role.FI, Role.USER], user)) {
            computedColor = "success";
          }
          break;
        default:
          break;
      }

      setColor(computedColor);
    }
  }, [user, status, intermediated]);

  return (
    <div className="widget-content-right">
      <div className={`badge bg-${color}`}>
        {t(statusSpaceToUnderScore(newStatus ?? status))}
      </div>
    </div>
  );
};

export default StatusRenderer;
