import { useQuery } from '@tanstack/react-query'
import Input from 'components/inputs/Input'
import Amount from 'components/widgets/Amount'
import LogoPreviewer from 'containers/common/Previewers/Logo'
import { Form, Formik } from 'formik'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { schools } from 'utils/api/schools'
import FileType from 'utils/enums/FileType'
import { arabicValue } from 'utils/functions/utilities'
import { useUser } from 'utils/hooks/auth'
import * as Yup from 'yup'

type Props = {
    preview: {
        school_id: string;
        name: string;
        description: string;
        price: number;
        quantity: number;
        custom_requirement_en?: string;
        custom_requirement_ar?: string;
        is_active: boolean | number;
    }
}

const AddonPaymentPagePreviewer = (props: Props) => {
    const { t } = useTranslation(['addons', 'common', 'schools'])
    const { preview } = props

    const { data: user } = useUser({});
    const { data: schoolData } = useQuery(
        ['schools', preview.school_id],
        schools.view(preview.school_id), {
        enabled: !!preview.school_id
    })

    if (!schoolData) return <></>

    const logo = schoolData?.files?.find((file: any) => file.type === FileType.LOGO)?.id

    async function submit(values: any) {
    }

    return (
        <>
            <Container className="my-5">

                <Row className='gy-3'>
                    <Col className='d-flex flex-column align-items-center'>
                        {logo &&
                            <div className='rounded-3 my-2 overflow-hidden shadow'>
                                <LogoPreviewer className='m-3' fileId={logo} />
                            </div>
                        }
                    </Col>
                </Row>

                {/* Addon Details */}
                <Row className="justify-content-center my-3">
                    <Col md={8}>
                        <Card>
                            <Card.Body className='p-3'>
                                <Card.Title className='text-center'>
                                    {arabicValue(schoolData, 'name')}
                                </Card.Title>
                                <Card.Text className='my-3'>
                                    <h5>{preview.name}</h5>
                                    <p>{preview.description}</p>
                                </Card.Text>
                                {/* inputs for parent name and number */}
                                <Formik
                                    initialValues={{
                                        name: '',
                                        mobile: '',
                                        custom_requirement: ''
                                    }}
                                    validationSchema={Yup.object({
                                        name: Yup.string().required('Required'),
                                        mobile: Yup.string().required('Required')
                                    })}

                                    onSubmit={submit}
                                >
                                    {({ values }) => <>
                                        <Form>
                                            <Row className='gy-3'>
                                                <Col lg={12} className='d-flex justify-content-between'>
                                                    <p className='fbs-2'>Name</p>
                                                    <p className='fbs-2'>الإسم</p>
                                                </Col>
                                                <Col lg={12}>
                                                    <Input type="text" name="name" />
                                                </Col>
                                                <Col lg={12} className='d-flex justify-content-between'>
                                                    <p className='fbs-2'>Mobile</p>
                                                    <p className='fbs-2'>الهاتف</p>
                                                </Col>
                                                <Col lg={12}>
                                                    <Input type="text" name="mobile" />
                                                </Col>
                                                {preview.custom_requirement_en &&
                                                    <>
                                                        <Col lg={12} className='d-flex justify-content-between'>
                                                            <p className='fbs-2'>
                                                                {preview.custom_requirement_en}
                                                            </p>
                                                            <p className='fbs-2'>
                                                                {preview.custom_requirement_ar}
                                                            </p>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <Input type="text" name="custom_requirement" />
                                                        </Col>
                                                    </>
                                                }
                                            </Row>


                                            <div className='text-center'>
                                                <Button type="submit" variant="primary" className='w-100 mt-3' >
                                                    <div className="d-flex justify-content-center">
                                                        <p className="mx-1">{t("addons:pay_addon")}</p> <Amount amount={preview.price ?? 0} />
                                                    </div>
                                                </Button>
                                            </div>
                                        </Form>
                                    </>}
                                </Formik>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Footer */}
                <Row className="mt-5 text-center">
                    <Col>
                        <p className='fbs-4'>Powered by Ruba&copy;</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AddonPaymentPagePreviewer