import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import { Pie, PieChart, ResponsiveContainer, Tooltip, Cell } from "recharts";
import { PieDataItem } from "utils/types/dashboard";

type Props = {
  loading: boolean;
  title: string;
  data?: PieDataItem[];
  currency?: boolean;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      className="fbs-4 fw-bold"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const MediumPieCard = (props: Props) => {
  const { loading, title, data, currency = false } = props;
  const { t } = useTranslation(["common"]);
  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "#fff",
          opacity: 0.5,
        }),
      }}
      spinner={<Loader active type="ball-pulse" />}
    >
      <Card>
        <Card.Body className="p-4">
          <Row>
            <Col
              lg={12}
              className="fs-7 d-flex justify-content-between text-graycool fw-semibold mb-3"
            >
              <p>{title}</p>
              {/* <i className="bi bi-chevron-right fs-5"></i> */}
            </Col>
          </Row>
          <Row>
            <Col lg={7} md={12} style={{ height: "170px" }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                  <Pie
                    dataKey="value"
                    data={data}
                    cx={80}
                    cy={80}
                    innerRadius={40}
                    outerRadius={80}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#82ca9d"
                  >
                    {data?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Col>
            <Col lg={5} md={12} className="d-flex align-items-center">
              <div className="d-flex align-items-center w-100">
                <ul className="w-100">
                  {data?.map((item) => (
                    <li
                      key={item.name}
                      style={{ color: item.color }}
                      className="text-capitalize fs-5"
                    >
                      <div className="">
                        <p className="fs-7 text-graycool">{item.name}</p>
                        <p className="fs-6 fw-bold text-dark">
                          {currency ? (
                            <>
                              {item.value?.toLocaleString(undefined, {
                                minimumFractionDigits: 3,
                              })}
                              <sup className="fbs-5"> {t('kd')}</sup>
                            </>
                          ) : (
                            item.value
                          )}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </LoadingOverlay>
  );
};

export default MediumPieCard;
