import React from "react";
import { Route, Routes } from "react-router-dom";
import ChatbotContacts from "./Contacts";
import ChatbotCreateQuestion from "./Create";
import ChatbotHistory from "./History";
import ChatbotQuestionsList from "./List";
import ChatbotSettings from "./Settings";

type Props = {};

const ChatbotRoutes = (props: Props) => {
  return (
    <Routes>
      <Route path="/new" element={<ChatbotCreateQuestion />} />
      <Route path="/settings" element={<ChatbotSettings />} />
      <Route path="/history" element={<ChatbotHistory />} />
      <Route path="/contacts" element={<ChatbotContacts />} />
      <Route path="/:id" element={<ChatbotCreateQuestion />} />
      <Route path="/" element={<ChatbotQuestionsList />} />
    </Routes>
  );
};

export default ChatbotRoutes;
