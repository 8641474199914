import client from "../api";

export function list(): Promise<any[]> {
  return client.get("reserves");
}

export function view(id?: any): Promise<any> {
  return client.get(`reserves/${id}`);
}

export function store(values: any): Promise<any> {
  return client.post("reserves", values);
}

export function update(id: string, values: any): Promise<any> {
  return client.put(`reserves/${id}`, values);
}

export function destroy(id: string | undefined) {
  return client.delete(`reserves/${id}`);
}

export function findByEntityId(
  id: string | undefined,
  entity: string = "user"
): Promise<any> {
  return client.get(`reserves/${entity}/${id}`);
}

export function rechargeLink(transactionIds: any[]): Promise<any> {
  return client.post(`reserves/recharge-link`, { transactionIds });
}

export function serviceFee(): Promise<string> {
  return client.get(`reserves/service-fee`);
}

function clients(id: string | undefined, params: any) {
  return client.get(`reserves/${id}/clients`, {
    params,
  });
}

function extra(id: any): Promise<any> {
  return client.get(`reserves/${id}/extra`);
}

export default {
  list,
  view,
  store,
  update,
  destroy,
  findByEntityId,
  rechargeLink,
  serviceFee,
  clients,
  extra
};
