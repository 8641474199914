import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  FormGroup,
} from "reactstrap";
import { chatbot } from "utils/api/chatbot";
import * as Yup from "yup";

const ChatbotSettings = () => {
  const navigate = useNavigate();
  const { data } = useQuery(["chatbot-settings"], chatbot.settings);
  const updateMutation = useMutation(chatbot.updateSettings);

  async function submit(values: any) {
    if (typeof values.allowed_contacts === "string")
      values.allowed_contacts = values.allowed_contacts.split(",") ?? [];
    updateMutation.mutate(values, {
      onSuccess: () => {
        navigate("/chatbot");
      },
    });
  }
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/chatbot">
            <FontAwesomeIcon icon={faUser} /> Chatbot
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Settings</BreadcrumbItem>
      </Breadcrumb>
      <TransitionGroup>
        <CSSTransition
          component="div"
          classNames="TabsAnimation"
          appear={true}
          timeout={1500}
          enter={false}
          exit={false}
        >
          <Card body>
            <Formik
              enableReinitialize
              initialValues={{
                ...(data?.value ?? {}),
              }}
              validationSchema={Yup.object().shape({})}
              onSubmit={submit}
            >
              <Form>
                <FormGroup check>
                  <Input type="checkbox" name="enabled" label="Enabled" />
                </FormGroup>
                <Input
                  type="text"
                  name="allowed_contacts"
                  label="Allowed Contacts (comma-separated)"
                />

                <Button color="primary" type="submit">
                  Save
                </Button>
              </Form>
            </Formik>
          </Card>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default ChatbotSettings;
