import { Card, Col, Row } from "react-bootstrap";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";

type Props = {
  loading: boolean;
  title: string;
  value: number | string | React.ReactNode;
};

const SmallCard = (props: Props) => {
  const { loading, title, value } = props;
  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "#fff",
          opacity: 0.5,
        }),
      }}
      spinner={<Loader active type="ball-pulse" />}
    >
      <Card>
        <Card.Body className="p-4">
          <Row>
            <Col
              lg={12}
              className="fs-7 d-flex justify-content-between text-graycool fw-semibold mb-3"
            >
              <p>{title}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="fs-3 fw-bold text-dark">
              {value}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </LoadingOverlay>
  );
};

export default SmallCard;
