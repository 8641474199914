import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import Icon from "components/widgets/Icon";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Fragment } from "react";

const CreateSchoolUsers = (props: any) => {
  const { userData } = props;
  const { t } = useTranslation(["common"]);
  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <Row>
      {values.users.map((user: any, i: number) => (
        <Fragment key={user.id}>
          <Col md="5" className="mb-3 mb-lg-5">
            <Select
              label={t("common:select_user")}
              name={`users[${i}].id`}
              options={userData?.map((i: any) => ({
                value: i.id,
                label: i.email,
              }))}
            />
          </Col>
          <Col md="5" className="mb-3 mb-lg-5">
            <Input label={t("common:title")} name={`users[${i}].title`} />
          </Col>
          <Col md="2" className="d-flex align-items-end mb-3 mb-lg-5">
            <Button
              className="w-100"
              variant="light"
              size="lg"
              onClick={() =>
                setFieldValue(
                  "users",
                  values.users.filter((item: any) => item.id != user.id)
                )
              }
            >
              <Icon name="trash" />
              {t("common:delete")}
            </Button>
          </Col>
        </Fragment>
      ))}
      <Col md="12" className="d-flex justify-content-end align-items-end">
        <Button
          className="w-100 w-sm-auto mb-3"
          variant="light"
          size="lg"
          onClick={() =>
            setFieldValue("users", [
              ...values.users,
              { id: DateTime.now(), title: "" },
            ])
          }
        >
          <i className="bi bi-plus-lg mx-3"></i>
          {t("schools:link_a_user")}
        </Button>
      </Col>
    </Row>
  );
};

export default CreateSchoolUsers;
