import * as Sentry from '@sentry/react';
import CookieConsentPopup from "components/widgets/CookieConsent";
import ChatbotRoutes from "containers/Chatbot";
import DashboardRoutes from "containers/Dashboard/routes";
import FIsRoutes from "containers/FIs/routes";
import GradesRoutes from "containers/Grades/routes";
import InstallmentsRoutes from "containers/Installments/routes";
import InterestsRoutes from "containers/Interests/routes";
import LogsRoutes from "containers/Logs/routes";
import PlansRoutes from "containers/Plans/routes";
import QuickRoutes from "containers/QuickSetup/routes";
import ReserveTransactionsRoutes from "containers/ReserveTransactions/routes";
import ReserveRoutes from "containers/Reserves/routes";
import SchoolsRoutes from "containers/Schools/routes";
import SettingsRoutes from 'containers/Settings/routes';
import StudentsRoutes from "containers/Students/routes";
import UsersRoutes from "containers/Users/routes";
import { onPageView } from "index";
import React, { useEffect } from "react";
import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoutes from "utils/routes/PrivateRoutes";
import ForgotPassword from "./containers/auth/ForgotPassword";
import Login from "./containers/auth/Login";
import Register from "./containers/auth/Register/index";
import "./utils/i18n/config";
import NotificationsRoutes from 'containers/Notifications/routes';
import AddonsRoutes from 'containers/Addons/routes';
import AddonPaymentPage from 'containers/Addons/Payment';
import AddonPaymentResultPage from 'containers/Addons/Result';

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://1115c438e140fb5c88e2fc12da0bbd45@o4505709805174784.ingest.sentry.io/4505709808123904",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],
    tracePropagationTargets: ["localhost", /^https:\/\/api\.rubapay\.com\/api/],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    debug: process.env.NODE_ENV === "development" ? true : false,
  });
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />} path="/">
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="addons/payment/result" element={<AddonPaymentResultPage />} />
      <Route path="addons/payment/:code" element={<AddonPaymentPage />} />
      <Route element={<PrivateRoutes />}>
        <Route path="/quick" element={<QuickRoutes />} />
        <Route element={<DashboardRoutes />} path="dashboard/*" />
        <Route element={<StudentsRoutes />} path="students/*" />
        <Route element={<PlansRoutes />} path="plans/*" />
        <Route element={<SchoolsRoutes />} path="schools/*" />
        <Route element={<FIsRoutes />} path="fis/*" />
        <Route element={<UsersRoutes />} path="users/*" />
        <Route element={<GradesRoutes />} path="grades/*" />
        <Route element={<InterestsRoutes />} path="interests/*" />
        <Route element={<LogsRoutes />} path="logs/*" />
        <Route element={<ChatbotRoutes />} path="chatbot/*" />
        <Route element={<InstallmentsRoutes />} path="installments/*" />
        <Route element={<NotificationsRoutes />} path="notifications/*" />
        <Route
          element={<ReserveTransactionsRoutes />}
          path="reserve-transactions/*"
        />
        <Route
          element={<ReserveRoutes />}
          path="reserves/*"
        />
        <Route element={<SettingsRoutes />} path="settings/*" />
        <Route element={<AddonsRoutes />} path="addons/*" />
        <Route element={<DashboardRoutes />} path="/" />
      </Route>
    </Route>
  )
);

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <CookieConsentPopup />
      <RouteTracking />
      <Outlet />
    </>
  );
}

const RouteTracking = () => {
  const location = useLocation();

  useEffect(() => {
    onPageView(location.pathname);
  }, [location]);

  return null;
};

export default App;
