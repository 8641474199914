import logo from "components/assets/utils/images/logo.svg";
import LanguageSwitch from "components/widgets/LanguageSwitch";
import Sidebar from "components/Layout/Sidebar";
import { useContext, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import Role from "utils/enums/Role";
import { useUser } from "utils/hooks/auth";
import { LayoutContext } from "./Context";

const Layout: React.FC = (props: any) => {
  const { children } = props;
  const { isSidebarVisible } = useContext(LayoutContext);
  const { data: user } = useUser();
  const inQuickSetup = useMatch("quick");
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<any>(null);

  const unfinishedAccount = !user?.marital_status;
  let unfinishedStudents = false;
  for (const student of user?.students ?? []) {
    if (!student?.grade_id) {
      unfinishedStudents = true;
      break;
    }
  }

  if (
    !inQuickSetup &&
    user?.role_id === Role.USER &&
    (unfinishedAccount || unfinishedStudents)
  ) {
    navigate("/quick");
  }

  useEffect(() => {
    document.documentElement.dir = user?.language === "ar" ? "rtl" : "ltr";
  }, [user?.language]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [location]);

  return (
    <>
      {isSidebarVisible ? (
        <Container fluid className="d-flex px-0 vh-100">
          <Sidebar />

          <Container fluid className="overflow-y-scroll overflow-x-hidden p-0" ref={ref}>
            <Row className="bg-light bg-opacity-10 p-3 d-flex d-sm-none flex-row justify-content-between align-items-center mb-3 mb-lg-5 border-bottom">
              <Col xs="2" className="offset-2 offset-sm-0 mx-auto mx-sm-0">
                <img src={logo} width={78} />
              </Col>
              <Col xs="2" className="d-flex justify-content-end">
                <LanguageSwitch />
              </Col>
            </Row>
            <Row className="content-container px-4 pt-4">{children}</Row>
          </Container>
        </Container>
      ) : (
        children
      )}
    </>
  );
};

export default Layout;
