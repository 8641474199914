import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateSchool from "./Create";
import ListSchools from "./List";
// import CreatePlan from "./Create";
// import ViewPlan from "./View";

const SchoolsRoutes = () => {
  return (
    <Routes >
      <Route path="/new" element={<CreateSchool />} />
      <Route path="/:id" element={<CreateSchool />} />
      <Route path="/" element={<ListSchools />} />
    </Routes>
  );
};

export default SchoolsRoutes;
