import cx from "classnames";
import passwordChangedImage from "components/assets/utils/images/auth/password-changed.svg";
import logo from "components/assets/utils/images/logo.svg";
import Input from "components/inputs/Input";
import OTPField from "components/inputs/OTP";
import PhoneInput from "components/inputs/Phone";
import LanguageSwitch from "components/widgets/LanguageSwitch";
import { Form, Formik } from "formik";
import i18n from "i18next";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { getOtp, resetPassword, verifyOtp } from "utils/api/auth";
// Layout

const ForgotPassword = () => {
  const defaultOtpTimer = 59;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [otpTimer, setOtpTimer] = useState(defaultOtpTimer);
  async function submit(values, formikBag) {
    const { stage, type, uid, password } = values;
    try {
      if (stage == 0) {
        await getOtp(uid, true, true);
        formikBag.setFieldValue("stage", 1);
      }
      if (stage == 1) {
        await verifyOtp(uid, values.otp);
        formikBag.setFieldValue("stage", 2);
      }
      if (stage == 2) {
        const res = await resetPassword({
          type,
          uid: uid.toString(),
          password,
        });
        formikBag.setFieldValue("stage", 3);
      }
      if (stage == 3) navigate("/login");
      setOtpTimer(defaultOtpTimer);
    } catch (e) {
      if (e.message) formikBag.setErrors(e.message);
      else formikBag.setErrors(e.errors);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setOtpTimer((otpTimer) => otpTimer - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const stages = [
    {
      title: t("forgot_password"),
      desc: t("use_form_below"),
      buttonTitle: t("verify"),
    },
    {
      title: t("verify_your_phone"),
      desc: t("we_sent_verification"),
      buttonTitle: t("reset_your_password"),
    },
    {
      title: t("create_your_password"),
      desc: t("choose_new_password"),
      buttonTitle: t("reset_your_password"),
    },
    {
      title: t("password_changed"),
      desc: t("welcome_back"),
      buttonTitle: t("sign_in"),
    },
  ];

  return (
    <Container
      fluid
      className="vh-100 py-4 d-flex flex-column justify-content-between"
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <img src={logo} width={78} />
          <LanguageSwitch />
        </div>
      </div>
      <Col lg="6" md="12" className="mx-auto">
        <Formik
          initialValues={{
            type: "mobile",
            uid: "",
            stage: 0,
          }}
          // validationSchema={Yup.object().shape({
          //   uid: Yup.string().required(t("mobile_email_required")),
          // })}
          onSubmit={submit}
        >
          {(props) => (
            <Form>
              <div className="d-flex justify-content-center align-items-center">
                <div
                  className={cx("w-100 w-lg-50 mx-auto", {
                    "text-center": props.values.stage == 3,
                  })}
                >
                  {props.values.stage == 3 && (
                    <div className="text-center mb-4">
                      <img src={passwordChangedImage} width={380} />
                    </div>
                  )}
                  <h3 className="text-dark fw-semibold">
                    {stages[props.values.stage].title}
                  </h3>
                  <p className="mt-1 mb-4 text-gray">
                    {stages[props.values.stage].desc}
                    {props.values.stage == 1 && (
                      <>
                        <span className="fw-bold">{props.values.uid} </span>
                        <a
                          href="#"
                          onClick={() => props.setFieldValue("stage", 0)}
                        >
                          ({t("edit")})
                        </a>
                      </>
                    )}
                  </p>
                  {typeof props.errors == "string" && (
                    <Alert color="danger">{props.errors}</Alert>
                  )}
                  {isArray(props.errors) && (
                    <Alert color="danger">
                      {props.errors.map((error) => (
                        <p className="my-0">
                          <b>{error.field}</b>: {error.message}
                        </p>
                      ))}
                    </Alert>
                  )}

                  {props.values.stage == 0 && (
                    <Row>
                      <Col md={12}>
                        <PhoneInput label={t("mobile")} name="uid" />
                        <Button
                          className="w-100 mt-4"
                          color="primary"
                          size="lg"
                          type="submit"
                        >
                          {stages[props.values.stage].buttonTitle}
                        </Button>
                        <p className="text-center mt-3">
                          {t("already_remember_password") + " "}

                          <Link to="/login" className="text-primary fw-">
                            {t("sign_in")}
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  )}
                  {props.values.stage == 1 && (
                    <>
                      <OTPField name="otp" />
                      <Button
                        className="w-100 mt-4"
                        color="primary"
                        size="lg"
                        type="submit"
                      >
                        {stages[props.values.stage].buttonTitle}
                      </Button>
                      <p className="text-center mt-4">
                        {otpTimer >= 0 ? (
                          t("resend_code", {
                            seconds: otpTimer,
                          })
                        ) : (
                          <a
                            href="#"
                            color="link"
                            className="text-decoration-none fw-semibold"
                            onClick={() => {
                              setOtpTimer(defaultOtpTimer);
                              getOtp(props.values.uid, true, true);
                            }}
                          >
                            {t("resend_otp")}
                          </a>
                        )}
                      </p>
                    </>
                  )}
                  {props.values.stage == 2 && (
                    <>
                      <Input type="password" label="Password" name="password" />
                      <Input
                        type="password"
                        label="Confirm Password"
                        name="passwordConfirmation"
                      />
                      <Button
                        className="w-100 my-3"
                        color="primary"
                        size="lg"
                        type="submit"
                      >
                        {stages[props.values.stage].buttonTitle}
                      </Button>
                    </>
                  )}
                  {props.values.stage == 3 && (
                    <Button
                      className="w-100 my-3"
                      color="primary"
                      size="lg"
                      type="submit"
                    >
                      {stages[props.values.stage].buttonTitle}
                    </Button>
                  )}
                </div>
              </div>
              <div
                className="text-center text-white opacity-8 mt-3"
                dangerouslySetInnerHTML={{ __html: t("copyright") }}
              />
            </Form>
          )}
        </Formik>
      </Col>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <a
            href={`https://rubapay.com/privacy.html`}
            target="_blank"
            className="text-gray me-3"
          >
            Privacy Policy
          </a>
          <a
            href={`https://rubapay.com/terms.html`}
            target="_blank"
            className="text-gray"
          >
            Terms of Service
          </a>
        </div>
        <div>
          <a href="https://facebook.com/findruba">
            <i className="mx-2 text-gray bi bi-facebook"></i>
          </a>
          <a href="https://twitter.com/findruba">
            <i className="mx-2 text-gray bi bi-twitter"></i>
          </a>
          <a href="https://instagram.com/findruba">
            <i className="mx-2 text-gray bi bi-instagram"></i>
          </a>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
