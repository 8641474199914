import { useMutation, useQueryClient } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import plans from "utils/api/plans";
import * as Yup from "yup";

const AdminPlanUpdateCuts = (props: { plan: any }) => {
  const { plan } = props;
  const client = useQueryClient();
  const { t } = useTranslation(["plans", "common"]);
  const [show, setShow] = useState(false);

  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateCuts(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Cuts updated");
      },
    }
  );

  function submit(values: any) {
    planMutation.mutate({ plan, values });
    setShow(false);
  }
  return (
    <>
      <Button variant="light" onClick={() => setShow(true)} size="lg" className="w-100">
        Update Cuts
      </Button>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">Update Cuts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={submit}
            initialValues={{ ruba_cut: plan.ruba_cut, fi_cut: plan.fi_cut }}
            validationSchema={Yup.object().shape({
              ruba_cut: Yup.number(),
              fi_cut: Yup.number(),
            })}
          >
            <Form>
              <Row className="gy-3">
                <Col lg={6}>
                  <Input
                    label="Ruba Cut"
                    name="ruba_cut"
                    type="number"
                    placeholder="Ruba Cut"
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    label="FI Cut"
                    name="fi_cut"
                    type="number"
                    placeholder="FI Cut"
                  />
                </Col>
                <Col lg={6}>
                  <Button
                    size="lg"
                    variant="primary"
                    type="submit"
                  >
                    Update Cuts
                  </Button>
                </Col>
              </Row>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminPlanUpdateCuts;
