import logo from "components/assets/utils/images/logo.svg";
import Input from "components/inputs/Input";
import PhoneInput from "components/inputs/Phone";
import LanguageSwitch from "components/widgets/LanguageSwitch";
import { Form, Formik } from "formik";
import i18n from "i18next";
import { isArray } from "lodash";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import users from "utils/api/users";
import { useLogin } from "utils/hooks/auth";
import { loginInitialValues, loginValidation } from "./validation";
import React, { useState, useEffect } from 'react';


// Layout

const Login = () => {
  const login = useLogin({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [devUsers, setDevUsers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    // Fetch and parse the users.md file
    fetch('/users.md')
      .then(response => response.text())
      .then(text => {
        const users = text.split('\n').map(line => {
          const [name, mobile, pass] = line.split(' ');
          return { name, mobile, pass };
        });
        setDevUsers(users);
      });
  }, []);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const selectUser = (user, props) => {
    setSelectedUser(user);
    console.log(user);
    props.setFieldValue('uid', user.mobile);
    props.setFieldValue('password', user.pass);
  };

  function navigateToDashboard() {
    navigate("/dashboard");
  }

  async function submit(values, formikBag) {
    const { setErrors } = formikBag;
    try {
      await login.mutateAsync(values);
      await users.updateLanguage(i18n.language);
      navigateToDashboard();
    } catch (e) {
      if (e.message) setErrors(e.message);
      else setErrors(e.errors);
    }
  }

  return (
    <>
      <Container
        fluid
        dir={i18n.language == "en" ? "ltr" : "rtl"}
        className="vh-100 py-4 d-flex flex-column justify-content-between"
      >
        <div className="d-flex align-items-center justify-content-between">
          <img src={logo} width={78} />
          <LanguageSwitch />
        </div>
        <div>
          <Formik
            initialValues={loginInitialValues}
            validationSchema={loginValidation}
            onSubmit={submit}
          >
            {(props) => (
              <Form>
                <Col lg={6} md="12" className="mx-auto">
                  {/* Dev Tools Dropdown */}
                  {process.env.NODE_ENV === 'development' && (
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="mb-3">
                      <DropdownToggle caret>
                        {selectedUser ? selectedUser.name : 'Select Dev User'}
                      </DropdownToggle>
                      <DropdownMenu>
                        {devUsers.map((user, index) => (
                          <DropdownItem key={index} onClick={() => selectUser(user, props)}>
                            {user.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  )}
                  <div>
                    <div>
                      <h3 className="text-dark fw-semibold">{t("welcome")}</h3>
                      <p className="text-gray my-3">{t("sign_in_for_ruba")}</p>

                      {typeof props.errors == "string" && (
                        <Alert color="danger">{props.errors}</Alert>
                      )}
                      {isArray(props.errors) && (
                        <Alert color="danger">
                          {props.errors.map((error) => (
                            <p className="my-0">
                              <b>{error.field}</b>: {error.message}
                            </p>
                          ))}
                        </Alert>
                      )}
                    </div>
                    <Row>
                      <Col md={12}>
                        <PhoneInput name="uid" label={t("mobile")} />
                      </Col>
                      <Col md={12} className="my-4">
                        <Input
                          size="lg"
                          label={t("password")}
                          type="password"
                          name="password"
                          id="examplePassword"
                          placeholder={t("password_here")}
                        />
                        <div className="text-end mt-2">
                          <Link
                            className="text-end text-gray"
                            to="/forgot-password"
                          >
                            {t("forgot_password")}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Button
                      className="w-100"
                      color="primary"
                      size="lg"
                      type="submit"
                    >
                      {t("sign_in")}
                    </Button>
                    <p className="text-center mt-4">
                      {t("no_account") + " "}

                      <Link to="/register" className="text-primary fw-">
                        {t("sign_up_now")}
                      </Link>
                    </p>
                  </div>
                </Col>
              </Form>
            )}
          </Formik>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <a
              href={`https://rubapay.com/privacy.html`}
              target="_blank"
              className="text-gray me-3"
            >
              Privacy Policy
            </a>
            <a
              href={`https://rubapay.com/terms.html`}
              target="_blank"
              className="text-gray"
            >
              Terms of Service
            </a>
          </div>
          <div>
            <a href="https://facebook.com/findruba">
              <i className="mx-2 text-gray bi bi-facebook"></i>
            </a>
            <a href="https://twitter.com/findruba">
              <i className="mx-2 text-gray bi bi-twitter"></i>
            </a>
            <a href="https://instagram.com/findruba">
              <i className="mx-2 text-gray bi bi-instagram"></i>
            </a>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Login;
