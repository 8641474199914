import { useQuery } from "@tanstack/react-query";
import MediumPieCard from "components/widgets/Dashboard/MediumPieCard";
import { Card, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import dashboard from "utils/api/dashboard";
import { graphColors } from "utils/constants";

type Props = {};

const AdminGMVWidget = (props: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject = Object.fromEntries(queryParams.entries());

  const { data, isFetching } = useQuery(
    ["dashboard", "admin-gmv", queryParamsObject],
    () => dashboard.adminGMV(queryParamsObject),
    {
      placeholderData: [],
    }
  );

  const { t } = useTranslation(["plans"]);
  return (
    <>
      <Col lg="12" className="mb-3">
        <p className="fw-bold">{t("common:monthly_plans")}</p>
      </Col>
      <Col lg={6} className="mb-3" >
        <Card className="h-100">
          <Card.Body style={{ height: "170px" }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart barCategoryGap={2} data={data?.monthly_plans}>
                <XAxis fontSize={12} dataKey="namedPeriod" />
                <Tooltip />
                <Bar dataKey="total" fill="#1570EF" />
              </BarChart>
            </ResponsiveContainer>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6} className="mb-3">
        <MediumPieCard
          loading={isFetching}
          title={t("plans:total_plans")}
          currency
          data={data.groups?.map((item: any, index: number) => ({
            name: item.name,
            value: +item.value,
            color: graphColors[index]
          }))}
        />
      </Col>
    </>
  );
};

export default AdminGMVWidget;
