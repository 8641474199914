import { useQuery } from '@tanstack/react-query';
import Amount from 'components/widgets/Amount';
import ReserveTransactionStatusRenderer from 'components/widgets/ReserveTransactionStatusRenderer';
import { Card, Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { reserveTransactions } from 'utils/api/reserveTransactions';
import { ReserveTransactionStatus } from 'utils/enums/ReserveTransactionStatus';
import { dateAndTime } from 'utils/functions/utilities';

type Props = {}

const ClientsTransfers = (props: Props) => {
    const { t } = useTranslation(["common", "plans", "schools"]);
    const { id } = useParams<{ id: string }>();
    const { data } = useQuery(["reserve-transactions-transfers", {
        id: id
    }], () => reserveTransactions.transferByReserveId(id!), {
        enabled: !!id,
        placeholderData: []
    })

    return (
        <Row className='gy-4'>
            <Col lg={12}>
                <Card className="main-card mb-3" >
                    <Card.Header>
                        <h6 className="text-dark fs-6 fw-bold m-0">{t("transfers")}</h6>
                    </Card.Header>
                    <Card.Body>

                        <Table className="mb-0">
                            <thead>
                                <tr>
                                    <th>{t("scheduled_date")}</th>
                                    <th>{t("plans:amount")}</th>
                                    <th>{t("discounted")}</th>
                                    {/* settled */}
                                    <th>{t("settled")}</th>
                                    {/* <th>{t("clients")}</th> */}
                                    {/* <th>{t("plans:transactions")}</th> */}
                                    <th>{t("plans:status")}</th>
                                    {/* <th>{t("plans:actions")}</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.map((trx: any) => (
                                        <tr key={trx.id}>
                                            <td>{dateAndTime(trx.scheduled_date, true)}</td>
                                            <td><Amount amount={trx.amount} /></td>
                                            <td><Amount amount={trx.amount_after_discount} /></td>
                                            <td><Amount amount={trx.paid} /></td>
                                            {/* <td>{trx.clients}</td> */}
                                            {/* <td>{trx.transactions}</td> */}
                                            <td>
                                                {trx.amount_after_discount === trx.paid ?
                                                    <div className="widget-content-right">
                                                        <div className={`badge bg-gray`}>{t('settled')}</div>
                                                    </div> :
                                                    trx.paid == 0 ? <div className="widget-content-right">
                                                        <div className={`badge bg-gray`}>{t('not_settled')}</div>
                                                    </div> :
                                                        <div className="widget-content-right">
                                                            <div className={`badge bg-gray`}>{t('partially_settled')}</div>
                                                        </div>}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card >
            </Col>
        </Row >
    )
}

export default ClientsTransfers