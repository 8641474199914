import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import planSubmittedImage from "components/assets/utils/images/plan/plan_submitted.svg";
import AccountForm from "components/forms/Account";
import Checkbox from "components/inputs/Checkbox";
import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { countries } from "utils/api/countries";
import plans from "utils/api/plans";
import { arabicValue, gradeLabel } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import * as Yup from "yup";
import TagManager from "react-gtm-module";
import Amount from "components/widgets/Amount";

type Props = {
  previousStep: (skipStep?: boolean) => void;
  nextStep: () => void;
};

const PlanConfirmation = (props: Props) => {
  const { previousStep } = props;
  const client = useQueryClient();
  const { id } = useParams();
  const { data: user } = useUser();
  const { t } = useTranslation(["common", "plans"]);
  const { data: countriesData } = useQuery(["countries"], countries.list);
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSubmitted, setShowSubmitted] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const updatePlan = useMutation((values) => plans.update(id, values), {
    onSuccess: (data: any) => client.invalidateQueries(["plans", id]),
  });

  const { data: plan } = useQuery(["plans", id], () => plans.view(id), {
    initialData: {
      user: {},
      marital_status: "",
      employment_type: "",
    },
  });

  async function submit(values: any, formikBag: FormikHelpers<any>) {
    try {
      if (!values.terms) {
        setShowConfirmation(true);
        return;
      }
      await updatePlan.mutateAsync(values);
      TagManager.dataLayer({
        dataLayer: {
          event: "submit_complete_plan",
        },
      });
      setShowSubmitted(true);
    } catch (e) { }
  }

  const attributes = [
    { label: t("legal_name"), accessor: (val: any) => val.user.name },
    { label: t("email"), accessor: (val: any) => val.user.email },
    { label: t("mobile"), accessor: (val: any) => val.user.mobile },
    {
      label: t("plans:marital_status"),
      accessor: (val: any) => t(val.marital_status.toLowerCase()),
    },
    {
      label: t("plans:nationality"),
      accessor: (val: any) =>
        countriesData?.find((country: any) => country.id == val.nationality_id)
          ?.name,
    },
    { label: t("plans:company"), accessor: (val: any) => val.company },
    {
      label: t("plans:employment_type"),
      accessor: (val: any) => t(`plans:${val.employment_type.toLowerCase()}`),
    },
    { label: t("plans:salary"), accessor: (val: any) => val.salary },
    {
      label: t("common:year"),
      accessor: (val: any) => val.current_year,
    },
    {
      label: t("plans:monthly_loans"),
      accessor: (val: any) => val.monthly_loans,
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        uploading: false,
        terms: false,
        terms2: false,
        terms3: plan.paynow ? true : false,
        ...plan,
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={submit}
    >
      {(props) => (
        <>
          <Modal
            size="lg"
            show={editUserModal}
            onHide={() => setEditUserModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("plans:your_information")} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AccountForm
                callback={async (val: any) => updatePlan.mutateAsync(val)}
                nextStep={() => {
                  setEditUserModal(false);
                }}
              />
            </Modal.Body>
          </Modal>
          <Submitted
            show={showSubmitted}
            hide={() => navigate("/plans")}
            user={user}
          />
          <Confirmation
            user={user}
            formikBag={props}
            show={showConfirmation}
            hide={() => setShowConfirmation(false)}
          />
          <Col lg={8}>
            <Row>
              <Col lg={12} className="d-flex justify-content-between pe-0 mb-3">
                <h5 className="text-dark fw-bold">
                  {t("plans:your_information")}
                </h5>
                <Button
                  type="button"
                  onClick={() => setEditUserModal(true)}
                  className="px-3"
                  size="sm"
                  variant="outline-light"
                >
                  {t("edit")}
                </Button>
              </Col>
              <Col
                lg={12}
                className="p-3 bg-light-blue border border-primary rounded-2"
              >
                <Row>
                  {attributes.map((attribute) => (
                    <Col key={attribute.label} lg={4} className="mb-4">
                      <p className="mb-2 fw-semibold text-gray fs-7">
                        {attribute.label}
                      </p>
                      <p className="fw-semibold text-dark-graycool fs-7">
                        {attribute.accessor(plan)}
                      </p>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={12}>
                <hr className="bg-light my-4" style={{ height: 1 }} />
              </Col>
              <Col lg={12}>
                <h5 className="text-dark fw-bold">
                  {t("plans:selected_students")}
                </h5>
              </Col>
              {plan.students?.map((student: any) => (
                <Col key={student.id} lg={6} className="p-1">
                  <div className="p-3 bg-light-blue border border-primary rounded-2">
                    <p className="mb-2 fw-bold text-dark fs-7">
                      {student.name}
                    </p>
                    <p className="fw-semibold text-gray fbs-4 mb-3">
                      {arabicValue(student.school, 'name')} - {gradeLabel(student.grade)}
                    </p>
                    <span className="px-2 py-1 mt-2 text-primary fbs-4 border rounded-2 border-primary">
                      {plan.current_year}
                    </span>
                  </div>
                </Col>
              ))}
              {/* {plan.current_year == 0 && (
                <Col lg={12} className="px-0 mt-3">
                  <Alert variant="warning" className="fw-semibold">
                    {t("plans:apply_for_future_year")}
                  </Alert>
                </Col>
              )} */}
              <Col lg={12}>
                <hr className="bg-light my-4" style={{ height: 1 }} />
              </Col>
              <Col lg={12}>
                <Row className="justify-content-between mb-5">
                  <Col
                    lg={5}
                    className="text-dark d-flex align-items-center fw-bold"
                  >
                    {t("plans:selected_payment_plan")}
                  </Col>
                  <Col lg={5}>
                    <div className="p-3 text-dark bg-light-blue border border-primary rounded-2">
                      {plan.paynow ?
                        <>
                          <h6 className="fw-bold fs-7 text-capitalize">
                            {t("plans:pay_as_per_school_schedule")}
                          </h6>
                          <Amount amount={plan.amount} />
                        </>
                        :
                        <>
                          <h6 className="fw-bold fs-7 text-capitalize">
                            {plan?.number_of_installments} {t("plans:payments")}
                          </h6>
                          <p className="fw-semibold">
                            {plan?.monthly_payment} {t("kd")}
                            <span className="text-gray fbs-4">
                              {" "}
                              {t("every_month")}
                            </span>
                          </p>
                        </>
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg={12} className="d-flex justify-content-between">
            <Button onClick={() => previousStep(props.values.paynow)} variant="light" size="lg">
              {user?.language == "ar" ? (
                <i className="bi bi-arrow-right ms-3"></i>
              ) : (
                <i className="bi bi-arrow-left me-3"></i>
              )}
              {t("common:back")}
            </Button>
            <Button
              disabled={props.values.uploading}
              onClick={props.submitForm}
              size="lg"
            >
              {t("common:next")}
              {user?.language == "ar" ? (
                <i className="bi bi-arrow-left me-3"></i>
              ) : (
                <i className="bi bi-arrow-right ms-3"></i>
              )}
            </Button>
          </Col>
        </>
      )}
    </Formik>
  );
};

function Submitted(props: any) {
  const { show, hide, user } = props;
  const { t } = useTranslation(["plans", "common"]);

  return (
    <Modal show={show} size="lg" onHide={hide}>
      <Modal.Body>
        <Container>
          <Row>
            <Col lg={12} className="my-2 text-center">
              <img src={planSubmittedImage} />
              <h4 className="text-dark fw-semibold mt-4">
                {t("plan_submitted_successfully")}
              </h4>
              <p className="text-gray">{t("thank_you_using_rubapay")}</p>
            </Col>
            <Col lg={12} className="my-2">
              <Button onClick={hide} size="lg" className="w-100 py-3">
                {t("view_my_plans")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

function Confirmation(props: any) {
  const { formikBag, percentage, user, show, hide } = props;
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation(["plans", "common"]);

  function checkTerms() {
    if (
      formikBag.values.terms &&
      formikBag.values.terms2 &&
      formikBag.values.terms3
    ) {
      hide();
      formikBag.setFieldValue("terms", true);
      formikBag.submitForm();
    } else {
      setError(t("please_accept_terms"));
    }
  }
  return (
    <Modal show={show} size="lg" onHide={hide}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>{t("common:user_consent")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            {error && (
              <Col lg={12} className="my-2">
                <Alert variant="danger">{error}</Alert>
              </Col>
            )}
            <Col lg={12} className="my-2">
              <Checkbox
                name="terms"
                label={
                  <Trans i18nKey="plans:accept_our_terms">
                    By signing up, you agree to our{" "}
                    <a
                      className="text-dark fw-bold"
                      href={`https://rubapay.com/privacy`}
                      target="_blank"
                    >
                      Privacy Policy{" "}
                    </a>{" "}
                    and{" "}
                    <a
                      className="text-dark fw-bold"
                      href={`https://rubapay.com/terms`}
                      target="_blank"
                    >
                      Terms of Use{" "}
                    </a>
                  </Trans>
                }
              />
              <Checkbox name="terms2" label={t("plans:willing_to_submit")} />

              {!formikBag.values.paynow && (
                <Checkbox
                  name="terms3"
                  label={t("plans:approval_processing_fees")}
                />
              )}
            </Col>
            <Col lg={12} className="my-2">
              <Button onClick={checkTerms} size="lg" className="w-100 py-3">
                {t("common:submit")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default PlanConfirmation;
