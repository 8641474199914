import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateReserveTransactions from "./Create";
// import PlansList from "./List";
// import CreatePlan from "./Create";
// import NotListedSchools from "./NotListedSchools";
// import ViewPlan from "./View";

const ReserveTransactionsRoutes = () => {
  return (
    <Routes >
      {/* <Route path="/" element={<PlansList />} /> */}
      <Route path="/:id/scheduler" element={<CreateReserveTransactions />} />
      {/* <Route path="/not-listed" element={<NotListedSchools />} /> */}
      {/* <Route path="/:id/edit" element={<CreatePlan />} /> */}
      {/* <Route path="/:id" element={<ViewPlan />} /> */}
    </Routes>
  );
};

export default ReserveTransactionsRoutes;
