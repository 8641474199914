import { useQuery } from "@tanstack/react-query";
import MediumPieCard from "components/widgets/Dashboard/MediumPieCard";
import { Card, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import dashboard from "utils/api/dashboard";
import { graphColors } from "utils/constants";

type Props = {};

const AdminTotalReserve = (props: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject = Object.fromEntries(queryParams.entries());

  const { data, isFetching } = useQuery(
    ["dashboard", "admin-total-reserve", queryParamsObject],
    () => dashboard.adminTotalReserve(queryParamsObject),
    {
      placeholderData: [],
    }
  );

  const { t } = useTranslation(["common"]);
  return (
    <>
      <Col lg={6} className="mb-3">
        <MediumPieCard
          loading={isFetching}
          title={t("common:reserves")}
          currency
          data={data.groups?.map((item: any, index: number) => ({
            name: item.reserve_status,
            value: +item.amount,
            color: graphColors[index]
          }))}
        />
      </Col>
    </>
  );
};

export default AdminTotalReserve;
