import ReserveStatusRenderer from 'components/widgets/ReserveStatusRenderer';
import StatusRenderer from 'components/widgets/StatusRenderer';
import React, { useEffect, useState } from 'react';
import PlanStatus from 'utils/enums/PlanStatus';
import Role from 'utils/enums/Role';
import { isOneOfRoles, isRole } from 'utils/functions/utilities';
import { useUser } from 'utils/hooks/auth';

type Props = {
  plan: any;
};

const PlanAndReserveStatuses = (props: Props) => {
  const { data: user } = useUser({});
  const plan = props.plan;

  const [shouldHidePlanStatus, setShouldHidePlanStatus] = useState(false);
  const [shouldHideReserveStatus, setShouldHideReserveStatus] = useState(false);

  useEffect(() => {
    if (plan && user) {
      let hidePlanStatus = false;
      let hideReserveStatus = false;

      if (plan.school.reserve_enabled && !isOneOfRoles([Role.ADMIN, Role.FI], user) && plan.reserve_status) {
        hidePlanStatus = [PlanStatus.REJECTED_FI, PlanStatus.REJECTED_RUBA, PlanStatus.USER_WITHDRAWN].includes(plan.status);
      }

      if (isRole(Role.FI, user)) {
        hideReserveStatus = true;
      }

      setShouldHidePlanStatus(hidePlanStatus);
      setShouldHideReserveStatus(hideReserveStatus);
    }
  }, [plan, user]);

  return (
    <>
      {!shouldHidePlanStatus && <StatusRenderer status={plan.status} intermediated={plan.fi?.intermediated} />}
      {!shouldHideReserveStatus && <ReserveStatusRenderer status={plan.reserve_status} />}
    </>
  );
};

export default PlanAndReserveStatuses;
