import { capitalize } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import users from "utils/api/users";
import Role from "utils/enums/Role";
import { usePaginate } from "utils/hooks/paginate";
import TablePages from "components/widgets/TablePages";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const ListUsers = (props: any) => {
  const [filter, setFilter] = useState({});
  const paginate = usePaginate({
    key: "users",
    api: users.list,
    filter
  });

  const { t } = useTranslation(["common"]);

  function submit(values: any) {
    const newFilters: any = {};
    if (values.search) newFilters["search"] = values.search;
    setFilter(newFilters);
  }
  return (
    <Row>
      <Col lg="12">
        <Card className="main-card mb-3">
          <Card.Body>
            <Card.Title>
              <Formik
                enableReinitialize
                initialValues={{
                  search: "",
                }}
                onSubmit={submit}
              >
                <Form>
                  <Row>
                    <Col lg="8" className="">
                      <i
                        style={{ zIndex: 1 }}
                        className="position-absolute mt-2 bi bi-search fs-5 text-black-50 ms-3 py-2"
                      ></i>
                      <Input
                        className="ps-5 mt-lg-0"
                        name="search"
                        placeholder={t("common:search")}
                      />
                    </Col>
                    <Col lg="2">
                      <Button type="submit" color="primary" className="h-100">
                        Search
                      </Button>
                    </Col>
                    <Col lg="2" className="d-flex justify-content-end">
                      <Link to="/users/new">
                        <Button color="primary" className="h-100">
                          Create New User!
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Form>
              </Formik>
            </Card.Title>
            <Table striped className="mb-0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginate?.data?.data?.map((item: any) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.mobile}</td>
                    <td>{item.email}</td>
                    <td>{capitalize(Role[item.role_id])}</td>
                    <td>
                      <Link to={"/users/" + item.id}>
                        <Button size="sm" color="secondary">
                          Edit
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TablePages {...paginate} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ListUsers;
