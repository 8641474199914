import * as Yup from "yup";
import i18n from "utils/i18n/config";

export const registerValidation = Yup.object().shape({
  mobile: Yup.string().required(),
  otp: Yup.string()
    .length(4)
    .when("stage", {
      is: (stage) => stage === 1,
      then: (schema) => schema.required(i18n.t("otp_required")),
    }),
  password: Yup.string()
    .min(8, i18n.t("common:minimum_length_field", { minLength: 8 }))
    .when("stage", {
      is: (stage) => stage === 2,
      then: (schema) => schema.required(i18n.t("password_required")),
    }),
  passwordConfirmation: Yup.string()
    .when("stage", {
      is: (stage) => stage === 2,
      then: (schema) => schema.required(i18n.t("password_required")),
    })
    .oneOf([Yup.ref("password")], i18n.t("passwords_must_match")),
});

export const registerInitialValues = {
  mobile: "",
  otp: "",
  password: "",
  gender: 1,
  stage: 0,
};
