import { useMutation, useQueryClient } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import Amount from "components/widgets/Amount";
import { Form, Formik, useFormikContext } from "formik";
import { DateTime } from "luxon";
import { Fragment } from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import reserves from "utils/api/reserves";
import { ReserveCategory } from "utils/enums/ReserveCategory";

type Props = {};

const CreateReserves = (props: Props) => {
    const { t } = useTranslation(["schools", "common"]);
    const { values, setFieldValue } = useFormikContext<any>();
    const client = useQueryClient();

    function newDate() {
        let dates = values.payment_dates || [];
        setFieldValue("payment_dates", [...dates, { percentage: 0, date: DateTime.now() }]);
    }

    const reserveUpdateMutation = useMutation(
        (data: any) => {
            return reserves.update(data.id, data);
        },
        {
            onSuccess: () => {
                client.invalidateQueries(["schools"]);
            },
        }
    );

    const reserveStoreMutation = useMutation(
        (data: any) => {
            return reserves.store(data);
        },
        {
            onSuccess: () => {
                client.invalidateQueries(["schools"]);
            },
        }
    );

    return (
        <Card>
            <Card.Header>
                <Card.Title className="fs-6 fw-bold">
                    {t("common:reserves")}
                    {values.payment_dates?.reduce((total: number, paymentDate: any) => total + paymentDate.percentage, 0) > 100 && (
                        <div className={`badge bg-danger`}>
                            {t('common:exceeded_percentage')}
                        </div>
                    )}
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Row className="gy-3 align-items-center">
                    {values?.reserves?.map((reserve: any, index: number) => (
                        <Fragment key={index}>
                            <Col md="4" className="fw-semibold" >
                                RRN
                            </Col>
                            <Col md="4" className="fw-semibold" >
                                Balance
                            </Col>
                            <Col md="4" className="fw-semibold" >
                                Type
                            </Col>
                            <Col md="4" >
                                <Link to={`/reserves/${reserve.id}`}>
                                    {reserve.reserve_number}
                                </Link>
                            </Col>
                            <Col md="4" >
                                <Amount amount={reserve.balance} />
                            </Col>
                            <Col md="4" >
                                <Select
                                    onChange={(e) => {
                                        const params = {
                                            id: reserve.id,
                                            category: e.target.value
                                        }
                                        reserveUpdateMutation.mutate(params);
                                    }
                                    }
                                    name={`reserves[${index}].category`}
                                    options={[
                                        { label: "reserve", value: ReserveCategory.RESERVE },
                                        { label: "paynow", value: ReserveCategory.PAYNOW },
                                        { label: "collection", value: ReserveCategory.COLLECTION },
                                    ]}
                                />
                            </Col>
                        </Fragment>
                    ))}
                    <Col md="12" className="d-flex justify-content-end mt-4">
                        {/* <Button
                            className="mx-3 w-sm-auto mb-3"
                            variant="light"
                            size="lg"
                            type="submit"
                        >
                            {t("common:save_changes")}
                        </Button> */}
                        <Button
                            className="w-sm-auto mb-3"
                            variant="light"
                            size="lg"
                            onClick={()=>{
                                reserveStoreMutation.mutate({
                                    school_id: values.id,
                                    type: "school",
                                    category: ReserveCategory.RESERVE
                                });
                            }}
                        >
                            <i className="bi bi-plus-lg mx-3"></i>
                            {t("common:add_reserve")}
                        </Button>
                    </Col>
                </Row>
            </Card.Body >
        </Card >
    );
};

export default CreateReserves;
