import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateUser from "./Create";
import ListUsers from "./List";
import ProfileUser from "./Profile";

const UsersRoutes = () => {
  return (
    <Routes >
      <Route path="/profile" element={<ProfileUser />} />
      <Route path="/new" element={<CreateUser />} />
      <Route path="/:id" element={<CreateUser />} />
      <Route path="/" element={<ListUsers />} />
    </Routes>
  );
};

export default UsersRoutes;
