import AccountForm from "components/forms/Account";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {};

const ProfileUser = (props: Props) => {
  function nextStep() {}
  return (
    <Container>
      <Row>
        <AccountForm hideSubmit uploads nextStep={nextStep} Header={Header} />
      </Row>
    </Container>
  );
};

function Header(props: any) {
  const { t } = useTranslation(["common"]);
  return (
    <Row>
      <Col lg={9} className="d-flex justify-content-between mb-2 mb-lg-5">
        <div>
          <h5 className="text-dark fw-bold">{t("profile")}</h5>
          <p className="text-gray">{t("edit_your_profile")}</p>
        </div>
      </Col>
      <Col lg={3}>
        <Button type="submit" size="lg" className="mb-3 w-100">
          {t("save_changes")}
        </Button>
      </Col>
    </Row>
  );
}

export default ProfileUser;
