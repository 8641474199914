import { useQuery } from "@tanstack/react-query";
import MediumLineCard from "components/widgets/Dashboard/MediumLineCard";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import dashboard from "utils/api/dashboard";

type Props = {};

const AdminReserveStatsWidget = (props: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject = Object.fromEntries(queryParams.entries());

  const { t } = useTranslation(["common"]);
  const { data, isFetching } = useQuery(
    ["dashboard", {
      endpoint: "adminReserveStats",
      ...queryParamsObject,
    }],
    () => dashboard.adminReserveStats(queryParamsObject),


  );

  return (
    <>
      <Col lg="12" className="mb-3">
        <p className="fw-bold">{t("reserve_stats")}</p>
      </Col>
      <Col lg={4} className="mb-3">
        <MediumLineCard
          loading={isFetching}
          title={t("plans:generated_revenue")}
          // subTitle={t("common:last_x_months", { months: 12 })}
          difference={data?.rubaRevenue?.difference}
          data={data?.rubaRevenue?.data}
          text={
            <>
              {data?.rubaRevenue?.amount.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </>
          }
        />
      </Col>
      <Col lg={4} className="mb-3">
        <MediumLineCard
          loading={isFetching}
          title={t("school_revenue")}
          // subTitle={t("common:last_x_months", { months: 12 })}
          difference={data?.schoolRevenue?.difference}
          data={data?.schoolRevenue?.data}
          text={
            <>
              {data?.schoolRevenue?.amount.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </>
          }
        />
      </Col>
      <Col lg={4} className="mb-3">
        <MediumLineCard
          loading={isFetching}
          title={t("unsettled_revenue")}
          // subTitle={t("common:last_x_months", { months: 12 })}
          difference={data?.unsettledAmount?.difference}
          data={data?.unsettledAmount?.data}
          text={
            <>
              {data?.unsettledAmount?.amount.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </>
          }
        />
      </Col>
    </>
  );
};

export default AdminReserveStatsWidget;
