import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import plans from "utils/api/plans";
import * as Yup from "yup";
import { useState } from "react";
import { Button, Col, Modal, ModalBody, Row } from "react-bootstrap";

const PaymentDuer = (props: any) => {
  const { plan } = props;
  const { t } = useTranslation(["plans", "auth", "common"]);
  const client = useQueryClient();
  const [show, setShow] = useState(false);

  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updatePaymentDue(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  async function submit(values: any) {
    planMutation.mutate({ plan, values: values });
  }

  return (
    <>
      <Button
        variant="light"
        onClick={() => setShow(true)}
        size="lg"
        className="w-100"
      >
        Payment Due
      </Button>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <Modal.Title className="fs-6">Payment Due</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={submit}
            initialValues={{
              payment_due: plan.payment_due,
            }}
            validationSchema={Yup.object().shape({
              paymendDue: Yup.string(),
            })}
          >
            <Form>
              <Row>
                <Col lg={12}>
                  <Input name="payment_due" label="Payment Due" type="date" />
                </Col>
                <Col lg={12}>
                  <Button type="submit" size="lg" className="mt-4 w-100" color="primary">
                    Payment Due
                  </Button>
                </Col>
              </Row>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentDuer;