import { useQuery } from "@tanstack/react-query";
import SmallCard from "components/widgets/Dashboard/SmallCard";
import { DateTime } from "luxon";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import plans from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";

type Props = {};

const FIPendingActionsWidget = (props: Props) => {
  const { data, isFetching } = useQuery(["plans"], () => plans.list({}), {
    select: (data) => {
      const parsed = {
        new_pending: 0,
        signature: 0,
        approval: 0,
        transfers: 0,
        transactions: 0,
      };
      data?.data?.forEach((plan: any) => {
        if (
          [
            PlanStatus.INCOMPLETE,
            PlanStatus.PENDING_DOCUMENTS,
            PlanStatus.PENDING_RUBA,
          ].includes(plan.status)
        ) {
          if (DateTime.fromISO(plan.created_at).diffNow("weeks").weeks >= -1)
            parsed.new_pending += 1;
        }
        if (plan.status === PlanStatus.PENDING_SIGNATURE) parsed.signature += 1;
        if (plan.status === PlanStatus.PENDING_FI) parsed.approval += 1;
        if (plan.status === PlanStatus.PENDING_PAYMENT)
          parsed.transfers += plan.amount - plan.fi_cut - plan.ruba_cut;
        if (plan.status === PlanStatus.PENDING_RUBA_TID)
          parsed.transactions += plan.ruba_cut;
      });
      return parsed;
    },
  });
  const { t } = useTranslation(["plans"]);
  return (
    <>
      <Col lg="12" className="mb-3">
        <p className="fw-bold">{t("pending_actions")}</p>
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("new_plans")}
          value={data?.new_pending}
        />
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("pending_signature")}
          value={data?.signature}
        />
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("pending_approval")}
          value={data?.approval}
        />
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("pending_transfers")}
          value={
            <p>
              {data?.transfers?.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </p>
          }
        />
      </Col>
      <Col sm="12" lg="auto" className="mb-3 flex-grow-1">
        <SmallCard
          loading={isFetching}
          title={t("pending_transactions")}
          value={
            <p>
              {data?.transactions?.toLocaleString()}
              <sup className="fs-5"> KD</sup>
            </p>
          }
        />
      </Col>
    </>
  );
};

export default FIPendingActionsWidget;
