import { Col, Row } from "react-bootstrap";
import OnboardedSchoolsWidget from "../Widgets/OnboardedSchools";
import TopSchoolsWidget from "../Widgets/TopSchools";
import WelcomeWidget from "../Widgets/Welcome";
import AdminPendingActionsWidget from "../Widgets/AdminPendingActions";
import AdminStatsWidget from "../Widgets/AdminStats";
import AdminTotal from "../Widgets/AdminTotal";
import AdminGMVWidget from "../Widgets/AdminGMV";
import AdminReserveStatsWidget from "../Widgets/AdminReserveStats";
import AdminServiceFeeStatsWidget from "../Widgets/AdminServiceFeeStats";
import AdminStudentStatsWidget from "../Widgets/AdminStudentStats";
import AdminTotalReserve from "../Widgets/AdminTotalReserve";
import AdminBranchesByType from "../Widgets/AdminBranchesByType";

const AdminWidgets = () => {
  return (
    <>
      <WelcomeWidget />
      <AdminPendingActionsWidget />
      <AdminGMVWidget />
      <AdminStudentStatsWidget />
      <AdminTotalReserve />
      <AdminServiceFeeStatsWidget />
      <AdminStatsWidget />
      <AdminReserveStatsWidget />
      <AdminTotal />
      <TopSchoolsWidget />
      <AdminBranchesByType />
      {/* <OnboardedSchoolsWidget /> */}
    </>
  );
};

export default AdminWidgets;
