import Input from "components/inputs/Input";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "react-bootstrap";

const CreateSchoolBranches = () => {
  const { t } = useTranslation(["schools", "common"]);
  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title className="fs-6 fw-bold">{t("branches")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {values.branches?.map((branch: any, i: number) => (
          <Row key={i} className="mb-3 mb-lg-5">
            {branch.id && <Input type="hidden" name={`branches[${i}].id`} />}
            <Col md="5" className="mb-3">
              <Input label={t("common:name")} name={`branches[${i}].name`} />
            </Col>
            <Col md="5" className="mb-3">
              <Input
                label={t("common:arabic_name")}
                name={`branches[${i}].name_ar`}
              />
            </Col>
            <Col md="2" className="d-flex align-items-end mb-3">
              <Button
                className="w-100"
                variant="light"
                size="lg"
                onClick={() =>
                  setFieldValue(
                    "branches",
                    values.branches.filter((item: any) => item.id != branch.id)
                  )
                }
              >
                <i className="bi bi-trash3 mx-1"></i>
                {t("common:delete")}
              </Button>
            </Col>
          </Row>
        ))}
        <Row>
          <Col md="12" className="d-flex justify-content-end">
            <Button
              className="w-100 w-sm-auto mb-3"
              variant="light"
              size="lg"
              onClick={() =>
                setFieldValue("branches", [
                  ...values.branches,
                  { id: DateTime.now().toMillis(), name: "" },
                ])
              }
            >
              <i className="bi bi-plus-lg mx-3"></i>
              {t("add_a_branch")}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreateSchoolBranches;
