import { useMutation, useQuery } from '@tanstack/react-query'
import Input from 'components/inputs/Input'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import reserves from 'utils/api/reserves'

type Props = {
  vendor: string
  show: boolean
  setShow: (value: boolean) => void
}

const VendorExtraModal = (props: Props) => {
  const { vendor, show, setShow } = props;
  const { id } = useParams();

  const { data: extra, isLoading } = useQuery(["reserve", "extra", id], () =>
    reserves.extra(id),
  );
  const update = useMutation((values: any) => reserves.update(id!, values));

  async function submit(extras: {
    username: any,
    password: any,
    merchant_code: any;
    access_code: any;
    secret_key: any;
    iv_key: any;
  }) {
    await update.mutateAsync({ extras });
  }

  return (
    <Modal
      centered
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={() => setShow(false)}
      size="lg"
    >
      <Modal.Header className="d-flex justify-content-between" closeButton>
        <Modal.Title className="fs-6">Vendor Extra Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          onSubmit={submit}
          initialValues={{
            username: extra?.username || '',
            password: extra?.password || '',
            merchant_code: extra?.merchant_code || '',
            access_code: extra?.access_code || '',
            secret_key: extra?.secret_key || '',
            iv_key: extra?.iv_key || ''
          }}
        >
          <Form>
            <Row className='gy-4'>
              <Col md="6">
                <Input label="Username" name="username" />
              </Col>
              <Col md="6">
                <Input label="Password" name="password" />
              </Col>
              <Col md="6">
                <Input label="Merchant code" name="merchant_code" />
              </Col>
              <Col md="6">
                <Input label="Access code" name="access_code" />
              </Col>
              <Col md="6">
                <Input label="Secret key" name="secret_key" />
              </Col>
              <Col md="6">
                <Input label="IV key" name="iv_key" />
              </Col>
              <Col md="12" className='d-flex justify-content-end'>
                <Button type="submit">Save</Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default VendorExtraModal