import { useQuery } from "@tanstack/react-query";
import SmallCard from "components/widgets/Dashboard/SmallCard";
import { DateTime } from "luxon";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import plans from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";

type Props = {};

const SchoolPendingActionsWidget = (props: Props) => {
  const { data, isFetching } = useQuery(["plans"], ()=>plans.list({}), {
    select: (data) => {
      const parsed = {
        new_pending: 0,
        quotation: 0,
        invoice: 0,
        receipt: 0,
      };
      data?.data?.forEach((plan: any) => {
        if (
          [
            PlanStatus.INCOMPLETE,
            PlanStatus.PENDING_DOCUMENTS,
            PlanStatus.PENDING_RUBA,
          ].includes(plan.status)
        ) {
          if (DateTime.fromISO(plan.created_at).diffNow("weeks").weeks >= -1)
            parsed.new_pending += 1;
        }
        if (plan.status === PlanStatus.PENDING_QUOTATION) parsed.quotation += 1;
        if (plan.status === PlanStatus.PENDING_INVOICE) parsed.invoice += 1;
        if (plan.status === PlanStatus.PENDING_RECEIPT) parsed.receipt += 1;
      });
      return parsed;
    },
  });
  const { t } = useTranslation(["plans"]);
  return (
    <>
      <Col lg="12" className="mb-3">
        <p className="fw-bold">{t("pending_actions")}</p>
      </Col>
      <Col lg="3" className="mb-3">
        <SmallCard
          loading={isFetching}
          title={t("new_plans")}
          value={data?.new_pending}
        />
      </Col>
      <Col lg="3" className="mb-3">
        <SmallCard
          loading={isFetching}
          title={t("pending_quotation")}
          value={data?.quotation}
        />
      </Col>
      <Col lg="3" className="mb-3">
        <SmallCard
          loading={isFetching}
          title={t("pending_invoice")}
          value={data?.invoice}
        />
      </Col>
      <Col lg="3" className="mb-3">
        <SmallCard
          loading={isFetching}
          title={t("pending_receipt")}
          value={data?.receipt}
        />
      </Col>
    </>
  );
};

export default SchoolPendingActionsWidget;
