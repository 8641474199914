import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Checkbox from "components/inputs/Checkbox";
import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import ReserveTable from "components/widgets/ReserveTable";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import { Fragment, useState } from "react";
import { Accordion, Alert, Button, Col, FormGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import plans from "utils/api/plans";
import { reserveTransactions } from "utils/api/reserveTransactions";
import reserves from "utils/api/reserves";
import Role from "utils/enums/Role";
import { isRole } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import TransactionDetails from "./TransactionDetails";
import useAlert from "utils/hooks/alert";
import ReserveStatus from "utils/enums/ReserveStatus";
import { Form as BootstrapForm } from "react-bootstrap";

type Props = {};

const CreateReserveTransactions = (props: Props) => {
  const { id } = useParams();
  const { data: user } = useUser();
  const { t } = useTranslation(["schools", "common", "plans"]);
  const client = useQueryClient();
  const { confirmOrDeny } = useAlert();
  const [reserveReason, setReserveReason] = useState("");

  const { data: plan } = useQuery(["plans", id], () => plans.view(id), {
    initialData: {},
  });

  const proposeScheduleMutation = useMutation((values: any) => reserveTransactions.proposeSchedule(plan.id, values));

  const { data: userReserveData } = useQuery(
    ["reserves", plan.user?.id, { type: "user" }],
    () => reserves.findByEntityId(plan.user?.id, "user"),
    {
      enabled: !!plan.user?.id,
    }
  );

  const { data: schoolReserveData } = useQuery(
    ["reserves", plan.school?.id, { type: "school" }],
    () => reserves.findByEntityId(plan.school?.id, "school"),
    {
      enabled: !!plan.school?.id && !isRole(Role.FI, user),
    }
  );

  const { data: fiReserveData } = useQuery(
    ["reserves", plan.fi?.id, { type: "fi" }],
    () => reserves.findByEntityId(plan.fi?.id, "fi"),
    {
      enabled: !!plan.fi?.id,
    }
  );

  const planMutation = useMutation(
    (params: any) => {
      return plans.updateStatus(plan.id, params);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  const selectedGrades =
    plan.students?.map(
      (student: any) =>
        plan.school?.grades?.find(
          (grade: any) => grade.id == student.plan_grade_id
        )?.name
    ) ?? [];
  const selectedGradesCosts =
    plan.students?.map(
      (student: any) =>
        plan.school?.grades?.find(
          (grade: any) => grade.id == student.plan_grade_id
        )?.cost
    ) ?? [];

  async function submit(values: any) {
    const response = await reserveTransactions.prepareSchedule({
      planId: plan.id,
      amounts: values.transaction_amount,
      dates: values.transaction_date,
      isPaid: values.is_paid
    })
    const updatePlanResponse = await proposeScheduleMutation.mutateAsync({
      students: plan.students,
      extras: {
        [isRole(Role.FI, user) ? 'collection' : 'reserve']: response
      }
    });
  }

  if (proposeScheduleMutation.isSuccess) {
    return <Navigate replace to={"/plans/" + plan.id} />
  }

  function updateDates(formikBag: any) {
    const currentDate = DateTime.fromFormat(
      formikBag.values.starting_date,
      "yyyy-MM-dd"
    );
    for (
      let index = 0;
      index < formikBag.values.transaction_amount.length;
      index++
    ) {
      formikBag.setFieldValue(
        `transaction_date[${index}]`,
        currentDate.plus({ months: index }).toFormat("yyyy-MM-dd")
      );
    }
  }

  function updateAmount(formikBag: any) {
    for (
      let index = 0;
      index < formikBag.values.transaction_amount.length;
      index++
    ) {
      formikBag.setFieldValue(
        `transaction_amount[${index}]`,
        formikBag.values.initial_amount
      );
    }
  }

  function updateNumberOfTransactions(e: any, formikBag: any) {
    const numberOfTransactions = e.target.value;
    formikBag.setFieldValue(
      "transaction_amount",
      formikBag.values.transaction_amount.slice(0, numberOfTransactions)
    );
    formikBag.setFieldValue(
      "is_paid",
      formikBag.values.is_paid.slice(0, numberOfTransactions)
    );
    for (let index = 0; index < numberOfTransactions; index++) {
      formikBag.setFieldValue(
        `transaction_amount[${index}]`,
        formikBag.values.transaction_amount[index] ?? 0
      );
      formikBag.setFieldValue(
        `is_paid[${index}]`,
        formikBag.values.is_paid[index] ?? false
      );
    }

    formikBag.handleChange(e);
  }

  if (!plan.school?.payment_dates && !isRole(Role.FI, user)) {
    return (
      <Alert
        variant="danger"
        className="d-flex align-items-center justify-content-between"
      >
        <p>{t("no_payment_dates")}</p>
        <Link
          to={`/schools/${plan.school?.id}`}
          className="text-decoration-none"
        >
          <Button variant="link" size="sm">
            {t("add_payment_dates")}
          </Button>
        </Link>
      </Alert>
    );
  }


  function destroy() {
    confirmOrDeny({
      confirmButtonText: t("common:yes"),
      denyButtonText: t("common:no"),
      onConfirm: () => {
        const extras = plan.extras;
        delete extras.reserve;
        delete extras.reserve_comment;
        let params: any = {
          status: plan.status,
          reserve_status: ReserveStatus.REJECTED,
          extras
        }
        if (reserveReason) {
          params = {
            ...params,
            reserve_reason: reserveReason
          }
        }
        return planMutation.mutate(params)
      },
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        number_of_transactions: 1,
        transaction_amount: [],
        transaction_date: [],
        is_paid: [],
        starting_date: DateTime.now().toFormat("yyyy-MM-dd"),
      }}
      onSubmit={submit}
    >
      {(formikBag) => (
        <Form>
          <Row className="gy-3">
            <Accordion className="compact" defaultActiveKey="0">
              {
                <RoleBasedRenderer
                  admin={
                    <>
                      <Accordion.Item eventKey="school">
                        <Accordion.Header>School: {plan.school?.name}</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col lg={6} className="mb-2">
                              <p>Amount: {plan.amount?.toLocaleString()}</p>
                            </Col>
                            <Col lg={6} className="mb-2">
                              <p>
                                Number of transactions:{" "}
                                {plan.number_of_installments}
                              </p>
                            </Col>
                            <Col lg={6} className="mb-2">
                              {plan.school?.payment_dates?.length > 0 && (
                                <p>
                                  Payment Dates:{" "}
                                  {plan.school?.payment_dates
                                    ?.map((pd: any) => DateTime.fromISO(pd.date).toFormat("dd LLL"))
                                    .join(", ")}
                                </p>
                              )}
                            </Col>
                            <Col lg={6}>
                              <p>Selected Grades: {selectedGrades?.join(", ")}</p>
                            </Col>
                            <Col lg={6}>
                              <p>Costs Grades: {selectedGradesCosts?.join(", ")}</p>
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="reserve">
                        <Accordion.Header><h6>Reserves details</h6></Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col lg={6}>
                              <p>
                                User Reserve number: {userReserveData?.reserve_number}
                              </p>
                            </Col>
                            <Col lg={6}>
                              <p>User Balance: {userReserveData?.balance}</p>
                            </Col>

                            <RoleBasedRenderer
                              admin={
                                <>
                                  <Col lg={6}>
                                    <p>
                                      School Reserve number:{" "}
                                      {schoolReserveData?.reserve_number}
                                    </p>
                                  </Col>
                                  <Col lg={6}>
                                    <p>School Balance: {schoolReserveData?.balance}</p>
                                  </Col>
                                </>
                              }
                              fi={
                                <>
                                  <Col lg={6}>
                                    <p>
                                      Finanacial Institution Reserve number:{" "}
                                      {fiReserveData?.reserve_number}
                                    </p>
                                  </Col>
                                  <Col lg={6}>
                                    <p>
                                      Finanacial Institution Balance:{" "}
                                      {fiReserveData?.balance}
                                    </p>
                                  </Col>
                                </>
                              }
                            />
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                      {plan.extras?.reserve && (
                        <Accordion.Item eventKey="comments">
                          <Accordion.Header><h6>Current Reserve</h6></Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col lg={12}>
                                <ReserveTable reserve={plan.extras?.reserve} />
                              </Col>
                              {plan.extras?.reserve_comment &&
                                <Col lg={12}>
                                  <Alert variant="warning">
                                    <h6>School comments</h6>
                                    <p>{plan.extras?.reserve_comment}</p>
                                  </Alert>
                                </Col>
                              }
                              <Col lg={12} className="d-flex justify-content-end">
                                <BootstrapForm.Select
                                  name="reserve_status"
                                  onChange={(e: any) => {
                                    setReserveReason(e.target.value);
                                  }}
                                >
                                  <option value="Another application submitted">Heavy first installment</option>
                                  <option value="Another application submitted">Interested in another institution</option>
                                  <option value="Another application submitted">Decided to pay directly to institution</option>
                                  <option value="Another application submitted">Another application submitted</option>
                                  <option value="Similar to nursery payments">Similar to nursery payments</option>
                                  <option value="No answer">No answer</option>
                                  <option value="No reason">No reason</option>
                                </BootstrapForm.Select>
                                <Button
                                  onClick={destroy}
                                  variant="danger"
                                  type="button"
                                  size="lg"
                                >
                                  {t("plans:rejected")}
                                </Button>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                    </>
                  }
                  fi={
                    <Accordion.Item eventKey="school">
                      <Accordion.Header>School: {plan.school?.name}</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg={6} className="mb-2">
                            <h6>{`${t('plans:id')}: ${plan.public_id}`}</h6>
                          </Col>
                          <Col lg={6} className="mb-2">
                            <h6>{`${t('plans:name')}: ${plan.user?.name}`}</h6>
                          </Col>
                          <Col lg={6} className="mb-2">
                            {`${t("plans:amount")}: ${plan.amount?.toLocaleString()}`}
                          </Col>
                          <Col lg={6} className="mb-2">
                            <p>
                              {`${t("plans:installments")}: ${plan.number_of_installments}`}
                            </p>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  }

                />
              }
            </Accordion>

            <Col lg={12} className="mb-3">
              <h5>{t('common:transaction_plan_creator')}</h5>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Select
                className="w-100 mb-3"
                label={t('common:number_of_transactions')}
                name="number_of_transactions"
                onChange={(e: any) => updateNumberOfTransactions(e, formikBag)}
                options={Array.from({ length: 12 }, (_, i) => ({
                  label: i + 1,
                  value: i + 1,
                }))}
              />
            </Col>
            <RoleBasedRenderer
              fi={
                <Col lg={6}>
                  <Input label={t('common:custom_id')} name={`custom_id`} />
                </Col>
              }
            />
          </Row>
          <Row>
            <Col lg={3}>
              <Input
                className="w-100 mb-3"
                label={t('common:initial_amount')}
                name="initial_amount"
              />
            </Col>
            <Col lg={3} className="d-flex align-items-center">
              <Button
                onClick={() => updateAmount(formikBag)}
                type="button"
                className="w-100"
                size="lg"
              >
                {t('common:update_amounts')}
              </Button>
            </Col>
            <Col lg={3}>
              <Input
                className="w-100 mb-3"
                label={t('common:starting_date')}
                name="starting_date"
                type="date"
              />
            </Col>
            <Col lg={3} className="d-flex align-items-center">
              <Button
                onClick={() => updateDates(formikBag)}
                type="button"
                className="w-100"
                size="lg"
              >
                {t('common:update_dates')}
              </Button>
            </Col>

            {formikBag.values.transaction_amount.map((_, i) => (
              <Fragment key={i}>
                <Col lg={5} className="mb-3">
                  <Input
                    key={i}
                    label={t('common:transaction_amount_x', { x: i + 1 })}
                    name={`transaction_amount[${i}]`}
                    type="number"
                  />
                </Col>
                <Col lg={5} className="mb-3">
                  <Input
                    key={i}
                    label={t('common:transaction_date_x', { x: i + 1 })}
                    name={`transaction_date[${i}]`}
                    type="date"
                  />
                </Col>
                <Col lg={2} className="mb-3">
                  <FormGroup className="pt-0 pt-sm-4">
                    <Checkbox
                      label={t('common:is_paid')}
                      name={`is_paid[${i}]`}
                      type="checkbox"
                    />
                  </FormGroup>
                </Col>
              </Fragment>
            ))}
            <Col lg={12}>
              <RoleBasedRenderer
                admin={
                  <TransactionDetails
                    planId={plan.id}
                    isPaid={formikBag.values.is_paid}
                    amounts={formikBag.values.transaction_amount}
                    dates={formikBag.values.transaction_date}
                  />
                }
              />
            </Col>
            <Col lg={12}>
              <Button size="lg" className="w-100" type="submit">
                {user?.role_id === Role.FI ? t("common:create_installment_plan") : t("common:propose_reserve_plan")}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};



export default CreateReserveTransactions;
