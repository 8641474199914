import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  Area,
  AreaChart,
  ResponsiveContainer
} from "recharts";
import { LineDataItem } from "utils/types/dashboard";

type Props = {
  loading: boolean;
  title: string;
  subTitle?: string;
  text: string | React.ReactNode;
  data?: LineDataItem[];
  difference?: number;
};

const MediumLineCard = (props: Props) => {
  const { loading, title, data, text, difference, subTitle } = props;
  const { t } = useTranslation(["common"]);
  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "#fff",
          opacity: 0.5,
        }),
      }}
      spinner={<Loader active type="ball-pulse" />}
    >
      <Card>
        <Card.Body className="p-4">
          <Row>
            <Col
              lg={12}
              className="d-flex justify-content-between  mb-3"
            >
              <p className="fs-7 text-graycool fw-semibold">{title}</p>
              {subTitle && <p className="fbs-4 text-graycool fw-normal">{subTitle}</p>}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} className="fs-3 fw-bold text-dark my-3">
              <p className="fs-3 fw-bold text-dark">{text}</p>
            </Col>
            <Col lg={12} md={12} style={{ height: "70px" }}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart width={500} height={50} data={data}>
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke={(difference ?? 0) > 0 ? "#12B76A" : "#d92d20"}
                    fill={(difference ?? 0) > 0 ? "#ECFDF3" : "#d92d2026"}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Col>
            <Col lg={12} className="mt-1">
              {difference && (
                <p className="fs-7">
                  {difference > 0 ? (
                    <span className="text-success me-1">
                      <i className="bi bi-arrow-up me-1"></i>
                      {difference}%
                    </span>
                  ) : (
                    <span className="text-danger me-1">
                      <i className="bi bi-arrow-down me-1"></i>
                      {difference}%
                    </span>
                  )}
                  {t("growth_percentage")}
                </p>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </LoadingOverlay>
  );
};

export default MediumLineCard;
