import { AddonType } from "utils/types/Addon";
import client from "../api";

const base = "addons";

export function list(): Promise<AddonType[]> {
    return client.get(base);
}

export function view(id: string): Promise<AddonType> {
    return client.get(`${base}/${id}`);
}

export function store(values: AddonType): Promise<AddonType> {
    return client.post(base, values);
}

export function update(id: string, values: AddonType): Promise<AddonType> {
    return client.put(`${base}/${id}`, values);
}

export function destroy(id: string): Promise<AddonType> {
    return client.delete(`${base}/${id}`);
}

export function viewByCode(code: string): Promise<any> {
    return client.get(`${base}/code/${code}`);
}

export function generateLink(values: any): Promise<string> {
    return client.post(`${base}/generate-link`,
        values
    );
}

// customers by addon id
export function customers(id: string): Promise<any> {
    return client.get(`${base}/${id}/customers`);
}

export const addons = {
    list,
    view,
    store,
    update,
    destroy,
    viewByCode,
    generateLink,
    customers
};
