import React, { useEffect, useState } from "react";
import Role from "utils/enums/Role";
import { useUser } from "utils/hooks/auth";

type RoleBasedRendererProps = {
  user?: React.ReactNode;
  school?: React.ReactNode;
  fi?: React.ReactNode;
  moderator?: React.ReactNode;
  admin?: React.ReactNode;
};

const RoleBasedRenderer = (props: RoleBasedRendererProps | null) => {
  const [roleName, setRoleName] = useState("");
  const user = useUser({});

  useEffect(() => {
    if (user?.data?.role_id) {
      setRoleName(Role[user.data.role_id].toLowerCase());
    }

    // Cleanup function to avoid state updates on unmounted components
    return () => {
      setRoleName("");
    };
  }, [user]);

  return (
    <>
      {props &&
        props[roleName as keyof typeof props] &&
        props[roleName as keyof typeof props]}
    </>
  );
};

export default RoleBasedRenderer;
