import Amount from "components/widgets/Amount";
import TransactionStatusRenderer from "components/widgets/TransactionStatusRenderer";
import TransactionTypeRenderer from "components/widgets/TransactionTypeRenderer";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Card, Col, ProgressBar, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { transactions } from "utils/api/transactions";
import { breakPoints } from "utils/constants";
import TransactionStatus from "utils/enums/TransactionStatus";
import TransactionType from "utils/enums/TransactionType";
import {
  arabicValue,
  download,
  installmentColor,
  isRole,
  paymentIsDone,
} from "utils/functions/utilities";
import useAlert from "utils/hooks/alert";
import { usePaginate } from "utils/hooks/paginate";
import useWindowDimensions from "utils/hooks/window";

type Props = {
  reserve?: any;
};

const InstallmentsHistory = (props: Props) => {
  const { confirmOrDeny } = useAlert();
  const [filter, setFilter] = useState<any>({
    type: [TransactionType.RECHARGE, TransactionType.COLLECTION, TransactionType.PAYNOW],
  });
  const { width } = useWindowDimensions();
  const { data } = usePaginate({
    key: "history_transactions",
    api: transactions.list,
    filter,
    options: {
      placeholderData: {
        successful: [],
        total_paid: 0,
        total_amount: 0,
        progress: 0,
      },
      select: ({ data }: any) => {
        if (props.reserve?.transactions) data = props.reserve?.transactions;
        const successful = data?.filter((item: any) => paymentIsDone(item));
        const total_paid = successful?.reduce(
          (acc: number, item: any) => acc + item.amount,
          0
        );
        const total_amount = data?.reduce(
          (acc: number, item: any) => acc + item.amount,
          0
        );
        const progress = (total_paid / total_amount) * 100;
        return { successful, total_paid, total_amount, progress };
      },
      enabled: !props.reserve,
    },
  });

  const { t } = useTranslation(["common", "plans", "schools"]);

  function refund(id: number) {
    confirmOrDeny({
      title: t("refund_request"),
      text: t("refund_request_desc"),
      confirmButtonText: t("request_refund"),
      showDenyButton: false,
      onConfirm: () => {
        transactions.requestRefund(id);
      },
    });
  }

  async function getInvoice(id: number) {
    const data = await transactions.generatedDocument(id, "invoice");
    download(`Rubapay-${id}-invoice.pdf`, data);
  }
  return (
    <>
      {width > breakPoints.md ?
        <>
          <Col lg={12} className="mb-5">
            <Row className="text-dark">
              <Col lg={6} className="mb-2 mb-sm-3">
                <p className="fbs-4 mb-1">{t("common:total_paid")}</p>
                <p className="fs-4 fw-semibold">
                  {data?.total_paid?.toLocaleString() || 0}
                  <sup className="mx-1">{t("kd")}</sup>
                </p>
              </Col>

              <Col lg={6} className="mb-2 mb-sm-3 text-end">
                <p className="fbs-4 mb-1">{t("plans:total_amount")}</p>
                <p className="fs-4 fw-semibold">
                  {data?.total_amount?.toLocaleString()}{" "}
                  <sup className="">{t("kd")}</sup>
                </p>
              </Col>
              <Col lg={12}>
                <ProgressBar
                  now={data?.progress}
                  label={`${data?.progress.toFixed(0)}%`}
                  variant="success"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <Card.Body>
                <Table className="mb-0">
                  <thead>
                    <tr>
                      <th>{t("payment_date")}</th>
                      <th>{t("plans:amount")}</th>
                      <th>{t("plans:school")}</th>
                      <th>{t("plans:installments")}</th>
                      <th>{t("common:type")}</th>
                      <th>{t("plans:status")}</th>
                      <th>{t("plans:actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.successful?.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          {DateTime.fromISO(item.payment_date).toFormat(
                            "yyyy/MM/dd"
                          )}
                        </td>
                        <td className={"fw-bold " + installmentColor(item)}>
                          {item.amount}
                        </td>
                        <td className="fw-bold">
                          {arabicValue(item.plan.school, "name")}
                        </td>
                        <td className="fw-bold">
                          {t("x_out_of_y", {
                            x: item.order,
                            y: item.total_per_plan,
                          })}
                        </td>
                        <td>
                          <TransactionTypeRenderer type={item.type} />
                        </td>
                        <td className="fw-bold">
                          <TransactionStatusRenderer
                            {...item}
                          />
                        </td>
                        <td>
                          {(item.status === TransactionStatus.SUCCESS && item.type === TransactionType.RECHARGE) && (
                            <Button
                              className="p-0"
                              onClick={() => refund(item.id)}
                              variant="link"
                            >
                              <i className="bi bi-repeat"></i>
                            </Button>
                          )}
                          {item.status != TransactionStatus.MANUALLY_SETTLED &&
                            <Button
                              className="p-0 text-decoration-none fs-7 fw-semibold text-dark text-opacity-75"
                              onClick={() => getInvoice(item.id)}
                              variant="link"
                            >
                              <i className="bi bi-arrow-down-circle mx-1"></i>
                              {t("invoice")}
                            </Button>
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </> :
        data?.successful?.map((item: any) => (
          <Col
            onClick={() => getInvoice(item.id)}
            lg={12}
            className="border border-light rounded-2 mb-3 p-3"
          >
            <div className="d-flex justify-content-between">
              <h6 className={"fw-bold " + installmentColor(item)}>
                <Amount amount={item.amount} />
              </h6>
              <p className="fbs-4 fw-semibold">
                <TransactionStatusRenderer
                  {...item}
                />
              </p>
            </div>
            <p className="fs-7 fw-semibold text-graycool mb-2">
              {/* {arabicValue(item.school, "name")} */}
            </p>
            <div className="d-flex justify-content-start mb-2">
              <div className="fbs-4">
                {arabicValue(props.reserve?.school ?? item.plan.school, "name")}
                {/* <TransactionTypeRenderer status={item.type} /> */}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <p className="fbs-4 fw-semibold text-dark">
                {t("plans:installments")}
              </p>
              <p className="fbs-4 fw-semibold text-dark">{t("plans:plan_id")}</p>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <p className="fbs-4 text-gray">{t("x_out_of_y", {
                x: item.order,
                y: item.total_per_plan,
              })}</p>
              <p className="fbs-4 text-gray">{item?.plan?.public_id}</p>
            </div>
            {item.status != TransactionStatus.MANUALLY_SETTLED &&
              <div className="d-flex justify-content-end">
                <Button
                  className="p-0 text-decoration-none fs-7 fw-semibold text-dark text-opacity-75"
                  variant="link"
                >
                  <i className="bi bi-arrow-down-circle mx-1"></i>
                  {t("invoice")}
                </Button>
              </div>
            }
          </Col>
        ))}
    </>
  );
};

export default InstallmentsHistory;
