import React from 'react'
import { Route, Routes } from 'react-router-dom'
import QuickCreateAccount from './CreateAccount'
import QuickSetup from '.'

type Props = {}

const QuickRoutes = (props: Props) => {
  return (
    <Routes>
        <Route path="/" element={<QuickSetup />} />
    </Routes>
  )
}

export default QuickRoutes