import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import plans from "utils/api/plans";
import * as Yup from "yup";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Fragment, useState } from "react";
import { useUser } from "utils/hooks/auth";
import { DateTime } from "luxon";
import { dateAndTime } from "utils/functions/utilities";
import Icon from "components/widgets/Icon";

const AdminPlanNoteTaker = (props: any) => {
  const { plan } = props;
  const { t } = useTranslation(["plans", "auth", "common"]);
  const [preview, setPreview] = useState(true);
  const client = useQueryClient();
  const { data: user } = useUser();
  const noteMutation = useMutation(
    (note: any) => plans.note(plan.id, note ? JSON.stringify(note) : null),
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
      },
    }
  );

  function createNote(text: string) {
    return {
      text: text,
      user: user?.name,
      date: DateTime.now().toISO(),
    };
  }

  function deleteNote(currentNote: any, date: string) {
    if (currentNote === "") {
      noteMutation.mutate(null);
      return;
    }
    const newNotes = currentNote.filter((note: any) => note.date !== date);
    noteMutation.mutate(newNotes);
  }

  async function submit(values: any) {
    let note: any[] = [];
    if (plan.note != null) {
      note = JSON.parse(plan.note);
    }
    note.push(createNote(values.note));
    noteMutation.mutate(note);
  }
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title className="fs-6">{t("plans:notes")}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Formik
            onSubmit={submit}
            initialValues={{
              note: "",
            }}
            validationSchema={Yup.object().shape({
              note: Yup.string(),
            })}
          >
            <Form>
              <Row>
                <Col lg={12}>
                  <Input
                    className="mb-3"
                    label={t("write_new_note")}
                    name="note"
                    type="textarea"
                  />
                </Col>
                <Col lg={12} className="text-end mb-3">
                  <Button className="w-100" variant="light" type="submit">
                    {t("common:submit")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Formik>
          <NoteDisplay
            deleteNote={deleteNote}
            preview={preview}
            note={plan.note}
          />
        </Card.Body>
        <Card.Footer
          onClick={() => setPreview((prev) => !prev)}
          className="cursor-pointer"
        >
          {preview ? t("common:view_more") : t("common:view_less")}
        </Card.Footer>
      </Card>
    </>
  );
};

function NoteDisplay(props: any) {
  const { preview, note, deleteNote } = props;
  if (note == null) return <></>;
  let parsedNote: any = "";
  try {
    parsedNote = JSON.parse(note);
  } catch (e) {
    parsedNote = note;
  }
  return (
    <>
      {typeof parsedNote === "string" ? (
        <>
          <div className="fw-semibold fs-7 text-dark d-flex justify-content-between mb-2">
            <p>Old Note</p>
            <Icon
              className="cursor-pointer"
              onClick={() => deleteNote("", note.date)}
              name="trash"
            />
          </div>
          <p>{note}</p>
        </>
      ) : (
        <Row>
          {parsedNote
            .reverse()
            .slice(0, preview ? 3 : parsedNote.length)
            .map((note: any) => (
              <Fragment key={note.date}>
                <Col
                  lg={12}
                  className="fw-semibold fs-6 text-dark d-flex justify-content-between"
                >
                  <p>{note.user}</p>
                  <Icon
                    className="cursor-pointer"
                    onClick={() => deleteNote(parsedNote, note.date)}
                    name="trash"
                  />
                </Col>
                <Col lg={12} className="fs-7 text-gray">
                  {dateAndTime(note.date)}
                </Col>
                <Col lg={12} className="mt-2 fw-semibold fs-6  text-dark mb-4">
                  {note.text}
                </Col>
              </Fragment>
            ))}
        </Row>
      )}
    </>
  );
}

export default AdminPlanNoteTaker;
