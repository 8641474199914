import { useQuery } from "@tanstack/react-query";
import Checkbox from "components/inputs/Checkbox";
import Input from "components/inputs/Input";
import Radio from "components/inputs/Radio";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Accordion, Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import logs from "utils/api/logs";
import ReserveStatus from "utils/enums/ReserveStatus";
import { statusSpaceToUnderScore } from "utils/functions/utilities";

const LogsFilters = (props: any) => {
  const { filter, setFilter } = props;
  const [show, setShow] = useState(false);
  const { t } = useTranslation(["logs", "common"]);
  const { data: filterables } = useQuery(["filterables"], logs.filterables, {
    placeholderData: {
      action: [],
    },
  });

  function submit(values: any) {
    const newFilters: any = {
      search: values.search,
      action: [],
    };

    Object.entries(values.action).forEach(([action, value]) => {
      if (value) newFilters.action.push(action);
    });
    setFilter(newFilters);
    setShow(false);
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        search: filter.search,
        action: filterables?.action?.reduce?.(
          (acc: any, action: any) => ({
            ...acc,
            [action]: false,
          }),
          {}
        ),
      }}
      onSubmit={submit}
    >
      {(formikBag) => (
        <Form>
          <Row className="d-flex justify-content-end flex-wrap">
            <Col lg="6" className="position-relative mb-3 mb-lg-0">
              <i
                style={{ zIndex: 1 }}
                className="position-absolute mt-2 bi bi-search fs-5 text-black-50 ms-3 py-2"
              ></i>
              <Input
                className="ps-5 mt-lg-0"
                name="search"
                placeholder={t("common:search")}
              />
            </Col>
            <Col className="mb-3 mb-sm-0">
              <Button
                type="button"
                className="w-100 h-100 p-3"
                variant="outline-light"
                onClick={() => setShow(true)}
              >
                <i className="bi bi-filter me-2"></i>
                {t("common:filters")}
              </Button>
            </Col>
            <Col className="mb-3 mb-sm-0">
              <Button
                type="button"
                className="w-100 h-100 p-3 px-4"
                variant="primary"
                onClick={formikBag.submitForm}
              >
                {t("common:apply")}
              </Button>
            </Col>
            <Modal
              centered
              aria-labelledby="contained-modal-title-vcenter"
              show={show}
              onHide={() => setShow(false)}
              size="sm"
            >
              <Modal.Header
                className="d-flex justify-content-between"
                closeButton
              >
                <Modal.Title className="fs-6">
                  {t("common:filters")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Accordion className="mb-4">
                  {filterables?.status?.length > 0 && (
                    <Accordion.Item eventKey="0" className="p-2 border-0">
                      <Accordion.Header>{t("status")}</Accordion.Header>
                      <Accordion.Body>
                        {filterables?.status?.map((status: string) => (
                          <Checkbox
                            key={status}
                            name={`status[${status}]`}
                            label={t(statusSpaceToUnderScore(status))}
                          />
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {filterables?.branches?.length > 0 && (
                    <Accordion.Item eventKey="1" className="p-2 border-0">
                      <Accordion.Header>
                        {t("schools:branches")}
                      </Accordion.Header>
                      <Accordion.Body>
                        {filterables?.branches?.map((branch: any) => (
                          <Checkbox
                            key={branch.id}
                            name={`branches[${branch.id}]`}
                            label={branch.name}
                          />
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  <Accordion.Item eventKey="2" className="p-2 border-0">
                    <Accordion.Header>{t("action")}</Accordion.Header>
                    <Accordion.Body>
                      {filterables?.action?.map((action: string) => (
                        <Checkbox
                          key={action}
                          name={`action[${action}]`}
                          label={t(action)}
                        />
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Modal.Body>
            </Modal>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default LogsFilters;
