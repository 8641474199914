import { useQuery } from '@tanstack/react-query'
import Icon from 'components/widgets/Icon'
import React from 'react'
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { addons } from 'utils/api/addons'
import { usePaginate } from 'utils/hooks/paginate'

type Props = {}

const AddonCustomers = (props: Props) => {
    const { id } = useParams<{ id: string }>()
    const [filter, setFilter] = React.useState({})
    const { t } = useTranslation(["addons", "common", "auth"]);
    const { data: addonData } = useQuery(['addons', id], () => addons.view(id!), {
        enabled: !!id,
    });
    const paginate = usePaginate({
        key: 'addon-customers',
        api: () => addons.customers(id!),
        filter,
        options: {
            enabled: !!id,
        }
    })

    return (
        <>
            {/* breadcrumbs similar to the create page */}
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/addons">
                        <Icon name="bagPlus" /> {t("addons:all_addons")}
                    </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                    {id ? addonData?.name : t("addons:new_addon")}
                </BreadcrumbItem>
            </Breadcrumb>
            <Row>
                <Col lg={12}>
                    {/* table */}
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>{t("common:name")}</th>
                                <th>{t("common:email")}</th>
                                <th>{t('common:status')}</th>
                                <th>{addonData?.custom_requirement_en}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginate?.data?.data?.map((customer: any) => (
                                <tr key={customer.id}>
                                    <td>{customer.name}</td>
                                    <td>{customer.mobile}</td>
                                    <td>{customer.transaction?.status}</td>
                                    <td>{customer.custom_requirement}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>

    )
}

export default AddonCustomers