// AdminBranchesByType

import { useQuery } from '@tanstack/react-query';
import MediumPieCard from 'components/widgets/Dashboard/MediumPieCard';
import { capitalize } from 'lodash';
import { Card, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import dashboard from 'utils/api/dashboard';
import { graphColors } from 'utils/constants';

type Props = {}

const AdminBranchesByType = (props: Props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamsObject = Object.fromEntries(queryParams.entries());
    const { t } = useTranslation(["common"]);

    const { data, isFetching } = useQuery(
        ["dashboard", "admin-branches-by-type", queryParamsObject],
        () => dashboard.adminBranchesByType(queryParamsObject),
        {
            placeholderData: [],
        }
    );

    return (
        <>
            <Col lg={6} className="mb-3" >
                <MediumPieCard
                    loading={isFetching}
                    title={t("total_branches")}
                    data={data?.map((item: any, index: number) => ({
                        name: capitalize(item.name),
                        value: +item.value,
                        color: graphColors[index]
                    }))}
                />
            </Col>
        </>
    );
};

export default AdminBranchesByType;