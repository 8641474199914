import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

type SuccessProps = {
  message?: string;
  onConfirm?: any;
};
type ConfirmOrDenyProps = {
  title?: string;
  text?: string;
  onConfirm?: any;
  onDeny?: any;
  confirmButtonText?: string;
  denyButtonText?: string;
  showDenyButton?: boolean;
};
const MySwal = withReactContent(Swal);

const useAlert = () => {
  const { t } = useTranslation(["common"]);
  const confirmOrDeny = ({
    title,
    text,
    confirmButtonText,
    denyButtonText,
    onConfirm,
    onDeny,
    showDenyButton = true,
  }: ConfirmOrDenyProps) =>
    MySwal.fire({
      title: title ?? t("are_you_sure"),
      text: text ?? t("wont_be_able_to_revert"),
      showDenyButton,
      showCancelButton: true,
      confirmButtonText: confirmButtonText ?? "Save",
      denyButtonText: denyButtonText ?? `Don't save`,
      cancelButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm?.();
      } else if (result.isDenied) {
        onDeny?.();
      }
    });
  const success = ({ message, onConfirm }: SuccessProps) =>
    MySwal.fire({
      title: t("are_you_sure"),
      text: message ?? t("wont_be_able_to_revert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm?.();
      }
    });
  return { success, confirmOrDeny };
};

export default useAlert;
