import { useQuery } from "@tanstack/react-query";
import MediumBarCard from "components/widgets/Dashboard/MediumBarCard";
import MediumPieCard from "components/widgets/Dashboard/MediumPieCard";
import VerticalMediumPieCard from "components/widgets/Dashboard/VerticalMediumPieCard";
import Icon from "components/widgets/Icon";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import dashboard from "utils/api/dashboard";

type Props = {

};

const StudentsList = (props: Props) => {
  const { data, isFetching } = useQuery(
    ["onboarded-schools"],
    dashboard.studentsDemographics
  );
  const { t } = useTranslation(["common"]);

  return (
    <Container>
      <Row className="row-gap-3">
        <Col lg={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <Icon name="house" size={20} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("plans:students")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col lg={12}>
          <h4 className="text-dark fw-semibold">{t("plans:students")}</h4>
          <hr style={{ height: "1px" }} className="bg-light opacity-50" />
        </Col>

        <Col lg={4}>
          <VerticalMediumPieCard
            loading={isFetching}
            title={t("common:top_3_nationalities")}
            data={data?.nationalities}
          />
        </Col>
        <Col lg={4}>
          <VerticalMediumPieCard
            loading={isFetching}
            title={t("common:ruba_enrollee_gender_distribution")}
            data={data?.genders}
          />
        </Col>

        <Col lg={4}>
          <MediumBarCard
            loading={isFetching}
            title={t("common:top_grades")}
            data={data?.grades}
          />
        </Col>
      </Row>

    </Container>
  );
};


export default StudentsList;
