import { configureAuth } from "react-query-auth";

import {
  AuthResponse,
  getUserProfile,
  loginWithUidAndPassword,
  logout,
  registerWithUidAndPassword,
} from "../api/auth";
import { storage } from "../storage";

export type LoginCredentials = {
  mobile: string;
  password: string;
};

export type RegisterCredentials = {
  mobile: string;
  password: string;
};

export async function handleUserResponse(data: AuthResponse) {
  const {
    jwt: { token },
    user,
  } = data;
  storage.setToken(`Bearer ${token}`);
  return user;
}

async function userFn() {
  const token = await storage.getToken();
  if (!token) return null;
  const res = await getUserProfile();
  return res?.user ?? null;
}

async function loginFn(data: LoginCredentials) {
  const response = await loginWithUidAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data: RegisterCredentials) {
  const response = await registerWithUidAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  const response = await logout();
  if (response) storage.clearToken();
}

export const { useUser, useLogin, useRegister, useLogout, AuthLoader } =
  configureAuth({
    userFn,
    loginFn,
    registerFn,
    logoutFn,
  });
