import client from "../api";

export function list() {
  return client.get("files") as any;
}

export function view(id?: string):Promise<any> {
  return client.get(`files/${id}`);
}

export async function destroy(id: number) {
  return client.delete(`files/${id}`) as any;
}


export function download(url:string) {
  var fileExt = url?.split(".").pop();
  client.get(url, { responseType: "blob" }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response as unknown as BlobPart]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file."+fileExt); //or any other extension
    document.body.appendChild(link);
    link.click();
  }).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });
;
}

const files = { list, view,download,destroy };

export default files;
