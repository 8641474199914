import React from "react";
import { Route, Routes } from "react-router-dom";
import InstallmentsList from "./List";
// import CreatePlan from "./Create";
// import NotListedSchools from "./NotListedSchools";
// import ViewPlan from "./View";

const InstallmentsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<InstallmentsList />} />
      {/* <Route path="/:id/scheduler" element={<CreateInstallments />} /> */}
      {/* <Route path="/not-listed" element={<NotListedSchools />} /> */}
      {/* <Route path="/:id/edit" element={<CreatePlan />} /> */}
      {/* <Route path="/:id" element={<ViewPlan />} /> */}
    </Routes>
  );
};

export default InstallmentsRoutes;
