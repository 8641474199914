import client from "../api";

export function list(params: object | undefined = undefined):Promise<any> {
  return client.get("students", { params });
}

export function update(id:number,values: any) {
  return client.put(`students/${id}`, values) as any;
}

export function store(values: any) {
  return client.post("students", values) as any;
}

export async function destroy(id: number) {
  return client.delete(`students/${id}`) as any;
}

const students = {
  list,
  store,
  update,
  destroy
};

export default students;
