import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import React, { useState } from "react";

type Props = {
  filter: any;
  setFilter: any;
};

const Filter = (props: Props) => {
  const { filter, setFilter } = props;
  
  function submit(values: any) {
    setFilter(values);
  }
  return (
    <Formik initialValues={{ search: "" }} onSubmit={submit}>
      <Form>
        <Input name="search" />
      </Form>
    </Formik>
  );
};

export default Filter;
