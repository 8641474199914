import Login from "containers/auth/Login";
import Layout from "containers/common/Layout";
import { LayoutProvider } from "containers/common/Layout/Context";
import { Outlet } from "react-router-dom";
import { AuthLoader } from "utils/hooks/auth";

const PrivateRoutes = () => {
  return (
    <AuthLoader
      renderLoading={() => <div>Loading ...</div>}
      renderUnauthenticated={() => <Login />}
    >
      <LayoutProvider>
        <Layout>
          <Outlet />
        </Layout>
      </LayoutProvider>
    </AuthLoader>
  );
};

export default PrivateRoutes;
