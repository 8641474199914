import { useMutation, useQueryClient } from '@tanstack/react-query';
import Checkbox from 'components/inputs/Checkbox';
import Input from 'components/inputs/Input';
import Amount from 'components/widgets/Amount';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { Fragment, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import plans from 'utils/api/plans';
import { IUpdateProposedSchedule, reserveTransactions } from 'utils/api/reserveTransactions';
import PlanStatus from 'utils/enums/PlanStatus';

type Props = {
    plan: any
}

const PlanPendingApproval = (props: Props) => {
    const { plan } = props;
    const { t } = useTranslation(["common", "plans"]);
    const [show, setShow] = useState(false);
    const [scheduleUpdated, setScheduleUpdated] = useState(false);
    const client = useQueryClient();
    const updatePlan = useMutation((values: any) => plans.updateStatus(plan.id, values));

    const updateProposedScheduleMutation = useMutation(
        (data: IUpdateProposedSchedule) => {
            return reserveTransactions.updateProposedSchedule(plan.id, data)
        },
    );

    function decide(decision: 'accept' | 'reject', reserve_comment: string = '') {
        updateProposedScheduleMutation.mutate({ decision, reserve_comment },
            {
                onSuccess: (data: any) => {
                    client.invalidateQueries(['plan']);
                    if (data.success) {
                        toast.success(data.message);
                    } else {
                        toast.error(data.message);
                    }
                    setShow(false)
                }
            }
        );
    }

    async function updateSchedule(values: any) {
        const response = await reserveTransactions.prepareSchedule(
            {
                planId: plan.id,
                amounts: values.transactions.map((t: any) => t.amount),
                dates: plan.extras?.paynow.map((p: any) => p.paymentDates[0].date),
                isPaid: values.transactions.map((t: any) => !!t.isPaid),
            }
        )
        await plans.updateAmount(plan.id, { amount: values.amount });
        await updatePlan.mutateAsync({
            status: plan.status,
            extras: {
                ...plan.extras,
                paynow: response,
            }
        });
        client.invalidateQueries(['plan']);
        setScheduleUpdated(false);
    }

    return (
        <>
            {plan.status == PlanStatus.PENDING_SCHOOL_APPROVAL && <>
                <Button
                    variant="primary"
                    onClick={() => setShow(true)}
                    size="lg"
                    className="w-100"
                    type="button"
                >
                    {t("common:action_required")}
                </Button>
                <Modal
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    show={show}
                    onHide={() => setShow(false)}
                    size="lg"
                >
                    <Modal.Header
                        className="d-flex justify-content-between"
                        closeButton
                    >
                        <Modal.Title className="fs-6">
                            {t("plans:requested_to_pay_per_school_schedule")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                amount: plan.amount,
                                transactions: plan.extras?.paynow?.map((p: any) => p.paymentDates[0])
                            }}
                            onSubmit={updateSchedule}
                        >
                            {({ values, handleSubmit }) => (
                                <Form onSubmit={handleSubmit} onChange={() => setScheduleUpdated(true)}>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>{t("date")}</th>
                                                <th>{t("plans:amount")}</th>
                                                <th>{t("status")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {plan.extras?.paynow?.map((tuition: any, index: number) => (
                                                <Fragment key={tuition.date}>
                                                    {tuition.paymentDates?.map((paymentDate: any, ind: number) => (
                                                        <Fragment key={paymentDate.date}>
                                                            <tr >
                                                                <td>
                                                                    {DateTime.fromISO(paymentDate.date).toLocaleString({
                                                                        month: "short",
                                                                        day: "numeric",
                                                                        year: "numeric",
                                                                    })}
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <Input size="sm" name={`transactions[${index}].amount`} type="number" value={paymentDate.amount} />
                                                                        <p className='mx-1'>KD</p>
                                                                    </div>
                                                                    {/* <Amount amount={paymentDate.amount} /> */}
                                                                </td>
                                                                <td>
                                                                    <Checkbox
                                                                        name={`transactions[${index}].isPaid`}
                                                                        label={values.transactions[index].isPaid ? t("paid") : t("pending")}
                                                                    />
                                                                    {/* <div className={`badge bg-${paymentDate.isPaid ? 'success' : 'gray'}`}>
                                                                        {paymentDate.isPaid ? t("paid") : t("pending")}
                                                                    </div> */}
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    ))}
                                                </Fragment>
                                            ))}
                                        </tbody>
                                    </Table>

                                    <div className="d-flex justify-content-between mt-3">
                                        <Button
                                            type="button"
                                            onClick={() => decide('reject')}
                                            variant="danger">
                                            {t("plans:reject")}
                                        </Button>
                                        <div>
                                            {scheduleUpdated &&
                                                <Button
                                                    type="submit"
                                                    variant="primary" className="me-2">
                                                    {t("update_schedule")}
                                                </Button>
                                            }
                                            <Button
                                                type="button"
                                                onClick={() => decide('accept')}
                                                variant="primary" className="me-2">
                                                {t("plans:approve")}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>
            </>
            }
        </>
    )
}

export default PlanPendingApproval