import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import plans from "utils/api/plans";
import PlanModeratorActionsForm from "./ModeratorActions/PlanPendingModeratorActions";
import ModeratorPlanUpdateStatus from "./ModeratorActions/UpdateStatus";
import PlanStatus from "utils/enums/PlanStatus";

const PlanModeratorActions = (props: { plan: any }) => {
  const { plan } = props;
  const client = useQueryClient();

  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  return (
    <Row className="gy-3">
      {plan.status == PlanStatus.PENDING_RUBA && (
        <Col lg={12}>
          <PlanModeratorActionsForm plan={plan} planMutation={planMutation} />
        </Col>
      )}
      {/* <Col lg={12}>
        <ModeratorPlanUpdateStatus plan={plan} />
      </Col> */}
    </Row>
  );
};

export default PlanModeratorActions;
