import { useMutation, useQueryClient } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { plans } from "utils/api/plans";
import FileType from "utils/enums/FileType";
import PlanStatus from "utils/enums/PlanStatus";
import * as Yup from "yup";

const PlanPendingReceipt = (props: any) => {
  const { plan } = props;
  const { t } = useTranslation(["common", "plans"]);
  const client = useQueryClient();
  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  function submit(values: any) {
    const { status, ...files } = values;
    plans.upload({
      planId: plan.id,
      files,
    });
    planMutation.mutate({ plan, values });
  }

  let status = PlanStatus.ACTIVE;
  if (plan.ruba_cut > 0) status = PlanStatus.PENDING_RUBA_TID;
  if (plan.ruba_cut < 0) status = PlanStatus.PENDING_RUBA_SCHOOL_TID;

  return (
    <>
      {PlanStatus.PENDING_RECEIPT == plan.status && (
        <Formik
          onSubmit={submit}
          initialValues={{
            status,
            [FileType.SIGNED_RECEIPT]: "",
          }}
          validationSchema={Yup.object().shape({
            [FileType.SIGNED_RECEIPT]: Yup.string().required(
              t("document_required")
            ),
          })}
        >
          <Form>
            <Row>
              <Col md={12}>
                <Input
                  type="file"
                  id="receipt"
                  name={FileType.SIGNED_RECEIPT}
                  label={t("signed_receipt")}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-3" md="12">
                <Button type="submit" className="w-100" size="lg">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default PlanPendingReceipt;
